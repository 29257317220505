import { useDispatch, useSelector } from "react-redux";
import useGenerationEndpoint from "../../input/useGenerationEndpoint";
import { BoltSettings } from "../v4/ImageSettings";
import useCurrentWorkspace from "../../../hooks/useCurrrentWorkspace";
import { partialUpdateStatePayload, updateImageStrength } from "../../../reducers/formReducer";
import { V4StyledSlider as ValuedSlider } from '../../base/ValuedSlider';
import { Tooltip } from 'react-tippy';
import { ReactComponent as InfoIcon } from "../../../assets/InfoIconGray.svg";

const StrengthTitles = {
	1: "Loosely similar",
	2: "Somewhat Similar",
	3: "Kinda Similar",
	4: "Fairly Similar",
	5: "Very Similar",
	6: "Close Match",
	7: "Strict Match",
}

const ColorTitles = {
	1: "Light Color Match",
	2: "Weak Color Match",
	3: "Mild Color Match",
	4: "Medium Color Match",
	5: "Solid Color Match",
	6: "Strong Color Match",
	7: "Exact Color Match",
}

const ControlnetSettings = (props) => {
	const dispatch = useDispatch()
	const strengthEnabled = useSelector((state) => state.form.payload.cnSwitch)

	const depthEnabled = useSelector((state) => state.form.payload.depthSwitch)
	const cannyEnabled = useSelector((state) => state.form.payload.cannySwitch)
	const imageStrength = useSelector((state) => state.form.payload.imageStrength)

	const handleDepthEnabled = (val) => {
		dispatch(partialUpdateStatePayload({
			depthSwitch: val,
			cannySwitch: !val,
			imageStrength: val ? 4 : 3,
		}))
	}

	const handleCannyEnabled = (val) => {
		dispatch(partialUpdateStatePayload({
			cannySwitch: val,
			depthSwitch: !val,
			imageStrength: val ? 3 : 4,
		}))
	}

	return (
		<div className="flex justify-between gap-2 items-center">
			<div className="text-xs text-icon-text-color flex gap-2 items-center">
				<span className="text-xs font-bold bg-app-bg-gray rounded-md w-6 h-6 flex items-center justify-center">
					{imageStrength}
				</span>
				<span>
					{StrengthTitles[imageStrength]}
				</span>
			</div>
			<div
				className={`flex flex-row gap-4 ${
					strengthEnabled ? '' : 'opacity-5'
				}`}
			>
				<label
					className='flex items-center justify-center gap-1 cursor-pointer'
				>
					<input
						className='form-checkbox h-[13px] w-[13px] rounded-[4px] border-[#4A525B] border-[1px] bg-transparent text-custom-green focus:ring-transparent focus:ring-offset-0 '
						type='checkbox'
						checked={cannyEnabled}
						onChange={(e) => handleCannyEnabled(e.target.checked)}
						disabled={!strengthEnabled}
					/>
					<span className='text-xs font-inter text-white font-semibold select-none'>
						Details
					</span>
				</label>
				<label
					className='flex items-center justify-center gap-1 cursor-pointer'
				>
					<input
						className='form-checkbox h-[13px] w-[13px] rounded-[4px] border-[#4A525B] border-[1px] bg-transparent text-custom-green focus:ring-transparent focus:ring-offset-0 '
						type='checkbox'
						checked={depthEnabled}
						onChange={(e) => handleDepthEnabled(e.target.checked)}
						disabled={!strengthEnabled}
					/>
					<span className='text-xs font-inter text-white font-semibold select-none'>
						Depth
					</span>
				</label>
			</div>
		</div>
	)
}

export const FxSettings = (props) => {
	const { wideLayout } = props;
  const dispatch = useDispatch();

  const { workspace } = useCurrentWorkspace();

	const { generationStyle } = useGenerationEndpoint()

	// Image Strength
	const strengthEnabled = useSelector((state) => state.form.payload.cnSwitch)
	const imageStrength = useSelector((state) => state.form.payload.imageStrength)

	// Image colors
	const useImageColors = useSelector((state) => state.form.payload.useImageColors);
	const colorInfluence = useSelector((state) => state.form.payload.k_sampler_denoise_value);

	// Image Strength
	const setImageStrength = (val) => {
		dispatch(updateImageStrength(val))
	}

	const handleStrengthEnabled = (val) => {
		console.log("Updating switch")
		dispatch(partialUpdateStatePayload({
			cnSwitch: val,
			...(!val && !useImageColors && {
				useImageColors: true,
			})
		}))
	}

	// Image colors
	const handleUseImageColors = (val) => {
		dispatch(partialUpdateStatePayload({
			useImageColors: val,
			...(!val && !strengthEnabled && {
				cnSwitch: true,
			})
		}))
	}

	const handleColorInfluence = (val) => {
		dispatch(partialUpdateStatePayload({
			k_sampler_denoise_value: val
		}))
	}

	// Background Separation
	const useBackgroundSeparation = useSelector((state) => state.form.payload.useBackgroundSeparation);
	const backgroundSeparation = useSelector((state) => state.form.payload.backgroundSeparation);

	const handleBackgroundSeparation = (val) => {
		dispatch(partialUpdateStatePayload({
			backgroundSeparation: val
		}))
	}

	const handleUseBackgroundSeparation = (val) => {
		dispatch(partialUpdateStatePayload({
			useBackgroundSeparation: val
		}))
	}

  return (
    <div
      className={`flex flex-col gap-4 w-full`}
    >
			<div className={` `}>
				<ValuedSlider 
					label="Image Strength"
					title="Adjust the strength of form and depth matching"
					values={[...Array(7)].map((_, i) => ({ name: `${i + 1}`, value: i + 1}))}
					value={imageStrength}
					onChange={setImageStrength}

					disabled={!strengthEnabled}
					// disablable={!wideLayout}
					disablable
					onDisabled={(e) => handleStrengthEnabled(!e)}

					wideLayout={wideLayout}

					otherControls={wideLayout && <ControlnetSettings /> }
				/>
				{!wideLayout && (
					<ControlnetSettings /> 
				)}
			</div>
			<div>
				<ValuedSlider 
					label="Use Image Colors"
					title="Adjust the strength of original image color matching"
					values={[...Array(7)].map((_, i) => ({ name: `${i + 1}`, value: i + 1}))}
					value={colorInfluence}
					onChange={handleColorInfluence}

					disablable
					disabled={!useImageColors}
					onDisabled={(e) => handleUseImageColors(!e)} 

					wideLayout={wideLayout}
				/>
				<div className="text-xs text-icon-text-color flex gap-2 items-center">
					<span className="text-xs font-bold bg-app-bg-gray rounded-md w-6 h-6 flex items-center justify-center">
						{colorInfluence}
					</span>
					<span>
						{ColorTitles[colorInfluence]}
					</span>
				</div>
			</div>

    </div>
  );
};

const ImageSettings = (props) => {
	const { wideLayout } = props;
	const { generationStyle } = useGenerationEndpoint()
	return (
		<>
			{(
				generationStyle === 'style-transfer'
			) && (
				<BoltSettings wideLayout={wideLayout} />
			)}
			{(
				generationStyle === 'fx'
			) && (
				<FxSettings wideLayout={wideLayout} />
			)}
		</>
	)
}

export default ImageSettings;
