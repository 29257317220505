import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProtectedRoute from '../components/ProtectedRoute';
import { ReactComponent as Logo } from "../assets/logo.svg";
import questionnaire from "../assets/questionnaire.png";
import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { QuestionnairePages } from "../data/questionnaire";
import effect from "../assets/effect.png";
import {
  loadQuestionnaireAnswers,
  submitQuestionnaireAnswers,
  updateUserAgreements,
} from "../reducers/userReducer";
import { FormControlLabel, TextField, Checkbox } from "@material-ui/core";
import { styled } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";
import SpinnerLoader from "../components/base/SpinnerLoader";

const CircleCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked": {
    color: "#3DD598",
  },
  "& .MuiSvgIcon-root": {
    borderRadius: "50%",
    border: "2px solid white",
    width: 24,
    height: 24,
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    backgroundColor: "#3DD598",
    border: "2px solid #3DD598",
  },
}));

const QuestionnairePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
	const store = useStore()
  const questionnaireData = useSelector(state => state.userSlice.questionnaireData);
  const openQuestion = useSelector(
    (state) => state.userSlice.showQuestionnaireModal
  );
  const user = useSelector((state) => state.userSlice.user);
  const [index, setIndex] = useState(0);
  const [values, setValues] = useState({ discovery: questionnaireData?.discovery, occupation: questionnaireData?.occupation, usageExplanation: questionnaireData?.usageExplanation });
  const [error, setError] = useState();
  const [loaded, setLoaded] = useState(false);
  const [occupation, setOccupation] = useState("");
  const [width, setWidth] = useState("");
  const [resize, setResize] = useState("");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const inputRef = useRef();

  const currentQuestion = useMemo(() => QuestionnairePages[index], [index]);

  const [agreements, setAgreements] = useState({
    tos_accepted: user?.tos_accepted,
    marketing_emails: user?.marketing.emailsEnabled,
  });

  const loadPreviousAnswers = async () => {
    setLoaded(false);
    try {
      await dispatch(loadQuestionnaireAnswers()).unwrap();
      setIndex(store.getState().userSlice.showQuestionnaireModal ? 0 : 2);
      setLoaded(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event) => {
    setOccupation(event.target.value);
  };

  const getUsername = () => {
    if (user?.name)
      return user?.name
        ?.split(" ")
        .map((slice) => slice[0])
        .join("");
    if (user?.discordUsername) return user.discordUsername.charAt(0);

    return "A";
  };

  const next = async () => {
    if (index === 2) {
      if (!agreements.tos_accepted) {
        setError("You need to accept the terms of service.");
        return;
      }
    } else {
      if (!values[currentQuestion.key]) {
        setError("Please select an option to continue.");
        return;
      }
    }

    setError();
    let res = {
      ...values,
    };
    if (res[currentQuestion.key] === "-1")
      res[currentQuestion.key] = inputRef.current.value;
    if (index + 1 < QuestionnairePages.length) {
      setIndex((ind) => ind + 1);
      // setInputVal('');
      setValues(res);
      let nextQuestion = QuestionnairePages[index + 1];
      let nextAnswer = res[nextQuestion.key];
      if (
        nextAnswer &&
        nextQuestion.options.map((item) => item.title).indexOf(nextAnswer) < 0
      ) {
        setValues({
          ...res,
          [nextQuestion.key]: "-1",
        });

        inputRef.current.value = nextAnswer;
      } else {
        setValues(res);
        inputRef.current.value = "";
      }
    } else {
      try {
				setLoaded(false)
        try {
          await dispatch(submitQuestionnaireAnswers({...res})).unwrap();
          // await dispatch(submitQuestionnaireAnswers({})).unwrap();
          await dispatch(updateUserAgreements(agreements)).unwrap();
          // await dispatch(updateUserAgreements({tos_accepted: false})).unwrap();
          await dispatch(loadQuestionnaireAnswers()).unwrap();
          if (agreements.tos_accepted) {
            navigate("/?loggedIn=true");
          }
        } catch(e) {
          setError('Something went wrong. Please try again.')
        } finally {
					setLoaded(true)
				}
      } catch (e) {
        console.log(e);
        toast("Failed to submit the answers", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          autoClose: 2000,
        });
      }
    }
  };

  const prev = async () => {
    if (user) setError();
    let res = {
      ...values,
    };
    if (res[currentQuestion.key] === "-1")
      res[currentQuestion.key] = inputRef.current.value;
    if (index > 0) {
      setIndex((ind) => ind - 1);
      let previousQuestion = QuestionnairePages[index - 1];
      let previousAnswer = res[previousQuestion.key];
      if (
        previousAnswer &&
        previousQuestion.options
          .map((item) => item.title)
          .indexOf(previousAnswer) < 0
      ) {
        setValues({
          ...res,
          [previousQuestion.key]: "-1",
        });

        inputRef.current.value = previousAnswer;
      } else {
        setValues(res);
        inputRef.current.value = "";
      }
    }
  };

  useEffect(() => {
    if (user) loadPreviousAnswers();
  }, [user]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWidth(window.innerWidth < 1440 ? false : true);
      setResize(window.innerWidth <= 768 ? false : true);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <ProtectedRoute>
      <div className="flex w-[100%] relative bg-app-black justify-around overflow-hidden">
        <div className="font-bold text-xl text-white flex gap-2 top-4 left-3 top-3 absolute">
          <Logo className="mr-[3px]" />
          <span className="hidden lg:flex mr-[5px] font-poppins font-black">
            Logo Diffusion
          </span>
        </div>
        {!loaded ? (
          <div className="relative w-full flex items-center justify-center">
            <div className="relative max-w-[80%] w-[300px] aspect-square">
              <SpinnerLoader
                timed={false}
                isLoading
                classNames={{ backdrop: "bg-opacity-0" }}
              />
            </div>
          </div>
        ) : (
          <>
            <div
              className={`col-lg-6 col-md-6 w-[100%] flex`}
              style={{ flexDirection: "column", justifyContent: "center" }}
            >
              <div
                style={{
                  paddingRight: !resize
                    ? "1rem"
                    : !width
                    ? "calc((100% - 510px) / 2)"
                    : "5rem",
                  paddingLeft: !resize
                    ? "1rem"
                    : !width
                    ? "calc((100% - 510px) / 2)"
                    : "5rem",
                  boxSizing: "border-box", // Ensures padding doesn't affect the element's width
                }}
              >
								{openQuestion ? (
									<>
										<div
											className="flex flex-row items-center gap-3"
											style={{ justifyContent: "space-between", paddingTop: "74px" }}
										>
											<h1
												className="text-white text-4xl font-bold"
												style={{
													fontSize: "clamp(2.5rem, 3vw, 3.65rem)",
													width: "clamp(428px, 34vw, 590px)",
													fontSizeAdjust: 0.5,
													height: "auto",
													lineHeight: "1.1",
												}}
											>
												{currentQuestion.title}
											</h1>
											<span
												className="text-carousel-next-count mr-1 rounded-md bg-[#3DD598]/5 border-[#3DD598]/15"
												style={{
													border: "1.5px solid #3DD59826",
													color: "#3DD598",
													display: "flex",
													justifyContent: "center",
													borderRadius: "32px",
													width: "clamp(64px, 4.5vw, 80px)",
													fontSize: "clamp(14px, 1.04vw, 20px)",
													height: "clamp(28px, 1.8vw, 48px)",
													alignItems: "center",
												}}
											>
												{index + 1} / {QuestionnairePages.length}
											</span>
										</div>

										<RadioGroup
											className="grid grid-cols-2 grid-rows-2 gap-4"
											style={{ paddingTop: "64px" }}
											value={values[currentQuestion.key] || ""}
											onChange={(val) => {
												if (val === "-1") {
													inputRef.current.focus();
												}
												setValues((old) => ({
													...old,
													[currentQuestion.key]: val,
												}));
											}}
										>
											{currentQuestion.options.map((resp) => {
												const Icon = resp.icon;
												return (
													<RadioGroup.Option
														className={`flex flex-col rounded-md bg-app-bg-gray ui-checked:outline-[2px] ui-checked:[outline-style:solid] ui-checked:outline-app-green transition-all duration-300 cursor-pointer`}
														style={{
															backgroundColor:
																resp.title === "Other" ? "#282828" : "transparent",
															borderRadius: "20px",
															border: "1px solid #282828",
															padding:
																"clamp(22px, 1.55vw, 42px) clamp(24px, 1.7vw, 44px)",
															boxShadow: "0px 5px 1.5px -4px #080808",
														}}
														key={resp.title}
														value={resp.title}
													>
														<Icon
															className="text-white"
															style={{
																width: "clamp(24px, 1.8vw, 36px)",
																height: "clamp(24px, 1.8vw, 36px)",
																color: "#A8A8A8",
															}}
														/>
														<span
															className="text-white text-base"
															id="textTitle"
															style={{
																fontSize: "clamp(14px, 1.04vw, 20px)",
																marginTop: "clamp(25px, 1.73vw, 45px)",
																color: "#A8A8A8",
															}}
														>
															{resp.title}
														</span>
														<span
															className="w-11/12 text-sm text-modal-description"
															style={{
																fontSize: "clamp(10px, 0.73vw, 14px)",
																color: "#636363",
															}}
														>
															{resp.subtitle}
														</span>
													</RadioGroup.Option>
												);
											})}
										</RadioGroup>
									</>
								) : (<></>)}

                <span className="text-red-600">{error}</span>

                {index <= 0 ? (
                  <div style={{ paddingTop: "65px" }}>
                    <TextField
                      id="occupation"
                      label="Enter your occupation"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        style: { color: "white !important" },
                      }}
                      InputProps={{
                        style: {
                          color: "white",
                          border: "2px solid #282828",
                          borderRadius: "16px",
                        },
                      }}
                      type="text"
                      value={occupation}
                      onChange={handleChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                {index === 2 && (
                  <div className={`flex flex-col`} style={{ paddingTop: "39px", }}>
                    <FormControlLabel
                      control={
                        <CircleCheckbox
                          id="tos"
                          value="tos"
                          checked={agreements.tos_accepted}
                          onChange={(e) =>
                            setAgreements((a) => ({
                              ...a,
                              tos_accepted: e.target.checked,
                            }))
                          }
                          icon={
                            <span className="rounded-full w-6 h-6 border-2 border-white" />
                          }
                          checkedIcon={
                            <span className="rounded-full w-6 h-6 bg-[#3DD598] flex items-center justify-center">
                              <CheckIcon className="text-white" />
                            </span>
                          }
                        />
                      }
                      label={
                        <span className="text-[12px] text-[#A8A8A8]">
                          I have read and agree to the{" "}
                          <a
                            href="/terms"
                            className="underline"
                            style={{ fontSize: "12px", color: "#A8A8A8" }}
                          >
                            Terms and Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href="/privacy"
                            className="underline"
                            style={{ fontSize: "12px", color: "#A8A8A8" }}
                          >
                            Privacy Policy
                          </a>
                        </span>
                      }
                      className="text-white text-sm"
                    />
                    <FormControlLabel
                      control={
                        <CircleCheckbox
                          id="marketing"
                          value="marketing"
                          checked={agreements.marketing_emails}
                          onChange={(e) =>
                            setAgreements((a) => ({
                              ...a,
                              marketing_emails: e.target.checked,
                            }))
                          }
                          icon={
                            <span className="rounded-full w-6 h-6 border-2 border-white" />
                          }
                          checkedIcon={
                            <span className="rounded-full w-6 h-6 bg-[#3DD598] flex items-center justify-center">
                              <CheckIcon className="text-white" />
                            </span>
                          }
                        />
                      }
                      label={
                        <span className="text-[12px] text-[#A8A8A8]">
                          I'd like to receive product updates, news, and marketing
                          communications from Logo Diffusion.
                        </span>
                      }
                      className="text-white text-sm"
                    />
                  </div>
                )}

                <div
                  className="flex flex-row gap-2 justify-around"
                  style={{
                    paddingTop: "74px",
                    paddingBottom: "10px",
                    justifyContent: "space-between",
                    display: "flex"
                  }}
                >
                  {index <= 0 || !openQuestion ? (
                    <div></div>
                  ) : (
                    <button
                      className="border-white h-12 rounded-md border border-solid border-carousel-button-border group flex gap-1 items-center justify-center transition-all duration-300 [&_path]:transition-all [&_path]:duration-300 group disabled:cursor-not-allowed"
                      style={{
                        borderRadius: "90px",
                        border: "1px solid #282828",
                        padding:
                          "clamp(16px, 1.25vw, 28px) clamp(32px, 2vw, 56px)",
                      }}
                      disabled={index <= 0}
                      onClick={prev}
                    >
                      <span
                        className="text-white group-disabled:text-carousel-next-count"
                        style={{
                          color: "#A8A8A8",
                          fontSize: "clamp(14px, 1.04vw, 20px)",
                        }}
                      >
                        Previous
                      </span>
                    </button>
                  )}
                  <button
                    className="h-12 border border-solid border-carousel-button-border bg-app-bg-gray rounded-md group flex gap-1 items-center justify-center transition-all duration-300 [&_path]:transition-all [&_path]:duration-300 group disabled:cursor-not-allowed bg-white"
                    style={{
                      borderRadius: "90px",
                      backgroundColor: "#E2E2E2",
                      padding: "clamp(16px, 1.25vw, 28px) clamp(32px, 2vw, 56px)",
                    }}
                    onClick={next}
                  >
                    <span
                      className="text-black group-disabled:text-carousel-next-count"
                      style={{ fontSize: "clamp(14px, 1.04vw, 20px)" }}
                    >
                      Continue
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <button className="w-12 aspect-square rounded-full absolute top-6 right-6 bg-white bg-opacity-60 hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center z-10">
              {getUsername()}
              {/* <img
                src={avatar}
                alt="avatar"
                className="w-full h-auto"
              /> */}
            </button>
            {!width ? (
              <div></div>
            ) : (
              <div
                className="relative col-lg-6 col-md-6 w-[100%]"
                style={{
                  padding: "10px",
                  backgroundImage: !loaded ? "" : !width ? "" : `url(${effect})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "calc(100% + 15px) top",
                  height: windowHeight < "895" ? "" : "100vh",
                }}
              >
                <div
                  className="bg-[#222222]/95"
                  style={{
                    height: "100%",
                    borderRadius: "32px",
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    justifyContent: "flex-end",
                  }}
                >
          <img
            src={questionnaire}
            alt="questionnaire"
            style={{
              maxWidth: "90%",
              maxHeight: "80%",
              objectFit: "cover",
              display: "block",
            }}
          />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </ProtectedRoute>
  );
};

export default QuestionnairePage;
