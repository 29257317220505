import React, { useMemo, useState } from 'react';
import Dropdown from '../base/Dropdown';
import { Popover, Disclosure } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { usePopper } from 'react-popper';
import {
  partialUpdateStatePayload,
  updateDimensions,
  updateImageQuality,
  updateImagesToGenerate,
  updateNegativePrompt,
  updatePromptRigidness,
  updateSampler,
  updateScheduler,
} from '../../reducers/formReducer';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import { ReactComponent as CaretDownIcon } from '../../assets/caret-up.svg';
import { ReactComponent as CaretDownIconV4 } from '../../assets/caret-up-v4.svg';
import Radio from '../base/Radio';
import samplerOptions from '../../data/samplers';
import DimensionDefaultValues from '../../data/dimensions';
import {
  ControlnetModes,
  ImagesToGenerateValues,
  IterationsAcceptedValeus,
  PromptRigidnessAcceptedValues,
  SCHEDULER_VALUES,
  LORAS,
  UseAsControlImageValues,
  imageWeightAcceptedValues,
} from './constants';
import SeedGenerator from '../SeedGenerator';
import { Tooltip } from 'react-tippy';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import Slider from '../base/Slider';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import { useLoraValue } from '../../hooks/useLoraValue';
import InlineImageSelector from '../base/InlineImageSelector';
import SeedGeneratorV4 from '../SeedGeneratorV4';
import { shortenFields } from '../../helpers/shortenFields';

const LightningSettings = (props) => {
  const dispatch = useDispatch();

  const postprocess = useSelector((state) => state.form.payload.postprocess);
  const second_pass_params = useSelector(
    (state) => state.form.payload.second_pass_params
  );
  const insertLightningLora = useSelector(
    (state) => state.form.payload.insertLightningLora
  );
  const handleInsertLightningLora = (value) => {
    dispatch(partialUpdateStatePayload({ insertLightningLora: value }));
  };
  const handlePostprocess = (value) => {
    dispatch(partialUpdateStatePayload({ postprocess: value }));
  };
  const handleSecondPass = (value) => {
    dispatch(partialUpdateStatePayload({ second_pass_params: value }));
  };

  const ip_adapter_scale = useSelector(
    (state) => state.form.payload.ip_adapter_scale
  );
  const ip_adapter_image = useSelector(
    (state) => state.form.payload.ip_adapter_image
  );
  const handleIpScale = (value) => {
    dispatch(partialUpdateStatePayload({ ip_adapter_scale: value }));
  };
  const handleIpImage = (value) => {
    dispatch(partialUpdateStatePayload({ ip_adapter_image: value }));
  };

  return (
    <>
      <hr className='border-button-green' />
      <Radio
        values={[]}
        value={postprocess?.adjust_saturation || ''}
        onChange={(v) =>
          handlePostprocess({
            ...postprocess,
            adjust_saturation: v || undefined,
          })
        }
        label={'Adjust Saturation'}
        title='Lightning: Post process - Adjust saturation'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      <Radio
        values={[]}
        value={postprocess?.adjust_sharpness || ''}
        onChange={(v) =>
          handlePostprocess({
            ...postprocess,
            adjust_sharpness: v || undefined,
          })
        }
        label={'Adjust Sharpness'}
        title='Lightning: Post process - Adjust sharpness'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />

      <button
        type='reset'
        onClick={() => handlePostprocess(undefined)}
        className='text-gray-400 text-sm flex justify-center items-center cursor-pointer focus:outline-none overflow-hidden rounded-lg bg-overlay-bg h-7 px-2 active:text-tag-text active:bg-app-green'
      >
        Clear postprocess params
      </button>

      <Radio
        values={[]}
        value={second_pass_params?.num_inference_steps || ''}
        onChange={(v) =>
          handleSecondPass({
            ...second_pass_params,
            num_inference_steps: v || undefined,
          })
        }
        label={'No. Inference Steps'}
        title='Lightning: Second pass - number of inference steps'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      <Radio
        values={[]}
        value={second_pass_params?.scheduler || ''}
        onChange={(v) =>
          handleSecondPass({ ...second_pass_params, scheduler: v || undefined })
        }
        label={'Lightning Scheduler'}
        title='Lightning: Second pass - Scheduler(DEFAULT: LCM)'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      <Radio
        values={[]}
        value={second_pass_params?.cfg_scale || ''}
        onChange={(v) =>
          handleSecondPass({ ...second_pass_params, cfg_scale: v || undefined })
        }
        label={'Lightning CFG scale'}
        title='Lightning: Second pass - CFG scale'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      <Radio
        values={[]}
        value={second_pass_params?.strength || ''}
        onChange={(v) =>
          handleSecondPass({ ...second_pass_params, strength: v || undefined })
        }
        label={'Second pass strength'}
        title='Lightning: Second pass - Strength'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />

      <button
        type='reset'
        onClick={() => handleSecondPass(undefined)}
        className='text-gray-400 text-sm flex justify-center items-center cursor-pointer focus:outline-none overflow-hidden rounded-lg bg-overlay-bg h-7 px-2 active:text-tag-text active:bg-app-green'
      >
        Clear second pass params
      </button>

      <Radio
        values={[]}
        value={ip_adapter_scale || ''}
        onChange={(v) => handleIpScale(v)}
        label={'IP Adapter scale'}
        title='Lightning: IP Adapter scale'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      <InlineImageSelector
        preview
        values={[]}
        value={ip_adapter_image || ''}
        onChange={(v) => handleIpImage(v)}
        label={'IP Adapter Image'}
        title='Lightning: IP Adapter Image'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      <button
        type='reset'
        onClick={() => {
          handleIpImage(undefined);
          handleIpScale(undefined);
        }}
        className='text-gray-400 text-sm flex justify-center items-center cursor-pointer focus:outline-none overflow-hidden rounded-lg bg-overlay-bg h-7 px-2 active:text-tag-text active:bg-app-green'
      >
        Clear IP adapter
      </button>

      <Radio
        values={UseAsControlImageValues}
        value={insertLightningLora ?? true}
        onChange={handleInsertLightningLora}
        label={'Insert Lightning Lora'}
        title='Insert Lightning Lora'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
        fixedValues
      />
      <hr className='border-button-green' />
    </>
  );
};

const WaterfallSettings = (props) => {
  const dispatch = useDispatch();

  const stagec_cfg = useSelector((state) => state.form.payload.stagec_cfg);
  const stagec_steps = useSelector((state) => state.form.payload.stagec_steps);
  const stagec_denoise = useSelector(
    (state) => state.form.payload.stagec_denoise
  );

  const stageb_steps = useSelector((state) => state.form.payload.stageb_steps);
  const stageb_denoise = useSelector(
    (state) => state.form.payload.stageb_denoise
  );

  const refiner_denoise = useSelector(
    (state) => state.form.payload.refiner_denoise
  );
  const refiner_steps = useSelector(
    (state) => state.form.payload.refiner_steps
  );

  const saturation = useSelector((state) => state.form.payload.saturation);

  const handleUpdate = (key, value) => {
    dispatch(partialUpdateStatePayload({ [key]: value }));
  };

  return (
    <>
      <hr className='border-button-green' />
      <h4 className='font-bold text-white text-center'>Waterfall Settings</h4>
      <Radio
        values={[]}
        value={stagec_cfg || ''}
        onChange={(v) => handleUpdate('stagec_cfg', v)}
        label={'Stage C: CFG'}
        title='Stage C: CFG'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      <Radio
        values={[]}
        value={stagec_steps || ''}
        onChange={(v) => handleUpdate('stagec_steps', v)}
        label={'Stage C: Steps'}
        title='Stage C: Steps'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      <Radio
        values={[]}
        value={stagec_denoise || ''}
        onChange={(v) => handleUpdate('stagec_denoise', v)}
        label={'Stage C: Denoise'}
        title='Stage C: Denoise'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />

      <Radio
        values={[]}
        value={stageb_steps || ''}
        onChange={(v) => handleUpdate('stageb_steps', v)}
        label={'Stage B: Steps'}
        title='Stage B: Steps'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      {/*
			<Radio
				values={[]}
				value={stageb_denoise || ""}
				onChange={(v) => handleUpdate('stageb_denoise', v)}
				label={"Stage B: Denoise"}
				title="Stage B: Denoise"
				appliedClasses={{
					option: 'w-[4.5rem] md:w-[2.85rem] justify-center'
				}}
			/>
			*/}

      <Radio
        values={[]}
        value={refiner_denoise || ''}
        onChange={(v) => handleUpdate('refiner_denoise', v)}
        label={'Refiner: Denoise'}
        title='Refiner: Denoise'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      <Radio
        values={[]}
        value={refiner_steps || ''}
        onChange={(v) => handleUpdate('refiner_steps', v)}
        label={'Refiner: Steps'}
        title='Refiner: Steps'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />

      <Radio
        values={[]}
        value={saturation || ''}
        onChange={(v) => handleUpdate('saturation', v)}
        label={'Saturation'}
        title='Saturation'
        appliedClasses={{
          option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
        }}
      />
      <hr className='border-button-green' />
    </>
  );
};

const AdvancedSettings = (props) => {
  const dispatch = useDispatch();
  const iterations = useSelector((state) => state.form.payload.imageQuality);
  const promptRigidness = useSelector(
    (state) => state.form.payload.promptRigidness
  );
  const sampler = useSelector((state) => state.form.payload.sampler);
  const scheduler = useSelector((state) => state.form.payload.scheduler);
  const imagesToGenerate = useSelector(
    (state) => state.form.payload.imagesToGenerate
  );
  const negativePrompt = useSelector(
    (state) => state.form.payload.negativePrompt
  );

  const dimension = useSelector((state) => state.form.payload.dimension);
  const redesignMethod = useSelector((state) => state.form.method);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);
  const user = useSelector((state) => state.userSlice.user);

  const {
    lora: lora0,
    updateValue: updateLoraValue0,
    updateLora: updateLora0,
  } = useLoraValue({ loraIndex: 0 });
  const {
    lora: lora1,
    updateValue: updateLoraValue1,
    updateLora: updateLora1,
  } = useLoraValue({ loraIndex: 1 });

  const canny_low_threshold = useSelector(
    (state) => state.form.payload.canny_low_threshold
  );
  const canny_high_threshold = useSelector(
    (state) => state.form.payload.canny_low_threshold
  );

  const handleCannyLowThreshold = (value) => {
    dispatch(partialUpdateStatePayload({ canny_low_threshold: value }));
  };
  const handleCannyHighThreshold = (value) => {
    dispatch(partialUpdateStatePayload({ canny_high_threshold: value }));
  };

  const handleIterations = (value) => {
    dispatch(updateImageQuality(value));
  };
  const handlePromptRigidness = (value) => {
    dispatch(updatePromptRigidness(value));
  };

  const handleSampler = (value) => {
    dispatch(updateSampler(value));
  };

  const handleScheduler = (value) => {
    dispatch(updateScheduler(value));
  };

  const handleImagesToGenerate = (value) => {
    dispatch(updateImagesToGenerate(value));
  };

  const handleNegativePrompt = (value) => {
    dispatch(updateNegativePrompt(value));
  };

  const disablePromptInjection = useSelector(
    (state) => state.form.payload.overridePromptInjection
  );
  const handleDisablePromptInjection = (value) => {
    dispatch(partialUpdateStatePayload({ overridePromptInjection: value }));
    // dispatch(partialUpdateState(value))
  };

  const useRefiner = useSelector((state) => state.form.payload.useRefiner);
  const refinerInfluence = useSelector(
    (state) => state.form.payload.refinerInfluence
  );
  const schedulerRefiner = useSelector(
    (state) => state.form.payload.schedulerRefiner
  );
  const strengthRefiner = useSelector(
    (state) => state.form.payload.strengthRefiner
  );
  const handleUseRefiner = (value) => {
    dispatch(partialUpdateStatePayload({ useRefiner: value }));
    // dispatch(partialUpdateState(value))
  };
  const handleRefinerInfluence = (value) => {
    dispatch(partialUpdateStatePayload({ refinerInfluence: value }));
    // dispatch(partialUpdateState(value))
  };
  const handleSchedulerRefiner = (value) => {
    dispatch(partialUpdateStatePayload({ schedulerRefiner: value }));
    // dispatch(partialUpdateState(value))
  };
  const handleStrengthRefiner = (value) => {
    dispatch(partialUpdateStatePayload({ strengthRefiner: value }));
    // dispatch(partialUpdateState(value))
  };

  const useMaxWorkers = useSelector(
    (state) => state.form.payload.useMaxWorkers
  );
  const handleMaxWorkers = (value) => {
    dispatch(partialUpdateStatePayload({ useMaxWorkers: value }));
    // dispatch(partialUpdateState(value))
  };

  const controlGuidanceStart = useSelector(
    (state) => state.form.payload.controlGuidanceStart
  );
  const controlGuidanceEnd = useSelector(
    (state) => state.form.payload.controlGuidanceEnd
  );
  const handleGuidanceStart = (value) => {
    dispatch(partialUpdateStatePayload({ controlGuidanceStart: value }));
  };
  const handleGuidanceEnd = (value) => {
    dispatch(partialUpdateStatePayload({ controlGuidanceEnd: value }));
  };

  const enableMultipleControlnets = useSelector(
    (state) => state.form.payload.enableMultipleControlnets
  );
  const depthWeight = useSelector((state) => state.form.payload.depthWeight);
  const depthWeightGuidanceEnd = useSelector(
    (state) => state.form.payload.depthWeightGuidanceEnd
  );
  const handleEnableMultipleControlnets = (value) => {
    dispatch(partialUpdateStatePayload({ enableMultipleControlnets: value }));
  };
  const handleDepthWeight = (value) => {
    dispatch(partialUpdateStatePayload({ depthWeight: value }));
  };
  const handleDepthWeightGuidanceEnd = (value) => {
    dispatch(partialUpdateStatePayload({ depthWeightGuidanceEnd: value }));
  };

  const overridePod = useSelector(
    (state) => state.form.payload.runpodOverridePod
  );
  const handleOverridePod = (value) => {
    dispatch(partialUpdateStatePayload({ runpodOverridePod: value }));
  };

  const imageWeight = useSelector((state) => state.form.payload.imageWeight);
  const imageAsControlImage = useSelector(
    (state) => state.form.payload.image_as_control_image
  );

  const controlnetMode = useSelector(
    (state) => state.form.payload.controlnetMode
  );
  const handleControlnetMode = (value) => {
    dispatch(
      partialUpdateStatePayload({
        controlnetMode: value,
        imageWeight:
          imageWeightAcceptedValues[mode][value][imageAsControlImage][
            redesignMethod
          ][
            imageWeightAcceptedValues[mode][controlnetMode][
              imageAsControlImage
            ][redesignMethod].findIndex((item) => item.value === imageWeight)
          ].value,
      })
    );
  };

  const denoising_start = useSelector(
    (state) => state.form.payload.denoising_start
  );
  const denoising_end = useSelector(
    (state) => state.form.payload.denoising_end
  );

  const handleDenoisingStart = (value) => {
    dispatch(partialUpdateStatePayload({ denoising_start: value }));
  };
  const handleDenoisingEnd = (value) => {
    dispatch(partialUpdateStatePayload({ denoising_end: value }));
  };

  const runpodBatchSize = useSelector(
    (state) => state.form.payload.runpodBatchSize
  );
  const handleRunpodBatchSize = (value) => {
    dispatch(partialUpdateStatePayload({ runpodBatchSize: value }));
  };

  const { mode, generationStyle } = useGenerationEndpoint();

  const dimensionValues = useMemo(() => {
    if (mode === 'v4.0') {
      return ['1024 x 1024'].map((item) => ({
        name: item,
        value: item,
      }));
    }
    if (mode === 'v3.0') {
      return ['1024 x 1024'].map((item) => ({
        name: item,
        value: item,
      }));
    }
    if (mode === 'v2.0') {
      return DimensionDefaultValues.map((item) => ({
        name: item,
        value: item,
      }));
    }
    return DimensionDefaultValues.map((item) => ({
      name: item,
      value: item,
      disabled: redesignMethod === 'ddd' && item === '512 x 512',
    }));
  }, [redesignMethod, mode]);

  const { workspace } = useCurrentWorkspace();

  const PanelAttributes = useMemo(() => {
    return {
      className: 'flex flex-col gap-4 py-2 ',
    };
  }, []);

  const handleDimensions = (value) => {
    dispatch(updateDimensions(value));
  };

  const _runpodPayload = useSelector((state) => state.form.__runpod_payload);
  const _runpodError = useSelector((state) => state.form.__runpod_error);

  const runpodPayload = useMemo(() => {
		return shortenFields(_runpodPayload)
	}, [_runpodPayload])
  const runpodError = useMemo(() => {
		return shortenFields(_runpodError)
	}, [_runpodError])

  return (
    <Disclosure
      className={`relative rounded-xl px-3 ${
        mode >= 'v4.0' ? 'bg-style-bg py-4' : 'bg-app-bg-gray '
      }  p-2 flex flex-col`}
      as='div'
    >
      <Disclosure.Button
        className={`outline-none text-sm ${
          mode >= 'v4.0' ? 'text-white' : 'text-gray-label'
        }  flex items-center justify-between font-sans-pro text-[15.13px] font-semibold`}
      >
        <span className='flex'>
          Advanced Settings
          <Tooltip title='advanced setting' position='right'>
            <InfoIcon className='w-[20px] h-[20px] ml-1' />
          </Tooltip>
        </span>
        {mode >= 'v4.0' ? (
          <CaretDownIconV4 className='transition-all duration-300 ui-open:-rotate-90 [&_path]:fill-chevron-icon w-6 h-6 max-h' />
        ) : (
          <CaretDownIcon className='transition-all duration-300 ui-not-open:rotate-180 [&_path]:fill-chevron-icon w-6 h-6 max-h' />
        )}
      </Disclosure.Button>
      <Disclosure.Panel {...PanelAttributes}>
        {(mode === 'v2.0' || mode === 'v3.0') && (
          <>
            <Radio
              values={ControlnetModes}
              value={controlnetMode}
              onChange={handleControlnetMode}
              label={'Redesign Priority'}
              title='Redesign Priority'
              appliedClasses={{
                option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
              }}
              fixedValues
            />
          </>
        )}
        {(user?.isAdmin || user?.isDev) && mode < 'v4.0' && isDevModeEnabled && (
          <>
            {(mode === 'v2.0' || mode === 'v3.0') && (
              <>
                <Radio
                  values={[]}
                  value={controlGuidanceStart}
                  onChange={handleGuidanceStart}
                  label={'Control Guidance Start'}
                  title='Control Guidance Start'
                  appliedClasses={{
                    option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
                  }}
                />
                <Radio
                  values={[]}
                  value={controlGuidanceEnd}
                  onChange={handleGuidanceEnd}
                  label={'Control Guidance End'}
                  title='Control Guidance End'
                  appliedClasses={{
                    option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
                  }}
                />

                <Radio
                  values={UseAsControlImageValues}
                  value={enableMultipleControlnets}
                  onChange={handleEnableMultipleControlnets}
                  label={'Enable Multiple Controlnets'}
                  title='Enable Multiple Controlnets'
                  appliedClasses={{
                    option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
                  }}
                  fixedValues
                />
                <Radio
                  values={[]}
                  value={depthWeight}
                  onChange={handleDepthWeight}
                  label={'Depth Weight'}
                  title='Depth Weight'
                  appliedClasses={{
                    option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
                  }}
                />
                <Radio
                  values={[]}
                  value={depthWeightGuidanceEnd}
                  onChange={handleDepthWeightGuidanceEnd}
                  label={'Depth Weight Guidance End'}
                  title='Depth Weight Guidance End'
                  appliedClasses={{
                    option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
                  }}
                />
              </>
            )}
            {generationStyle === 'waterfall' && <WaterfallSettings />}
            {generationStyle === 'bolt' ? (
              <LightningSettings />
            ) : (
              <>
                <Radio
                  values={UseAsControlImageValues}
                  value={useRefiner}
                  onChange={handleUseRefiner}
                  label={'Use Refiner'}
                  title='Use refiner'
                  appliedClasses={{
                    option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
                  }}
                  fixedValues
                />
                <Radio
                  values={[]}
                  value={refinerInfluence}
                  onChange={handleRefinerInfluence}
                  label={'Refiner Influence'}
                  title='Default value is 0.2 for txt2img and 0.1 for img2img'
                  appliedClasses={{
                    option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
                  }}
                />
              </>
            )}
            {mode !== 'v3.0' && (
              <Radio
                values={UseAsControlImageValues}
                value={useMaxWorkers}
                onChange={handleMaxWorkers}
                label={'Use multiple workers'}
                title='Generate each image on a different worker for better performance'
                appliedClasses={{
                  option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
                }}
                fixedValues
              />
            )}
            <Radio
              values={[]}
              value={runpodBatchSize || ''}
              onChange={handleRunpodBatchSize}
              label={'Images / Job'}
              title='No. images that will be generated with each request(batch size)'
              appliedClasses={{
                option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
              }}
            />
            <Radio
              values={[]}
              value={overridePod}
              onChange={handleOverridePod}
              label={'Runpod Pod ID'}
              title='Override which runpod pod will be used for the generation'
              appliedClasses={{
                option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
              }}
            />
            <Radio
              values={UseAsControlImageValues}
              value={disablePromptInjection}
              onChange={handleDisablePromptInjection}
              label={'Disable Prompt Injection'}
              title='If set to Yes, no prompt injection will happen on the backend'
              appliedClasses={{
                option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
              }}
              fixedValues
            />
          </>
        )}
        {mode === 'v3.0' && isDevModeEnabled && (
          <>
            <Dropdown
              values={LORAS}
              value={lora0[0]}
              onChange={updateLora0}
              label={'Lora 01'}
              title='Lora 01'
              fixedValues
            />
            <Radio
              values={[]}
              value={lora0[1]}
              onChange={updateLoraValue0(1)}
              label={'Lora 01 parameter'}
              title='Lora 01 parameter'
              disabled={!lora0[0]}
            />
            <Radio
              values={[]}
              value={lora0[2]}
              onChange={updateLoraValue0(2)}
              label={'Lora 01 clip strength'}
              title='Lora 01 clip strength'
              disabled={!lora0[0]}
            />

            <Dropdown
              values={LORAS}
              value={lora1[0]}
              onChange={updateLora1}
              label={'Lora 02'}
              title='Lora 02'
              fixedValues
            />
            <Radio
              values={[]}
              value={lora1[1]}
              onChange={updateLoraValue1(1)}
              label={'Lora 02 parameter'}
              title='Lora 02 parameter'
              disabled={!lora1[0]}
            />
            <Radio
              values={[]}
              value={lora1[2]}
              onChange={updateLoraValue1(2)}
              label={'Lora 01 clip strength'}
              title='Lora 01 clip strength'
              disabled={!lora1[0]}
            />

            <Radio
              values={[]}
              value={canny_low_threshold}
              onChange={handleCannyLowThreshold}
              label={'Canny Low Threshold'}
              title='Canny Low Threshold'
            />
            <Radio
              values={[]}
              value={canny_high_threshold}
              onChange={handleCannyHighThreshold}
              label={'Canny High Threshold'}
              title='Canny High Threshold'
            />

            <Radio
              values={[]}
              value={denoising_start}
              onChange={handleDenoisingStart}
              label={'Denoising Start'}
              title='Denoising Start'
            />
            <Radio
              values={[]}
              value={denoising_end}
              onChange={handleDenoisingEnd}
              label={'Denoising End'}
              title='Denoising End'
            />
          </>
        )}
        {mode === 'v3.0' ? (
          <>
            {isDevModeEnabled && (
              <>
                <Dropdown
                  values={SCHEDULER_VALUES}
                  value={scheduler}
                  onChange={handleScheduler}
                  label={'Scheduler'}
                  title='Choose the method used for diffusion sampling, different samplers render distinct results.'
                />
                <Radio
                  values={PromptRigidnessAcceptedValues}
                  value={promptRigidness}
                  onChange={handlePromptRigidness}
                  label={'Prompt Rigidness'}
                  title='Higher values results in images that follow your prompt more literally.'
                />
                <Dropdown
                  values={[...SCHEDULER_VALUES, { value: null, name: 'Null' }]}
                  value={schedulerRefiner}
                  onChange={handleSchedulerRefiner}
                  label={'Scheduler Refiner'}
                  title=''
                />
                <Radio
                  values={[]}
                  value={strengthRefiner}
                  onChange={handleStrengthRefiner}
                  label={'Strength refiner'}
                  title=''
                />
              </>
            )}
          </>
        ) : (
          mode < 'v4.0' && (
            <Dropdown
              values={samplerOptions.map(({ slug, label }) => ({
                name: label,
                value: slug,
              }))}
              value={sampler}
              onChange={handleSampler}
              label={'Sampler'}
              title='Choose the method used for diffusion sampling, different samplers render distinct results.'
            />
          )
        )}
        {mode >= 'v4.0' && (
          <>
            <div className='flex flex-col items-start gap-4'>
              <div className='flex flex-col w-full items-start gap-2'>
                <p className='font-roboto font-bold text-sm text-gray-label'>
                  Images to Generate
                </p>
                <Dropdown
                  values={ImagesToGenerateValues}
                  value={imagesToGenerate}
                  onChange={handleImagesToGenerate}
                  appliedClasses={{
                    root: 'w-full',
                    buttonCnt: '!max-w-[100%]',
                    button:
                      '!rounded-[4px] border-none bg-app-bg-gray h-[40px]',
                  }}
                />
              </div>
              <div className='flex flex-col w-full  items-start gap-2'>
                <p className='font-roboto font-bold text-sm text-gray-label'>
                  Demensions
                </p>
                <Dropdown
                  values={dimensionValues}
                  value={dimension}
                  onChange={handleDimensions}
                  appliedClasses={{
                    root: 'w-full',
                    buttonCnt: '!max-w-[100%]',
                    button:
                      '!rounded-[4px] border-none bg-app-bg-gray h-[40px]',
                  }}
                />
              </div>
              <div className='flex flex-col w-full  items-start gap-2'>
                <p className='font-roboto font-bold text-sm text-gray-label'>
                  Seed
                </p>
                <SeedGeneratorV4 />
              </div>
            </div>
          </>
        )}
        {mode < 'v4.0' && (
          <>
            <SeedGenerator />
            <Radio
              values={IterationsAcceptedValeus[mode]}
              value={iterations}
              onChange={handleIterations}
              label={'Iterations'}
              title='Higher quality creates better images but takes longer, Sweet spot is 35-55(2-4).'
            />
          </>
        )}
        {mode !== 'v3.0' && mode < 'v4.0' && (
          <Radio
            values={PromptRigidnessAcceptedValues}
            value={promptRigidness}
            onChange={handlePromptRigidness}
            label={'Prompt Rigidness'}
            title='Higher values results in images that follow your prompt more literally.'
          />
        )}
        {mode < 'v4.0' && (
          <>
            <Dropdown
              values={dimensionValues}
              value={dimension}
              onChange={handleDimensions}
              label={'Dimensions'}
              title='Set image width and height.'
            />
            <Dropdown
              values={ImagesToGenerateValues}
              value={imagesToGenerate}
              onChange={handleImagesToGenerate}
              label={'Images To Generate'}
              title="Determine the total number of images you'd like to generate."
            />
          </>
        )}
        {mode !== 'v3.0' && mode < 'v4.0' && (
          <>
            <div className='flex items-center text-gray-label font-poppins font-bold text-xs gap-1'>
              Negative prompt:
              <Tooltip
                title={
                  'Describe details you wish to exclude from your images, such as colors, objects, or styles.'
                }
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <textarea
              className='bg-app-black rounded-2xl border border-solid border-select-border p-2 w-full outline-none text-gray-400 text-sm 4xl:text-base resize-none custom-scroll -mt-2'
              rows={4}
              value={negativePrompt}
              onChange={(e) => handleNegativePrompt(e.target.value)}
              placeholder='Describe the content you want to exclude'
            />
          </>
        )}

        {isDevModeEnabled && mode < 'v4.0' && (
          <>
            <div className='flex items-center text-gray-label font-poppins font-bold text-xs gap-1'>
              Runpod Payload
              <Tooltip title={"Here's the data sent from our api to runpod"}>
                <InfoIcon />
              </Tooltip>
            </div>
            <textarea
              className='bg-app-black rounded-2xl border border-solid border-select-border p-2 w-full outline-none text-gray-400 text-sm 4xl:text-base resize-none custom-scroll -mt-2'
              rows={6}
              value={runpodPayload}
              placeholder='Runpod payload will be displayed here'
            ></textarea>

            <div className='flex items-center text-gray-label font-poppins font-bold text-xs gap-1'>
              Runpod Error
              <Tooltip title={"Here's the data sent from our api to runpod"}>
                <InfoIcon />
              </Tooltip>
            </div>
            <textarea
              className='bg-app-black rounded-2xl border border-solid border-select-border p-2 w-full outline-none text-gray-400 text-sm 4xl:text-base resize-none custom-scroll -mt-2'
              rows={6}
              value={JSON.stringify(runpodError)}
              placeholder='Runpod payload will be displayed here'
            ></textarea>
          </>
        )}
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default AdvancedSettings;
