import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useCurrentWorkspace from '../hooks/useCurrrentWorkspace';
import { GENERATION_STATUS, bookmarkImage, deleteImage, setImageAsInspiration, showImageModal, submitFeedback, updateStyleImage, updateStyleImageUrl } from '../reducers/formReducer';
import { ContainerContext } from './image-to-image/V4ContainerContext';
import useGenerationEndpoint from './input/useGenerationEndpoint';
import Tooltip from './Tooltip';
import { Popover, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
 
import DownloadImage from '../services/ImageDownloader';

import { ReactComponent as CloseIcon } from '../assets/CloseEditor.svg';
import { ReactComponent as UpscaleIcon } from '../assets/upscale-icon.svg';
import { ReactComponent as DownloadIcon } from '../assets/DownloadIcon.svg';
import { ReactComponent as ThumbsUpIcon } from '../assets/ThumbsUpOutline.svg';
import { ReactComponent as ThumbsDownIcon } from '../assets/ThumbsDownOutline.svg';
import { ReactComponent as BookmarkIcon } from '../assets/BookmarkIconGray.svg';
import { ReactComponent as BookmarkIconGreen } from '../assets/BookmarkIconGreenOutline.svg';
import { ReactComponent as UseAsImageSketchIcon } from '../assets/UseAsImageSketch.svg';
import { ReactComponent as EditIcon } from '../assets/EditCanvas.svg';
import { ReactComponent as ArrowDownIcon } from '../assets/caret-up-v4.svg';
import { ReactComponent as UseStyleImageIcon } from '../assets/UseStyleImage.svg';
import { ReactComponent as UseInputImageIcon } from '../assets/UseInputImage.svg';
import { ReactComponent as VectorizeImageIcon } from '../assets/VectorizeIcon.svg';
import { ReactComponent as RemoveBGIcon } from '../assets/RemoveBG.svg';
import { ReactComponent as DeleteIcon } from '../assets/CleanV4.svg';
import LoadingButton from './base/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import QueueStatus, { useQueueVisibility } from './queue/QueueStatus';

function GeneratedImagePlaceholderV4({
  isMain,
  isGridView,
  image,
  onClick,
  mainGeneration,
  appliedClasses = {},
  props,
}) {
	const dispatch = useDispatch();

	const navigate = useNavigate();

  const user = useSelector((state) => state.userSlice.user);

  const { workspace } = useCurrentWorkspace();
  const { mode } = useGenerationEndpoint();
  const workspace_classnames = {
    'image-workspace': {
      container:
        appliedClasses.container +
        (isMain
          ? ' bg-app-bg-gray' + (mode >= 'v4.0' ? '' : 'aspect-square h-full')
          : ' w-full aspect-square bg-[#222229] border border-app-search-gray'),
      text_preview:
        appliedClasses.text_preview + (isMain ? ' w-1/2' : ' w-full'),
    },
    'text-workspace': {
      container:
        appliedClasses.container +
        (isMain
          ? ' aspect-square w-full h-full bg-app-bg-gray'
          : ' w-full aspect-square bg-[#222229] border border-app-search-gray'),
      text_preview:
        appliedClasses.text_preview + (isMain ? ' w-1/2' : ' w-full'),
    },
  };

  const containerClasses = `${workspace_classnames[workspace].container} flex items-center justify-center aspect-square rounded-md 2xl:rounded-2xl relative`;
  const { width, height } = useContext(ContainerContext);
  const containerStyle =
    width && height
      ? {
          width: `${width}px`,
          height: `${height}px`,
        }
      : {};

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [130, 8] },
      },
    ],
  });
  const [showToolIcons, setShowToolIcons] = useState(false);

  const { show: showQueue } = useQueueVisibility();

  const [timeElapsed, setTimeElapsed] = useState(() => {
		const x = (Date.now() - image?._startTime) / 1000;

		if (isNaN(x)) return 0

		return Math.floor(x)
	});
  const generationStatus = useSelector((state) => state.form.generationStatus);
  const isLoading = useMemo(() => {
    if (
      image?.status &&
      [GENERATION_STATUS.IN_QUEUE, GENERATION_STATUS.GENERATING].indexOf(
        image.status
      ) > -1
    ) {
      return true;
    }
    return (
      [GENERATION_STATUS.IN_QUEUE, GENERATION_STATUS.GENERATING].indexOf(
        generationStatus
      ) > -1
    );
  }, [generationStatus, image]);

  const showImagePlaceholders = useSelector(
    (state) => state.form.showPlaceholders
  );

  const timer = useRef(null);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setTimeElapsed((t) => {
					const x = (Date.now() - image?._startTime) / 1000;

					if (isNaN(x)) return t

					return Math.floor(x)
				})
      }, 1000);

      timer.current = interval;
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
      setTimeElapsed(0);
    };
  }, [isLoading, image?._startTime]);

  const handleMouseEnter = () => {
    setShowToolIcons(true);
  };
  const handleMouseLeave = () => {
    setShowToolIcons(false);
  };

  const calculateLoaderPercentage = (timeElapsed) => {
    if (timeElapsed < 1) return 100;

    const calculateVal = (n) =>
      [...Array(n)].reduce((a, b, ind) => a + 1 / (ind + 1), 0);

    const rate =
      1 -
      (calculateVal(timeElapsed + 5) - calculateVal(3)) /
        (calculateVal(65) - calculateVal(3));

    return rate * 100;
  };

  const handleBookmarkClicked = async () => {
    try {
      const resp = await dispatch(
        bookmarkImage({
          _id: image._id,
          bookmarked: !image.bookmarked,
        })
      ).unwrap();

    } catch (e) {
			console.error(e)
      toast('Something went wrong', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

	const handleImageAsSketch = async () => {
		return await dispatch(setImageAsInspiration({ imageUrl: image.imageUrl })).unwrap()
	}

  const handleDeleteGeneration = async () => {
    try {
      await dispatch(deleteImage(image._id)).unwrap();
    } catch (e) {
      toast('Something went wrong', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleFeedback = async (feedback) => {
    try {
      await dispatch(
        submitFeedback({
          id: image._id,
          feedback: feedback === image.feedback ? 0 : feedback,
        })
      ).unwrap();
    } catch (e) {
      console.log(e);
      toast(e?.error?.detail || 'Failed to submit feedback', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

	const handleOpenTool = (toolName) => {
		navigate(`/${toolName}?generationId=${image._id}&fileName=${image.prompt}&imageUrl=${image.imageUrl}`)
	}

  if ([GENERATION_STATUS.FAILED].indexOf(image?.status) > -1) {
    return (
      <div
        className='overflow-hidden  border border-dashed border-gray-600 bg-app-bg-gray flex items-center justify-center aspect-square rounded-md 2xl:rounded-2xl relative'
        style={containerStyle}
      >
        <div className='flex flex-col items-center justify-center z-10 gap-4'>
          <CloseIcon className='w-12 h-12' />

          <span className='text-xs text-center text-[#8A8A92]'>Failed!</span>
        </div>
      </div>
    );
  }

	const handleOpenImage = () => {
    dispatch(showImageModal(image.imageUrl));
	}

  if (!showImagePlaceholders && image && image.imageUrl) {
    return (
      <div
        className={`${containerClasses} overflow-hidden relative group/image`}
        style={containerStyle}
      >
        <div
          className={`relative cursor-pointer ${workspace === 'image-workspace' ? 'w-full h-full' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={onClick || handleOpenImage}
        >
					{isMain && showQueue && (
						<div className="absolute top-4 left-4 right-4 z-10 " onClick={e => e.stopPropagation()}>
							<QueueStatus useParentWidth />
						</div>
					)}
          <img
						key={image.imageUrl}
            src={image.imageUrl}
            alt={image.prompt}
            className={`w-full aspect-square rounded-md 2xl:rounded-2xl ${
              mainGeneration?._id === image?._id && !isMain
                ? 'border-app-green border-[2px]'
                : ''
            }`}
          />
          {(isMain ||
            isGridView) && (
              <>
                <span className={`opacity-0 group-hover/image:opacity-100 -right-4 -top-4 absolute group-hover/image:right-4 group-hover/image:top-4 flex flex-row z-20 transition-all duration-300`} onClick={e => e.stopPropagation()}>
                  <Tooltip title='Download'>
                    <LoadingButton
                      className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] p-2 w-[32px] h-[32px] lg:p-0 flex items-center justify-center rounded-[4px] cursor-pointer aspect-square group`}
                      onClick={(e) => DownloadImage.download('raster', image, {
												element: e,
												user,
												imageName: image.prompt,
											})}
                    >
                      <DownloadIcon
                        className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
                      />
                    </LoadingButton>
                  </Tooltip>
                </span>
                <span
                  className={`opacity-0 group-hover/image:opacity-100 -right-4 -bottom-4 absolute group-hover/image:right-4 group-hover/image:bottom-4 flex flex-row z-20 transition-all duration-300`}
									onClick={e => e.stopPropagation()}
                >
                  <Tooltip title='Upscale'>
                    <LoadingButton
                      className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] p-2 w-[32px] h-[32px] lg:p-0 flex items-center justify-center rounded-[4px] cursor-pointer aspect-square group`}
                      onClick={() => handleOpenTool('upscale')}
                    >
                      <UpscaleIcon
                        className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
                      />
                    </LoadingButton>
                  </Tooltip>
                </span>
                <div className='opacity-0 group-hover/image:opacity-100 absolute -left-4 -bottom-4 group-hover/image:left-4 group-hover/image:bottom-4 flex flex-row gap-[8px] z-20 transition-all duration-300' onClick={e => e.stopPropagation()}>
                  <Tooltip title='Like'>
                    <LoadingButton
                      className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] p-2 w-[32px] h-[32px] lg:p-0 flex items-center justify-center rounded-[4px] cursor-pointer aspect-square group`}
                      onClick={() => handleFeedback(1)}
                    >
                      <ThumbsUpIcon
                        className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  ${
													image?.feedback > 0 ? 
														'[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/50' :
														'[&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]'
												}`}
                      />
                    </LoadingButton>
                  </Tooltip>
                  <Tooltip title='Dislike'>
                    <LoadingButton
                      className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] p-2 w-[32px] h-[32px] lg:p-0 flex items-center justify-center rounded-[4px] cursor-pointer aspect-square group`}
                      onClick={() => handleFeedback(-1)}
                    >
                      <ThumbsDownIcon
                        className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  ${
													image?.feedback < 0 ? 
														'[&_path]:fill-dislike group-hover:[&_path]:fill-dislike/50' :
														'[&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]'
												} `}
                      />
                    </LoadingButton>
                  </Tooltip>
                  <Tooltip title='Bookmark'>
                    <LoadingButton
                      className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] p-2 w-[32px] h-[32px] lg:p-0 flex items-center justify-center rounded-[4px] cursor-pointer aspect-square group`}
                      onClick={handleBookmarkClicked}
                    >
											{image?.bookmarked ? (
												<BookmarkIconGreen 
													className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[19px] lg:h-[19px]  [&_path]:fill-app-green group-hover:[&_path]:fill-app-green/50`}
												/>
											) : (
												<BookmarkIcon 
													className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[19px] lg:h-[19px]  [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
												/>
											)}
                    </LoadingButton>
                  </Tooltip>

									{workspace === 'image-workspace' && (
										<LoadingButton
											className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] p-2 flex items-center space-x-2 rounded-[4px] justify-center cursor-pointer group
												group-hover:flex-grow group-hover:space-x-4 group-hover:justify-start aspect-auto group-hover:aspect-auto `}
											style={{
												minWidth: '32px',
												height: '32px',
												transition: 'width 0.3s',
											}}
											errorMessage="Failed to load image"
											onClick={handleImageAsSketch}
										>
											<div className='w-5 h-5'>
												<UseAsImageSketchIcon
													className={`lg:w-[20px] lg:h-[20px] [&_path]:fill-bg-app-gray group-hover:[&_path]:fill-app-green`}
												/>
											</div>

												<span
													className={`hidden opacity-0 group-hover:block group-hover:opacity-100 transition-all duration-200 text-[14px] text-[#838387]`}
												>
													Use Image as Sketch
												</span>
										</LoadingButton>
									)}
                </div>
                <div className='opacity-0 group-hover/image:opacity-100 absolute bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[4px] -left-4 -top-4 group-hover/image:left-4 group-hover/image:top-4 flex flex-row z-20 transition-all duration-300' onClick={e => e.stopPropagation()}>
                  <Popover>
                    {({ open, close }) => (
                      <>
                        <Popover.Button ref={setAnchorEl}>
                          <Tooltip title='Edit'>
                            <button
                              className={`p-2 w-[42px] h-[32px] lg:p-0 flex items-center justify-center rounded-[4px] cursor-pointer aspect-square group`}
                              onClick={() => {}}
                            >
                              <EditIcon
                                className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
                              />
                              <ArrowDownIcon
                                className={`max-lg:aspect-square -rotate-90 max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px] [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
                              />
                            </button>
                          </Tooltip>
                        </Popover.Button>

                        <Transition
                          as={React.Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                          {...props}
                        >
                          <Popover.Panel
                            className='border border-solid border-[#838387] rounded-[6px] shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] bg-white/80 flex flex-col p-[14px] gap-[10px] items-start justify-center w-auto h-auto relative'
                            ref={setPopperEl}
                            style={styles.popper}
                            {...attributes.popper}
                          >
                            <LoadingButton
                              className={`w-[140px] h-[19px] flex items-center justify-start gap-2 rounded-[4px] cursor-pointer group`}
															errorMessage="Failed to load image"
															onClick={() => dispatch(updateStyleImageUrl(image.imageUrl))}
                            >
                              <UseStyleImageIcon
                                className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[21px] lg:h-[21px] [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#13131A]`}
                              />
                              <span className='text-[#4A4A4A] text-[12px] font-medium hover:text-[#13131A]'>
                                Use as style Image
                              </span>
                            </LoadingButton>
                            <LoadingButton
                              className={`w-[140px] h-[19px] flex items-center justify-start gap-2 rounded-[4px] cursor-pointer group`}
                              onClick={handleImageAsSketch}
                            >
                              <UseInputImageIcon
                                className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[19px] lg:h-[19px] [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#13131A]`}
                              />
                              <span className='text-[#4A4A4A] text-[12px] font-medium hover:text-[#13131A]'>
                                Use as input Image
                              </span>
                            </LoadingButton>
                            <LoadingButton
                              className={`w-[140px] h-[19px] flex items-center justify-start gap-2 rounded-[4px] cursor-pointer group`}
															onClick={() => handleOpenTool('vectorizer')}
                            >
                              <VectorizeImageIcon
                                className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[19px] lg:h-[19px] [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#13131A]`}
                              />
                              <span className='text-[#4A4A4A] text-[12px] font-medium hover:text-[#13131A]'>
                                Vectorize Image
                              </span>
                            </LoadingButton>
                            <LoadingButton
                              className={`w-[145px] h-[19px] flex items-center justify-start gap-2 rounded-[4px] cursor-pointer group`}
															onClick={() => handleOpenTool('bg-remover')}
                            >
                              <RemoveBGIcon
                                className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[18px] lg:h-[18px] [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#13131A]`}
                              />
                              <span className='text-[#4A4A4A] text-[12px] font-medium hover:text-[#13131A]'>
                                Remove background
                              </span>
                            </LoadingButton>
                            <LoadingButton
                              className={`w-[140px] h-[19px] flex items-center justify-start gap-2 rounded-[4px] cursor-pointer group`}
                              onClick={handleDeleteGeneration}
                            >
                              <DeleteIcon
                                className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[19px] lg:h-[19px] [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#13131A]`}
                              />
                              <span className='text-[#4A4A4A] text-[12px] font-medium hover:text-[#13131A]'>
                                Delete Image
                              </span>
                            </LoadingButton>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              </>
            )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={twMerge(`${containerClasses} overflow-hidden`, appliedClasses.pendingBox)}
      style={containerStyle}
		onClick={onClick}
    >

      {image?.generationId && !image?._id &&
				(<div
					className='absolute top-0 bottom-0 right-20 left-0 bg-app-search-gray transition-all duration-300 roundedd-l-md 2xl:rounded-l-2xl'
					style={{ right: `${calculateLoaderPercentage(timeElapsed)}%` }}
				></div>
			)}
			{isMain && showQueue && (
				<div className="absolute top-4 left-4 right-4 ">
					<QueueStatus useParentWidth />
				</div>
			)}
      {isLoading ? (
				<>
					{image?.generationId && !image?._id ? (
						<div className='flex flex-col items-center justify-center z-10'>
							<div className={`${isMain ? 'mb-3 loader' : 'mb-1 v4loader'} `}></div>
							<span className='text-xs text-center text-[#8A8A92]'>
								({timeElapsed}s)
							</span>
						</div>
					) : (
						<div className='flex flex-col items-center justify-center z-10'>
							<div className={`${isMain ? 'mb-3 loader' : 'mb-1 v4loader'} `}></div>
							{(isGridView || isMain) && (
								<span className='text-xs text-center text-[#8A8A92]'>
									Starting generation...
								</span>
							)}
						</div>
					)}
				</>
      ) : (
        <p
          className={`text-[#464653] w-[270px] ${isMain ? '' : 'hidden'} ${
            workspace_classnames[workspace].text_preview
          } p-1 text-center text-[12px] xs:text-xs`}
        >
          Write your first prompt and click generate, to see results here.
        </p>
      )}
    </div>
  );
}

export default GeneratedImagePlaceholderV4;
