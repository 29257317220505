import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { usePopper } from "react-popper";

import { logoutUser } from '../reducers/userReducer';
import { setIsMobileNavOpen, setIsTutorialModalOpen } from '../reducers/appReducer';

import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as LogoutIcon } from '../assets/LogoutIcon.svg';
import { ReactComponent as TutorialsIcon } from '../assets/TutorialsIcon.svg';
import { ReactComponent as MobileNavToggler } from '../assets/MobileNavToggler.svg';
import { ReactComponent as TokenIconGreen } from '../assets/TokenIconGreen.svg';
import { ReactComponent as SparkleIcon } from '../assets/Sparkle.svg';
import { ReactComponent as BugIcon } from '../assets/BugIcon.svg';
import { ReactComponent as InfoIcon } from '../assets/InfoIconGray.svg';
import { ReactComponent as CarrotIcon } from '../assets/version-caret-down.svg';
import { _switchDevMode, updateOutOfCreditText, updateShowOutOfCreditModal } from '../reducers/formReducer';
import Community from './header/Community';
import { Listbox, Switch, Transition } from '@headlessui/react';
import useGenerationEndpoint from './input/useGenerationEndpoint';
import BaseTooltip from './base/BaseTooltip';
import TutorialModal from './modals/TutorialModal';

function Navbar() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const user = useSelector(state => state.userSlice.user);
	const isMobileNavOpen = useSelector(state => state.appSlice.isMobileNavOpen);

	const [isTooltipTutorial, setIsTooltipTutorial] = useState(false)

	const handleLogout = () => {
		dispatch(logoutUser()).then(res => {
			localStorage.removeItem('userInfo');
			navigate('/login');
		});
	}

	const getUsername = () => {
		if(user?.name) return user?.name?.split(' ').map(slice => slice[0]).join('');
		if(user?.discordUsername) return user.discordUsername.charAt(0);

		return 'A';
	}

	const handleUpgrade = () => {
		dispatch(updateShowOutOfCreditModal(true));
		dispatch(updateOutOfCreditText({
			title: <>Upgrade for more credits<br />and features</>,
			subtitle: null,
		}));
	}

	const hideTooltipTutorial = useCallback(() => {
		setIsTooltipTutorial(false)
		localStorage.setItem('hideTooltipTutorial', 'true');
	}, [])

	const [tooltipEl, setTooltipEl] = useState();
  const [tutorialArrowEl, setTutorialArrowEl] = useState();
  const [promptEl, setPromptEl] = useState();
  let tutorialPopper = usePopper(promptEl, tooltipEl, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [100, 16],
        }
      },
      { 
        name: 'arrow',
        options: { 
          element: tutorialArrowEl,
          padding: {}
        },
      },
    ],
  });

	useEffect(() => {
		const hideTooltipTutorial = localStorage.getItem('hideTooltipTutorial');
		if (!hideTooltipTutorial) {
			setIsTooltipTutorial(true)
		}
	}, [])

  const appsumoLicenseObj = useSelector((state) => state.userSlice.sumolingLicense);
	const appsumoLicense = useMemo(() => {
		if (user?.appsumoLicense && user?.appsumoLicense.invoices && user?.appsumoLicense.active) {
			return user?.appsumoLicense
		}
		if (appsumoLicenseObj && appsumoLicenseObj?.invoices && appsumoLicenseObj?.active) {
			return appsumoLicenseObj
		}
		return {}
	}, [user, appsumoLicenseObj])

	const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);
	const handleDevMode = (value) => dispatch(_switchDevMode(value));

  const { method, mode, setMode } =
    useGenerationEndpoint();

	const [modeSelectorAnchorEl, setModeSelectorAnchorEl] = useState();
  const [modeSelectorEl, setModeSelectorEl] = useState();
  let modeSelectorPopper = usePopper(modeSelectorAnchorEl, modeSelectorEl, {
		strategy: 'fixed',
		placement: 'bottom-start',
	});

	const [v1ModeElement, setV1ModeElement] = useState();
	const [v2ModeElement, setV2ModeElement] = useState();
	const [v3ModeElement, setV3ModeElement] = useState(); 
	const [v4ModeElement, setV4ModeElement] = useState(); 
	const [v5ModeElement, setV5ModeElement] = useState(); 

	const [userCreditRef, setUserCreditRef] = useState(null);

	return (
		<div className='bg-black p-3 px-4 lg:px-8 static z-40'>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					<div className='flex gap-2 items-center mr-[15px] sm:mr-[40px]'>
						<Link to="/" className='font-bold text-xl text-white flex gap-2 items-center'>
							<Logo className='mr-[3px]' />
							<span className='hidden lg:flex mr-[5px] font-poppins font-black'>Logo Diffusion</span>
						</Link>

						<Listbox value={mode} onChange={setMode}>
							{({ open }) => (
								<>
									<span ref={setModeSelectorAnchorEl}>
										<Listbox.Button className='text-[10px] px-1 lg:px-2 text-xs text-black bg-app-green font-normal p-1 rounded-md flex gap-1 items-center' >
											{mode}
											<CarrotIcon className={`${open ? 'rotate-180' : 'rotate-0'} transition-all duration-200 [&>path]:fill-black w-[5px] h-[5px]`}/>
										</Listbox.Button>
									</span>
									<Transition
										show={open}
										as={React.Fragment}
										enter="transition duration-100 ease-out"
										enterFrom="transform scale-95 opacity-0"
										enterTo="transform scale-100 opacity-100"
										leave="transition duration-75 ease-out"
										leaveFrom="transform scale-100 opacity-100"
										leaveTo="transform scale-95 opacity-0"
									>
										<div 
											ref={setModeSelectorEl}
											{...modeSelectorPopper.attributes.popper}
											style={modeSelectorPopper.styles.popper}
											className="bg-overlay-bg py-2 rounded-lg mt-1"
										>
											<BaseTooltip 
												anchorEl={v4ModeElement} 
												placement="right"
												placementArrowClasses="right-full"
												placementArrowElClasses="w-full h-full rotate-45 border-select-border bg-style-method-enabled border-0 border-b border-l translate-x-1/2"
											>
												<h3 >
													<span className="text-title-white text-sm font-bold font-roboto">
														Logo Diffusion v.4 
													</span>
													<span className="text-title-white text-xs font-normal font-roboto">
														(2 Credits/Image)
													</span>
												</h3>
												<div className="font-roboto text-input-color text-xs font-normal ">
													TBA
												</div>
											</BaseTooltip>
											<BaseTooltip 
												anchorEl={v3ModeElement} 
												placement="right"
												placementArrowClasses="right-full"
												placementArrowElClasses="w-full h-full rotate-45 border-select-border bg-style-method-enabled border-0 border-b border-l translate-x-1/2"
											>
												<h3 >
													<span className="text-title-white text-sm font-bold font-roboto">
														Logo Diffusion v.3 
													</span>
													<span className="text-title-white text-xs font-normal font-roboto">
														(2 Credits/Image)
													</span>
												</h3>
												<div className="font-roboto text-input-color text-xs font-normal ">
													TBA
												</div>
											</BaseTooltip>
											<BaseTooltip 
												anchorEl={v2ModeElement} 
												placement="right"
												placementArrowClasses="right-full"
												placementArrowElClasses="w-full h-full rotate-45 border-select-border bg-style-method-enabled border-0 border-b border-l translate-x-1/2"
											>
												<h3 >
													<span className="text-title-white text-sm font-bold font-roboto">
														Logo Diffusion v.2 
													</span>
													<span className="text-title-white text-xs font-normal font-roboto">
														(2 Credits/Image)
													</span>
												</h3>
												<div className="font-roboto text-input-color text-xs font-normal ">
													Higher quality results, slower render time (1 minute per 4 images), minimum 1024x0124 image quality
												</div>
											</BaseTooltip>
											<BaseTooltip
												anchorEl={v1ModeElement}
												placement="right"
												placementArrowClasses="right-full"
												placementArrowElClasses="w-full h-full rotate-45 border-select-border bg-style-method-enabled border-0 border-b border-l translate-x-1/2"
											>
												<h3 >
													<span className="text-title-white text-sm font-bold font-roboto">
														Logo Diffusion v.1
													</span>
													<span className="text-title-white text-xs font-normal font-roboto">
														(1 Credit/Image)
													</span>
												</h3>
												<div className="font-roboto text-input-color text-xs font-normal ">
													Medium quality results, faster render time (30 seconds per 4 images) minimum 512x512 image quality.
												</div>
											</BaseTooltip>

											<Listbox.Options static>
												<Listbox.Option value={'v5.0'} as={React.Fragment}>
													<li
														ref={setV5ModeElement}
														className={`text-sm font-bold px-3 py-1 bg-overlay-bg text-white ui-active:text-opacity-30 ui-selected:text-app-green cursor-pointer`}
													>
														v5.0
													</li>
												</Listbox.Option>
												<Listbox.Option value={'v4.0'} as={React.Fragment}>
													<li
														ref={setV4ModeElement}
														className={`text-sm font-bold px-3 py-1 bg-overlay-bg text-white ui-active:text-opacity-30 ui-selected:text-app-green cursor-pointer`}
													>
														v4.0
													</li>
												</Listbox.Option>
												<Listbox.Option value={'v3.0'} as={React.Fragment}>
													<li
														ref={setV3ModeElement}
														className={`text-sm font-bold px-3 py-1 bg-overlay-bg text-white ui-active:text-opacity-30 ui-selected:text-app-green cursor-pointer`}
													>
														v3.0
													</li>
												</Listbox.Option>
												<Listbox.Option value={'v2.0'} as={React.Fragment}>
													<li
														ref={setV2ModeElement}
														className={`text-sm font-bold px-3 py-1 bg-overlay-bg text-white ui-active:text-opacity-30 ui-selected:text-app-green cursor-pointer`}
													>
														v2.0
													</li>
												</Listbox.Option>
												<Listbox.Option value={'v1.1'} as={React.Fragment}>
													<li
														ref={setV1ModeElement}
														className={`text-sm font-bold px-3 py-1 bg-overlay-bg text-white ui-active:text-opacity-70 ui-selected:text-app-green cursor-pointer`}
													>
														v1.1
													</li>
												</Listbox.Option>
											</Listbox.Options>
										</div>
									</Transition>
								</>
							)}
						</Listbox>
					</div>
					<button
						ref={setPromptEl}
						className='bg-transparent lg:bg-[#1C1C24] rounded-lg text-[#FAFAFB] text-[12px] xs:text-sm px-[10px] sm:px-[16px] py-[5px] sm:py-[10px] flex items-center gap-2 font-poppins font-semibold mr-[18px]'
						disabled={location.pathname === '/documentation'}
						onClick={e => dispatch(setIsTutorialModalOpen(true))}
					>
						<span className='hidden lg:inline'>Tutorials</span>
						<TutorialsIcon />
					</button>

					{isTooltipTutorial && (
						<div 
							className="flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-[13px] relative transition-all duration-300  w-80 rounded-md"
							ref={setTooltipEl}
							style={tutorialPopper.styles.popper}
							{...tutorialPopper.attributes.popper}
						>
							<div 
								ref={setTutorialArrowEl} 
								style={tutorialPopper.styles.arrow} 
								{...tutorialPopper.attributes.arrow} 
								className="h-3 w-3 inline-block bottom-full"
							>
								<div className="w-full h-full bg-style-method-enabled translate-x-1/2 translate-y-1/2 rotate-45 border-select-border border-solid border-0 border-t border-l"></div>
							</div>
							<div className='text-left'>
								<div className='inline-flex items-center gap-1'>
									<InfoIcon /><span className='text-[14px] text-title-white text-sm font-roboto'>You can always access tutorials here</span>
								</div>
								<div className='leading-5 text-[12px] font-roboto text-input-color'>We’ve prepared a few tutorials to get you started with Logo Diffusion, and help you get great results in no time.</div>
								<div className='flex justify-end'>
									<button className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3 cursor-pointer' onClick={hideTooltipTutorial}>GOT IT</button>
								</div>
							</div>
						</div>
					)}

					

					<Community />

					<a
						className='rounded-lg text-[#70707B] text-[10px] xs:text-sm flex items-center gap-1 font-poppins font-semibold'
						href="https://forms.gle/mELBcw24ya6BBo1F9"
						target='_blank' rel="noreferrer"
					>
						Report a bug
						<BugIcon className='h-[18px]'/>
					</a>
				</div>

				<div className='hidden lg:flex items-center gap-3'>
					<div className='text-[#92929D] self-stretch flex items-center gap-[5px] font-inter'>
						<span className='hidden lg:inline'>Credits</span>
						<TokenIconGreen className='w-[14px] h-[17px]'/>

						<span className='flex items-center gap-[2px]' ref={setUserCreditRef}>
							<span>{user?.remainingCredits + ((user?.plan?.active || user?.isSumoling) ? (user?.individualCredits || 0) : 0)}</span>
							{/* <span className='text-[#4F4F58]'>/</span>
							<span className='text-[#4F4F58]'>{user?.plan?.maxTokens}</span> */}
						</span>
						
						<BaseTooltip 
							anchorEl={userCreditRef}
							boxClasses="bg-overlay-bg border-0 text-white font-bold text-sm font-inter my-3 px-3 w-44"
							placement="bottom"
							placementArrowClasses="bottom-full"
							placementArrowElClasses='w-full h-full bg-overlay-bg -rotate-45 translate-y-1.5'
						>
							<div className="flex items-center ">
								Subscription <TokenIconGreen className='ml-1 w-[14px] h-[17px] inline-block'/>: {user?.remainingCredits}
							</div>
							<div className="flex items-center ">
								Individual <TokenIconGreen className='ml-1 w-[14px] h-[17px] inline-block'/>: {user?.individualCredits}
							</div>

						</BaseTooltip>

					</div>
					{user?.isSumoling ? appsumoLicense?.invoices && (
						<a
							id="generate-button"
							className={`bg-button-purple text-white font-semibold font-poppins rounded-md text-xs flex items-center justify-center gap-1 py-[9px] px-2`}
							href={`https://appsumo.com/account/redemption/${appsumoLicense?.invoices[appsumoLicense?.invoices?.length - 1]}#change-plan`}
						>
								<SparkleIcon className='max-md:[inline-size:1.75em] '/> 
								<span className='hidden md:inline'>Upgrade</span>
						</a>
					) : (
						<button
							id="generate-button"
							className={`bg-button-purple text-white font-semibold font-poppins rounded-md text-xs flex items-center justify-center gap-1 py-[9px] px-2`}
							onClick={handleUpgrade}
						>
								<SparkleIcon className='max-md:[inline-size:1.75em] '/> 
								<span className='hidden md:inline'>Upgrade</span>
						</button>
					)}

					{(user?.isAdmin || user?.isDev) && (
						<Switch
							checked={isDevModeEnabled}
							onChange={handleDevMode}
							className="relative inline-flex h-7 w-12 items-center rounded-full ui-checked:bg-button-purple ui-not-checked:bg-gray-label"
						>
							<span className="inline-block h-5 w-5 transform rounded-full bg-white transition ui-checked:translate-x-6 ui-not-checked:translate-x-1 uppercase text-[0.5rem] font-poppins font-bold flex items-center justify-center" >
								DEV
							</span>
						</Switch>
					)}

					<Link to='/profile' className='hidden lg:flex text-white text-sm border-white border-2 px-1 rounded-md bg-gray-400 font-poppins font-medium self-stretch items-center'>
						{getUsername()}
					</Link>

					<LogoutIcon className="cursor-pointer" onClick={handleLogout} />
				</div>

				<div className='flex items-center justify-end lg:hidden'>
					<div className='text-[#92929D] hidden sm:flex items-center gap-[5px] font-inter mr-[25px]'>
							<span className='hidden lg:inline'>Credits</span>
							<TokenIconGreen className='w-[14px] h-[17px]'/>

							<span className='flex items-center gap-[2px]'>
								<span>{user?.remainingCredits}</span>
								<span className='text-[#4F4F58]'>/</span>
								<span className='text-[#4F4F58]'>{user?.plan?.maxCredits || 100}</span>
							</span>
						</div>

					<MobileNavToggler onClick={e => dispatch(setIsMobileNavOpen(!isMobileNavOpen))} />
				</div>

        		<TutorialModal />

				{/* <QuestionnaireModal /> */}

			</div>
		</div>
	)
};

export default Navbar;

