import { ReactComponent as CloudUploadImageIcon } from '../assets/CloudUploadImageIcon.svg';
import { ReactComponent as TrashIconV4 } from '../assets/TrashIconWhiteV4.svg';
import { ReactComponent as EditCanvasIcon } from '../assets/EditCanvas.svg';
import { ReactComponent as CaretDownIconV4 } from '../assets/caret-up-v4.svg';
import { ReactComponent as UploadImageIconV4 } from '../assets/UploadImageIconV4.svg';
import { ReactComponent as InfoIcon } from '../assets/InfoIconGray.svg';

import { Disclosure } from '@headlessui/react';
import ImageCropper from './ImageCropper';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_COLORS,
  setBackgroundImage,
  setIsEditImage,
  setIsOpen,
  setPresetColor,
} from '../reducers/imageEditorReducer';
import { IMAGE_EXPORT_SIZE } from './editor/settings';
import { emptyJPEG } from './editor/image_generator';
import RedesignV4 from './redesign/RedesignV4';
import Tooltip from '../components/Tooltip';
import ImageSettingsV4 from './redesign/v4/ImageSettings';
import ImageSettingsV5 from './redesign/v5/ImageSettings';
import { partialUpdateStatePayload, updateInputImage } from '../reducers/formReducer';
import useGenerationEndpoint from './input/useGenerationEndpoint';

export function toDataURL(src, callback, outputFormat) {
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function () {
    var canvas = document.createElement('CANVAS');
    var ctx = canvas.getContext('2d');
    var dataURL;
    canvas.height = IMAGE_EXPORT_SIZE;
    canvas.width = IMAGE_EXPORT_SIZE;
    ctx.drawImage(this, 0, 0, IMAGE_EXPORT_SIZE, IMAGE_EXPORT_SIZE);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };
  img.src = src;
  if (img.complete || img.complete === undefined) {
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
    img.src = src;
  }
}

function FileUploadInput(props) {
  const uploaderRef = useRef();
  const dispatch = useDispatch();

  const openEmptyCanvas = () => {
    dispatch(setPresetColor(DEFAULT_COLORS));
    dispatch(setBackgroundImage(emptyJPEG));
    dispatch(setIsEditImage(false));
    dispatch(setIsOpen(true));
  };

  const PanelAttributes = useMemo(() => {
    return {
      className: 'flex flex-col gap-4 py-2 ',
    };
  }, []);

	const image = useSelector((state) => state.form.payload.inputImage);

	const [defaultOpen, setDefaultOpen] = useState(false);

	const { mode } = useGenerationEndpoint()

	useEffect(() => {
		if (image) setDefaultOpen(true)
	}, [image])

	const ImageSettings = useMemo(() => {
		if (mode === 'v5.0') return ImageSettingsV5
		if (mode === 'v4.0') return ImageSettingsV4
	}, [mode])

  return (
    <>
      <Disclosure
        className={`relative rounded-xl px-3 bg-style-bg py-4 p-2 flex flex-col`}
        as='div'
				defaultOpen={!!image || defaultOpen}
				key={!!image}
      >
        <Disclosure.Button
          className={`outline-none text-sm
            text-white flex items-center justify-between font-sans-pro text-[15.13px] font-semibold`}
        >
          <span className='flex'>
            Input Image
            <Tooltip title='Add an input reference image to match its form and depth' position='right'>
              <InfoIcon className='w-[20px] h-[20px] ml-1' />
            </Tooltip>
          </span>
          <CaretDownIconV4 className='transition-all duration-300 ui-open:-rotate-90 [&_path]:fill-chevron-icon w-6 h-6 max-h' />
        </Disclosure.Button>
        <Disclosure.Panel {...PanelAttributes}>
          <div className='flex flex-row justify-around items-center gap-4 border-gray-800 py-2 px-1'>
            <div
              className={`flex flex-col w-[52%] aspect-square items-center justify-center ${
                props.imageToUpload ? 'outline' : 'outline-dashed'
              } outline-2 outline-offset-2 outline-icon-text-color rounded-[8px]`}
            >
              <ImagePreview
                imageToUpload={props.imageToUpload}
                onRemoveUploadedImage={props.onRemoveUploadedImage}
                handleImageUpload={props.handleImageUpload}
              />
            </div>
            <div className='flex flex-col w-[48%] gap-2'>
              <button
                className='flex justify-start gap-[3px] items-center bg-button-purple p-[6px] rounded-[4px]'
                onClick={(e) => uploaderRef.current.click()}
              >
                <CloudUploadImageIcon className='w-[17px] h-[17px]' />
                <p className='text-white text-center text-[11px] font-semibold'>
                  Upload image
                </p>
              </button>

              <div className='h-full w-[2px] bg-white bg-opacity-10 shrink-0'></div>
              {!props.imageToUpload ? (
                <button
                  className='flex justify-start gap-[3px] items-center bg-button-purple p-[6px] rounded-[4px]'
                  onClick={openEmptyCanvas}
                >
                  <EditCanvasIcon className='w-[15px] h-[15px] [&_path]:fill-white' />
                  <p className='text-white text-center text-[11px] font-semibold whitespace-nowrap'>
                    Start sketching
                  </p>
                </button>
              ) : (
                <button
                  className='flex justify-start gap-[3px] items-center bg-app-bg-gray p-[6px] rounded-[4px]'
                  onClick={(e) => dispatch(setIsOpen(true))}
                >
                  <EditCanvasIcon className='w-[15px] h-[15px] [&_path]:fill-switch-label-white' />
                  <p className='text-switch-label-white text-center text-[11px] font-semibold'>
                    Edit Image
                  </p>
                </button>
              )}
            </div>
          </div>

          <input
            ref={uploaderRef}
            type='file'
            id='imgUpload'
            name='img'
            accept='image/*'
            onChange={(e) => props.handleImageUpload(e.target.files)}
            className=' hidden absolute opacity-0 '
          />
          {props.imageToUpload && <ImageSettings key="image-settings" />}
        </Disclosure.Panel>
      </Disclosure>
    </>
  );
}

function ImagePreview(props) {
  const uploaderRef = useRef();
  const dispatch = useDispatch();
  return (
    <>
      {props.imageToUpload ? (
        <div className='relative group overflow-hidden rounded-[8px]'>
          <>
            {' '}
            <button
              className='group/delete basis-[45%] flex-grow shrink-0 !outline-none absolute -top-3 right-[3px] gap-1 mt-4'
              onClick={(e) => props.onRemoveUploadedImage()}
            >
              <TrashIconV4 className='w-[20px] h-[20px] group-hover/delete:[&_path]:fill-app-green' />
            </button>
            <img
              className='min-h-full min-w-full rounded-[8px]'
              src={props.imageToUpload}
              alt='to upload'
            />
          </>
        </div>
      ) : (
        <button
          className='flex w-full h-full items-center justify-center'
          onClick={(e) => uploaderRef.current.click()}
        >
          <UploadImageIconV4 className='w-auto' />
          <input
            ref={uploaderRef}
            type='file'
            id='imgUpload'
            name='img'
            accept='image/*'
            onChange={(e) => props.handleImageUpload(e.target.files)}
            className=' hidden absolute opacity-0 '
          />
        </button>
      )}
    </>
  );
}

function ImageUploadV4(props) {
  const { renderInput } = props;

  const [isCropping, setIsCropping] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);

  const InputComponent = useMemo(
    () => renderInput || FileUploadInput,
    [renderInput]
  );

  const handleImageSelection = (files) => {
    const [file] = files;

    if (file) {
      const url = URL.createObjectURL(file);
      const image = new Image();

      image.onload = () => {
        const { width, height } = image;

        if (width !== height) {
          setImageToCrop(url);
          setIsCropping(true);
        } else {
          toDataURL(url, (base64) => {
            setImage(base64);
          });
        }
      };

      image.src = url;
    }
  };

	const dispatch = useDispatch()
	const image = useSelector((state) => state.form.payload.inputImage);

	const setImage = (image) => {
		dispatch(partialUpdateStatePayload({
			inputImage: image
		}))

    dispatch(updateInputImage(image));
    dispatch(setBackgroundImage(image));
    dispatch(setIsEditImage(true));
	}

  const handleImageCropped = (file, base64Image) => {
    const url = URL.createObjectURL(file);

    setImage(base64Image);
    // setImageToUpload(url);

    setImageToCrop(null);
    setIsCropping(false);
  };

  return (
    <>
      <ImageCropper
        isCropping={isCropping}
        imageToCrop={imageToCrop}
        onConfirm={handleImageCropped}
        onCancel={() => {
          setIsCropping(false);
          setImageToCrop(null);
        }}
      />
      <InputComponent
        handleImageUpload={handleImageSelection}
        imageToUpload={image}
        onRemoveUploadedImage={() => setImage(undefined)}
      />
    </>
  );
}

export default ImageUploadV4;
