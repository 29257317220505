import { Popover, RadioGroup, Transition, Disclosure } from '@headlessui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CaretDownIconV4 } from '../../assets/caret-up.svg';
import {
  partialUpdateStatePayload,
  updateShowStyleSelector,
  updateShowStyleSelectorTutorial,
} from '../../reducers/formReducer';
import STYLES, { NoneIcon, V3_GROUPS, V4_GROUPS } from './content-styles';
import useGenerationEndpoint from './useGenerationEndpoint';
import { CONFIG_PROPS, STYLE_SETTINGS } from './style-config/constants';
import Tooltip from '../../components/Tooltip';
import { V4_STYLES } from './v4-content-styles';
import { V5_GROUPS, V5_STYLES } from './v5-styles';

const V5StyleSelector = (props) => {
  const dispatch = useDispatch();

  const { method, generationStyle, setMethod, mode } = useGenerationEndpoint();

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [tutorialEl, setTutorialEl] = useState();
  const [tutorialArrowEl, setTutorialArrowEl] = useState();

  const [clickedIndex, _setClickedIndex] = useState('logo-design');
  const currentGroupRef = useRef('logo-design');
  const setClickedIndex = (x) => {
    currentGroupRef.current = x;
    _setClickedIndex(x);
  };

  const handleButtonClick = (index) => {
    setClickedIndex(index);
  };

  const filteredStyle = useMemo(() => {
    if (clickedIndex) {
      const _filteredStyle = STYLES[method].filter(
        (it) => it.group === clickedIndex
      );
      return _filteredStyle;
    }
    return STYLES[method];
  }, [clickedIndex, method]);

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'top',
    strategy: 'fixed',
  });

  let tutorialPopper = usePopper(popperEl, tutorialEl, {
    placement: 'right',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: tutorialArrowEl,
          padding: -50,
        },
      },
    ],
  });

  const style = useSelector((state) => state.form.payload.style);

  const isOpen = useSelector((state) => state.form.showStyleSelector);
  useEffect(() => {
    if (isOpen) {
      // anchorEl?.click();
      dispatch(updateShowStyleSelector(false));
    }
  }, [isOpen]);

  const SWITCH_STYLES = useMemo(() => {
    if (mode === 'v5.0') return V5_STYLES;
    if (mode === 'v4.0') return V4_STYLES;
  }, [mode]);

  const handleCloseTutorial = () => {
    dispatch(updateShowStyleSelectorTutorial(false));
  };

  const showTutorial = useSelector(
    (state) => state.form.showStyleSelectorTutorial
  );

  const currentStyle = useMemo(
    () => STYLES[method].find((item) => item.id === style),
    [method, style]
  );
  const CurrentStyleIcon = useMemo(
    () => currentStyle?.icon || NoneIcon,
    [currentStyle]
  );

  useEffect(() => {
    if (
      currentGroupRef.current !== currentStyle?.group &&
      currentStyle?.group
    ) {
      setClickedIndex(currentStyle.group);
    }
  }, [currentStyle]);

  const handleStyle = (value) => {
    const style = SWITCH_STYLES.find((item) => item.id === value);
    console.log('Setting endpoint', style.endpoint);
    dispatch(partialUpdateStatePayload({ style: value }));
    setMethod(style.endpoint);
  };

  if (generationStyle === 'style-transfer') {
    return (
      <div
        className=' relative rounded-xl bg-style-bg py-[9px] p-2 flex flex-row justify-between items-center font-poppins outline-none text-sm text-select-label-disabled f
			ont-semibold  '
      >
        <div className='flex items-center gap-4 justify-start'>
          <div className='flex flex-col items-start font-sans-pro text-[15.13px] font-semibold'>
            <span className='flex'>
              Style Categories
              <Tooltip
                title='Style categories cannot be used alongside a style image'
                position='right'
              >
                <InfoIcon className='w-[20px] h-[20px] ml-1' />
              </Tooltip>
            </span>
            <p className='truncate text-icon-text-color font-sans-pro text-[10px] grow text-center'>
              {currentStyle?.name}
            </p>
          </div>
        </div>
        <CaretDownIconV4 className='transition-all duration-300 -rotate-90 ui-open:-rotate-90 [&_path]:fill-chevron-icon w-3 h-3 max-h' />
      </div>
    );
  }

  return (
    <Popover as={React.Fragment}>
      {({ open, close }) => (
        <>
          <Popover.Button
            className='relative aspect-square flex-shrink-0 w-[69px] h-[69px] rounded-md overflow-hidden my-auto max-md:hidden bg-app-bg-gray'
            ref={setAnchorEl}
          >
            <div className='flex items-center gap-4 justify-start'>
							<div className="h-[69px] w-[69px] ">
								<CurrentStyleIcon
									className={`max-h-full `}
								/>
							</div>
              <p className='truncate font-roboto font-medium text-xs grow text-center absolute bottom-0 left-0 right-0 bg-black/80 text-v5-style-selector-label px-1 py-[2px]'>
                {currentStyle?.name}
              </p>
            </div>
          </Popover.Button>
          <Transition
            as={React.Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
            {...props}
          >
            <Popover.Panel
              className='border border-solid border-app-search-gray rounded-xl bg-app-bg-gray pb-3 mb-4 flex gap-3 w-112 h-[286px] relative'
              ref={setPopperEl}
              style={styles.popper}
              {...attributes.popper}
            >
              <div className='flex flex-col w-40 h-full pr-2 pl-6 pt-4'>
                <h3 className='font-bold flex flex-row gap-1 items-center text-title-white text-base col-start-1 col-end-4'>
                  Styles
                </h3>
                {V3_GROUPS.map(({ icon: Icon, ...item }, index) => {
                  return (
                    <button
                      key={index}
                      className={`flex items-center justify-start bg-app-bg-gray max-w-[123px] p-[5px] gap-[3px] rounded-[8px] my-[2px] text-[13px] ${
                        item.id === clickedIndex
                          ? 'border border-1 border-app-green'
                          : ''
                      }`}
                      onClick={() => handleButtonClick(item.id)}
                    >
                      <Icon
                        className={`w-[24px] h-[24px] ${
                          item.id === clickedIndex ? '[&_path]:fill-white' : ''
                        }`}
                      />
                      <div
                        className={`${
                          item.id === clickedIndex ? 'text-white' : ''
                        }`}
                      >
                        {item.name}
                      </div>
                    </button>
                  );
                })}
              </div>
              <div
                className={`bg-app-bg-gray w-72 h-full transition-all duration-500 overflow-hidden pt-4 px-2 `}
              >
                <RadioGroup
                  value={style}
                  onChange={handleStyle}
                  className='grid grid-cols-3 gap-3 [&>div]:text-style-text [&>div]:text-xs max-h-full overflow-x-hidden overflow-y-auto custom-scroll px-2'
                >
                  {filteredStyle.map(({ icon: Icon, ...item }) => {
                    return (
                      <RadioGroup.Option
                        disabled={item.disabled}
                        key={item.id}
                        value={item.id}
                        className='flex flex-col items-center gap-1 relative cursor-pointer ui-disabled:cursor-not-allowed aspect-square rounded-lg bg-style-bg ui-disabled:bg-style-disabled border border-solid border-style-bg ui-checked:border-app-green overflow-hidden'
                      >
                        <Icon
                          className={`${item.icon_classes}`}
                        />
                        <div className='bg-black/80 p-1 text-[0.7rem] font-roboto [line-height:0.8rem] absolute bottom-0 right-0 left-0 text-v5-style-selector-label line-clamp-2 text-ellipsis whitespace-normal text-center'>
                          {item.name}
                        </div>
                        {item.beta && (
                          <div className='bg-app-green/10 rounded-[0.2rem] px-1 text-app-green text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                            Beta
                          </div>
                        )}
                        {item.new && (
                          <div className='bg-app-green/10 rounded-[0.2rem] px-1 text-app-green text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                            New
                          </div>
                        )}
                        {item.soon && (
                          <div className='bg-white/10 rounded-[0.2rem] px-1 text-style-soon-badge text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                            Soon
                          </div>
                        )}
                      </RadioGroup.Option>
                    );
                  })}
                </RadioGroup>
              </div>

              {showTutorial && (
                <div
                  className='flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300 mr-4 w-60 rounded-md'
                  ref={setTutorialEl}
                  style={tutorialPopper.styles.popper}
                  {...tutorialPopper.attributes.popper}
                >
                  <div
                    ref={setTutorialArrowEl}
                    style={tutorialPopper.styles.arrow}
                    {...tutorialPopper.attributes.arrow}
                    className='h-3 w-3 inline-block left-full '
                  >
                    <div className='w-full h-full bg-style-method-enabled -translate-y-10 -translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-t border-r'></div>
                  </div>
                  <div className='flex items-center justify-start gap-2'>
                    <span>
                      <InfoIcon />
                    </span>
                    <span className='text-title-white text-sm font-roboto'>
                      Choose a style first.
                    </span>
                  </div>
                  <div className='font-roboto text-input-color text-xs '>
                    Before you can generate your first image, you need to pick a
                    logo design style.
                  </div>
                  <div className='flex items-center justify-end mt-1'>
                    <button
                      className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3'
                      onClick={handleCloseTutorial}
                    >
                      Got It
                    </button>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default V5StyleSelector;
