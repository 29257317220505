import { ReactComponent as PictorialStyle } from '../../assets/styles/v5/pictorial.svg';
import { ReactComponent as MascotStyle } from '../../assets/styles/v5/mascot.svg';
import { ReactComponent as RetroMascotStyle } from '../../assets/styles/v5/retro-mascot.svg';
import { ReactComponent as BadgeStyle } from '../../assets/styles/v5/badge.svg';
import { ReactComponent as CartoonStyle } from '../../assets/styles/v5/cartoon.svg';
import { ReactComponent as IconStyle } from '../../assets/styles/v5/icon.svg';
import { ReactComponent as AbstractStyle } from '../../assets/styles/v5/abstract.svg';
import { ReactComponent as LineArt } from '../../assets/styles/v5/lineart.svg';
import { ReactComponent as LogoType } from '../../assets/styles/v5/logotype.svg';
import { ReactComponent as Monogram } from '../../assets/styles/v5/monogram.svg';
import { ReactComponent as Engraved } from '../../assets/styles/v5/engraved.svg';

import { ReactComponent as CancelIcon } from '../../assets/CancelIcon.svg';
import { ReactComponent as LogoDesign } from '../../assets/styles/v3/logo-design.svg';
import { ReactComponent as None } from '../../assets/styles/v3/none.svg';

import ThStylized3D from '../../assets/styles/v3/stylized-3d.jpg';
import ThRealistic3D from '../../assets/styles/v3/realistic-3d.jpg';
import Th3DText from '../../assets/styles/v3/3d-text.jpg';
import ThInflated from '../../assets/styles/v3/inflated.jpg';
import ThEmbroidered from '../../assets/styles/v3/embroidered.jpg';
import ThIsometric from '../../assets/styles/v3/isometric.jpg';
import ThLowPoly from '../../assets/styles/v3/Low-poly.jpg';

import ThStylizedCartoon from '../../assets/styles/v3/stylized-cartoon.jpg';
import ThPixelArt from '../../assets/styles/v3/pixel-art.jpg';
import ThFlatGraphics from '../../assets/styles/v3/flat-graphic.jpg';
import ThColoringBook from '../../assets/styles/v3/coloring-book.jpg';
import ThCyberPunk from '../../assets/styles/v3/cyberpunk.jpg';
import ThSticker from '../../assets/styles/v3/sticker.jpg';
import ThMarker from '../../assets/styles/v3/marker.jpg';
import ThColorSketchNote from '../../assets/styles/v3/color-sketch-note.jpg';
import ThSketchNote from '../../assets/styles/v3/sketchnote.jpg';
import ThDigitalArt from '../../assets/styles/v3/digital-art.jpeg';
import ThFantasyArt from '../../assets/styles/v3/fantasy.jpg';
import ThPopArt from '../../assets/styles/v3/popart.jpg';
import ThComicArt from '../../assets/styles/v3/comic-art.jpg';
import ThManga from '../../assets/styles/v3/manga.jpg';
import ThKawaii from '../../assets/styles/v3/kawaii.jpg';
import ThPunkCollage from '../../assets/styles/v3/punk.jpg';
import ThDarkArts from '../../assets/styles/v3/dark-art.jpg';
import ThCharcoalSketch from '../../assets/styles/v3/charcoal.jpg';
import ThHalftone from '../../assets/styles/v3/halftone.jpg';

import ThCinematic from '../../assets/styles/v3/cinematic.jpg';
import ThHyperrealism from '../../assets/styles/v3/hyperreal.jpg';
import ThCraftClay from '../../assets/styles/v3/craft-clay.jpg';
import ThFuturistic from '../../assets/styles/v3/futuristic.jpg';
import ThOrigami from '../../assets/styles/v3/origami.jpg';
import ThPaperCraft from '../../assets/styles/v3/papercraft.jpg';

import ThOilPainting from '../../assets/styles/v3/oil-painting.jpg';
import ThFantasyIllustration from '../../assets/styles/v3/fantasy-illustration.jpg';
import ThWaterColor from '../../assets/styles/v3/watercolor.jpg';
import ThArtDeco from '../../assets/styles/v3/art-deco.jpg';
import ThBauhaus from '../../assets/styles/v3/bauhaus.jpg';
import { twMerge } from 'tailwind-merge';

const ImageThumbnail = (image, props={}) => (new_props={}) => <img src={image} {...props} {...new_props} className={twMerge(props.className, new_props.className)}/>;

const V5ImageThumbnail = (Image, props={}) => (
	(new_props={}) => (
		<div className="flex items-center justify-center px-[6px] pt-[6px] pb-[26px] w-full h-full">
			<Image {...props} {...new_props} className={twMerge("w-full h-full", props.className, new_props.className)}/>
		</div>
	));

export const V5_GROUPS = [
  {
    id: 'logo-design',
    name: 'Logo Design',
    icon: LogoDesign,
  },
  {
    id: 'none',
    name: 'None',
    icon: None,
  },
];

export const V5_STYLES = [
  //logo design
	{
		id: 'pictorial',
			name: 'Pictorial',
			icon: V5ImageThumbnail(PictorialStyle),
			icon_preview: PictorialStyle,
			group: 'logo-design',
			endpoint: 'v5/fx',
	},
	{
		id: 'mascot',
		name: 'Mascot',
		icon: V5ImageThumbnail(MascotStyle),
		icon_preview: MascotStyle,
		group: 'logo-design',
		endpoint: 'v5/fx',
	},
	{
		id: 'retro-mascot',
		name: 'Retro Mascot',
		icon: V5ImageThumbnail(RetroMascotStyle),
		icon_preview: RetroMascotStyle,
		group: 'logo-design',
		endpoint: 'v5/fx',
		new: true,
	},
	{
		id: 'badge',
		name: 'Badge/Crest',
		icon: V5ImageThumbnail(BadgeStyle),
		icon_preview: BadgeStyle,
		group: 'logo-design',
		endpoint: 'v5/fx',
	},
	{
		id: 'cartoon',
		name: 'Cartoon',
		icon: V5ImageThumbnail(CartoonStyle),
		icon_preview: CartoonStyle,
		group: 'logo-design',
		endpoint: 'v5/fx',
	},
	{
		id: 'logo-type',
		name: 'Logo Type',
		icon: V5ImageThumbnail(LogoType),
		icon_preview: LogoType,
		new: true,
		group: 'logo-design',
		endpoint: 'v5/fx',
	},
	{
		id: 'abstract',
		name: 'Abstract',
		icon: V5ImageThumbnail(AbstractStyle),
		icon_preview: AbstractStyle,
		group: 'logo-design',
		endpoint: 'v5/fx',
	},
	{
		id: 'line-art',
		name: 'Line Art',
		icon: V5ImageThumbnail(LineArt),
		icon_preview: LineArt,
		icon_preview_classes: 'pt-1 px-[2px]',
		group: 'logo-design',
		endpoint: 'v5/fx',
	},
	{
		id: 'icon',
		name: 'Icon/Emoji',
		icon: V5ImageThumbnail(IconStyle),
		icon_preview: IconStyle,
		group: 'logo-design',
		endpoint: 'v5/fx',
	},
	{
		id: 'monogram',
		name: 'Monogram',
		icon: V5ImageThumbnail(Monogram),
		icon_preview: Monogram,
		new: true,
		group: 'logo-design',
		endpoint: 'v5/fx',
	},
	{
		id: 'engraved',
		name: 'Engraved',
		icon: V5ImageThumbnail(Engraved),
		icon_preview: Engraved,
    new: true,
    group: 'logo-design',
		endpoint: 'v5/fx',
  },

	// 2D
  {
    id: 'stylized-cartoon',
    name: 'Stylized Cartoon',
    icon: ImageThumbnail(ThStylizedCartoon),
    group: '2d-art',
    new: true,
		endpoint: 'v5/fx',
  },
  {
    id: 'pixel-art',
    name: 'Pixel Art',
    icon: ImageThumbnail(ThPixelArt),
    group: '2d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'flat-graphics',
    name: 'Flat Graphics',
    icon: ImageThumbnail(ThFlatGraphics),
    group: '2d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'coloring-book',
    name: 'Coloring book',
    icon: ImageThumbnail(ThColoringBook),
    group: '2d-art',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'cyberpunk',
    name: 'CyberPunk',
    icon: ImageThumbnail(ThCyberPunk),
    group: '2d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'sticker',
    name: 'Sticker',
    icon: ImageThumbnail(ThSticker),
    group: '2d-art',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'marker-drawing',
    name: 'Marker Drawing',
    icon: ImageThumbnail(ThMarker),
    group: '2d-art',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'sketch-note',
    name: 'Sketch Note',
    icon: ImageThumbnail(ThSketchNote),
    group: '2d-art',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'color-sketch-note',
    name: 'Color Sketch Note',
    icon: ImageThumbnail(ThColorSketchNote),
    group: '2d-art',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'digital-art',
    name: 'Digital art',
    icon: ImageThumbnail(ThDigitalArt),
    group: '2d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'fantasy-art',
    name: 'Fantasy art',
    icon: ImageThumbnail(ThFantasyArt),
    group: '2d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'pop-art',
    name: 'Pop Art',
    icon: ImageThumbnail(ThPopArt),
    group: '2d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'comic-art',
    name: 'Comic Art',
    icon: ImageThumbnail(ThComicArt),
    group: '2d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'manga',
    name: 'Manga',
    icon: ImageThumbnail(ThManga),
    group: '2d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'kawaii',
    name: 'Kawaii',
    icon: ImageThumbnail(ThKawaii),
    group: '2d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'punk-collage',
    name: 'Punk Collage',
    icon: ImageThumbnail(ThPunkCollage),
    group: '2d-art',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'dark-arts',
    name: 'Dark Arts',
    icon: ImageThumbnail(ThDarkArts),
    group: '2d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'charcoal-sketch',
    name: 'Charcoal Sketch',
    icon: ImageThumbnail(ThCharcoalSketch),
    group: '2d-art',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'halftone',
    name: 'Halftone',
    icon: ImageThumbnail(ThHalftone),
    group: '2d-art',
		endpoint: 'v5/fx',
    new: true,
  },

	// 3D
  {
    id: 'stylized-3d',
    name: 'Stylized 3D',
    icon: ImageThumbnail(ThStylized3D),
    group: '3d-art',
		endpoint: 'v5/fx',
  },
  {
    id: '3d',
    name: 'Realistic 3D',
    icon: ImageThumbnail(ThRealistic3D),
    group: '3d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'inflated',
    name: 'Inflated',
    icon: ImageThumbnail(ThInflated),
    group: '3d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'embroidered',
    name: 'Embroidered',
    icon: ImageThumbnail(ThEmbroidered),
    group: '3d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'isometric',
    name: 'Isometric',
    icon: ImageThumbnail(ThIsometric),
    group: '3d-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'low-poly',
    name: 'Low Poly',
    icon: ImageThumbnail(ThLowPoly),
    group: '3d-art',
		endpoint: 'v5/fx',
  },
  {
    id: '3d-text',
    name: '3D Text',
    icon: ImageThumbnail(Th3DText),
    group: '3d-art',
		endpoint: 'v5/fx',
    beta: true,
  },

	// Realistic
  {
    id: 'cinematic',
    name: 'Cinematic',
    icon: ImageThumbnail(ThCinematic),
    group: 'realistic',
		endpoint: 'v5/fx',
  },
  {
    id: 'hyperrealism',
    name: 'Hyperrealism',
    icon: ImageThumbnail(ThHyperrealism),
    group: 'realistic',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'craft-clay',
    name: 'Craft Clay',
    icon: ImageThumbnail(ThCraftClay),
    group: 'realistic',
		endpoint: 'v5/fx',
  },
  {
    id: 'futuristic',
    name: 'Futuristic',
    icon: ImageThumbnail(ThFuturistic),
    group: 'realistic',
		endpoint: 'v5/fx',
  },
  {
    id: 'origami',
    name: 'Origami',
    icon: ImageThumbnail(ThOrigami),
    group: 'realistic',
		endpoint: 'v5/fx',
  },

	// Fine Art
  {
    id: 'oil-painting',
    name: 'Oil Painting',
    icon: ImageThumbnail(ThOilPainting),
    group: 'fine-art',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'fantasy-illustration',
    name: 'Fantasy Illustration',
    icon: ImageThumbnail(ThFantasyIllustration),
    group: 'fine-art',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'watercolor',
    name: 'Water Color',
    icon: ImageThumbnail(ThWaterColor),
    group: 'fine-art',
		endpoint: 'v5/fx',
  },
  {
    id: 'art-deco',
    name: 'Art Deco',
    icon: ImageThumbnail(ThArtDeco),
    group: 'fine-art',
		endpoint: 'v5/fx',
    new: true,
  },
  {
    id: 'bauhaus',
    name: 'Bauhaus',
    icon: ImageThumbnail(ThBauhaus),
    group: 'fine-art',
		endpoint: 'v5/fx',
    new: true,
  },


  // none

  {
    id: 'none',
    name: 'None',
    icon: CancelIcon,
    icon_classes: 'p-3',
    group: 'none',
		endpoint: 'v5/fx',

  },
];
