import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMethod } from "../../reducers/formReducer";


export const MODES = {
  'v1.1': {
		__default: 'redesign',
    '2d': 'redesign',
    '3d': 'ddd',
  },
  'v2.0': {
		__default: 'sdxl',
    '2d': 'sdxl',
    '3d': 'sdxl-3d',
  },
  'v3.0': {
		__default: 'v3/2d',
    '2d': 'v3/2d',
    lightning: 'v3/lightning',
    cascade: 'v3/cascade',
  },
  'v4.0': {
		__default: 'v4/xl',
    'waterfall': 'v4/waterfall',
    'bolt': 'v4/bolt',
    'xl': 'v4/xl',
    'style-transfer': 'v4/style-transfer',
  },
  'v5.0': {
		__default: 'v5/fx',
    'fx': 'v5/fx',
    'style-transfer': 'v5/style-transfer',
  },
}

export const getGenerationVersion = (method) => {
	if(method.startsWith('v5/')) {
		return 'v5.0'
	} else if(method.startsWith('v4/')) {
		return 'v4.0'
	} else if (method.startsWith('v3/')) {
		return 'v3.0'
	}		return method.includes('sdxl') ? 'v2.0' : 'v1.1'
}

const useGenerationEndpoint = (props={}) => {
  const { method: overrideMethod = null } = props
  const interalMethod = useSelector((state) => state.form.method);

  const method = useMemo(() => {
    if (overrideMethod === 'gen') return 'redesign';
    return overrideMethod || interalMethod;
  }, [interalMethod, overrideMethod])

  const dispatch = useDispatch();

  const mode = useMemo(() => {
		return getGenerationVersion(method)
	}, [method])
  const generationStyle = useMemo(() => {
		const obj = Object.entries(MODES[mode]).filter(([key, val]) => key !== '__default').find((item) => item[1] === method)
		if (obj) return obj[0]
		return null;
	}, [method, mode]);

  const setMethod = (value) => {
    dispatch(updateMethod(value));
  };

  const setMode = (newMode) => {
    setMethod(MODES[newMode][generationStyle] ?? MODES[newMode].__default);
  }
  const setGenerationStyle = (newGenerationStyle) => {
    setMethod(MODES[mode][newGenerationStyle]);
  }

  return {
    method,
    setMethod,
    mode,
    setMode,
    generationStyle,
    setGenerationStyle,
  }
}

export default useGenerationEndpoint;
