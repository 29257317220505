import { Helmet } from 'react-helmet';
import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import UserLayout from '../layouts/UserLayout';
import ImageComparison from '../components/ImageComparison';
import SpinnerLoader from '../components/base/SpinnerLoader';
import {
  bgRemoveTool,
  updateAfterBgRemoveImage,
  updateInputImage,
} from '../reducers/formReducer';
import {
  DEFAULT_COLORS,
  setBackgroundImage,
  setIsEditImage,
  setPresetColor,
} from '../reducers/imageEditorReducer';
import { emptyJPEG } from '../components/editor/image_generator';

import { ReactComponent as BgRemoveIcon } from '../assets/bg-remove-icon.svg';
import { ReactComponent as UpscaleUploadImageIcon } from '../assets/UpscaleUploadImageIcon.svg';
import { ReactComponent as RemoveBackgroundIcon } from '../assets/RemoveBG.svg';
import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../assets/arrow-back.svg';
import { ReactComponent as Download } from '../assets/DownloadIcon.svg';
import { convertImageToBase64 } from '../helpers/convertUrlToBase64';

const BgRemover = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.userSlice.user);
  const afterImage = useSelector((state) =>
    state.form.afterBgRemoveImage
      ? `data:image/png;base64,${state.form.afterBgRemoveImage}`
      : null
  );
  const isRemovingBgTool = useSelector((state) => state.form.isRemovingBgTool);

	const [generationId, setGenerationId] = useState();

  const dispatch = useDispatch();
  const uploadRef = useRef();

  const [beforeImage, setBeforeImage] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [showRemoveBgSpinner, setShowRemoveBgSpinner] = useState(false);
  const isImageUploaded = !!beforeImage;

  const doneBgRemove = useMemo(() => {
    return afterImage && beforeImage;
  }, [afterImage, beforeImage]);

  const handleImageUpload = useCallback(
    (event) => {
      const file = event.target.files[0];

      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          setBeforeImage(reader.result);
          setFileName(file.name);
        };
        reader.readAsDataURL(file);
      }
    },
    [setBeforeImage, setFileName]
  );

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          setBeforeImage(reader.result);
          setFileName(file.name);
        };
        reader.readAsDataURL(file);
      }
    },
    [setBeforeImage, setFileName]
  );

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleRemoveImage = useCallback(() => {
    setBeforeImage(null);
    setFileName(null);
  }, [setBeforeImage, setFileName]);

  const handleBgRemove = async (e) => {
    e.stopPropagation();
    setShowRemoveBgSpinner(true);
    try {
      await dispatch(bgRemoveTool(beforeImage)).unwrap();
    } catch (e) {
      console.log(e);

      toast(e?.error?.detail || 'Failed to remove background', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
    setShowRemoveBgSpinner(false);
  };

  const handleBack = useCallback(() => {
    dispatch(updateAfterBgRemoveImage());
    // setBeforeImage(null);
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateAfterBgRemoveImage());
  }, []);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = afterImage;
    link.download = `LoDi-${fileName}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImgToImg = () => {
    dispatch(updateInputImage(afterImage));
    dispatch(setPresetColor(DEFAULT_COLORS));
    dispatch(setBackgroundImage(emptyJPEG));
    dispatch(setIsEditImage(false));
    navigate('/gen/image-workspace?fromBGRemover=true');
  };

  const [searchParams] = useSearchParams();
	const [isLoadingImage, setIsLoadingImage] = useState(false)

	const loadUrlImage = async (url) => {
		setIsLoadingImage(true)
		try {
      const base64 = await convertImageToBase64(url, null, null);
			setBeforeImage(base64)
		
		} catch (e) {
      console.log(e);
      toast(e?.error?.detail || 'Failed to vectorize image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
		} finally {
			setIsLoadingImage(false)
		}
	}

	useEffect(() => {
		if (searchParams.get('generationId')) {
			setGenerationId(searchParams.get('generationId'))
		}

		console.log("Search params", searchParams)

		if (searchParams.get('imageUrl')) {
			loadUrlImage(searchParams.get('imageUrl'))
		}

		if (searchParams.get('fileName')) {
			setFileName(searchParams.get('fileName'))
		}
	}, [searchParams])

  return (
    <UserLayout containerClasses='flex flex-col items-center justify-center h-full'>
      <Helmet>
        <title>Background Remover | Logo Diffusion</title>
      </Helmet>
      {!isRemovingBgTool && !doneBgRemove ? (
        <div className='flex flex-col items-center w-full pt-2'>
          <BgRemoveIcon className='w-12 h-12' />
          <h1 className='text-white text-[52px] text-center font-bold mt-4 uppercase'>
            Background remover
          </h1>
          <p className='text-base text-center text-app-green mt-[12px] px-2 text-center'>
            Remove Backgrounds from your images in seconds with logo Diffusion
          </p>
          {!beforeImage ? (
            <div className='flex items-center justify-center bg-app-bg-gray h-[240px] w-[80%] mt-[42.19px] rounded-[8px] p-4'>
              <input
                type='file'
                accept='image/*'
                className='hidden'
                ref={uploadRef}
                onChange={handleImageUpload}
              />
              <div
                className='w-full h-full border-2 border-[#30303E] border-dashed rounded flex items-center justify-center relative'
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <button
                  type='button'
                  className='w-full flex flex-col items-center'
                  onClick={() => uploadRef.current.click()}
                >
                  <UpscaleUploadImageIcon className='w-8 h-8 mb-4' />
                  <p className='text-white text-base'>
                    Click to upload your image or drag and drop it here
                  </p>
                </button>

									<SpinnerLoader isLoading={isLoadingImage} />
              </div>
            </div>
          ) : (
						<div className='flex flex-col max-h-[calc(100vh-370px)] max-w-[90%] md:max-w-[65%] mt-4 rounded-[20px]'>
							<img
								src={beforeImage}
								alt='uploaded'
								className='h-[calc(100%-48px)] rounded-t-[14px]'
							/>
							<div className='flex items-center justify-between whitespace-nowrap w-full bg-[#E7E7E7] rounded-b-[14px] px-3 py-2'>
								<p className='text-ellipsis overflow-hidden'>{fileName}</p>
								<button
									onClick={handleRemoveImage}
									className='text-red-500 rounded text-2xl'
								>
									<span>&times;</span>
								</button>
							</div>
						</div>
          )}
          <div className='flex items-center justify-evenly w-auto gap-[17px] rounded-[24px] mt-4'>
            <button
              className={`flex justify-center items-center h-[40px] px-[10px] text-[14px] font-semibold text-white bg-button-purple rounded-[12px] cursor-pointer ${
                !isImageUploaded ? 'pointer-events-none opacity-50' : ''
              }`}
              onClick={handleBgRemove}
              disabled={!isImageUploaded}
            >
              Remove Background{' '}
              <span className='text-[10px] text-[#B7A3F1]'> (2) credits</span>
            </button>
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center px-5 py-2'>
          {!doneBgRemove ? (
            <div className='relative flex items-center justify-center bg-app-bg-gray h-auto max-h-[77vh] w-auto max-w-[90vw] mt-[21px] rounded-[20px] border-[4.3px] border-[#30303E] border-dashed'>
              <img
                src={beforeImage}
                alt='uploaded'
                className='w-full h-full object-cover rounded-[20px]'
              />
              <SpinnerLoader
                classNames={{
                  backdrop: 'z-30 !rounded-[20px]',
                }}
                isLoading={showRemoveBgSpinner}
                icon={<RemoveBackgroundIcon />}
              />
            </div>
          ) : (
            <div className='relative h-auto max-h-[77vh] w-auto max-w-[90vw]'>
              <ImageComparison
                beforeImage={beforeImage}
                afterImage={afterImage}

								handleDownload={handleDownload}
								handleBack={handleBack}

								handleImageToImage={handleImgToImg}
              />
            </div>
          )}
					{!doneBgRemove && (
						<div className='flex items-center justify-evenly w-auto gap-[17px] px-4 h-[72px] bg-app-bg-gray rounded-[24px] mt-[38px]'>
              <button className='flex justify-center items-center h-[40px] px-[10px] text-[14px] font-semibold text-white bg-button-purple rounded-[12px]'>
                Removing <span className='text-[10px] text-[#B7A3F1]'>...</span>
              </button>
						</div>
					)}
        </div>
      )}
    </UserLayout>
  );
};

export default BgRemover;
