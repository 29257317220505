
import { renderToString } from "react-dom/server";
import { ReactComponent as Lightning } from "../assets/Lightning.svg";
import { ReactComponent as CloseIcon } from "../assets/CloseIcon.svg";

export const getValueAndImportance = (val) => {
  if (/^\(.+:\d.\d\)$/g.test(val)) {
    const [pureValue, importanceStr] = val.replace(/^\(+/g, "").replace(/\)+$/g, "").split(':');

    const importanceValue = Number(importanceStr.split('.')[1]);

    return [pureValue, Math.ceil(importanceValue / 2)];
  } 

  return [val, 0];
}

export const getPromptText = val => {
	let result = [''];
	let parantheses_count = 0;
	val.split('').forEach((element) => {
		if (parantheses_count === 0 && /^\s$/g.test(element)) {
			result.push('');
			return;
		}

		if (element === '(') parantheses_count++;
		if (element === ')') parantheses_count--;

		result[result.length - 1] += element;
	});
	return result.map(x => getValueAndImportance(x)[0]).join(' ');
}


export const setImportance = (val, cnt) => {

  let pureValue = val
  if (/^\(.+:\d.\d\)$/g.test(val)) pureValue = val.replace(/^\(+/g, "").replace(/\)+$/g, "").split(':')[0];

  const currentImportanceLevel = /^\(.+:\d.\d\)$/g.test(val) ? Number(val.replace(/^\(+/g, "").replace(/\)+$/g, "").split(':')[1].split(".")[1]) : 0

  const newImportanceLevel = cnt == currentImportanceLevel ? 0 : cnt || 0;

  const importanceStr = [...Array(newImportanceLevel)].map(() => "+").join('')

  if (newImportanceLevel === 0) 
    return pureValue

  return `(${pureValue}:1.${newImportanceLevel * 2})`
};

export const convertToHtml = (value, options = { editable: true }) => {
  return renderToString(
    [
      (value.length > 0 && value[0].endsWith('+') ? '\uFEFF' : undefined),
      ...value
        .map((item, ind) => {
          // const pureValue = item.replace(/\++$/g, "").replace(/^\(+/g, "").replace(/\)+$/g, "");
          // const importanceLevel = item.split("").reverse().reduce((sum, cur, ind, arr) => cur === '+' ? sum + (sum === ind): sum, 0)

          const [pureValue, importanceLevel] = getValueAndImportance(item)

          if (importanceLevel === 0) return pureValue;
          return (
            <span
              id={ind}
              data-importance={importanceLevel}
              data-pos={ind}
              style={{
                // position: "relative",
                // borderRadius: "0.5em",
                border: "2px solid transparent",
                ...(importanceLevel > 0 && {
                  borderColor: "#292932",
                }),
                // padding: "0.25em",
              }}
              contentEditable="false"
              className="p-1 rounded-lg inline-block "
            >
              {[...Array(Number(importanceLevel))].map((item) => {
                return <Lightning className="[&>*]:fill-app-green inline h-3 w-[0.375rem] mr-1" key={item} />;
              })}
              <span contentEditable={options.editable ? "true" : "false"} className="focus:outline-0">
                {pureValue}
              </span>
              {options.editable && <CloseIcon data-pos={ind} data-action="unimportant" className="inline w-4 h-4 ml-1 cursor-pointer" contentEditable="false" />}
            </span>
          );
        })
        .reduce(
          (arr, val, curInd, initArr) => [...arr, val, " ", ],
          []
        ),
      (value.length > 0 && value[value.length - 1].endsWith('+') ? '\uFEFF' : undefined),
    ].filter((item) => !!item)
  );
};

export const parentheseSplit = (str) => {
  let result = ['']
  let parantheses_count = 0;
  str.split('').forEach(element => {
    if (parantheses_count === 0 && /^\s$/g.test(element)) {
      result.push('')
      return;
    }

    if (element === '(') parantheses_count++
    if (element === ')') parantheses_count--

    result[result.length - 1] += element
  });

  return result.filter(item => !!item)
}
