import { Popover, RadioGroup, Transition, Disclosure } from '@headlessui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SettingsIcon } from '../../assets/styles/style-config/settings-icon.svg';
import { ReactComponent as RandomizeIcon } from '../../assets/styles/style-config/randomize.svg';
import { ReactComponent as CarrotIcon } from '../../assets/CaretDownIcon.svg';
import { ReactComponent as CaretDownIconV4 } from '../../assets/caret-up-v4.svg';
import {
  partialUpdateStatePayload,
  resetStyleConfig,
  updateConfigTutorial,
  updateContentStyle,
  updateShowStyleSelector,
  updateShowStyleSelectorTutorial,
  setStyleConfig,
} from '../../reducers/formReducer';
import STYLES, { NoneIcon, V4_GROUPS } from './content-styles';
import useGenerationEndpoint from './useGenerationEndpoint';
import StyleSettings from './style-config/StyleSettings';
import { CONFIG_PROPS, STYLE_SETTINGS } from './style-config/constants';
import Tooltip from '../Tooltip';
import { V4_STYLES } from './v4-content-styles';
import BaseTooltip from '../base/BaseTooltip';
import CustomToogleSwitch from '../base/ToogleSwitch';
import Config from './style-config/Config';

const isSettingsDisabled = (method, style) => {
  if (STYLE_SETTINGS[method]?.[style] === undefined) return true;
  return (
    STYLE_SETTINGS[method]?.[style].filter((c) => c.section_id !== 'colors')
      .length === 0
  );
};

const TOOLTIP_DATA_LOGO = {
  'v4/waterfall': (
    <ul className='text-white text-xs list-disc space-y-1 pl-3'>
      <li>Good at creating minimalist logos and text</li>
      <li>Can be unpredictable but often creative</li>
      <li>Results might be too minimalist at times</li>
      <li>Averages 35 to 45 seconds per request of 4 images</li>
    </ul>
  ),
  'v4/xl': (
    <ul className='text-white text-xs list-disc space-y-1 pl-3'>
      <li>Good at creating detailed logos</li>
      <li>Follows prompts more closely</li>
      <li>May offer fewer options with very specific requests</li>
      <li>Averages 40-60 seconds per request of 4 images</li>
    </ul>
  ),
};

const TOOLTIP_DATA_ART = {
  'v4/waterfall': (
    <ul className='text-white text-xs list-disc space-y-1 pl-3'>
      <li>Better at creating detailed images</li>
      <li>Perfect for realistic styles</li>
      <li>Can get too creative and might result in weird images</li>
      <li>May not follow the prompt precisely at times</li>
    </ul>
  ),
  'v4/bolt': (
    <ul className='text-white text-xs list-disc space-y-1 pl-3'>
      <li>Better at creating detailed images</li>
      <li>Perfect for realistic styles</li>
      <li>Can get too creative and might result in weird images</li>
      <li>May not follow the prompt precisely at times</li>
    </ul>
  ),
  'v4/xl': (
    <ul className='text-white text-xs list-disc space-y-1 pl-3'>
      <li>Better at creating 2D art, and illustrations</li>
      <li>Offers more contrast and defined images instead of small details</li>
      <li>Better at following your prompts precisely</li>
      <li>Better understanding of artistic styles and concepts</li>
    </ul>
  ),
};

const StyleSelectorMobile = (props) => {
  const dispatch = useDispatch();

  const { method, generationStyle, setMethod } = useGenerationEndpoint();

  const [isStyleSettings, setIsStyleSettings] = useState(false);

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [tutorialEl, setTutorialEl] = useState();
  const [tutorialArrowEl, setTutorialArrowEl] = useState();

  const [clickedIndex, _setClickedIndex] = useState('logo-design');
  const currentGroupRef = useRef('logo-design');
  const setClickedIndex = (x) => {
    currentGroupRef.current = x;
    _setClickedIndex(x);
  };
  const [filteredStyle, setFilteredStyle] = useState(STYLES[method]);

  const tooltipData =
    clickedIndex === 'logo-design' ? TOOLTIP_DATA_LOGO : TOOLTIP_DATA_ART;

  const handleButtonClick = (index) => {
    setClickedIndex(index);
  };

  useEffect(() => {
    if (clickedIndex) {
      const _filteredStyle = STYLES[method].filter(
        (it) => it.group === clickedIndex
      );
      setFilteredStyle(_filteredStyle);
    }
  }, [clickedIndex]);

  let tutorialPopper = usePopper(popperEl, tutorialEl, {
    placement: 'right',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: tutorialArrowEl,
          padding: -50,
        },
      },
    ],
  });

  const style = useSelector((state) => state.form.payload.style);

  const isOpen = useSelector((state) => state.form.showStyleSelector);
  useEffect(() => {
    if (isOpen) {
      // anchorEl?.click();
      dispatch(updateShowStyleSelector(false));
    }
  }, [isOpen]);

  const handleCloseTutorial = () => {
    dispatch(updateShowStyleSelectorTutorial(false));
  };

  const showTutorial = useSelector(
    (state) => state.form.showStyleSelectorTutorial
  );

  const currentStyle = useMemo(
    () => STYLES[method].find((item) => item.id === style),
    [method, style]
  );
  const CurrentStyleIcon = useMemo(
    () => currentStyle?.icon_preview || currentStyle?.icon || NoneIcon,
    [currentStyle]
  );

  const config = useMemo(
    () => STYLE_SETTINGS[method]?.[style] || [],
    [style, method]
  );

  useEffect(() => {
    if (
      currentGroupRef.current !== currentStyle?.group &&
      currentStyle?.group
    ) {
      setClickedIndex(currentStyle.group);
    }
  }, [currentStyle]);

  const style_config = useSelector((state) => state.form.payload.styleConfig);
  const settingsDisabled = isSettingsDisabled(method, style);

  const handleStyle = (value) => {
    const style = V4_STYLES.find((item) => item.id === value);
    console.log('Setting endpoint', style.endpoint);
    dispatch(partialUpdateStatePayload({ style: value }));
    setMethod(style.endpoint);

    if (!!CONFIG_PROPS[method]?.[value] && !isSettingsDisabled(method, value)) {
      setTimeout(() => {});
    }
  };
  const PanelAttributes = useMemo(() => {
    return {
      className: 'flex flex-col gap-4 py-2 ',
    };
  }, []);

  const handleCheckboxChange = () => {
    setIsStyleSettings(!isStyleSettings);
  };

  const handleConfigChange = (config_id, value) => {
    console.log('config change', config_id, value);
    dispatch(
      setStyleConfig({
        config_id,
        value,
      })
    );
  };

  const [selectedType, setSelectedType] = useState('');

  const [aModelEl, setAModelEl] = useState();
  const [bModelEl, setBModelEl] = useState();

  if (generationStyle === 'style-transfer') {
    return (
      <div
        className=' relative rounded-xl bg-style-bg py-[9px] p-2 flex flex-row justify-between items-center font-poppins outline-none text-sm text-select-label-disabled f
			ont-semibold  '
      >
        <div className='flex items-center gap-4 justify-start'>
          <div className='flex flex-col items-start font-sans-pro text-[15.13px] font-semibold'>
            <span className='flex'>
              Style categories cannot be used alongside a style image
            </span>
            <p className='truncate text-icon-text-color font-sans-pro text-[10px] grow text-center'>
              {currentStyle?.name}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='flex items-center justify-between pb-3'>
        <label className='inline-flex items-center justify-center cursor-pointer bg-app-bg-gray h-[32px] rounded-[6.6px] px-[3px]'>
          <input
            id='toogleswitch'
            type='checkbox'
            checked={isStyleSettings}
            onChange={handleCheckboxChange}
            className='hidden peer'
          />
          <span
            className={`px-4 flex items-center h-[26px] text-[10px] rounded-[4.4px] transition-colors duration-200 ${
              !isStyleSettings
                ? 'bg-switch-bg-gray  text-white'
                : ' text-icon-text-color'
            }`}
          >
            Style Categories
          </span>
          <span
            className={`px-4 flex items-center h-[26px] text-[10px]  rounded-[4.4px] transition-colors duration-200 ${
              isStyleSettings
                ? 'bg-switch-bg-gray  text-white'
                : 'text-icon-text-color'
            }`}
          >
            Style Settings
          </span>
        </label>
      </div>
      {!isStyleSettings ? (
        <>
          <div className='flex items-center gap-4 justify-start'>
            <div className='flex flex-col items-start font-sans-pro text-[15.13px] font-semibold'>
              <p
                className='truncate text-icon-text-color font-sans-pro text-[12px] grow text-center flex gap-2'
                onClick={(e) => e.stopPropagation()}
              >
                {currentStyle?.a_endpoint !== undefined &&
                  currentStyle?.b_endpoint !== undefined && (
                    <RadioGroup
                      value={method}
                      onChange={(v) => setMethod(v)}
                      as='div'
                      className='flex flex-row gap-2 items-center'
                    >
                      <RadioGroup.Label>Model:</RadioGroup.Label>
                      <RadioGroup.Option
                        ref={setAModelEl}
                        className={({ active, checked }) =>
                          ` flex items-center font-bold text-sm
											${
                        checked
                          ? 'bg-app-green  text-switch-label-green '
                          : 'bg-app-bg-gray  text-deactive '
                      }
											flex cursor-pointer focus:outline-none overflow-hidden rounded-sm px-1 aspect-square ${'ui-disabled:bg-carousel-next-count ui-disabled:text-icon-fill ui-disabled:cursor-not-allowed'}`
                        }
                        value={currentStyle.a_endpoint}
                        aria-disabled={currentStyle.a_endpoint === null}
                        disabled={currentStyle.a_endpoint === null}
                      >
                        A
                      </RadioGroup.Option>
                      <RadioGroup.Option
                        ref={setBModelEl}
                        className={({ active, checked }) =>
                          ` flex items-center font-bold text-sm
											${
                        checked
                          ? 'bg-app-green  text-switch-label-green '
                          : 'bg-app-bg-gray  text-deactive '
                      }
											flex cursor-pointer focus:outline-none overflow-hidden rounded-sm px-1 aspect-square ui-disabled:bg-carousel-next-count ui-disabled:text-icon-fill ui-disabled:cursor-not-allowed`
                        }
                        value={currentStyle.b_endpoint}
                        aria-disabled={currentStyle.b_endpoint === null}
                        disabled={currentStyle.b_endpoint === null}
                      >
                        B
                      </RadioGroup.Option>

                      <BaseTooltip
                        anchorEl={aModelEl}
                        placement='bottom'
                        boxClasses='mt-3'
                        placementArrowClasses='bottom-full'
                        placementArrowElClasses='w-full h-full rotate-45 border-select-border bg-style-method-enabled border-0 border-t border-l translate-y-1/2'
                      >
                        {tooltipData[currentStyle.a_endpoint]}
                      </BaseTooltip>
                      <BaseTooltip
                        anchorEl={bModelEl}
                        placement='bottom'
                        boxClasses='mt-3'
                        placementArrowClasses='bottom-full'
                        placementArrowElClasses='w-full h-full rotate-45 border-select-border bg-style-method-enabled border-0 border-t border-l translate-y-1/2'
                      >
                        {tooltipData[currentStyle.b_endpoint]}
                      </BaseTooltip>
                      {currentStyle.c_endpoint && (
                        <RadioGroup.Option
                          className={({ active, checked }) =>
                            ` flex items-center font-bold text-sm
												${
                          checked
                            ? 'bg-app-green  text-switch-label-green '
                            : 'bg-app-bg-gray  text-deactive '
                        }
												flex cursor-pointer focus:outline-none overflow-hidden rounded-sm px-1 aspect-square ui-disabled:bg-carousel-next-count ui-disabled:text-icon-fill ui-disabled:cursor-not-allowed`
                          }
                          value={currentStyle.c_endpoint}
                          aria-disabled={currentStyle.c_endpoint === null}
                          disabled={currentStyle.c_endpoint === null}
                        >
                          C
                        </RadioGroup.Option>
                      )}
                    </RadioGroup>
                  )}
                {currentStyle?.name}
              </p>
            </div>
          </div>
          <div className='flex flex-col gap-[2px] pt-2'>
            <div className='flex flex-row w-full h-full gap-[7px] overflow-x-auto custom-scroll v4-custom-scroll pb-3 pt-1'>
              {V4_GROUPS.map(({ icon: Icon, ...item }, index) => {
                return (
                  <button
                    key={index}
                    className={`flex items-center justify-center gap-2 bg-app-bg-gray max-w-[123px] h-[24px] px-[10px] rounded-[4px] text-[10px] font-inter flex-grow whitespace-nowrap ${
                      item.id === clickedIndex
                        ? 'border border-1 border-app-green'
                        : ''
                    }`}
                    onClick={() => handleButtonClick(item.id)}
                  >
                    <div
                      className={`${
                        item.id === clickedIndex
                          ? 'text-white font-bold'
                          : 'text-icon-text-color'
                      }`}
                    >
                      {item.name}
                    </div>
                    {item?.types && (
                      <RadioGroup
                        value={selectedType}
                        onChange={setSelectedType}
                        className='flex gap-1 items-center'
                      >
                        {item?.types.map((t) => (
                          <RadioGroup.Option
                            key={t}
                            className={({ active, checked }) =>
                              ` flex items-center font-bold text-xs
														${
                              checked
                                ? 'bg-app-green  text-white '
                                : 'bg-overlay-bg  text-icon-text-color '
                            }
														flex cursor-pointer focus:outline-none overflow-hidden rounded-sm px-1`
                            }
                            value={t}
                          >
                            {t}
                          </RadioGroup.Option>
                        ))}
                      </RadioGroup>
                    )}
                  </button>
                );
              })}
            </div>
            <div
              className={`w-full h-full transition-all duration-500 max-h-[175px]  overflow-x-auto custom-scroll v4-custom-scroll pb-3 pt-2`}
            >
              <RadioGroup
                value={style}
                onChange={handleStyle}
                className='grid grid-flow-col auto-cols-[70px] gap-2 [&>div]:text-style-text [&>div]:text-xs pr-1 w-full'
                // style={{
                // 	gridTemplateRows: `repeat(${Math.ceil(filteredStyle.length / 3)}, minmax(0, 1fr))`
                // }}
              >
                {filteredStyle.map(({ icon: Icon, ...item }) => {
                  return (
                    <RadioGroup.Option
                      disabled={item.disabled}
                      key={item.id}
                      value={item.id}
                      className='aspect-square relative cursor-pointer ui-disabled:cursor-not-allowed rounded-lg overflow-hidden bg-app-bg-gray border-2 border-solid border-transparent ui-checked:border-app-green'
                    >
                      <div className='flex items-center justify-center rounded-[8px] w-full aspect-square overflow-hidden box-content '>
                        <Icon className={`${item.icon_classes} `} />
                      </div>
                      <div className='absolute bottom-0 left-0 right-0 px-2 py-1 bg-black/60 flex text-[0.6rem] [line-height:0.8rem] text-icon-text-color-v4 font-inter line-clamp-2 text-ellipsis whitespace-normal text-center'>
                        {item.name}
                      </div>
                      {item.beta && (
                        <div className='bg-app-green/10 rounded-[0.2rem] px-1 text-app-green text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                          Beta
                        </div>
                      )}
                      {item.new && (
                        <div className='bg-app-green/10 rounded-[0.2rem] px-1 text-app-green text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                          New
                        </div>
                      )}
                      {item.soon && (
                        <div className='bg-white/10 rounded-[0.2rem] px-1 text-style-soon-badge text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                          Soon
                        </div>
                      )}
                    </RadioGroup.Option>
                  );
                })}
              </RadioGroup>
            </div>

            {showTutorial && (
              <div
                className='flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300 ml-4 w-60 rounded-md z-[100]'
                ref={setTutorialEl}
                style={tutorialPopper.styles.popper}
                {...tutorialPopper.attributes.popper}
              >
                <div
                  ref={setTutorialArrowEl}
                  style={tutorialPopper.styles.arrow}
                  {...tutorialPopper.attributes.arrow}
                  className='h-3 w-3 inline-block right-full '
                >
                  <div className='w-full h-full bg-style-method-enabled -translate-y-10 translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-b border-l'></div>
                </div>
                <div className='flex items-center justify-start gap-2'>
                  <span>
                    <InfoIcon />
                  </span>
                  <span className='text-title-white text-sm font-roboto'>
                    Choose a style first.
                  </span>
                </div>
                <div className='font-roboto text-input-color text-xs '>
                  Before you can generate your first image, you need to pick a
                  logo design style.
                </div>
                <div className='flex items-center justify-end mt-1'>
                  <button
                    className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3'
                    onClick={handleCloseTutorial}
                  >
                    Got It
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className='flex flex-col gap-6 w-full max-w-full'>
            {config
              .filter((config) => config.section_id !== 'colors')
              .map((config_item) => (
                <React.Fragment key={config_item.section_id}>
                  {false && (
                    <h3 className='font-roboto font-bold text-sm text-gray-label'>
                      {config_item.name}
                    </h3>
                  )}
                  {config_item.elements.map((el) =>
                    el.component ? (
                      <el.component
                        config_metadata={el}
                        value={style_config[el.id]}
                        onChange={(val) => handleConfigChange(el.id, val)}
                        key={el.id}
                        label={config_item.name}
                      />
                    ) : (
                      <Config
                        config_metadata={el}
                        value={style_config[el.id]}
                        onChange={(val) => handleConfigChange(el.id, val)}
                        key={el.id}
                        label={config_item.name}
                      />
                    )
                  )}
                </React.Fragment>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default StyleSelectorMobile;
