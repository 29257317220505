import { useDispatch, useSelector } from "react-redux"
import { updatePromptState } from "../../reducers/formReducer"
import { useCallback } from "react"

const usePromptStatus = () => {
	const state = useSelector((state) => state.form.promptState)

	const isLoading = state === 'loading'
	const isAnimating = state === 'animate'
	const isIdle = state === 'idle'

	const dispatch = useDispatch();

	const endAnimation = useCallback(() => {
		dispatch(updatePromptState('idle'))
	}, [dispatch])

	return {
		isIdle,
		isLoading,
		isAnimating,
		endAnimation,
	}
}

export default usePromptStatus;
