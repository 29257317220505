import React, { forwardRef, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyle from "../../../hooks/useStyle";
import { randomize_config_item } from '../style-config/constants';
import useGenerationEndpoint from "../useGenerationEndpoint";
import { twMerge } from "tailwind-merge";
import { usePopper } from "react-popper";
import { updateConfigTutorial } from "../../../reducers/formReducer";
import { ReactComponent as InfoIcon } from '../../../assets/InfoIconGray.svg';
import { Popover, Transition } from "@headlessui/react";
import { createPortal } from "react-dom";
import { LetterPanel } from "../prompt-handlers/Letter";

const LETTERS = [
	...[...Array(26)].map((_, ind) =>
		String.fromCharCode(ind + 'A'.charCodeAt(0))
	),
	...[...Array(10)].map((_, i) => `${i}`)
]

const SingleLetter = forwardRef((props, ref) => {
	const { index, config_value, handleChange, include_tutorial } = props

  const style = useStyle();

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);
	const dispatch = useDispatch();

	const { method, mode } = useGenerationEndpoint();


  const [modeSelectorAnchorEl, setModeSelectorAnchorEl] = useState();
  const [modeSelectorEl, setModeSelectorEl] = useState();
  let modeSelectorPopper = usePopper(modeSelectorAnchorEl, modeSelectorEl, {
    strategy: 'fixed',
    placement: 'bottom-start',
  });

  const [configTutorialEl, setConfigTutorialEl] = useState();
  const [configTutorialArrowEl, setConfigTutorialArrowEl] = useState();
  let configTutorialPopper = usePopper(modeSelectorAnchorEl, configTutorialEl, {
    placement: 'right',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: configTutorialArrowEl,
          // padding: -100,
        },
      },
    ],
  });

  const configTutorial = useSelector((state) => state.form.configTutorial);
  const handleCloseConfigTutorial = () => {
    dispatch(updateConfigTutorial({ ...configTutorial, style: null }));
  };

	return (
		<Popover value={config_value} onChange={handleChange} >
			{({ open, close }) => (
				<>
					<Popover.Button
						className={twMerge(` bg-overlay-bg 
			text-icon-text-color font-roboto text-xs gap-1 items-center rounded h-[29px] px-2 
			inline-flex transition-all duration-300 group/inline whitespace-nowrap`)}
						ref={(r) => {
							setModeSelectorAnchorEl(r)
							if (ref) ref.current = r
						}}
						onClick={(e) => e.stopPropagation()}
					>
						<span className="rounded-sm bg-app-green text-center w-4 h-4 font-bold font-roboto text-switch-label-green">{config_value || '?'}</span>
						Main Letter
					</Popover.Button>

					{include_tutorial && configTutorial.style === style && 
						createPortal(
							<div
								className='flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300 ml-4 w-60 rounded-md'
								ref={setConfigTutorialEl}
								style={configTutorialPopper.styles.popper}
								{...configTutorialPopper.attributes.popper}
							>
								<div
									ref={setConfigTutorialArrowEl}
									style={configTutorialPopper.styles.arrow}
									{...configTutorialPopper.attributes.arrow}
									className='h-3 w-3 inline-block right-full top-1/2 '
								>
									<div className='w-full h-full bg-style-method-enabled translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-b border-l' />
								</div>
								<div className='flex items-center justify-start gap-2'>
									<span>
										<InfoIcon />
									</span>
									<span className='text-title-white text-sm font-roboto'>
										{configTutorial.title}
									</span>
								</div>
								<div className='font-roboto text-input-color text-xs '>
									{configTutorial.body}
								</div>
								<div className='flex items-center justify-end mt-1'>
									<button
										className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3'
										onClick={handleCloseConfigTutorial}
										type='button'
									>
										Got It
									</button>
								</div>
							</div>,
							document.getElementById('popup-root')
						)
					}

					<LetterPanel {...props} open={open} close={close} ref={setModeSelectorEl} popper={modeSelectorPopper} />
				</>
			)}
		</Popover>
	)
})

const LetterConfig = (props) => {
  const { config_metadata, value, onChange, label } = props;

  const config_value = useMemo(
    () => value?.value,
    [value,]
  );

	const mainRef = useRef(null)

	return (
		<SingleLetter include_tutorial handleChange={onChange} config_value={config_value} value={value} ref={mainRef} />
		
	)
}

export default LetterConfig;
