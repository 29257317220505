import React, { useMemo, useState, useEffect, useRef } from 'react';
import { STYLE_SETTINGS } from './constants';
import Config from './Config';
import { useDispatch, useSelector } from 'react-redux';
import { setStyleConfig } from '../../../reducers/formReducer';
import { Disclosure } from '@headlessui/react';
import Tooltip from '../../../components/Tooltip';

import { ReactComponent as CaretDownIconV4 } from '../../../assets//caret-up-v4.svg';
import { ReactComponent as InfoIcon } from '../../../assets/InfoIconGray.svg';
import useGenerationEndpoint from '../useGenerationEndpoint';

const StyleSettingsV4 = (props) => {
  const style = useSelector((state) => state.form.payload.style);
  const method = useSelector((state) => state.form.method);

  const config = useMemo(
    () => STYLE_SETTINGS[method]?.[style] || [],
    [style, method]
  );

	const style_config = useSelector((state) => state.form.payload.styleConfig);

  const dispatch = useDispatch();

	const { mode } = useGenerationEndpoint();

  const handleConfigChange = (config_id, value) => {
		console.log("config change", config_id, value)
    dispatch(
      setStyleConfig({
        config_id,
        value,
      })
    );
  };

  const buttonRef = useRef(null);

  const PanelAttributes = useMemo(() => {
    return {
      className: 'flex flex-col gap-4 py-2 ',
    };
  }, []);

  if (config === undefined)
    return (
      <div className='flex flex-col gap-2 text-center font-bold font-poppins text-select-label-disabled w-full max-w-full'>
        No customizations available for this style!
      </div>
    );

  return (
    <Disclosure
      className={`relative rounded-xl px-3 bg-style-bg p-4 flex flex-col`}
      as='div'
      ref={buttonRef}
			key={style}
			defaultOpen={mode === 'v4.0'}

    >
      <Disclosure.Button
        className={`outline-none text-sm
	  text-white flex items-center justify-between font-sans-pro text-[15.13px] font-semibold`}
      >
        <span className='flex'>
          Style Setting
          <Tooltip title='style setting' position='right'>
            <InfoIcon className='w-[20px] h-[20px] ml-1' />
          </Tooltip>
        </span>
        <CaretDownIconV4 className='transition-all duration-300 ui-open:-rotate-90 [&_path]:fill-chevron-icon w-6 h-6 max-h' />
      </Disclosure.Button>
      <Disclosure.Panel {...PanelAttributes}>
        {({ open }) => {
          return (
            <div className='flex flex-col gap-6 w-full max-w-full'>
              {config
                .filter((config) => config.section_id !== 'colors')
                .map((config_item) => (
                  <React.Fragment key={config_item.section_id}>
										{false && (
											<h3 className='font-roboto font-bold text-sm text-gray-label'>
												{config_item.name}
											</h3>
										)}
                    {config_item.elements.map((el) =>
                      el.component ? (
                        <el.component
                          config_metadata={el}
                          value={style_config[el.id]}
                          onChange={(val) => handleConfigChange(el.id, val)}
                          key={el.id}
													label={config_item.name}
                        />
                      ) : (
                        <Config
                          config_metadata={el}
                          value={style_config[el.id]}
                          onChange={(val) => handleConfigChange(el.id, val)}
                          key={el.id}
													label={config_item.name}
                        />
                      )
                    )}
                  </React.Fragment>
                ))}
            </div>
          );
        }}
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default StyleSettingsV4;
