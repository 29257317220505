import { motion } from "framer-motion"
import { Children, forwardRef, useEffect, useState } from "react"
import usePromptStatus from "../usePromptStatus"
import useStyle from "../../../hooks/useStyle"
import { V5_ORDERING } from "../style-config/v5-ordering"
import useGenerationEndpoint from "../useGenerationEndpoint"
import { useStore } from "react-redux"
import useUnwrappedStyleSettingsConfig, { useStyleSettingsMap } from "../../../hooks/useUnwrappedStyleSettingConfigs"
import { getPromptText, getValueAndImportance } from "../../../helpers/importanceHelpers"

const CHAR_DURATION = 0.005
const CHAR_STAGGER = 0.005

export const TextAnimationContext = (_props) => {
	const { as = 'div', children, ...props } = _props
	const { isIdle, isLoading, isAnimating, endAnimation, } = usePromptStatus();

	const Tag = motion[as];

	const style = useStyle();
  const { mode } = useGenerationEndpoint();
	const store = useStore()

	const styleConfig = useStyleSettingsMap();

	const [animation, setAnimation] = useState('hide')

	useEffect(() => {
		let timeout = null

		if (isAnimating) {
			setTimeout(() => setAnimation('show'), 100)
			const order = V5_ORDERING[style] ?? [{ id: 'prompt' }]

			const style_values = store.getState().form.payload.styleConfig
			const prompt = getPromptText(store.getState().form.payload.prompt)

			const chars = order.reduce((total, cur) => {
				if (cur.type === 'static') {
					return total + cur.text.length + (cur.delimiter?.length ?? 1)
				}

				if (cur.id === 'prompt') {
					return total + prompt.length
				}

				const config = styleConfig[cur.id]
				const value = style_values[cur.id]?.value
				if (!config || !value) return total 
				if (config.type === 'select') {
					const name = config.values.find(x => x.id === value)?.name || value
					return total + (name?.length ?? 0) + 1
				} else if (config.type === 'text' || config.type === 'letter') {
					return total + (value?.length ?? 0) + 1
				}
				
				return total
			}, 0)
			const time = chars * (CHAR_DURATION + CHAR_STAGGER / 2)

			console.log("Total length", chars, time)

			timeout = setTimeout(() => {
				endAnimation()
				setAnimation('hide')
			}, time * 1000)
		}
		return () => {
			if (timeout) clearTimeout(timeout)
		}
	}, [isAnimating, endAnimation, styleConfig, store, style])

	if (mode < 'v5.0') {
		return (
			<Tag {...props}>
				{children}
			</Tag>
		)
	}

	return (
		<Tag 
			// initial={isIdle ? "show" : "hide"} animate={isAnimating ? 'hide' : 'show'}
			initial={'hide'} animate={animation}
			variants={{
				hide: {
				},
				show: {
					transition: {
						staggerChildren: CHAR_STAGGER,
						// duration: 5,
					}
				},
			}}
			{...props}
		>
			{children}
		</Tag>
	)
}

const TextAnimationWrapper = forwardRef((_props, ref) => {
	const { as = "span", children, ...props } = _props
	const Tag = motion[as];

	const { isIdle, isLoading } = usePromptStatus()
  const { mode } = useGenerationEndpoint();
	if (isIdle || isLoading || mode < 'v5.0') {
		return (
			<Tag {...props} ref={ref}>
				{children}
			</Tag>
		)
	}


	return (
		<>
			{Children.toArray(children).map((item) => 
				typeof item === 'string' ? (
					item.split('').map((c, i) => (
						<motion.span 
							key={`${c}-${i}`}
							variants={{
								hide: { opacity: 0, y: 10 },
								show: { 
									opacity: 1, y: 0,
									transition: {
										duration: CHAR_DURATION,
									}
								}
							}}
						>
							{c}
						</motion.span>
					))
				) : item
			)}
		</>
	)
})

export default TextAnimationWrapper;
