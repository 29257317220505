
import { twMerge } from "tailwind-merge";
import { ReactComponent as InfoIcon } from "../../../../assets/InfoIconGray.svg";
import { ReactComponent as CloseIcon } from '../../../../assets/CloseEditor.svg';
import { Tooltip } from "react-tippy";
import { useDispatch } from "react-redux";
import { removeDevModeField } from "../../../../reducers/formReducer";

const DevTextArea = (props) => {
  const { 
		label, fieldName, title, value, onChange, disabled, 
		appliedClasses={}, clearable=true, inputProps
	} = props;

	const dispatch = useDispatch()
	
	return (
		<div className={twMerge(`flex justify-between `, appliedClasses.main)} disabled={disabled}>
			<label className={twMerge(`font-bold font-poppins text-xs flex gap-2 ${disabled ? 'text-select-label-disabled' : 'text-gray-label '} inline-flex items-center`, appliedClasses.label)}>
				{label}

				{(fieldName || clearable) && (
					<button onClick={e => {
						e.stopPropagation();
						if (fieldName)
							dispatch(removeDevModeField(fieldName))
						onChange("")
					}}>
						<CloseIcon className="w-3 h-3" />
					</button>
				)}
	
				<Tooltip title={`${title} - ${fieldName}`} >
					<InfoIcon className={twMerge(``, appliedClasses.infoTooltip)}/>
				</Tooltip>
			</label>
			<div className={twMerge("flex gap-2 basis-5/12 items-center justify-end grow-0 w-[41%]", appliedClasses.inputCnt)}>
				<textarea
					{...inputProps}
					className={twMerge(` p-1 text-sm text-center bg-transparent font-poppins text-white border border-solid border-select-border rounded-xl disabled:text-select-label-disabled w-full `, appliedClasses.optionContainer, appliedClasses.input)}
					value={value}
					onChange={(e) => onChange(e.target.value)}
					disabled={disabled}
				/>
			</div>
		</div>
	);
}

export default DevTextArea;
