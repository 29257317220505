import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ProtectedRoute from '../../components/ProtectedRoute';
import AppLayout from '../../layouts/AppLayout';

import environment from '../../helpers/envProvider';
import Redesign from '../../components/redesign/Redesign';
import PromptInput from '../../components/input/PromptInput';
import DesktopContentStyleHelpers from '../../components/DesktopContentStyleHelpers';
import Generations from '../../components/generation/Generations';
import ImageViewer from '../../components/ImageViewer';
import { Tab } from '@headlessui/react';

import { ReactComponent as MessageIconBlack } from '../../assets/MessageIconBlack.svg';
import { ReactComponent as SettingsIconBlack } from '../../assets/SettingsIconBlack.svg';
import { ReactComponent as StylesIcon } from '../../assets/StylesIconGray.svg';
import { ReactComponent as SettingsIcon } from '../../assets/SettingsIconGray.svg';
import { ReactComponent as InputImageIcon } from '../../assets/UseInputImage.svg';
import { ReactComponent as StyleImageIcon } from '../../assets/UseStyleImage.svg';
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg';
import ImageEditor from '../../components/editor/ImageEditor';
import TutorialModal from '../../components/modals/TutorialModal';
import QuestionnaireModal from '../../components/modals/QuestionnaireModal';
import QueueStatus from '../../components/queue/QueueStatus';
import useGenerationEndpoint from '../../components/input/useGenerationEndpoint';
import V4GenerationHistory from '../../components/generation/V4GenerationHistory';
import GenerationsV4 from '../../components/generation/GenerationsV4';
import RedesignV4 from '../../components/redesign/RedesignV4';
import GenerationsV4Grid from '../../components/generation/GenerationsV4Grid';
import { render } from '@testing-library/react';
import AdvancedSettingsMobile from '../../components/redesign/AndvancedSettingsMobile';
import StyleImageMobile from '../../components/StyleImageMobile';
import ImageUploadV4Mobile from '../../components/ImageUploadV4Mobile';
import StyleSelectorMobile from '../../components/input/StyleSelectorMobile';
// ${TempVar_API}
// import ImageFunctionServices from '../services/ImageFunctionService';

function toDataURL(src, callback, outputFormat) {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function () {
    const canvas = document.createElement('CANVAS');
    const ctx = canvas.getContext('2d');
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    ctx.drawImage(this, 0, 0);
    const dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };
  img.src = src;
  if (img.complete || img.complete === undefined) {
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
    img.src = src;
  }
}

const TextPipeline = () => {
  const [selectedTab, setSelectedTab] = useState('TEXT_PROMPT');
  const [isViewChange, setIsViewChange] = useState(true);
  const [activeButton, setActiveButton] = useState(null);
  const [modalContent, setModalContent] = useState('');
  const generationRef = useRef();
  const { mode: version } = useGenerationEndpoint();

  const onViewChange = (isGridView) => {
    setIsViewChange(isGridView);
  };

  const handleClickTabBarButton = (button) => {
    setActiveButton(button);
    setModalContent(button);
  };

  const closeModal = () => {
    setActiveButton(null);
    setModalContent('');
  };

  const renderModalContent = () => {
    switch (activeButton) {
      case 'Styles':
        return <StyleSelectorMobile />;
      case 'Input Image':
        return <ImageUploadV4Mobile />;
      case 'Style Image':
        return <StyleImageMobile />;
      case 'Settings':
        return <AdvancedSettingsMobile />;
      default:
        return null;
    }
  };

  return (
    <AppLayout>
      <ProtectedRoute>
        <Helmet>
          <title>Generator | Logo Diffusion</title>
        </Helmet>
        <div
          className={`flex flex-col md:flex-row px-0 sm:px-4 md:px-4 ${
            version >= 'v4.0' ? 'h-[100%]' : 'h-[90%]'
          }  overflow-y-hidden pt-[15px] 3xl:pt-[18px] 4xl:pt-[25px] pb-[85px] md:pb-4`}
        >
          {version >= 'v4.0' ? (
            <div
              className='hidden lg:block min-w-[250px] max-w-[321px] overflow-auto custom-scroll txt2img-custom-scroll !h-[calc(100%-40px)]'
              data-selected={selectedTab === 'PROMPT_SETTINGS'}
            >
              <RedesignV4 />
            </div>
          ) : (
            <div
              className={`rounded-[10px] md:min-w-[321px] md:max-w-[321px] md:overflow-y-auto overflow-x-visible md:h-full custom-scroll hidden data-[selected=true]:block md:block`}
              data-selected={selectedTab === 'PROMPT_SETTINGS'}
            >
              <Redesign />
            </div>
          )}
          {version >= 'v4.0' ? (
            <div className='relative grow flex flex-col items-center justify-end md:justify-center gap-[15px] 3xl:gap-[18px] px-4'>
              <div className={`relative items-start h-full w-full flex flex-row justify-center max-h-[calc(100%-300px)] ${ version === 'v5.0' ? 'lg:max-h-[calc(100%-190px)]' : 'lg:max-h-[calc(100%-150px)]'}  mb-12 mb:pb-0`}>
                <div
                  className={`flex items-center h-full w-full md:w-auto ${
                    isViewChange ? 'pb-[20px]' : 'pb-[12px] 3xl:pb-[60px]'
                  } justify-center`}
                >
                  {isViewChange ? (
                    <GenerationsV4Grid ref={generationRef} />
                  ) : (
                    <GenerationsV4 ref={generationRef} />
                  )}
                </div>
              </div>

              <PromptInput
                generationRef={generationRef}
                onViewChange={onViewChange}
                fixedPosition={false}
              />
            </div>
          ) : (
            <>
              <div className='relative grow'>
                <div
                  className='h-full hidden data-[selected=true]:block md:block'
                  data-selected={selectedTab === 'TEXT_PROMPT'}
                >
                  <QueueStatus />
                  <Generations ref={generationRef} />
                </div>

                <PromptInput generationRef={generationRef} />
              </div>
            </>
          )}
          {(version >= 'v4.0') && (
            <div className='hidden lg:block grow max-w-[94px] 4xl:h-[calc(100%-180px)] h-[calc(100%-180px)] -mr-4'>
              <V4GenerationHistory />
            </div>
          )}
          {version >= 'v4.0' && (
            <>
              <div className='lg:hidden fixed bottom-0 left-0 flex flex-col w-full z-30'>
                {activeButton && (
                  <>
                    <div
                      className='absolute insert-0 bottom-[60px] bg-black opacity-50 h-screen w-full flex items-end justify-center'
                      onClick={closeModal}
                    ></div>
                    <div className='absolute insert-0 bottom-[60px] w-full flex items-end justify-center'>
                      <div className='w-full h-auto  bg-style-bg p-4 rounded-t-[8px] '>
                        {renderModalContent()}
                        <button
                          onClick={closeModal}
                          className='absolute top-[16px] right-[16px] bg-app-bg-gray w-[32px] h-[32px] flex items-center justify-center rounded-[4px]'
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div className='bg-black flex text-active-icon text-[9px] justify-around py-4'>
                  <button
                    onClick={() => handleClickTabBarButton('Styles')}
                    className={`flex flex-col items-center `}
                  >
                    <StylesIcon
                      className={`w-[19px] h-[19px] mb-1  ${
                        activeButton === 'Styles'
                          ? '[&_path]:fill-app-green'
                          : ''
                      }`}
                    />
                    <span>Styles</span>
                  </button>
                  <button
                    onClick={() => handleClickTabBarButton('Input Image')}
                    className={`flex flex-col items-center `}
                  >
                    <InputImageIcon
                      className={`w-[19px] h-[19px] mb-1 ${
                        activeButton === 'Input Image'
                          ? '[&_path]:fill-app-green'
                          : ''
                      }`}
                    />
                    <span>Input Image</span>
                  </button>
                  <button
                    onClick={() => handleClickTabBarButton('Style Image')}
                    className={`flex flex-col items-center `}
                  >
                    <StyleImageIcon
                      className={`w-[20px] h-[20px] mb-1 ${
                        activeButton === 'Style Image'
                          ? '[&_path]:fill-app-green'
                          : ''
                      }`}
                    />
                    <span>Style Image</span>
                  </button>
                  <button
                    onClick={() => handleClickTabBarButton('Settings')}
                    className={`flex flex-col items-center `}
                  >
                    <SettingsIcon
                      className={`w-[18px] h-[19px] mb-1 ${
                        activeButton === 'Settings'
                          ? '[&_path]:fill-app-green'
                          : ''
                      }`}
                    />
                    <span>Settings</span>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <ImageViewer />

        <ImageEditor />
      </ProtectedRoute>
    </AppLayout>
  );
};

export default TextPipeline;
