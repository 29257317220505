import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { useDispatch, useStore } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { confirmUserEmail } from "../reducers/userReducer";
import useUsermavenWrapper from "../hooks/useUsermavenWrapper";

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

	const { track: usermavenTrack, id: usermavenId } = useUsermavenWrapper();
	const store = useStore();

  const performLogin = useCallback(async (token) => {
    setIsLoading(true)

    try {
      const response = await dispatch(confirmUserEmail({
        token,
      })).unwrap();

			usermavenId({
				id: store.getState().userSlice.user._id,
				email: store.getState().userSlice.user.email,
				created_at: store.getState().userSlice.user.createdAt,

				first_name: store.getState().userSlice.user.name,

				custom: {
					subscription_type: store.getState().userSlice.user.subscriptionProvider,
					stripe_subscription_price: store.getState().userSlice.user.plan?.productId,
				},
			});

			usermavenTrack('sign_up', {
				id: store.getState().userSlice.user._id,
				email: store.getState().userSlice.user.email,
				created_at: store.getState().userSlice.user.createdAt,

				first_name: store.getState().userSlice.user.name,
			})

      navigate('/?loggedIn=true');
    } catch(e) {
      console.log(e);
      toast(
        e.response?.data?.message || e.response?.data || "Something went wrong during confirmation",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          autoClose: 2000,
        }
      );
    }

    setIsLoading(false)
  }, [setIsLoading, navigate, dispatch, usermavenTrack, usermavenId, store])
  

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) performLogin(token);
  }, [searchParams, performLogin]);

  return (
    <div>
      <div className="max-w-[360px] w-[90%] mx-auto rounded-2xl flex flex-col justify-center min-h-screen">
        <Helmet>
          <title>Confirming your email | Logo Diffusion</title>
        </Helmet>

        <div className="font-bold text-xl text-white flex gap-2 items-center justify-center mb-8">
          <Logo />
          Logo Diffusion
          <span className="text-xs text-black bg-app-green font-normal p-1 px-2 rounded-md ml-2">
            v4.0
          </span>
        </div>

        <div className="bg-app-bg-gray text-white text-center rounded-2xl">
          <div className="p-4 flex gap-2 items-center justify-center">
            <h1 className="font-bold text-white text-lg ">
              Logging you in...
            </h1>
            {isLoading && (
              <div className="relative w-6 h-6 animate-spin rounded-full bg-gradient-to-r from-spinner-blue via-spinner-cyan to-spinner-lime opacity-100">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[calc(100%-0.25rem)] h-[calc(100%-0.25rem)] bg-app-bg-gray rounded-full">
                </div>
                {/* <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[calc(100%-0.5rem)] h-[calc(100%-0.5rem)] shadow-[0_0_0_99999px_rgba(0,0,0,.8)]"></div> */}
              </div>
            )}
          </div>
          <div className="bg-app-search-gray rounded-b-2xl text-sm p-4">
            Go to {" "}
            <Link to="/login" className="text-blue-600">
              Login
            </Link>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ConfirmEmail;
