import { Helmet } from 'react-helmet';
import { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import UserLayout from '../layouts/UserLayout';
import VectorizeResult from '../components/VectorizeResult';
import SpinnerLoader from '../components/base/SpinnerLoader';

import {
  vectorizeImageTool,
  updateAfterVectorizeImage,
} from '../reducers/formReducer';

import axios from 'axios';

import { ReactComponent as VectorizeIcon } from '../assets/vectorize-icon-white.svg';
import { ReactComponent as UpscaleUploadImageIcon } from '../assets/UpscaleUploadImageIcon.svg';
import { ReactComponent as VectorizeLoadingIcon } from '../assets/VectorizeIconLightGray.svg';
import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg';
import { ReactComponent as Download } from '../assets/DownloadIcon.svg';
import { useSearchParams } from 'react-router-dom';
import { convertImageToBase64 } from '../helpers/convertUrlToBase64';

const Vectorize = (props) => {
  const user = useSelector((state) => state.userSlice.user);
  const afterImageUrl = useSelector((state) =>
    state.form.afterVectorizeImage ? state.form.afterVectorizeImage : null
  );
  const isVectorizingTool = useSelector(
    (state) => state.form.isVectorizingTool
  );

  const [afterImage, setAfterImage] = useState(null);
  const [isVectorizing, setIsVectorizing] = useState(false);

	const [generationId, setGenerationId] = useState();

  useEffect(() => {
    if (afterImageUrl) {
      const fetchData = async () => {
        await axios
          .get(afterImageUrl, { responseType: 'blob' })
          .then((response) => {
            setAfterImage(
              URL.createObjectURL(
                new Blob([response.data], { type: 'image/svg+xml' })
              )
            );
          })
          .catch((error) => {
            console.error('Error fetching SVG data:', error);
          });
      };
      fetchData();
    }
  }, [afterImageUrl]);

  useEffect(() => {
    setIsVectorizing(isVectorizingTool || (!afterImage && !!afterImageUrl));
  }, [isVectorizingTool, afterImage, afterImageUrl]);

  const dispatch = useDispatch();
  const uploadRef = useRef();

  const [beforeImage, setBeforeImage] = useState(null);
  const [fileName, setFileName] = useState(null);
  const isImageUploaded = !!beforeImage;

  const doneVectorize = useMemo(() => {
    return afterImage && beforeImage;
  }, [afterImage, beforeImage]);

  const handleImageUpload = useCallback(
    (event) => {
      const file = event.target.files[0];

      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          setBeforeImage(reader.result);
          setFileName(file.name);
        };
        reader.readAsDataURL(file);
      }
    },
    [setBeforeImage, setFileName]
  );

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          setBeforeImage(reader.result);
          setFileName(file.name);
        };
        reader.readAsDataURL(file);
      }
    },
    [setBeforeImage, setFileName]
  );

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleRemoveImage = useCallback(() => {
    setBeforeImage(null);
    setFileName(null);
  }, [setBeforeImage, setFileName]);

  const handleVectorize = async (e) => {
    const base64Image = beforeImage.split(',')[1];
    e.stopPropagation();
    try {
      await dispatch(vectorizeImageTool(base64Image)).unwrap();
    } catch (e) {
      console.log(e);
      toast(e?.error?.detail || 'Failed to vectorize image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleBack = useCallback(() => {
    setAfterImage(null);
    dispatch(updateAfterVectorizeImage());
    // setBeforeImage(null);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(updateAfterVectorizeImage());
      setAfterImage(null);
    };
  }, []);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = afterImage;
    link.download = `LoDi-${fileName}.svg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [searchParams] = useSearchParams();
	const [isLoadingImage, setIsLoadingImage] = useState(false)

	const loadUrlImage = async (url) => {
		setIsLoadingImage(true)
		try {
      const base64 = await convertImageToBase64(url, null, null);
			setBeforeImage(base64)
		
		} catch (e) {
      console.log(e);
      toast(e?.error?.detail || 'Failed to vectorize image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
		} finally {
			setIsLoadingImage(false)
		}
	}

	useEffect(() => {
		if (searchParams.get('generationId')) {
			setGenerationId(searchParams.get('generationId'))
		}

		console.log("Search params", searchParams)

		if (searchParams.get('imageUrl')) {
			loadUrlImage(searchParams.get('imageUrl'))
		}

		if (searchParams.get('fileName')) {
			setFileName(searchParams.get('fileName'))
		}
	}, [searchParams])


  return (
    <UserLayout containerClasses='flex flex-col items-center justify-center h-full'>
      <Helmet>
        <title>Vectorizer | Logo Diffusion</title>
      </Helmet>
      {!isVectorizing && !doneVectorize ? (
        <div className='flex flex-col items-center w-full pt-2'>
          <VectorizeIcon className='w-12 h-12' />
          <h1 className='text-white text-[52px] text-center font-bold mt-4 uppercase'>
            Image Vectorizer
          </h1>
          <p className='text-base text-app-green mt-[12px]'>
            Turn your logos and 2D images into editable vectors (SVG) in seconds
          </p>
          {!beforeImage ? (
            <div className='flex items-center justify-center bg-app-bg-gray h-[240px] w-[80%] mt-[42.19px] rounded-[8px] p-4 relative'>
              <input
                type='file'
                accept='image/*'
                className='hidden'
                ref={uploadRef}
                onChange={handleImageUpload}
              />
              <div
                className='w-full h-full border-2 border-[#30303E] border-dashed rounded flex items-center justify-center'
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <button
                  type='button'
                  className='w-full flex flex-col items-center'
                  onClick={() => uploadRef.current.click()}
                >
                  <UpscaleUploadImageIcon className='w-8 h-8 mb-4' />
                  <p className='text-white text-base'>
                    Click to upload your image or drag and drop it here
                  </p>
                </button>
              </div>
							<SpinnerLoader isLoading={isLoadingImage} />
            </div>
          ) : (
						<div className='flex flex-col max-h-[calc(100vh-370px)] max-w-[90%] md:max-w-[65%] mt-4 rounded-[20px]'>
							<img
								src={beforeImage}
								alt='uploaded'
								className='h-[calc(100%-48px)] rounded-t-[14px]'
							/>
							<div className='flex items-center justify-between whitespace-nowrap w-full bg-[#E7E7E7] rounded-b-[14px] px-3 py-2'>
								<p className='text-ellipsis overflow-hidden'>{fileName}</p>
								<button
									onClick={handleRemoveImage}
									className='text-red-500 rounded text-2xl'
								>
									<span>&times;</span>
								</button>
							</div>
						</div>
          )}
          <div className='flex items-center justify-evenly w-auto gap-[17px] rounded-[24px] mt-4'>
            <button
              className={`flex justify-center items-center h-[40px] px-[10px] text-[14px] font-semibold text-white bg-button-purple rounded-[12px] cursor-pointer ${
                !isImageUploaded ? 'pointer-events-none opacity-50' : ''
              }`}
              onClick={handleVectorize}
              disabled={!isImageUploaded}
            >
              Vectorize{' '}
              <span className='text-[10px] text-[#B7A3F1]'> (5) credits</span>
            </button>
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center px-5 md:px-0'>
          {!doneVectorize ? (
            <div className='relative flex items-center justify-center bg-app-bg-gray h-auto max-h-[77vh] w-auto max-w-[90vw] mt-[21px] rounded-[20px] border-[4.3px] border-[#30303E] border-dashed'>
              <img
                src={beforeImage}
                alt='uploaded'
                className='w-full h-full object-cover rounded-[20px]'
              />
              <SpinnerLoader
                classNames={{
                  backdrop: 'z-30 !rounded-[20px]',
                }}
                isLoading={isVectorizing}
                icon={<VectorizeLoadingIcon />}
              />
            </div>
          ) : (
            <VectorizeResult
              beforeImage={beforeImage}
              afterImage={afterImage}
            />
          )}
          <div className='flex items-center justify-evenly w-auto gap-[17px] px-4 h-[72px] bg-app-bg-gray rounded-[24px] mt-[38px]'>
            {!doneVectorize && (
              <button className='flex justify-center items-center h-[40px] px-[10px] text-[14px] font-semibold text-white bg-button-purple rounded-[12px]'>
                Vectorizing{' '}
                <span className='text-[10px] text-[#B7A3F1]'>...</span>
              </button>
            )}
            {doneVectorize && (
              <>
                <button
                  className='flex justify-center items-center h-[40px] px-[12px] gap-1 text-[14px] font-semibold text-[#ACACAC] bg-[#30303E] rounded-[12px]'
                  onClick={handleBack}
                >
                  <ArrowLeft />
                  Back
                </button>

                <button
                  className='flex justify-center items-center h-[40px] px-[12px] gap-1 text-[14px] font-semibold text-white bg-button-purple rounded-[12px]'
                  onClick={handleDownload}
                >
                  Download Vector .svg
                  <Download />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </UserLayout>
  );
};

export default Vectorize;
