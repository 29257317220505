import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { flushSync } from 'react-dom';
import { convertToHtml, getPromptText, getValueAndImportance, setImportance } from '../../helpers/importanceHelpers';
import { ReactComponent as EnhanceIcon } from '../../assets/Prompt/enhance-prompt.svg';
import { ReactComponent as AcceptIcon } from '../../assets/Prompt/accept.svg';
import { ReactComponent as RedoIcon } from '../../assets/Prompt/redo.svg';
import { ReactComponent as RejectIcon } from '../../assets/Prompt/reject.svg';
import { ReactComponent as UndoIcon } from '../../assets/Prompt/undo.svg';
import { ReactComponent as FreezeIcon } from '../../assets/FreezeIcon.svg';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
    acceptEnhancedPrompt,
    enhancePrompt,
  rejectEnhancedPrompt,
  removePromptHelper,
  setStyleConfig,
  toggleEnhanceEnabled,
  toggleUserDisabledEnhance,
  undoLastEnhancement,
  updateShowStyleSelector,
} from '../../reducers/formReducer';
import allContentStyleHelpers from '../../data/promptHelpers';
import STYLES from './content-styles';
import useGenerationEndpoint from './useGenerationEndpoint';
import { motion, AnimatePresence } from 'framer-motion'
import { toast } from 'react-toastify';
import useStyle from '../../hooks/useStyle';
import { V5_ORDERING } from './style-config/v5-ordering';
import useUnwrappedStyleSettingsConfig, { useStyleSettingsMap } from '../../hooks/useUnwrappedStyleSettingConfigs';
import PromptHandler from './prompt-handlers/PromptHandler';
import Tooltip from '../../components/Tooltip';
import usePromptStatus from './usePromptStatus';
import TextAnimationWrapper, { TextAnimationContext } from './prompt-handlers/TextAnimationWrapper';
import { Switch } from '@headlessui/react';
import axios from 'axios';

const V2StyleConfig = (props) => { /* Deprecated */
  const { mode, method } = useGenerationEndpoint();
  const dispatch = useDispatch();
  const contentStyleHelpers = useSelector(
    (state) => state.form.payload.promptHelpers
  );
  const handleContentStyleHelperRemoved = (value) => {
    dispatch(removePromptHelper(value));
  };
  const contentStyle = useSelector((state) => state.form.payload.contentStyle);

	if (mode >= 'v3.0') return <></>

	return (
		<div className='absolute bottom-0 left-1 right-2 flex gap-2 z-10 overflow-x-auto custom-scroll pointer-events-none'>
			{contentStyleHelpers?.map((helper, i) => (
				<span
					className='flex justify-between items-center text-[0.6rem] 4xl:text-[13px] font-bold text-tag-text bg-app-green rounded-full pl-1 pr-1 relative mb-0 gap-1'
					key={`content-helper-${i}`}
				>
					#
					{allContentStyleHelpers.find((item) => item.slug === helper).name}
					<div
						className='flex font-bold text-[0.6rem] cursor-pointer items-center justify-center hover:text-white'
						onClick={(e) => handleContentStyleHelperRemoved(helper)}
					>
						x
					</div>
				</span>
			))}
			{mode < 'v3.0' && contentStyle && contentStyle !== 'none' && (
				<button
					className='flex justify-between items-center text-[0.6rem] 4xl:text-[13px] font-bold text-tag-text bg-app-green rounded-full pl-1 pr-1 relative mb-0 gap-1 pointer-events-auto'
					type='button'
					onClick={() => dispatch(updateShowStyleSelector(true))}
				>
					{STYLES[method].find((item) => item.id === contentStyle)?.name}
				</button>
			)}
		</div>
	)
}

const EnhanceControls = (props) => {
	const lastEnhancement = useSelector((state) => state.form.lastEnhancement)
	const enhanceHistory = useSelector((state) => state.form.enhanceHistory)

	const { generationStyle } = useGenerationEndpoint();

	const dispatch = useDispatch();

	const [open, setOpen] = useState("closed")
	const [showCancel, setShowCancel] = useState(false)

  const enhanceEnabled = useSelector(
    (state) => state.form.payload.enhanceEnabled
  );
	const store = useStore();
  const handleToggleEnhance = () => {
    // dispatch(toggleEnhancePrompt());
    dispatch(toggleEnhanceEnabled());
		// if (store.getState().form.payload.enhanceEnabled) {
		// 	dispatch(toggleUserDisabledEnhance(false))
		// } else {
		// 	dispatch(toggleUserDisabledEnhance(true))
		// }
  };

	const abortSignal = useRef(null);
	const enhance = async () => {
		try {
			setShowCancel(true)
			abortSignal.current = new AbortController()
			await dispatch(enhancePrompt({
				signal: abortSignal.current.signal
			})).unwrap();
		} catch(e) {
			if (e.isCancelled) {
				toast(
					'Request canclled',
					{
						position: toast.POSITION.BOTTOM_RIGHT,
						theme: 'dark',
						autoClose: 2000,
					}
				);
				return ;
			}
			console.error(e);
			toast(
				e?.error?.detail?.message ||
					e?.error?.detail ||
					'Somewhing went wrong',
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					theme: 'dark',
					autoClose: 2000,
				}
			);
		} finally {
			setShowCancel(false)
		}
	}

	const accept = (e) => {
		e.stopPropagation()
		dispatch(acceptEnhancedPrompt())
	}
	const reject = (e) => {
		e.stopPropagation()
		dispatch(rejectEnhancedPrompt())
	}

	const undo = (e) => {
		e.stopPropagation()
		dispatch(undoLastEnhancement())
	}

	const cancelEnhance = () => {
		if (abortSignal.current) {
			abortSignal.current.abort()
		}
	}

	const { isIdle, isLoading, isAnimating } = usePromptStatus()

	if (isLoading && showCancel) {
		return (
			<>
				<Tooltip 
					title={"Cancel enhancement"}
				>
					<button 
						onClick={cancelEnhance}
						className="ml-2
							rounded-lg border border-solid  border-app-search-gray p-1
							inline-flex flex-row items-center gap-1 align-[-4px]">
						<RejectIcon className="h-4 w-4" />
					</button>
				</Tooltip>
			</>
		)
	} else if (isLoading) return <></>

	if (isAnimating) return <></>
	if (generationStyle === 'style-transfer') return <></>

	if (!lastEnhancement.prompt || lastEnhancement.prompt_status) {
		return (
			<>
				<Tooltip 
					title={enhanceEnabled ? "Freeze Prompt" : "Unfreeze Prompt"}
				>
					<Switch
						id="enhance-btn"
						className={`ml-2
							rounded-md h-6 w-6 box-border 
							${enhanceEnabled ? 'border border-solid  border-app-search-gray' :
									'bg-v5-freeze/20'}
							inline-flex flex-row justify-center items-center gap-1 align-[-2px]`}
						checked={!enhanceEnabled}
						onChange={handleToggleEnhance}
					>

						<FreezeIcon className={`m-1 w-3 h-3 ${enhanceEnabled ? '[&_path]:fill-v5-prompt-handle-label' : '[&_path]:fill-v5-freeze '}`} />
					</Switch>
				</Tooltip>
				<Tooltip 
					title="Enhance prompt"
				>
					<motion.button 
						initial="open" variants={{ 
							clsoed: {}, 
							open: {}
						}} animate={open}
						layout
						id="enhance-btn"
						onClick={enhance}
						onMouseEnter={() => setOpen("open")}
						onMouseLeave={() => setOpen("closed")}
						className="ml-2
							rounded-md h-6 w-6 box-border border border-solid  border-app-search-gray 
							inline-flex flex-row justify-center items-center gap-1 align-[-4px]"
					>

						<EnhanceIcon className="w-4 h-4" />
					</motion.button>
				</Tooltip>
				{enhanceHistory.length > 0 && (
					<Tooltip title="Revert Last Prompt Enhancement">
						<button 
							onClick={undo}
							className="ml-2
								rounded-lg border border-solid  border-app-search-gray p-1
								inline-flex flex-row items-center gap-1 align-[-4px]">
							<UndoIcon className="w-4 h-4" />
						</button>
					</Tooltip>
				)}
				
			</>
		)
	}
	return (
		<span className="ml-2 inline-flex gap-1 border border-solid border-app-search-gray px-1 h-6 rounded-md box-border self-center align-[-4px]" onClick={e => e.stopPropagation()}>
			<Tooltip title="Accept">
				<button onClick={accept}>
					<AcceptIcon className="h-4 w-4" />
				</button>
			</Tooltip>
			<Tooltip title="Redo">
				<button onClick={enhance}>
					<RedoIcon className="h-4 w-4" />
				</button>
			</Tooltip>
			<Tooltip title="Revert">
				<button onClick={reject}>
					<RejectIcon className="h-4 w-4" />
				</button>
			</Tooltip>
		</span>
	)
}

const TextAreaWithImportance = (props) => {
  const {
    value,
    placeholder,
    updateValue,
    innerHtml,
    textAreaRef,
    importanceRef,
		shouldApplyUserPrompt,
		showEnhance,
		setShowEnhance,
  } = props;

  const dispatch = useDispatch();

  const { mode } = useGenerationEndpoint();

  const update = (e) => {
    const target = e.relatedTarget;
    if (importanceRef.current?.contains(target)) {
      return;
    }

    const calculateTextImportance = (text) => {
      let result = [''];
      let parantheses_count = 0;
      text.split('').forEach((element) => {
        if (parantheses_count === 0 && /^\s$/g.test(element)) {
          result.push('');
          return;
        }

        if (element === '(') parantheses_count++;
        if (element === ')') parantheses_count--;

        result[result.length - 1] += element;
      });

      return result
        .filter((item) => !!item.replace(/[\s\n]/g, ''))
        .map(
          (item, ind) => item.trim()
          // setImportance(item.trim(), ind === 0 ? importance : 0)
        );
    };

    // flushSync(() => {
    updateValue(() => {
      const element = textAreaRef.current;
      let newList = [];
      let leftover_text = '';
      for (let val of element.childNodes) {
        let importance = 0;
        if (!(val instanceof Text) && !(val instanceof String))
          importance = Number(val.dataset?.importance);

        if (importance > 0) {
          newList = [
            ...newList,
            ...calculateTextImportance(leftover_text),
            ...[val.innerText ?? val.textContent]
              // .split(" ")
              .filter((item) => !!item.replace(/[\s\n]/g, ''))
              .map((item, ind) =>
                // item.trim()
                setImportance(item.trim(), ind === 0 ? importance : 0)
              ),
          ];
          leftover_text = '';
          continue;
        }
        leftover_text += val.innerText ?? val.textContent;
      }
      return [...newList, ...calculateTextImportance(leftover_text)].filter(
        (item) => !!item
      );
    });
    // })
    // updateValue(newList);
  };

  const handleRemove = (e) => {
		e.stopPropagation()
    let target = e.target;

    if (target.tagName === 'PATH') target = target.parent;

    if (!target.dataset || target.dataset.action !== 'unimportant') return;

		e.stopPropagation()

    flushSync(() => {
      update(e);
    });

    if (target.dataset && target.dataset.action === 'unimportant') {
      const ind = Number(target.dataset.pos);
      updateValue((val) => {
        return [
          ...val.slice(0, ind),
          setImportance(val[ind], 0),
          ...val.slice(ind + 1),
        ];
      });
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const raw = window.event.clipboardData.getData('text/plain');

    let result = [''];
    let parantheses_count = 0;
    raw.split('').forEach((element) => {
      if (parantheses_count === 0 && /^\s$/g.test(element)) {
        result.push('');
        return;
      }

      if (element === '(') parantheses_count++;
      if (element === ')') parantheses_count--;

      result[result.length - 1] += element;
    });

    document.execCommand('insertHTML', false, convertToHtml(result));
  };

	const style = useStyle();
	const styleOrdering = useMemo(() => {
		if (mode !== 'v5.0') return [];
		return V5_ORDERING[style] ?? [{ id: 'prompt' }]
	}, [style, mode])

	const styleConfig = useStyleSettingsMap();

	const lastEnhancement = useSelector((state) => state.form.lastEnhancement)
	const { isIdle, isLoading } = usePromptStatus()

	const style_config = useSelector((state) => state.form.payload.styleConfig);

  const handleConfigChange = (config_id, value) => {
		console.log("config change", config_id, value)
    dispatch(
      setStyleConfig({
        config_id,
        value,
      })
    );
  };

	const showPromptParams = useMemo(() => {
		return !!lastEnhancement.prompt
	}, [lastEnhancement])

	const prePromptValues = useMemo(() => {
		const promptInd = styleOrdering.findIndex((y) => y.id === 'prompt')
		return styleOrdering.filter((item, i) => i < promptInd).map(i => ({ ...i, config: styleConfig[i.id]}))
	}, [styleOrdering, styleConfig, ])
	const postPromptValues = useMemo(() => {
		const promptInd = styleOrdering.findIndex((y) => y.id === 'prompt')
		return styleOrdering.filter((item, i) => i > promptInd).map(i => ({ ...i, config: styleConfig[i.id]}))
	}, [styleOrdering, styleConfig, ])
	console.log(postPromptValues)


  const prompt = useSelector((state) => state.form.payload.prompt);

  return (
    <>
      <div
        className={`relative flex-grow group ${
          mode === 'v5.0' ? 'md:min-h-full h-full md:mr-4' : 'h-[50px] lg:h-auto'
        }`}
        onSelect={(e) => {}}
				onBlur={(e) => {
					console.log("Blur", e)
					if (
						e.relatedTarget.id !== 'enhance-btn' &&
						(
							textAreaRef.current.childNodes.length < 1 || textAreaRef.current.innerText.length < 1
						)) {
						setShowEnhance(false)
					}
				}}
      >
				{mode < 'v5.0' && value.filter((item) => !!item).length === 0 && (
					<div className='absolute inset-1 text-gray-600 group-focus-within:hidden leading-6 pointer-events-none'>
						{placeholder}
					</div>
				)}
				<TextAnimationContext 
					className={`
						focus:outline-0 ${
							mode === 'v5.0' ? 'z-[5] min-h-[40px] w-full h-[90px] inline-block text-xs [line-height:28px]' 
								: 'absolute inset-1 overflow-y-auto z-[5] custom-scroll pb-4'
						} ${isLoading && lastEnhancement.prompt ? 'prompt-loading-animation' : ''} `}
					onClick={(e) => {
						if (textAreaRef.current.contains(e.target)) return;
						if (textAreaRef.current.contains(document.activeElement)) return ;
						textAreaRef.current.focus()
						window.getSelection().selectAllChildren(textAreaRef.current)
						window.getSelection().collapseToEnd()
						setShowEnhance(true)
					}}
				>
					{showPromptParams && prePromptValues.map((section) => (
						section.type === 'static' 
							? <TextAnimationWrapper key={section.id}>{section.text}{' '}</TextAnimationWrapper>
							: section.config && style_config[section.config.id]?.value && <PromptHandler 
									section={section}
									config_metadata={section.config}
									key={section.config.id}
									value={style_config[section.config.id]}
									onChange={(val) => handleConfigChange(section.config.id, val)}
								/>
					))}
					{!isIdle ? (
						<TextAnimationWrapper
							as="span"
							// innerRef={textAreaRef}
							ref={textAreaRef}
							className={`focus:outline-0 ${!lastEnhancement.prompt && isLoading ? 'prompt-loading-animation' : ''}`}
							style={{
								'--ch-size': innerHtml.length < 70 ? '2ch' : '10ch',
							}}
						>
							{getPromptText(prompt)}
						</TextAnimationWrapper>
					) : (
						<span
							// innerRef={textAreaRef}
							ref={textAreaRef}
							className={`focus:outline-0 min-h-[24px] min-w-[10px] ${value.filter((item) => !!item).length === 0 ? 'inline-block' : 'inline'}`}
							onPaste={handlePaste}
							contentEditable='true'
							onBlur={(e) => {
								flushSync(() => update(e))
							}}
							onClick={handleRemove}
							// onInput={update}
							// html={innerHtml}
							// onChange={(e) => setInnerHtml(e.target.value)}
							onChange={update}
							onInput={(e) => {
								shouldApplyUserPrompt.current = true
								// if (textAreaRef.current.childNodes.length >= 1 || textAreaRef.current.innerText.length >= 1) {
								// 	setShowEnhance(true);
								// } else setShowEnhance(false)
							}}
							dangerouslySetInnerHTML={{ __html: innerHtml }}
						/>
					)}
					{mode >= 'v5.0' && value.filter((item) => !!item).length === 0 && (
						<div className='text-gray-600 group-focus-within:hidden leading-6 pointer-events-none inline'>
							{placeholder}
						</div>
					)}

					{showPromptParams && postPromptValues.map((section, i, arr) => (
						section.type === 'static' 
							? <TextAnimationWrapper key={section.id}>{section.text}{' '}</TextAnimationWrapper>
							: section.config && style_config[section.config.id]?.value && <PromptHandler 
									section={section}
									config_metadata={section.config}
									key={section.config.id}
									value={style_config[section.config.id]}
									onChange={(val) => handleConfigChange(section.config.id, val)}
									last={i === arr.length}
								/>
					))}
					{/* {mode === 'v5.0' && value.filter((item) => !!item).length !== 0 && ( */}
					{mode === 'v5.0' && showEnhance && (
						<EnhanceControls />
					)}
				</TextAnimationContext>
				
				<V2StyleConfig />
      </div>
    </>
  );
};
export default TextAreaWithImportance;
