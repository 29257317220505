import React, { useState } from 'react';

const CustomToogleSwitch = (props) => {
  const { isChecked, onValue, offValue, handleCheckboxChange } = props;

  return (
    <label className='inline-flex items-center justify-center cursor-pointer bg-switch-bg-gray h-[40px] rounded-[12px] p-[2px]'>
      <input
        id='toogleswitch'
        type='checkbox'
        checked={isChecked}
        onChange={handleCheckboxChange}
        className='hidden peer'
      />
      <span
        className={`px-4 flex items-center h-[36px] rounded-l-[10px] ${
          !isChecked
            ? 'bg-app-green text-switch-label-green'
            : 'switch-bg-gray text-switch-label-white'
        }`}
      >
        {onValue}
      </span>
      <span
        className={`px-4 flex items-center h-[36px] rounded-r-[10px] ${
          isChecked
            ? 'bg-app-green text-switch-label-green'
            : 'switch-bg-gray  text-switch-label-white'
        }`}
      >
        {offValue}
      </span>
    </label>
  );
};

export default CustomToogleSwitch;
