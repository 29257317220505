import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { registerUser } from "../reducers/userReducer";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { ReactComponent as InfoIcon } from "../assets/InfoIcon.svg";
import { useSearchParams } from "react-router-dom";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

import { relogUser } from "../reducers/userReducer";

// import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as Logo } from "../assets/new-logo.svg";
import { ReactComponent as GoogleIcon } from "../assets/GoogleIcon.svg";
import { ReactComponent as DiscordIcon } from "../assets/DiscordIcon.svg";
import environment from "../helpers/envProvider";
import Tap from "@tapfiliate/tapfiliate-js";
import { registerUserToken } from "../firebase";

import HCaptcha from '@hcaptcha/react-hcaptcha';
import useUsermavenWrapper from "../hooks/useUsermavenWrapper";

const MAINTANANCE_MODE = environment('REACT_APP_MAINTANANCE') == 'true'
const MAINTANANCE_MESSAGE = environment('REACT_APP_MAINTANANCE_MESSAGE')

function Register() {
  const [email, setEmail] = useState("");
  const [emailEditable, setemailEditable] = useState(true);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");

  const isLoading = useSelector((state) => state.userSlice.isLoading);

	const captchaRef = useRef()

  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);

	const [view, setView] = useState('main');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

	const { track: usermavenTrack, id: usermavenId } = useUsermavenWrapper();
	const store = useStore();

	const setLastMethod = (val) => {
		localStorage.setItem('lastLoginMethod', val)
	}

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!email)
      return toast("Email is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        autoClose: 2000,
      });

    if (!name)
      return toast("Name is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        autoClose: 2000,
      });

    if (!password)
      return toast("Password is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        autoClose: 2000,
      });

    if (!token) {
			captchaRef.current.execute();
      return toast("Captcha is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        autoClose: 2000,
      });
		}

    try {
      const userData = await dispatch(
        registerUser({ email, name, password, captchaToken: token })
      ).unwrap();
			setLastMethod('email')
			Tap.customer(userData.user.stripeCustomerId);

			await registerUserToken(dispatch);
      // localStorage.setItem('userInfo', JSON.stringify({ id: userData.id, refreshToken: userData.refreshToken }));

      // navigate('/?loggedIn=true');

      setShowEmailConfirmation(true);
    } catch (error) {
      if (error.message) {
        toast(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          autoClose: 2000,
        });
      } else {
				console.log(error)
        toast(
          error.response?.data?.detail ||
          error.response?.data?.details ||
            error.message ||
            error.response ||
            "Something went wrong, please try again.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "dark",
            autoClose: 2000,
          }
        );
      }
    }
  };

  useEffect(() => {
    const userId = searchParams.get("userId");
    const refreshToken = searchParams.get("refreshToken");
    const error = searchParams.get("error");

    const email = searchParams.get("preset_email");

    if (email) {
      setEmail(email)
      setemailEditable(false);
      return ;
    }

		const selectPlan = searchParams.get("select_plan")

		if (selectPlan) {
			localStorage.setItem('selectPlan', selectPlan)
			localStorage.setItem('showUpgradeOnLogin', 'true')
		}


    if (error) {
      if(error === 'userExists' || error === 'alreadyRegistered') {
        toast("An account with this data already exists, please try logging in", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000
        });
				setTimeout(() => {
					navigate("/login");
				}, 2000)
      }
      if(error === 'noDiscordEmail') {
        toast("Your discord email is not verified, please try registering through a different method or verify your email in discord", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000
        });
      }
      if(error === 'verifyEmail') {
				setShowEmailConfirmation(true);
      }
    } else {
      if (userId && refreshToken) {
        dispatch(relogUser({ userId, refreshToken }))
          .unwrap()
          .then((res) => {
            if (res === "Invalid token") {
            } else {
							Tap.customer(res.stripeCustomerId);
							registerUserToken(dispatch);
              navigate("/?loggedIn=true");

							usermavenId({
								id: store.getState().userSlice.user._id,
								email: store.getState().userSlice.user.email,
								created_at: store.getState().userSlice.user.createdAt,

								first_name: store.getState().userSlice.user.name,

								custom: {
									subscription_type: store.getState().userSlice.user.subscriptionProvider,
									stripe_subscription_price: store.getState().userSlice.user.plan?.productId,
								},
							});

							usermavenTrack('sign_up', {
								id: store.getState().userSlice.user._id,
								email: store.getState().userSlice.user.email,
								created_at: store.getState().userSlice.user.createdAt,

								first_name: store.getState().userSlice.user.name,
							})
            }
          })
          .catch((e) => navigate("/login"));
      }
    }
  }, [searchParams, dispatch, navigate, store, usermavenTrack, usermavenId]);

  // useEffect(() => {
  //   if (!showEmailConfirmation) loadCaptchaEnginge(6, "#292932", "#fff");
  // }, [showEmailConfirmation]);

  return (
    <div>
      <div className="max-w-[360px] w-[90%] mx-auto rounded-2xl flex flex-col justify-center min-h-screen">
        <Helmet>
          <title>Register | Logo Diffusion</title>
        </Helmet>

				{MAINTANANCE_MODE && (
					<div className={"self-center rounded-lg bg-notification-purple-bg p-3 flex items-center gap-2 text-notification-purple-text mt-4 border border-solid border-notification-purple-border mb-4"}>
						<InfoIcon className="[&_path]:!fill-notification-purple-text w-6 h-6 shrink-0"/>
						<div className="flex-grow font-bold text-sm font-roboto">
							Maintenance mode: 
							{MAINTANANCE_MESSAGE || "Logo Diffusion is currently not available due to a Bot Attack, We’re currently working on a solution, and we’ll back as soon as we fix this issue."}
						</div>
					</div>
				)}

        {showEmailConfirmation ? (
          <div className="bg-app-bg-gray text-white text-center rounded-2xl">
            <div className="p-4">
              <h1 className="font-bold text-white text-lg mb-6">
                Registration complete
              </h1>
              <p>
                Check your email for the confirmation link.
              </p>
            </div>
            <div className="bg-app-search-gray rounded-b-2xl text-sm p-4">
              Go to {' '}
              <Link to="/login" className="text-blue-600">
                Login
              </Link>
            </div>
          </div>
        ) : (
					view === 'main' ? (
						<div className="bg-app-bg-gray text-white text-center rounded-2xl">
							<div className="p-4 flex flex-col gap-4">
								<Logo className="self-center mt-4 mb-2"/>
								<h1 className="text-xl font-semibold text-center mb-2"> Welcome to Logo Diffusion </h1>
								{isLoading && (
									<div className="flex items-center justify-center gap-4">
										<div className="relative w-6 h-6 animate-spin rounded-full bg-gradient-to-r from-spinner-blue via-spinner-cyan to-spinner-lime opacity-100">
											<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[calc(100%-0.25rem)] h-[calc(100%-0.25rem)] bg-app-bg-gray rounded-full">
											</div>
											{/* <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[calc(100%-0.5rem)] h-[calc(100%-0.5rem)] shadow-[0_0_0_99999px_rgba(0,0,0,.8)]"></div> */}
										</div>
										<h1 className="font-bold text-white text-lg ">
											Logging you in...
										</h1>
									</div>
								)}
								<div className="flex flex-col gap-2">
									{!MAINTANANCE_MODE && (
										<>
											<a
												onClick={() => setLastMethod('google')}
												href={`${
													environment("REACT_APP_AUTH_URL") ||
													"https://auth.logodiffusion.com"
												}/auth/google-redirect?action=register`}
												className="bg-azure-radiance text-white font-bold w-full rounded-lg p-3 text-sm flex items-center justify-center gap-[10px]"
											>
												<GoogleIcon className="w-[16px]" /> Register with Google
											</a>

											<a
												onClick={() => setLastMethod('discord')}
												href={`https://discord.com/api/oauth2/authorize?client_id=${environment('REACT_APP_DISCORD_CLIENT_ID')}&redirect_uri=${
													environment("REACT_APP_AUTH_URL") ||
													"https://auth.logodiffusion.com"
												}%2Fauth%2Fregister-discord&response_type=code&scope=identify%20email`}
												className="bg-discord-purple text-white font-bold w-full rounded-lg p-3 text-sm flex items-center justify-center gap-[10px]"
											>
												<DiscordIcon className="w-[16px]" /> Register with Discord
											</a>
										</>
									)}
									<button
										onClick={() => setView('email')}
										className="bg-white bg-opacity-[0.15] text-white font-bold w-full rounded-lg p-3 text-sm flex items-center justify-center gap-[10px]"
									>
										Continue with Email
									</button>
								</div>
							</div>
							<div className="bg-app-search-gray rounded-b-2xl text-sm p-4">
								Already have an account?{" "}
								<Link to="/login" className="text-blue-600">
									Login
								</Link>
							</div>
						</div>
					) : ( 
						<div className="bg-app-bg-gray text-white text-center rounded-2xl">
							<div className="p-4">
								<Logo className="self-center mt-4 mb-6 mx-auto"/>
								<h1 className="font-bold text-white text-xl mb-6">
									Sign up for your account
								</h1>

								<form className="flex flex-col">
									<input
										type="email"
										name="email"
										placeholder="Your email"
										className="bg-app-search-gray mb-3 p-2 rounded-md"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										disabled={!emailEditable}
									/>

									<input
										type="text"
										name="username"
										placeholder="Your name"
										className="bg-app-search-gray mb-3 p-2 rounded-md"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>

									<input
										type="password"
										name="password"
										placeholder="Create password"
										className="bg-app-search-gray mb-3 p-2 rounded-md"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									
									<div className="self-center mb-2">
										<HCaptcha 
											sitekey={environment('REACT_APP_HCAPTCHA_SITEKEY')}
											onVerify={(token) => setToken(token)}
											// onLoad={() => captchaRef.current.execute()}
											ref={captchaRef}
										/>
									</div>

									<button
										type="submit"
										className="bg-blue-600 text-white font-bold w-full rounded-lg p-3 mb-2 text-sm disabled:bg-blue-600/20 disabled:cursor-not-allowed"
										onClick={handleRegister}
										disabled={MAINTANANCE_MODE}
									>
										Register via E-Mail
									</button>

									<p className="text-sm mt-2 mb-4 text-[#55555F]">
										By signing up, you confirm that you’ve read and accepted our
										User Notice and Privacy Policy.
									</p>

								</form>
							</div>

							<div className="bg-app-search-gray rounded-b-2xl text-sm p-4">
								Already have an account?{" "}
								<Link to="/login" className="text-blue-600">
									Login
								</Link>
							</div>
						</div>
					)
        )}
      </div>

      <ToastContainer />
    </div>
  );
}

export default Register;
