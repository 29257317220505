import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import ProtectedRoute from '../components/ProtectedRoute';
import UserLayout from '../layouts/UserLayout';
import { Link, useNavigate } from 'react-router-dom';
import ImageViewer from '../components/ImageViewer';
import { toast } from 'react-toastify';
import { loadGenerationHistory, showImageModal } from '../reducers/formReducer';
import throttle from 'lodash/throttle';
import cloneDeep from 'lodash/cloneDeep';
import Dropdown from '../components/base/CustomDropdown/Dropdown';

import TextBackground from '../assets/dashboard/txt2img.png';
import ImageBackground from '../assets/dashboard/img2img.png';
import VectorBackground from '../assets/dashboard/vectorizer-preview.jpg';
import UpscaleBackground from '../assets/dashboard/upscaler.png';
import TextIcon from '../assets/dashboard/txt2img-icon.svg';
import ImageIcon from '../assets/dashboard/img2img-icon.svg';
import VectorizeIcon from '../assets/dashboard/vectorize-icon.svg';
import UpscaleIcon from '../assets/dashboard/upscale-icon.svg';
import { ReactComponent as TutorialPlayIcon } from '../assets/dashboard/play-icon.svg';
import { ReactComponent as LoadingDots } from '../assets/LoadingDots.svg';

import {
  setCurrentTutorial,
  setIsTutorialModalOpen,
  setLoadVideo,
} from '../reducers/appReducer';

import { useWindowDimensions } from '../hooks/useDimenssion';
import useTutorials from '../hooks/useTutorials';
import VersionAnnounceModal from '../components/modals/VersionAnnounceModal';
import authAxiosInstance from '../helpers/auth-axios';
import { V3_FILTER } from '../components/input/v3-remix-filter';
import useGenerationEndpoint, {
  MODES,
} from '../components/input/useGenerationEndpoint';
import { V4_GROUPS, V4_STYLES } from '../components/input/v4-content-styles';
import { STYLE_TRANSFER_STYLES } from '../components/StyleImage';
import { V5_GROUPS, V5_STYLES } from '../components/input/v5-styles';

const Highlight = ({ children }) => (
  <span className='text-dashboard-highlight font-bold'> {children} </span>
);

const cardData = [
  {
    imageUrl: TextBackground,
    title: 'Text to Image Workspace',
    description:
      'Start with a basic prompt and choose from our logo styles, 2D, and 3D Art styles.',
    link: '/gen/text-workspace',
  },
  {
    imageUrl: ImageBackground,
    title: 'Image to Image Workspace',
    description:
      'Use an image or a sketch to guide Logo Diffusion in crafting your design.',
    link: '/gen/image-workspace',
  },
  {
    imageUrl: VectorBackground,
    title: 'Image to Vector',
    description:
      'Convert any image into a clean vector .SVG file, ready to be used online or with illustrator.',
    link: 'vectorizer',
    isAvailable: (user) => !user?.isSumoling,
  },
  {
    imageUrl: UpscaleBackground,
    title: 'Image Upscaler',
    description:
      'Upscale any image up to 4x! and add more details to your image.',
    link: 'upscale',
    isAvailable: (user) => !user?.isSumoling,
  },
];

const Home = () => {
  const store = useStore();
  const user = useSelector((state) => state.userSlice.user);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const tutorials = useTutorials();
  const navigate = useNavigate();
  const loadingAnchor = useRef();

  const [options, setOptions] = useState(cloneDeep(V3_FILTER));

  const [gallery, setGallery] = useState([]);
  const [hasMoreGallery, setHasMoreGallery] = useState(true);
  const gallery_count = useRef(0);
  const is_loading = useRef(false);
  const total_gallery_count = useRef(-1);

  const { mode, method } = useGenerationEndpoint();

  const galleryFilters = useMemo(() => {
    if (!mode) return {};
    return {
      generationMethods: Object.values(MODES[mode]),
    };
  }, [mode]);

  const handleOptionChange = (id, checkedStatus) => {
    setOptions((prevOptions) => {
      if (id === 'all') {
        const opt = prevOptions.map((o) => {
          if (o.id === 'all') {
            return { ...o, checked: true };
          }
          const subItems = o.subItems.map((it) => {
            return { ...it, checked: false };
          });

          const op = { ...o, checked: false, subItems };
          return op;
        });
        return opt;
      }
      let resultOption = prevOptions.map((_option) => {
        let option = _option;
        let isParentClicked = false;

        if (option.id === id) {
          isParentClicked = true;
          option = { ...option, checked: checkedStatus };
        }

        if (option.subItems) {
          const subItems = option.subItems.map((subItem) => {
            if (isParentClicked) {
              return { ...subItem, checked: checkedStatus };
            }
            if (subItem.id === id) {
              return { ...subItem, checked: checkedStatus };
            } else {
              return subItem;
            }
          });
          const isRemovedAll = subItems.reduce((acc, item) => {
            return acc && !item.checked;
          }, true);
          if (isRemovedAll) {
            option = { ...option, checked: false };
          } else {
            option = { ...option, checked: true };
          }
          return { ...option, subItems: subItems };
        }

        return option;
      });

      console.log('resultOption', resultOption);
      const isAllCleared = resultOption.reduce((acc, item) => {
        if (item.id === 'all') return true;
        return acc && !item.checked;
      }, true);
      console.log('isAllCleared', isAllCleared);
      if (isAllCleared) {
        resultOption = resultOption.map((op) => {
          if (op.id === 'all') {
            return { ...op, checked: true };
          }
          return { ...op, checked: false };
        });
      } else {
        resultOption = resultOption.map((op) => {
          if (op.id === 'all') {
            return { ...op, checked: false };
          }
          return { ...op };
        });
      }
      return resultOption;
    });
  };

  const filters = useMemo(() => {
    let filters = options.map((o) => {
      const checkedItems = o.subItems.filter((i) => i.checked) || [];
      return checkedItems;
    });
    return filters.flat();
  }, [options]);

  const isSelectedFilter = useMemo(() => {
    return filters.length > 0;
  }, [filters]);

  const filteredGallery = useMemo(() => {
    console.log('Filters', filters);
    return gallery.filter(
      (g) =>
        filters.length === 0 ||
        filters.findIndex(
          (f) =>
            (g.contentStyle === f.id || g.style === f.id) &&
            (!f.endpoint ||
              f.endpoint === g.method ||
              f.a_endpoint === g.method ||
              f.b_endpoint === g.method ||
              f.c_endpoint === g.method)
        ) > -1
    );
  }, [filters, gallery]);

  const loadMore = throttle(async (abort) => {
    if (
      total_gallery_count.current === gallery_count.current ||
      is_loading.current === true
    )
      return;
    is_loading.current = true;
    try {
      const startFrom = gallery_count.current;
      const response = await authAxiosInstance.get(`/api/gallery`, {
        params: {
          range: `[${startFrom},${startFrom + 30}]`,
          filter: JSON.stringify(galleryFilters),
        },
        signal: abort,
      });

      setGallery((gallery) => {
        const new_gallery = [
          ...gallery,
          ...response.data.docs.map((item) => ({
            ...item,
            ...item.generation,
          })),
        ];

        setHasMoreGallery(new_gallery.length < response.data.totalDocs);

        gallery_count.current = new_gallery.length;
        total_gallery_count.current = response.data.totalDocs;

        return new_gallery;
      });
    } catch (e) {
      if (e.code === 'ERR_CANCELED') return;
      console.log(e);
      toast('Failed to load gallery', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    } finally {
      is_loading.current = false;
    }
  }, 5000);

  useEffect(() => {
    gallery_count.current = 0;
    total_gallery_count.current = -1;
    setGallery([]);
    setHasMoreGallery(true);
    const abort = new AbortController();
    loadMore(abort.signal);

    return () => {
      abort.abort();
    };
  }, [user, mode]);

  useEffect(() => {
    if (mode === 'v5.0') {
      setOptions([
        {
          id: 'all',
          name: 'All Styles',
          subItems: [],
          checked: true,
        },
        ...V5_GROUPS.map((g) => ({
          ...g,
          checked: false,
          subItems: V5_STYLES.filter((s) => s.group === g.id),
        })),
        {
          id: 'style-transfer',
          name: 'Style Transfer',
          subItems: STYLE_TRANSFER_STYLES.map((s) => ({
            name: s.name,
            id: s.value,
            endpoint: 'v5/style-transfer',
          })),
          checked: false,
        },
      ]);
    } else if (mode === 'v4.0') {
      setOptions([
        {
          id: 'all',
          name: 'All Styles',
          subItems: [],
          checked: true,
        },
        ...V4_GROUPS.map((g) => ({
          ...g,
          checked: false,
          subItems: V4_STYLES.filter((s) => s.group === g.id),
        })),
        {
          id: 'style-transfer',
          name: 'Style Transfer',
          subItems: STYLE_TRANSFER_STYLES.map((s) => ({
            name: s.name,
            id: s.value,
            endpoint: 'v4/style-transfer',
          })),
          checked: false,
        },
      ]);
    } else {
      setOptions(cloneDeep(V3_FILTER));
    }
  }, [mode]);

  const handleViewImage = (image) => {
    dispatch(showImageModal(image.imageUrl));
    // setViewingImage(image);
    // setIsImageViewerOpen(true);
    // setViewingImageIndex(generatedImages.findIndex(gen => gen._id === image._id));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        console.log('Intersection changed');
        if (store.getState().userSlice.user && entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (loadingAnchor.current) {
      observer.observe(loadingAnchor.current);
    }

    return () => {
      if (loadingAnchor.current) {
        observer.unobserve(loadingAnchor.current);
      }
    };
  }, [loadingAnchor, store, loadMore, gallery]);

  return (
    <UserLayout
      containerClasses='w-full md:px-12 max-sm:max-w-[95%] max-sm:mx-auto'
      notificationClasses='max-w-[1400px] mx-auto'
    >
      <ProtectedRoute>
        <div className='w-full h-full flex flex-col items-center md:items-stretch gap-8 py-8'>
          <h2 className='text-title-white text-2xl [line-height:1.8rem] font-bold font-inter text-center md:text-start'>
            Get Started
            <br />
            <p className='flex justify-between text-subtitle text-sm font-normal font-inter text-center md:text-start'>
              Choose a workspace to get started
            </p>
          </h2>
          <div className='grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-4 gap-5 px-3 md:px-0 flex-wrap w-full'>
            {cardData
              .filter((card) => (card.isAvailable || (() => true))(user))
              .map((card) => (
                <Link
                  className='flex-col w-full h-[300px] bg-[#1C1C24] rounded-[14.2px]'
                  to={card.link}
                >
                  <img
                    className='w-full h-[57%] object-cover rounded-t-[14.2px]'
                    src={card.imageUrl}
                    alt=''
                  />
                  <div className='flex flex-col w-full items-start justify-evenly h-[43%] px-[14px]'>
                    <p className='text-[19.98px] text-white font-bold'>
                      {card.title}
                    </p>
                    <p className='text-[12px] text-[#ADADAD]'>
                      {card.description}
                    </p>
                    <div className='flex w-full items-center justify-end pb-1'>
                      <Link
                        className='flex w-full justify-center items-center py-[5px] mx-[20px] text-[14px] font-bold text-white  hover:bg-opacity-70 focus:bg-opacity-70 rounded-[9px] transition-all duration-200 border-[1px] border-button-purple'
                        to={card.link}
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
          <h2 className='text-title-white text-2xl [line-height:1.8rem] font-bold font-inter text-center md:text-start'>
            Tutorials
            <br />
            <span className='flex justify-between text-subtitle text-sm font-normal font-inter text-center md:text-start'>
              short videos to introduce you to Logo Diffusion.
              <button
                type='button'
                className='text-button-purple'
                onClick={() => dispatch(setIsTutorialModalOpen(true))}
              >
                view all tutorials
              </button>
            </span>
          </h2>
          <div className='flex gap-4 flex-wrap w-full'>
            {tutorials
              .filter((_, i) => i < Math.max(4, width / 600))
              .map((item, ind) => (
                <button
                  type='button'
                  onClick={() => {
                    dispatch(setIsTutorialModalOpen(true));
                    setTimeout(() => {
                      dispatch(setCurrentTutorial(ind));
                      dispatch(setLoadVideo(ind));
                    });
                  }}
                  className='relative aspect-video basis-5/12 md:basis-2/12 xl:basis-1/12 grow flex items-center justify-center overflow-hidden rounded-xl cursor-pointer group text-start'
                >
                  <div className='absolute inset-0 overflow-hidden rounded-xl border border-solid border-app-black'>
                    <img
                      className='max-w-full max-h-full'
                      src={item.thumbnail}
                      alt={item.name}
                    />
                  </div>
                  <div className='flex absolute top-0 left-0 right-0 bottom-0 flex-col justify-end py-4 px-3 transition-all ease-in-out duration-400 text-white font-inter font-semibold text-sm bg-gradient-to-t from-app-black via-app-black/10 group-hover:via-black/20 to-transparent'>
                    {item.name}
                    <span className='font-normal text-xs'>{item.duration}</span>
                  </div>
                  <div className='w-12 absolute top-[50%] translate-x-[-50%] left-[50%] translate-y-[-50%] opacity-60 group-hover:opacity-100 transition-all duration-300'>
                    <TutorialPlayIcon className='w-full h-full' />
                  </div>
                </button>
              ))}
          </div>
          <div className='flex items-center justify-between'>
            <h2 className='text-title-white text-2xl [line-height:1.8rem] font-bold font-inter text-center md:text-start'>
              Discover and Remix
              <br />
              <span className='flex justify-between text-subtitle text-sm font-normal font-inter text-center md:text-start'>
                Get inspired and pick a design to start from
              </span>
            </h2>
            <Dropdown
              options={options}
              handleOptionChange={handleOptionChange}
              isSelectedFilter={isSelectedFilter}
            />
          </div>
          <ImageViewer
            gotoTextWorkspace={() => {
              navigate('/gen/text-workspace');
            }}
            generatedImages={gallery}
            toolOptions={{
              isShowBookmark: false,
              isShowDelete: false,
              isShowEdit: false,
              isShowLike: false,
              isShowDislike: false,
              isShowUpscale: false,
            }}
          />
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 gap-5'>
            {filteredGallery.map((image) => (
              <img
                className='cursor-pointer rounded-[20px] aspect-square w-full max-w-[250px]'
                src={image.imageUrl}
                alt={image.prompt}
                onClick={(e) => handleViewImage(image)}
                key={image.imageUrl}
              />
            ))}
          </div>
          <div
            ref={loadingAnchor}
            className='p-2 px-3 flex items-center justify-center bg-w col-span-full'
          >
            {hasMoreGallery ? (
              <span
                className='flex items-center justify-center'
                id='button-loading'
              >
                <LoadingDots className='h-2' />
              </span>
            ) : (
              <span className='rounded-lg text-selection-border px-6 py-3 text-sm font-montserrat font-light border border-solid border-samples-border-color'>
                More samples will be added weekly
              </span>
            )}
          </div>
        </div>

        <VersionAnnounceModal />
      </ProtectedRoute>
    </UserLayout>
  );
};

export default Home;
