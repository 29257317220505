import { DateInput, Edit, SimpleForm, ReferenceField, TextField, TextInput, Image, ImageField, BooleanInput, SelectField, AutocompleteArrayInput, NumberInput, SelectInput } from 'react-admin';
import Grid from '@mui/material/Grid';

const options = []

export const PriceEdit = () => (
    <Edit>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
							<TextField label="id" source="id" aria-readonly="true"/>
            </Grid>
            <Grid item xs={6}>
							<TextField source="environment" aria-readonly="true"/>
            </Grid>
            <Grid item xs={6}>
							<TextInput source="name" aria-readonly="false"/>
            </Grid>
            <Grid item xs={6}>
							<TextInput source="priceId" aria-readonly="false"/>
            </Grid>
            <Grid item xs={6}>
							<TextInput source="productId" aria-readonly="false"/>
            </Grid>
            <Grid item xs={6}>
							<NumberInput source="order" />
            </Grid>

            <Grid item xs={6}>
							<BooleanInput source="active" />
            </Grid>

            <Grid item xs={6}>
							<SelectInput source="group" choices={[
								{ id: 'extra-credit', name: 'Extra Credit'},
								{ id: 'subscription', name: 'Subscription'},
							]}/>
            </Grid>

						<Grid item xs={6}>
							<SelectInput source="type" choices={[
								{ id: 'onetime', name: 'One Time Payment'},
								{ id: 'subscription', name: 'Subscription'},
							]}/>
						</Grid>

            <Grid item xs={6}>
							<SelectInput source="Period" choices={[
								{ id: 'monthly', name: 'Monthly'},
								{ id: 'yearly', name: 'Yearly'},
							]}/>
            </Grid>

            <Grid item xs={6}>
							<AutocompleteArrayInput source="metadata.perks" onCreate={(v) => {
								const option = { id: v, name: v }
								options.push(option)
								return option
							}} choices={options}/>
            </Grid>

          </Grid>
        </SimpleForm>
    </Edit>
);

export default PriceEdit;
