import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyle from "../../../hooks/useStyle";
import { randomize_config_item } from '../style-config/constants';
import useGenerationEndpoint from "../useGenerationEndpoint";
import { twMerge } from "tailwind-merge";
import { usePopper } from "react-popper";
import { updateConfigTutorial } from "../../../reducers/formReducer";
import { ReactComponent as Icon } from '../../../assets/Prompt/main-text.svg';
import { ReactComponent as SaveIcon } from '../../../assets/SaveEditor.svg';
import { Listbox, Popover, Transition } from "@headlessui/react";
import { createPortal } from "react-dom";

const MainText = (props) => {
  const { config_metadata, value, onChange, label } = props;

	const { method, mode } = useGenerationEndpoint();

  const config_value = useMemo(
    () => value?.value || (config_metadata?.values || [])[0]?.id,
    [value, config_metadata]
  );
  const colors = useMemo(
    () => (value === undefined ? [] : value.colors),
    [value]
  );

  const style = useStyle();

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);
	const dispatch = useDispatch();

  const handleChange = (v) => {
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: true,
      value: v,
      colors,
    });
  };

  const [modeSelectorAnchorEl, setModeSelectorAnchorEl] = useState();
  const [modeSelectorEl, setModeSelectorEl] = useState();
  let modeSelectorPopper = usePopper(modeSelectorAnchorEl, modeSelectorEl, {
    strategy: 'fixed',
    placement: 'bottom-start',
  });

  const [configTutorialEl, setConfigTutorialEl] = useState();
  const [configTutorialArrowEl, setConfigTutorialArrowEl] = useState();
  let configTutorialPopper = usePopper(modeSelectorAnchorEl, configTutorialEl, {
    placement: 'right',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: configTutorialArrowEl,
          // padding: -100,
        },
      },
    ],
  });

  const configTutorial = useSelector((state) => state.form.configTutorial);
  const handleCloseConfigTutorial = () => {
    dispatch(updateConfigTutorial({ ...configTutorial, style: null }));
  };


	return (
		<Popover >
			{({ open, close }) => (
				<>
					<Popover.Button
						className={twMerge(`${open ? 
								'bg-overlay-bg/80 ' : 'bg-overlay-bg '
						} text-icon-text-color font-roboto text-xs gap-2 items-center rounded h-[29px] px-2 inline-flex transition-all duration-300 group/inline whitespace-nowrap capitalize`)}
						ref={setModeSelectorAnchorEl}
					>
						<Icon className={`transition-all duration-300 `} />
						{config_metadata.label ?? "Main Text"}
					</Popover.Button>

					<Transition
						show={open}
						as={React.Fragment}
						enter='transition duration-100 ease-out'
						enterFrom='transform scale-95 opacity-0'
						enterTo='transform scale-100 opacity-100'
						leave='transition duration-75 ease-out'
						leaveFrom='transform scale-100 opacity-100'
						leaveTo='transform scale-95 opacity-0'
					>
						<Popover.Panel
							static
							as='div'
							ref={setModeSelectorEl}
							{...modeSelectorPopper.attributes.popper}
							style={modeSelectorPopper.styles.popper}
							className={` mb-2
								bg-v5-list-bg
							p-2 rounded-lg mt-2 flex gap-2 `}
						>

							<input className="w-[200px] font-bold font-poppins text-xs gap-1 items-center rounded-sm py-1 px-2 inline-flex transition-all duration-300 bg-v5-text-input border-none outline-none focus:ring-app-green focus:ring-2 placeholder:text-white/40 text-white/60" 
										value={config_value} 
										placeholder={config_metadata.placeholder || "Company Name"}
										onChange={e => handleChange(e.target.value)}
									/>
							<button onClick={close} className="rounded-sm px-2 py-4 bg-v5-text-confirm">
								<SaveIcon className={`transition-all duration-300 `} />
							</button>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	)
}

export default MainText;
