import { useState, useEffect } from 'react';

import { ReactComponent as LoadingDots } from '../../assets/LoadingDots.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/CheckmarkIconWhite.svg';
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg';
import { ReactComponent as SparkleIcon } from '../../assets/Sparkle.svg';
import { ReactComponent as SparkleNewIcon } from '../../assets/SparkleNew.svg';
import { ReactComponent as Lightning } from '../../assets/LightningV5.svg';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  GENERATION_STATUS,
  acceptEnhancedPrompt,
  generateImages,
  updateConfigTutorial,
  updateInputImage,
  updateOutOfCreditText,
  updateShowOutOfCreditModal,
  updateShowPromptTutorial,
  updateShowStyleSelector,
  updateShowStyleSelectorTutorial,
} from '../../reducers/formReducer';
import useGenerationCost from '../../helpers/useGenerationCost';
import { EDITOR_SIZE, IMAGE_EXPORT_SIZE } from '../editor/settings';
import { resizeBase64 } from '../editor/resizers';
import { useEditor } from '../../hooks';
import { setShowAppsumo1024LimitDialog } from '../../reducers/userReducer';
import useGenerationEndpoint from './useGenerationEndpoint';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import { current } from '@reduxjs/toolkit';
import useStyle from '../../hooks/useStyle';
import { Tooltip } from '@material-ui/core';

function timeout(ms, timeouts) {
  return new Promise((resolve) => {
    const timeout = setTimeout(() => {
      timeouts?.splice(
        timeouts.findIndex((x) => x !== timeout),
        1
      );
      resolve();
    }, ms);
    timeouts?.push(timeout);
  });
}

const defaultClasses =
  'relative text-white font-bold rounded-2xl text-sm flex items-center justify-center gap-2 w-full overflow-hidden';

function GenerateButton(props) {
  const { generationRef, setMode, isMobileButton } = props;
  const { saveEditorImage } = useEditor();
  const [isAnimating, setIsAnimating] = useState(false);

  const [activeClasses, setActiveClasses] = useState('ready');

  const user = useSelector((state) => state.userSlice.user);

  const isGenerating = useSelector((state) => state.form.isLoading);
  const generationStatus = useSelector((state) => state.form.generationStatus);
  const imagesToGenerate = useSelector(
    (state) => state.form.payload.imagesToGenerate
  );
  const seed = useSelector((state) => state.form.payload.seed);
  const method = useSelector((state) => state.form.method);
  const inputImage = useSelector((state) => state.form.payload.inputImage);
  const prompt = useSelector((state) => state.form.payload.prompt);

  const style = useStyle();

  const currentWorkspace = useCurrentWorkspace();

  const style_config = useSelector((state) => state.form.payload.styleConfig);
  const lastEnhancement = useSelector((state) => state.form.lastEnhancement);

  const { mode } = useGenerationEndpoint();

  const cost = useGenerationCost();

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating((isAnimating) => !isAnimating);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timeouts = [];
    if (generationStatus === GENERATION_STATUS.FAILED) {
      failAnimations(timeouts);
    } else if (generationStatus === GENERATION_STATUS.IDLE) {
    } else if (generationStatus === GENERATION_STATUS.IN_QUEUE) {
    } else if (generationStatus === GENERATION_STATUS.SUCCEDED) {
      postAnimations(timeouts);
    } else if (generationStatus === GENERATION_STATUS.GENERATING) {
      preAnimations(timeouts);
    }

    return () => {
      timeouts.forEach((t) => {
        if (t) clearTimeout(t);
      });
    };
  }, [generationStatus]);

  const preAnimations = async (timeouts) => {
    setActiveClasses('starting-to-load');

    await timeout(500, timeouts);
    setActiveClasses('loading');

    await timeout(1000, timeouts);
  };

  const postAnimations = async (timeouts) => {
    setActiveClasses('starting-success');

    await timeout(1000, timeouts);
    setActiveClasses('success');

    await timeout(1000, timeouts);
    setActiveClasses('ending-success');

    await timeout(1000, timeouts);
    setActiveClasses('ready');
  };

  const failAnimations = async (timeouts) => {
    setActiveClasses('starting-fail');

    await timeout(1000, timeouts);
    setActiveClasses('fail');

    await timeout(1000, timeouts);
    setActiveClasses('ending-fail');

    await timeout(1000, timeouts);
    setActiveClasses('ready');
  };

  const handleGenerateClicked = async () => {
    await saveEditorImage();
    if ((mode === 'v4.0' || mode === 'v3.0') && style === 'logo-type') {
      if (!style_config.text?.value) {
        dispatch(
          updateConfigTutorial({
            style,
            title: 'Choose a main word first.',
            body: 'Before you can generate a Logotype, you need to pick a main word to base your logo on, try to keep it at 1 or 2 words max.',
            key: new Date(),
          })
        );
        return;
      }
    } else if ((mode === 'v4.0' || mode === 'v3.0') && style === '3d-text') {
      if (!style_config.text?.value) {
        dispatch(
          updateConfigTutorial({
            style,
            title: 'Choose a main word first.',
            body: 'Before you can generate a 3D text, you need to pick a main word to base your logo on, try to keep it at 1 or 2 words max.',
            key: new Date(),
          })
        );
        return;
      }
    } else if ((mode === 'v4.0' || mode === 'v3.0') && style === 'monogram') {
      if (!style_config.letter?.value) {
        dispatch(
          updateConfigTutorial({
            style,
            title: 'Choose a main letter first.',
            body: 'Before you can generate a monogram logo, you need to pick a main letter to base your logo on.',
            key: new Date(),
          })
        );
        return;
      }
    } else if (!prompt) {
      dispatch(updateShowPromptTutorial(true));
      return;
    }
    if (
      isGenerating ||
      [GENERATION_STATUS.GENERATING, GENERATION_STATUS.IN_QUEUE].indexOf(
        generationStatus
      ) > -1
    )
      return;
    if (user.remainingCredits + (user.individualCredits || 0) < cost) {
      dispatch(updateShowOutOfCreditModal(true));
      dispatch(updateOutOfCreditText());
      return;
    }

    if (localStorage.getItem('hasShowStylesTutorial') !== 'true') {
      dispatch(updateShowStyleSelector(true));
      dispatch(updateShowStyleSelectorTutorial(true));
      localStorage.setItem('hasShowStylesTutorial', 'true');
      return;
    }

    if (String(seed).length < 5 || String(seed).length > 22)
      return toast('Seed must be between 5 & 20 characters long.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });

    if (method === 'ddd' && !inputImage) {
      return toast(
        'Add an image or a sketch in redesign section (Top left) to use 3D',
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000,
        }
      );
    }

    if (!/^[0-9]+$/g.test(String(seed))) {
      return toast('Seed must contain only numbers.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }

		if (mode >= "v5.0" && lastEnhancement && lastEnhancement.prompt && !lastEnhancement.prompt_status) {
			dispatch(acceptEnhancedPrompt());

		}

    const serviceBusy = setTimeout(() => {
      toast(
        'As we’re experiencing a surge in demand, your patience is greatly appreciated.',
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000,
        }
      );
    }, 70000);

    if (generationRef?.current && current === 'text-workspace') {
      generationRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (setMode) setMode('PREVIEW');

    try {
      // preAnimations();

      const resp = await dispatch(generateImages()).unwrap();

      if (resp?.isAsync) {
        /*
				toast("Your request has been added to the queue, it will be processed shortly!", {
					position: toast.POSITION.BOTTOM_RIGHT,
					theme: 'dark',
					autoClose: 2000
				});
				*/

        // postAnimations();
        if (serviceBusy) clearTimeout(serviceBusy);
        return;
      }

      try {
        const notif = new Notification('Your generations are ready!');
      } catch (e) {
        console.log('Failed to create notification');
      }

      // postAnimations();
    } catch (e) {
      // failAnimations();
      if (e.error?.detail?.errorBehaviour?.showAppsumo1024Dialog) {
        dispatch(setShowAppsumo1024LimitDialog(true));
      } else if (e.error?.detail?.errorBehaviour?.showUpgradeDialog) {
        dispatch(
          updateShowOutOfCreditModal({
            open: true,
            title: <>{e.error?.detail?.message}</>,
          })
        );
      } else if (e.error?.detail?.errorBehaviour?.showFreeUserBlockDialog) {
        dispatch(
          updateShowOutOfCreditModal({
            open: true,
            title: (
              <>
                Due to high demand, we’ve paused image
                <br />
                generation for free plans for now.
              </>
            ),
            subtitle: (
              <>
                Switch to a paid plan for uninterrupted
                <br />
                access during peak times.
              </>
            ),
            image: 'highDemand',
          })
        );
      } else {
        console.log(e);
        toast(
          e?.error?.detail?.message ||
            e?.error?.detail ||
            'Generation Failed. No credits were used.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
            autoClose: 2000,
          }
        );
      }
    }
    if (serviceBusy) clearTimeout(serviceBusy);
    // props.handleGenerate();
  };

  return (
    <div
      className={`sparkle-button ${
        isMobileButton
          ? 'w-[69px]  h-[27px]'
          : 'w-[120px] h-auto basis-[120px] shrink-0 self-stretch'
      }   flex aspect-auto`}
    >
			<Tooltip title={"Start Image Generation"}>
				<button
					id='generate-button'
					className={`bg-button-purple ${
						isMobileButton ? '!rounded-[4px]' : ''
					} ${defaultClasses} ${activeClasses} ${
						isAnimating ? 'animation-running' : ''
					}`}
					onClick={handleGenerateClicked}
				>
					<span
						className={`absolute top-0 right-0 bottom-0 left-0 gap-1 flex ${mode === 'v5.0' ?'flex-row' : 'flex-col'} items-center justify-center`}
						id='button-ready'
					>
						<span className='flex gap-2 items-center justify-center self-stretch'>
							{/* <SparkleIcon className='max-md:[inline-size:1.75em]' /> */}
							{mode === 'v5.0' ? <Lightning className='max-md:[inline-size:3.75em] [&_path]:fill-white' /> : <SparkleNewIcon className='max-md:[inline-size:1.75em]' />}
							<span className={`${isMobileButton ? 'hidden' : 'inline'} `}>
								Generate
							</span>
						</span>
						<span
							className={`font-normal text-generation-cost text-xs`}
						>
							{mode === 'v5.0' && cost }
							{mode === 'v4.0' && !isMobileButton && `${cost} credits`}
							{mode <= 'v3.0'  && `${cost} credits`}
						</span>
					</span>

					<span
						className='absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center'
						id='button-loading'
					>
						<LoadingDots className='h-2' />
					</span>

					<span
						className='absolute top-0 right-0 bottom-0 left-0 gap-2 flex items-center justify-center'
						id='button-success'
					>
						<CheckmarkIcon className='w-5 h-5 fadeInUp transition-all duration-500' />
						<span className='hidden md:inline-block'>Done</span>
					</span>

					<span
						className='absolute top-0 right-0 bottom-0 left-0 gap-2 flex items-center justify-center'
						id='button-fail'
					>
						<CloseIcon className='w-5 h-5 fadeInUp transition-all duration-500' />
						<span className='hidden md:inline'>Failed</span>
					</span>
				</button>
			</Tooltip>
    </div>
  );
}

export default GenerateButton;
