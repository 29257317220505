import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STYLE_SETTINGS } from "../style-config/constants";
import { setStyleConfig, updateInputImage, updateStyleImage } from "../../../reducers/formReducer";
import { twMerge } from "tailwind-merge";
import { usePopper } from "react-popper";
import SelectConfig from "./SelectOption";
import LetterConfig from "./Letter";
import MainText from "./MainText";
import { V5_ORDERING } from "../style-config/v5-ordering";
import useGenerationEndpoint from "../useGenerationEndpoint";
import { setBackgroundImage } from "../../../reducers/imageEditorReducer";
import { ReactComponent as CloseIcon } from '../../../assets/CloseIcon.svg';

const ConfigTypes = {
	text: MainText,
	letter: LetterConfig,
	select: SelectConfig,
}

const StyleSettings = () => {
  const style = useSelector((state) => state.form.payload.style);
  const method = useSelector((state) => state.form.method);
  const { mode } = useGenerationEndpoint();

	const lastEnhancement = useSelector((state) => state.form.lastEnhancement)

  const config = useMemo(
    () => STYLE_SETTINGS[method]?.[style] || [],
    [style, method]
  );

	const style_config = useSelector((state) => state.form.payload.styleConfig);

	const styleOrdering = useMemo(() => {
		if (mode !== 'v5.0') return [];
		return V5_ORDERING[style] ?? []
	}, [style, mode])

	const showPromptParams = useMemo(() => {
		return !!lastEnhancement.prompt
	}, [lastEnhancement])


  const dispatch = useDispatch();

  const handleConfigChange = (config_id, value) => {
    dispatch(
      setStyleConfig({
        config_id,
        value,
      })
    );
  };

  const inputImage = useSelector((state) => state.form.payload.inputImage);
  const handleRemoveInputImage = () => {
    dispatch(updateInputImage(null));
    dispatch(setBackgroundImage(null));
  };

  const handleRemoveStyleImage = () => {
    dispatch(updateStyleImage());
  };

  const styleImage = useSelector((state) => state.form.payload.styleImage);
  const styleImageUrl = useSelector(
    (state) => state.form.payload.styleImageUrl
  );

	return (
		<div className="flex gap-2 ">
			{inputImage && (
				<button className='relative flex h-[29px] w-auto bg-overlay-bg rounded-[4px] gap-[6px] items-center justify-between shrink-0'>
					<button
						onClick={handleRemoveInputImage}
						className='absolute bg-[#101018] top-0 left-0 w-full h-[29px] rounded-[3px] flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity'
					>
						<CloseIcon className='w-[18px] h-[18px]' />
					</button>
					<div className='bg-[#42414A] rounded-l-[4px]'>
						<img
							src={inputImage}
							className='w-[29px] h-[29px] rounded-l-[4px]'
							alt='Input Img'
						/>
					</div>
					<p className='font-roboto text-style-text text-[12px] pr-2'>
						Input Image
					</p>
				</button>
			)}
			{(styleImage || styleImageUrl) && (
				<button className='relative flex h-[29px] w-auto bg-overlay-bg rounded-[4px] gap-[6px] items-center justify-between shrink-0'>
					<button
							onClick={handleRemoveStyleImage}
							className='absolute bg-[#101018] top-0 left-0 w-full h-[29px] rounded-[3px] flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity'
						>
							<CloseIcon className='w-[18px] h-[18px]' />
						</button>
					<div className='bg-[#42414A] rounded-l-[4px]'>
						<img
							src={styleImage || styleImageUrl}
							className='w-[29px] h-[29px] rounded-l-[4px]'
							alt='Input Img'
						/>
					</div>
					<p className='font-roboto text-style-text text-[12px] pr-2'>
						Style Image
					</p>
				</button>
			)}
			{config
				.filter((config) => config.section_id !== 'colors')
				.map((config_item) => {
					return (
						<React.Fragment key={config_item.section_id}>
							{config_item.elements.map((el) => {

								const Config = ConfigTypes[el.type]
								if (!Config) return <></>
								if (showPromptParams && style_config[el.id]?.value && !styleOrdering.find(item => item.id === el.id)?.pinned)
									return <></>
								// if (lastEnhancement.prompt && style_config[el.id]?.enabled) return <></>
								return (
									<Config
										config_metadata={{
											label: config_item.name,
											...el,
										}}
										value={style_config[el.id]}
										onChange={(val) => handleConfigChange(el.id, val)}
										key={el.id}
										label={config_item.name}
									/>
								)
							})}
						</React.Fragment>
					)
				})}
		</div>
	)
}

export default StyleSettings;
