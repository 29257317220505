import React, { useContext, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import ProtectedRoute from '../../components/ProtectedRoute';
import AppLayout from '../../layouts/AppLayout';

import PromptInput from '../../components/input/PromptInput';
import Generations from '../../components/generation/Generations';
import ImageViewer from '../../components/ImageViewer';

import { ReactComponent as EditCanvasIcon } from '../../assets/EditorIcon.svg';
import { ReactComponent as UploadImageIcon } from '../../assets/UploadImageIcon.svg';
import { ReactComponent as ArrowBack } from '../../assets/arrow-back.svg';
import OutOfCredits from '../../components/modals/OutOfCredits';
import GraphicEditor from '../../components/editor/GraphicEditor';
import GenerationHistory from '../../components/generation/GenerationHistory';
import { useWindowDimensions } from '../../hooks/useDimenssion';
import { breakpoints } from '../../constant/device';
import { EditorProvider } from '../../context';
import AdvancedSettings from '../../components/redesign/AndvancedSettings';
import Redesign from '../../components/redesign/Redesign';
import ImageUpload from '../../components/ImageUpload';
import {
  DEFAULT_COLORS,
  setBackgroundImage,
  setIsEditImage,
  setPresetColor,
} from '../../reducers/imageEditorReducer';
import { emptyJPEG } from '../../components/editor/image_generator';
import { updateInputImage } from '../../reducers/formReducer';
import QueueStatus, {
  useQueueVisibility,
} from '../../components/queue/QueueStatus';
import useGenerationEndpoint from '../../components/input/useGenerationEndpoint';
import V4GenerationHistory from '../../components/generation/V4GenerationHistory';
import GenerationsV4 from '../../components/generation/GenerationsV4';
import RedesignV4 from '../../components/redesign/RedesignV4';
import { ContainerContext, SquareContainerContextWrapper } from './V4ContainerContext';
import V4ImageSettings from '../redesign/v4/ImageSettings';
import V5ImageSettings from '../redesign/v5/ImageSettings';
import V4GraphicEditor from '../editor/V4GraphicsEditor';

const ImageSettingsWrapper = (props) => {
	const { width, height, containerSize } = useContext(ContainerContext)
	const containerStyle = width && height ? {
		width: `${width}px`,
	} : {};

	const { mode } = useGenerationEndpoint();

	const ImageSettings = useMemo(() => ({
		'v5.0': V5ImageSettings,
		'v4.0': V4ImageSettings,
	}[mode]), [mode])

	return (
		<div style={{ maxHeight: `${containerSize?.height * .20 - 40}px`, ...containerStyle }} className="overflow-y-auto custom-scroll min-w-[350px]">
			<ImageSettings wideLayout />
		</div>
	)
}

const ImageUploadWrapper = (props) => {
	const {uploadRef, handleInputImage, openEmptyCanvas } = props;
	const { width, height } = useContext(ContainerContext)
	const containerStyle = width && height ? {
		width: `${width}px`,
		height: `${height}px`,
	} : {};

	return (
		<div style={containerStyle}>
			<ImageUpload
				handleImageSelected={handleInputImage}
				renderInput={(props) => (
					<>
						<input
							type='file'
							className='hidden'
							ref={uploadRef}
							onChange={(e) =>
								props.handleImageUpload(e.target.files)
							}
						/>
						<div className='flex flex-col gap-6 aspect-square h-full'>
							<button
								type='button'
								className='basis-1/2 grow shrink border border-app-search-gray bg-app-bg-gray rounded-2xl flex flex-col gap-2 items-center justify-center'
								onClick={() => uploadRef.current.click()}
							>
								<UploadImageIcon className='w-14 h-14' />
								<span className='text-editor-label text-center font-roboto text-xs'>
									<span className='text-button-blue'>
										Click here{' '}
									</span>
									to upload your
									<br />
									image JPG/PNG up to 5MB
								</span>
							</button>
							<button
								type='button'
								className='basis-1/2 grow shrink border border-app-search-gray bg-app-bg-gray rounded-2xl flex flex-col gap-2 items-center justify-center'
								onClick={() => openEmptyCanvas()}
							>
								<EditCanvasIcon className='w-14 h-14' />
								<span className='text-editor-label text-center font-roboto text-xs'>
									<span className='text-button-blue'>
										Click here{' '}
									</span>
									to open the editor
									<br />
									and start sketching ideas
								</span>
							</button>
						</div>
					</>
				)}
			/>
		</div>
	)
}


const V4ImagePipeline = (props) => {
  const [mode, setMode] = useState('EDIT'); // EDIT/PREVIEW
  const windowDimens = useWindowDimensions();
  const isMobile = useMemo(
    () => windowDimens.width < breakpoints.LAPTOP,
    [windowDimens.width]
  );
  const generationRef = useRef();

  const { show: showQueue } = useQueueVisibility();

  const isGenerating = useSelector((state) => state.form.isLoading);

  const inputImage = useSelector((state) => state.form.payload.inputImage);

  const uploadRef = useRef();

  const dispatch = useDispatch();

  const switchToEditMode = () => {
    if (isGenerating) return;
    setMode('EDIT');
  };

  const openEmptyCanvas = () => {
    dispatch(setPresetColor(DEFAULT_COLORS));
    dispatch(setBackgroundImage(emptyJPEG));
    dispatch(setIsEditImage(false));
    dispatch(updateInputImage(emptyJPEG));
  };

  const handleInputImage = (value) => {
    dispatch(updateInputImage(value));
    dispatch(setBackgroundImage(value));
    dispatch(setIsEditImage(true));
  };

  const { mode: version } = useGenerationEndpoint();

	const containerRef = useRef(null)

  return (
		<SquareContainerContextWrapper containerRef={containerRef} heightLeftover={120}>
			<div
				className={` grid 
					grid-cols-[300px_minmax(0,1fr)_minmax(0,1fr)] 2xl:grid-cols-[300px_minmax(0,1fr)_minmax(0,1fr)_100px] 
					${version >= 'v5.0' ? 
							'grid-rows-[minmax(calc(100%-308px),1fr)_160px_100px] 2xl:grid-rows-[minmax(calc(100%-170px),1fr)_160px] ' : 
							'grid-rows-[minmax(calc(100%-248px),1fr)_90px_100px] 2xl:grid-rows-[minmax(calc(100%-100px),1fr)_90px] '
					}
					relative flex-wrap lg:flex-nowrap gap-4 custom-scroll pl-4 pr-4 pt-6 2xl:pr-0 2xl:py-6 md:max-h-[calc(100%-65px)] grow`}
			>
				{/*
				<div
					className={` fixed -translate-x-1/2 z-50 ${
						showQueue ? 'max-h-none p-4 ' : 'max-h-0 '
					}`}
					style={{
						top: generationRef.current?.getBoundingClientRect().top,
						left:
							(generationRef.current?.getBoundingClientRect().left +
								generationRef.current?.getBoundingClientRect().right) /
							2,
						width: generationRef.current?.getBoundingClientRect().width,
					}}
				>
				</div>
					*/}
				<div className='pr-2 overflow-auto custom-scroll txt2img-custom-scroll row-span-2'>
					<RedesignV4 />
				</div>
				{mode !== 'EDIT' && isMobile ? null : (
					<div
						className={` flex flex-col justify-start items-end gap-3`}
					>
						{inputImage ? (
							<>
								<V4GraphicEditor showToolbar={true} />
								<ImageSettingsWrapper />
							</>
						) : (
							<ImageUploadWrapper uploadRef={uploadRef} handleInputImage={handleInputImage} openEmptyCanvas={openEmptyCanvas} />
						)}

						
					</div>
				)}
				{mode !== 'PREVIEW' &&  isMobile ? null : (
					<div
						className={`row-start-1 col-start-3`}
					>
						<GenerationsV4 ref={generationRef} />
					</div>
				) }
				<div 
					className="-z-10 row-start-1 col-start-3"
					ref={containerRef}
				/>
				{mode !== 'PREVIEW' && isMobile ? null : (
					<div className={`flex justify-center lg:hidden`}>
						<button
							onClick={switchToEditMode}
							className='flex flex-row items-center text-icon-text-color gap-1 text-lg font-bold bg-app-bg-gray px-3 py-2 rounded-xl'
						>
							<ArrowBack />
							Back to editing
						</button>
					</div>
				)}

				<div className={`hidden md:block max-h-full h-full overflow-auto row-start-3 col-start-2 col-span-2 2xl:row-start-1 2xl:col-start-4 2xl:col-span-1`}>
					<V4GenerationHistory />
				</div>
				<div className="col-start-2 col-span-2 row-start-2">
					<PromptInput setMode={setMode} fixedPosition={false} />
				</div>
			</div>
		</SquareContainerContextWrapper>
	);
};

export default V4ImagePipeline;
