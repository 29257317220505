import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyle from "../../../hooks/useStyle";
import { randomize_config_item } from '../style-config/constants';
import { ReactComponent as RedoIcon } from '../../../assets/Prompt/redo.svg';
import { ReactComponent as RejectIcon } from '../../../assets/Prompt/reject.svg';
import { ReactComponent as FreezeIcon } from '../../../assets/FreezeIcon.svg';
import { ReactComponent as ChevronDown } from '../../../assets/ChevronDownLightGray.svg';
import { ReactComponent as AcceptIcon } from '../../../assets/Prompt/accept.svg';

import { ReactComponent as CheckmarkIcon } from '../../../assets/green_checkmark.svg';
import useGenerationEndpoint from "../useGenerationEndpoint";
import { Listbox, Transition } from '@headlessui/react';
import { twMerge } from "tailwind-merge";
import { usePopper } from "react-popper";

import { createPortal } from "react-dom";
import { Tooltip } from "react-tippy";

export const SelectOptionPortal = (props) => {
  const { 
		config_metadata, value, onChange, last, section,
		setPopoverEl, setArrowEl, popper,
		open,
	} = props;

	const { method, mode } = useGenerationEndpoint();
  const style = useStyle();

  const isDisabled = useMemo(
    () => (value === undefined ? false : !value.enabled),
    [value]
  );
  const isEnhanced = useMemo(
    () => (value === undefined ? true : value.__enhance),
    [value]
  );
  const config_value = useMemo(
    () => value?.value || (config_metadata?.values || [])[0]?.id,
    [value, config_metadata]
  );
  const colors = useMemo(
    () => (value === undefined ? [] : value.colors),
    [value]
  );

  const configOptions = useMemo(() => {
    return config_metadata.values?.map((e) => {
      return { ...e, value: e.id };
    });
  }, [config_metadata]);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const handleSelect = (v) => {
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: true,
      value: v,
      colors,
    });
  };

  const handleDisable = (v) => {
    if (!isDevModeEnabled && config_metadata.always_enabled) return;
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: v,
      colors,
      value: config_value,
    });
  };

  const handleEnhance = (v) => {
		console.log(method, style, config_metadata.id, value)
    onChange({
      ...value,
      // ...randomize_config_item(method, style, config_metadata.id, value),
      enabled: true,
      __randomized: false,
      __enhance: v,
    });
  };

	return createPortal(
			<Transition
				show={open}
				as={React.Fragment}
				leave='transition ease-in duration-100 '
				leaveFrom='opacity-100'
				leaveTo='opacity-0'
			>
				<Listbox.Options
					ref={setPopoverEl}
					style={popper.styles.popper}
					{...popper.attributes.popper}
					className={`my-2 overflow-hidden rounded shadow-lg pt-2 z-50 ${
							'bg-v5-list-bg '
					} text-gray-label font-poppins shadow-lg focus:outline-none text-sm md:text-xs z-50 min-w-[200px] `}
				>
					{configOptions.map((item) => (
						<Listbox.Option
							key={item.value}
							value={item.value}
							className={`h-[40px] flex items-center relative select-none cursor-pointer 
								ui-active:bg-v5-list-hover ui-active:text-white 
								py-1 pr-4 pl-7  ui-selected:text-white ui-disabled:text-app-search-gray`}
							disabled={item.disabled}
						>
							{({ selected }) => (
								<>
									<span className='truncate'>
										{selected
											? item.selectedName ?? item.name
											: item?.styledName ?? item.name}
									</span>
									{selected && (
										<span className='absolute inset-y-0 left-0 pl-2 flex items-center'>
											<AcceptIcon className='w-3 h-3 [&_path]:stroke-app-green' />
										</span>
									)}
								</>
							)}
						</Listbox.Option>
					))}
					<div className="flex flex-col gap-2 bg-[#101018] py-2">
						<div className="flex items-center justify-center gap-6 text-v5-prompt-handle-label">
							{config_metadata?.label}
						</div>
						<div className="flex items-center justify-center gap-6 ">
							<Tooltip title={"Freeze this parameter"}>
								<button
									role='checkbox'
									aria-checked={!isEnhanced && !isDisabled}
									type='button'
									onClick={() => handleEnhance(false)}
									className='checked:[&_path]:fill-app-green group/btn'
								>
									<FreezeIcon
										className={`w-4 h-3 ${
											!isEnhanced && !isDisabled
												? '[&_path]:fill-app-green group-hover/btn:[&_path]:fill-app-green/70'
												: 'group-hover/btn:[&_path]:fill-app-green/60 '
										}`}
									/>
								</button>
							</Tooltip>
							<Tooltip title={"Change this parameter on new generations"}>
								<button
									role='checkbox'
									aria-checked={isEnhanced}
									type='button'
									onClick={() => handleEnhance(true)}
									className='checked:[&_path]:fill-app-green group/btn'
								>
									<RedoIcon
										className={`group-hover/btn:rotate-180 transition-all duration-300 ${
											isEnhanced
												? '[&_path]:stroke-app-green group-hover/btn:[&_path]:stroke-app-green/70'
												: 'group-hover/btn:[&_path]:stroke-app-green/60'
										}`}
									/>
								</button>
							</Tooltip>
							{(!config_metadata.always_enabled) && (
								<Tooltip title={"Ignore this parameter"}>
									<button
										role='checkbox'
										aria-checked={isDisabled}
										type='button'
										onClick={() => handleDisable(isDisabled)}
										className='[&_path]:checked:fill-app-green group/btn'
									>
										<RejectIcon
											className={` ${
												isDisabled
													? '[&_path]:stroke-app-green group-hover/btn:[&_path]:stroke-app-green/70'
													: '[&_path]:stroke-[#828287] group-hover/btn:[&_path]:stroke-app-green/60'
											}`}
										/>
									</button>
								</Tooltip>
							)}
						</div>
					</div>
				</Listbox.Options>
			</Transition>,
			document.getElementById('popup-root'))
}

const SelectConfig = (props) => {
  const { config_metadata, value, onChange, last, section } = props;

  const config_value = useMemo(
    () => value?.value || (config_metadata?.values || [])[0]?.id,
    [value, config_metadata]
  );
  const colors = useMemo(
    () => (value === undefined ? [] : value.colors),
    [value]
  );

  const configOptions = useMemo(() => {
    return config_metadata.values?.map((e) => {
      return { ...e, value: e.id };
    });
  }, [config_metadata]);


  const handleSelect = (v) => {
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: true,
      value: v,
      colors,
    });
  };

	const [popoverEl, setPopoverEl] = useState();
  const [arrowEl, setArrowEl] = useState();
  const [anchorEl, setAnchorEl] = useState();
  let popper = usePopper(anchorEl, popoverEl, {
		placement: 'top',
		strategy: 'fixed',
		
    modifiers: [
      { 
        name: 'arrow',
        options: { 
          element: arrowEl,
          padding: {}
        },
      },
    ],
  });

	const selectedItem = useMemo(() => configOptions.find((item) => item.value === config_value), [config_value, configOptions])

	return (
		<Listbox value={config_value} onChange={handleSelect}>
      {({ open }) => (
        <>

					<Listbox.Button
						className={twMerge(`${open ? 
								'bg-overlay-bg/80 ' : 'bg-overlay-bg '
						} text-icon-text-color font-roboto text-xs gap-1 items-center rounded h-[29px] px-2 inline-flex transition-all duration-300 group/inline`)}
						ref={setAnchorEl}
					>
						<span className="whitespace-nowrap max-w-[15ch] truncate">{config_metadata?.label}</span>

						<ChevronDown className={`transition-all duration-300 [&>path]:stroke-icon-text-color ${open ? 'rotate-180 ' : ' '}`} />
					</Listbox.Button>
					<SelectOptionPortal 
						{...props}
						popoverEl={popoverEl}
						setPopoverEl={setPopoverEl}
						arrowEl={arrowEl}
						setArrowEl={setArrowEl}
						popper={popper}
						open={open}
						
					/>
        </>
      )}
		</Listbox>
	)
}

export default SelectConfig;

