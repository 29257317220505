import { useSelector } from "react-redux";
import useGenerationEndpoint from "../components/input/useGenerationEndpoint";
import { useMemo } from "react";

const useStyle = ({ mode: _input_mode } = {}) => {
  const v3Style = useSelector((state) => state.form.payload.contentStyle);
  const v4Style = useSelector((state) => state.form.payload.style);

	const { method, mode: _mode } = useGenerationEndpoint()

	const mode = useMemo(
		() => _input_mode !== undefined ? _input_mode : _mode
	, [_mode, _input_mode])


  const style = useMemo(() => {
		if (mode === 'v4.0' || mode === 'v5.0') return v4Style
		return v3Style
	}, [v3Style, v4Style, mode]);

	return style
}

export default useStyle;
