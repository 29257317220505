import LetterConfig from "./Letter";
import SelectConfig from "./Select";
import TextConfig from "./Text";


const ConfigTypes = {
	text: TextConfig,
	letter: LetterConfig,
	select: SelectConfig,
}

const PromptHandler = (props) => {
	const Item = ConfigTypes[props.config_metadata.type]
	if (!Item) return <></>
	return <Item {...props} />
}

export default PromptHandler;
