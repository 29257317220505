
import ConfigLetterSelector from './LetterSelector';
import ConfigTextInput from './TextInput';

import { ReactComponent as BadgeShieldIcon } from '../../../assets/styles/v3/badge-shapes/shield.svg';
import { ReactComponent as BadgeOvalIcon } from '../../../assets/styles/v3/badge-shapes/oval.svg';
import { ReactComponent as BadgeCircular } from '../../../assets/styles/v3/badge-shapes/circular.svg';
import { ReactComponent as BadgeTriangular } from '../../../assets/styles/v3/badge-shapes/triangular.svg';
import { ReactComponent as BadgeHexagonal } from '../../../assets/styles/v3/badge-shapes/hexagonal.svg';
import { ReactComponent as BadgeRectangular } from '../../../assets/styles/v3/badge-shapes/rectangular.svg';
import { ReactComponent as BadgeDiamond } from '../../../assets/styles/v3/badge-shapes/diamond.svg';
import { ReactComponent as BadgeSquare } from '../../../assets/styles/v3/badge-shapes/square.svg';

export const V5_SETTINGS = {
  pictorial: [
    {
      section_id: 'character_composition',
      name: 'Character Composition',
      elements: [
        {
          id: 'character_composition',
					type: 'select',
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'detail',
      name: 'Detail Level',
			elements: [
				{
					id: 'detail',
					type: 'select',
					values: [
						{
							id: 'low',
							name: 'Minimalist',
						},
						{
							id: 'high',
							name: 'Detailed',
						},
					],
				},
			],
    },
    {
      section_id: 'structure',
      name: 'Structure',
      elements: [
        {
          id: 'structure',
					type: 'select',
          values: [
            {
              id: 'geometric',
              name: 'Geometric',
            },
            {
              id: 'organic',
              name: 'Organic',
            },
          ],
        },
      ],
    },
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },

    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
					type: 'select',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  mascot: [
		{
			section_id: 'detail',
			name: 'Details',
			elements: [{ 
				id: 'detail',
				type: 'select',
				default_enabled: true,
				values: [
					{
						id: 'low',
						name: 'Minimalist',
					},
					{
						id: 'high',
						name: 'Detailed',
					},
				],
			}],
		},
		{
      section_id: 'character_composition',
      name: 'Character Composition',
      elements: [
        {
          id: 'character_composition',
					type: 'select',
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    // {
    //   section_id: 'composition',
    //   name: 'Composition',
    //   elements: [
    //     {
    //       id: 'composition',
				// 	type: 'select',
    //       values: [
    //         {
    //           id: 'symmetrical',
    //           name: 'Symmetrical',
    //         },
    //         {
    //           id: 'side-view',
    //           name: 'Side view',
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'retro-mascot': [
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
					type: 'select',
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'engraved': [
		{
			section_id: 'detail',
			name: 'Detail',
			elements: [
				{
					id: "detail",
					type: 'select',
					values: [
						{ id: 'low', name: "Simplified, minimalist", },
						{ id: 'high', name: "Illustrative, Detailed", },
					]
				}
			]
		},
		{
			section_id: 'style',
			name: 'Style',
			elements: [
				{
					id: "style",
					type: 'select',
					values: [
						{ id: 'vintage', name: "vintage" },
						{ id: "ornate", name: "Ornate" },
						{ id: "intricate", name: "Intricate" },
						{ id: "simplified", name: "Simplified" },
					]
				}
			]
		},
		{
			section_id: 'engraving_technique',
			name: 'Engraving Technique',
			elements: [
				{
					id: "engraving_technique",
					type: 'select',
					values: [
						{ id: "line-engraving ", name: "Line engraving" },
						{ id: "crosshatching", name: "Crosshatching" },
						{ id: "stippling", name: "Stippling" },
						{ id: "etching", name: "Etching" },
					]
				}
			]
		},
		{
			section_id: 'line_quality',
			name: 'Line Quality',
			elements: [
				{
					id: "line_quality",
					type: 'select',
					values: [
						{ id: "fine-detailed", name: "fine detailed lines" },
						{ id: "ultra-detailed", name: "ultra detailed lines" },
						{ id: "varied", name: "varied line thickness" },
						{ id: "thick", name: "thick lines" },
						{ id: "simplified", name: "simplified lines" },
					]
				}
			]
		},
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  badge: [
    {
      section_id: 'text',
      name: 'Main Text',
      elements: [
        {
          id: 'text',
					type: 'text',
					placeholder: "Brand Name",
          default_enabled: true,
          default: '',
          component: ConfigTextInput,
        },
      ],
    },
    {
      section_id: 'tagline',
      name: 'Tagline',
      elements: [
        {
          id: 'tagline',
					type: 'text',
					placeholder: "Brand's tagline",
          default_enabled: true,
          default: '',
          component: ConfigTextInput,
        },
      ],
    },
    {
      section_id: 'special',
      name: 'Badge Type',
      elements: [
        {
          id: 'special',
          default_enabled: true,
					type: 'select',
          values: [
            {
              id: 'shield',
              name: 'Sheild',
            },
            {
              id: 'emblem',
              name: 'Emblem',
            },
            {
              id: 'crest',
              name: 'Crest',
            },
          ],
        },
      ],
    },
    {
      section_id: 'shape',
      name: 'Badge Shape',
      elements: [
        {
          id: 'shape',
          default_enabled: true,
          default: 'shield',
					type: 'select',
          values: [
            {
              id: 'shield',
              name: 'Shield',
              styledName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeShieldIcon className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Shield</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeShieldIcon className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Shield</p>
                </div>
              ),
            },
            {
              id: 'oval',
              name: 'Oval',
              styledName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeOvalIcon className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Oval</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeOvalIcon className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Oval</p>
                </div>
              ),
            },
            {
              id: 'circular',
              name: 'Circular',
              styledName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px] rounded-[5px] mr-2'>
                    <BadgeCircular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Circular</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeCircular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Circular</p>
                </div>
              ),
            },
            {
              id: 'triangular',
              name: 'Triangular',
              styledName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px]rounded-[5px] mr-2'>
                    <BadgeTriangular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Triangular</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeTriangular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Triangular</p>
                </div>
              ),
            },
            {
              id: 'hexagonal',
              name: 'Hexagonal',
              styledName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px] rounded-[5px] mr-2'>
                    <BadgeHexagonal className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Hexagonal</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeHexagonal className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Hexagonal</p>
                </div>
              ),
            },
            {
              id: 'rectangular',
              name: 'Rectangular',
              styledName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px] rounded-[5px] mr-2'>
                    <BadgeRectangular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>
                    Rectangular
                  </p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeRectangular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>
                    Rectangular
                  </p>
                </div>
              ),
            },
            {
              id: 'diamond',
              name: 'Diamond',
              styledName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px] rounded-[5px] mr-2'>
                    <BadgeDiamond className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Diamond</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeDiamond className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Diamond</p>
                </div>
              ),
            },
            {
              id: 'square',
              name: 'Square',
              styledName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px] rounded-[5px] mr-2'>
                    <BadgeSquare className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Square</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeSquare className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Square</p>
                </div>
              ),
            },
          ],
        },
      ],
    },
    {
      section_id: 'detail',
      name: 'Detail',
      elements: [
        {
          id: 'detail',
          default_enabled: true,
					type: 'select',
          values: [
            {
              id: 'low',
              name: 'Modern, Minimalist',
            },
            {
              id: 'high',
              name: 'Illustrative',
            },
          ],
        },
      ],
    },
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
					type: 'select',
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  cartoon: [
    {
      section_id: 'character_composition',
      name: 'Character Composition',
      elements: [
        {
          id: 'character_composition',
					type: 'select',
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'detail',
      name: 'Detail Level',
      elements: [
				{
					id: 'detail',
					type: 'select',
					values: [
						{
							id: 'low',
							name: 'Minimalist',
						},
						{
							id: 'high',
							name: 'Detailed',
						},
					],
				},
			],
    },
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
					type: 'select',
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  icon: [
    {
      section_id: 'detail',
      name: 'Detail Level',
      elements: [
				{
					id: 'detail',
					type: 'select',
					always_enabled: true,
					values: [
						{
							id: 'low',
							name: 'Minimalist',
						},
						{
							id: 'high',
							name: 'Detailed',
						},
					],
				},
			],
    },
    {
      section_id: 'view',
      name: 'View',
      elements: [
        {
          id: 'view',
					type: 'select',
          values: [
            {
              id: 'three-quarters',
              name: 'Three-Quarter View',
            },
            {
              id: 'front',
              name: 'Front View',
            },
            {
              id: 'high-angle',
              name: 'High angle view',
            },
          ],
        },
      ],
    },
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  abstract: [
    {
      section_id: 'detail',
      name: 'Detail Level',
      elements: [
				{
					id: 'detail',
					type: 'select',
					default_enabled: true,
					values: [
						{
							id: 'low',
							name: 'Minimalist',
						},
						{
							id: 'high',
							name: 'Detailed',
						},
					],
				},
			],
    },
		{
			section_id: 'structure',
			name: "Structure",
			elements: [
				{
					id: 'structure',
					default_enabled: true,
					type: 'select',
					values: [
						{ id: 'organic', name: 'curved line, organic', },
						{ id: 'geometric', name: 'Geometric', },
					]
				},
			],
		},
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'line-art': [
		{
			section_id: 'line-thickness',
			name: 'Line Thickness',
			elements: [
				{
					id: 'line_thickness',
					type: 'select',
					values: [
						{
							id: 'thin',
							name: 'Thin Lines',
						},
						{
							id: 'thick',
							name: 'Thick Lines',
						},
					],
				},
			],
		},
    {
      section_id: 'structure',
      name: 'Structure',
      elements: [
        {
          id: 'structure',
					type: 'select',
          values: [
            {
              id: 'geometric',
              name: 'Geometric',
            },
            {
              id: 'curved',
              name: 'Curved',
            },
          ],
        },
      ],
    },
    {
      section_id: 'character_composition',
      name: 'Character Composition',
      elements: [
        {
          id: 'character_composition',
					type: 'select',
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'detail',
      name: 'Detail Level',
      elements: [
				{
					id: 'detail',
					type: 'select',
					values: [
						{
							id: 'low',
							name: 'Minimalist',
						},
						{
							id: 'high',
							name: 'Detailed',
						},
					],
				},
			],
    },
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 1,
          colorNames: ['Primary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  '3d-text': [
    {
      section_id: 'text',
      name: 'Your Main Text',
      elements: [
        {
          id: 'text',
					type: 'text',
					placeholder: "Brand Name",
          default_enabled: true,
          default: '',
          component: ConfigTextInput,
        },
      ],
    },
    {
      section_id: 'letter_type',
      name: 'Letter type',
      elements: [
        {
          id: 'letter_type',
					type: 'select',
          values: [
            {
              id: 'cursive',
              name: 'Cursive',
            },
            {
              id: 'block',
              name: 'Block letters',
            },
          ],
        },
      ],
    },
    {
      section_id: 'detail',
      name: 'Detail Level',
      elements: [
				{
					id: 'detail',
					type: 'select',
					values: [
						{
							id: 'low',
							name: 'Minimalist',
						},
						{
							id: 'high',
							name: 'Detailed',
						},
					],
				},
			],
    },
    {
      section_id: 'style',
      name: 'Style',
      elements: [
        {
          id: 'style',
					type: 'select',
          values: [
            // {
            //   id: 'minimalist',
            //   name: 'Minimalist',
            // },
            {
              id: 'traditional',
              name: 'Traditional',
            },
            {
              id: 'brush-strokes',
              name: 'Brush Strokes',
            },
            // {
            //   id: '3d',
            //   name: '3D',
            // },
            {
              id: 'modern',
              name: 'Modern',
            },
          ],
        },
      ],
    },
    {
      section_id: 'font_style',
      name: 'Font Style',
      elements: [
        {
          id: 'font_style',
					type: 'select',
          values: [
						{ id: 'serif-font', name: 'Serif font', },
						{ id: 'sans-serif-font', name: 'Sans font', },
						{ id: 'script-font', name: 'Script font', },
						{ id: 'cursive-font', name: 'Cursive font', },
						{ id: 'block-letters', name: 'Block letters', },
						{ id: 'rounded-letters', name: 'Rounded letters', },
						{ id: 'bubble-letters', name: 'Bubble letters', },
						{ id: 'gothic-letters', name: 'Gothic letters', },
						{ id: 'geometric-letters', name: 'Geometric letters', },
          ],
        },
      ],
    },
    {
      section_id: 'font_characteristic',
      name: 'Font Characteristic',
      elements: [
        {
          id: 'font_characteristic',
					type: 'select',
          values: [
						{ id: 'bold', name: 'Bold', },
						{ id: 'italic', name: 'Italic', },
						{ id: 'condensed', name: 'Condensed', },
						{ id: 'custo', name: 'Custom', },
						{ id: 'distresse', name: 'Distressed', },
						{ id: 'block_letter', name: 'Block lette', },
						{ id: 'uppercas', name: 'Uppercase', },
						{ id: 'lowercas', name: 'Lowercase', },
						{ id: 'stylize', name: 'Stylized', },
          ],
        },
      ],
    },
    {
      section_id: 'composition',
      name: 'Composition',
      elements: [
        {
          id: 'composition',
					type: 'select',
          values: [
						{ id: 'horizontal-alignment', name: 'Horizontal alignment', },
						{ id: 'vertical-alignment', name: 'Vertical alignment', },
						{ id: 'stacked-alignment', name: 'Stacked alignment', },
						{ id: 'diagonal-alignment', name: 'Diagonal alignment', },
						{ id: 'curved-alignment', name: 'Curved alignment', },
          ],
        },
      ],
    },
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'logo-type': [
    {
      section_id: 'text',
      name: 'Your Main Text',
      elements: [
        {
          id: 'text',
					type: 'text',
					placeholder: "Brand Name",
          default_enabled: true,
          default: '',
          component: ConfigTextInput,
        },
      ],
    },
    {
      section_id: 'tagline',
      name: 'Tagline',
      elements: [
        {
          id: 'tagline',
					type: 'text',
					placeholder: "Brand's tagline",
          default_enabled: true,
          default: '',
          component: ConfigTextInput,
        },
      ],
    },
    {
      section_id: 'letter_type',
      name: 'Letter type',
      elements: [
        {
          id: 'letter_type',
					type: 'select',
          values: [
            {
              id: 'cursive',
              name: 'Cursive',
            },
            {
              id: 'block',
              name: 'Block letters',
            },
          ],
        },
      ],
    },
    {
      section_id: 'detail',
      name: 'Detail Level',
      elements: [
				{
					id: 'detail',
					type: 'select',
					values: [
						{
							id: 'low',
							name: 'Minimalist',
						},
						{
							id: 'high',
							name: 'Detailed',
						},
					],
				},
			],
    },
    {
      section_id: 'style',
      name: 'Style',
      elements: [
        {
          id: 'style',
					type: 'select',
          values: [
            // {
            //   id: 'minimalist',
            //   name: 'Minimalist',
            // },
            {
              id: 'traditional',
              name: 'Traditional',
            },
            {
              id: 'brush-strokes',
              name: 'Brush Strokes',
            },
            // {
            //   id: '3d',
            //   name: '3D',
            // },
            {
              id: 'modern',
              name: 'Modern',
            },
          ],
        },
      ],
    },
    {
      section_id: 'font_style',
      name: 'Font Style',
      elements: [
        {
          id: 'font_style',
					type: 'select',
          values: [
						{ id: 'serif-font', name: 'Serif font', },
						{ id: 'sans-serif-font', name: 'Sans font', },
						{ id: 'script-font', name: 'Script font', },
						{ id: 'cursive-font', name: 'Cursive font', },
						{ id: 'block-letters', name: 'Block letters', },
						{ id: 'rounded-letters', name: 'Rounded letters', },
						{ id: 'bubble-letters', name: 'Bubble letters', },
						{ id: 'gothic-letters', name: 'Gothic letters', },
						{ id: 'geometric-letters', name: 'Geometric letters', },
          ],
        },
      ],
    },
    {
      section_id: 'font_characteristic',
      name: 'Font Characteristic',
      elements: [
        {
          id: 'font_characteristic',
					type: 'select',
          values: [
						{ id: 'bold', name: 'Bold', },
						{ id: 'italic', name: 'Italic', },
						{ id: 'condensed', name: 'Condensed', },
						{ id: 'custo', name: 'Custom', },
						{ id: 'distresse', name: 'Distressed', },
						{ id: 'block_letter', name: 'Block lette', },
						{ id: 'uppercas', name: 'Uppercase', },
						{ id: 'lowercas', name: 'Lowercase', },
						{ id: 'stylize', name: 'Stylized', },
          ],
        },
      ],
    },
    {
      section_id: 'composition',
      name: 'Composition',
      elements: [
        {
          id: 'composition',
					type: 'select',
          values: [
						{ id: 'horizontal-alignment', name: 'Horizontal alignment', },
						{ id: 'vertical-alignment', name: 'Vertical alignment', },
						{ id: 'stacked-alignment', name: 'Stacked alignment', },
						{ id: 'diagonal-alignment', name: 'Diagonal alignment', },
						{ id: 'curved-alignment', name: 'Curved alignment', },
          ],
        },
      ],
    },
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  monogram: [
    {
      section_id: 'Letter',
      name: 'Your Main Letter',
      elements: [
        {
          id: 'letter',
          default_enabled: true,
					type: 'letter',
          default: '',
          component: ConfigLetterSelector,
        },
      ],
    },
    {
      section_id: 'capitalization',
      name: 'Capitalization',
      elements: [
        {
          id: 'capitalization',
          default_enabled: true,
					type: 'select',
          values: [
						{ id: 'upper', name: "Uppwercase" },
						{ id: 'lower', name: "Lowercase" },
          ],
        },
      ],
    },
    {
      section_id: 'detail',
      name: 'Detail Level',
      elements: [
				{
					id: 'detail',
					type: 'select',
					values: [
						{
							id: 'low',
							name: 'Minimalist',
						},
						{
							id: 'high',
							name: 'Detailed',
						},
					],
				},
			],
    },
    {
      section_id: 'style',
      name: 'Style',
      elements: [
        {
          id: 'style',
          default_enabled: true,
					type: 'select',
          values: [
						{ id: "Organic", name: "organic" },
						{ id: "geometric", name: "Geometric" },
						{ id: "stylized", name: "Stylized" },
						{ id: "3D", name: "3D" },
          ],
        },
      ],
    },
    {
      section_id: 'font_characteristic',
      name: 'Font Characteristic',
      elements: [
        {
          id: 'font_characteristic',
          default_enabled: true,
					type: 'select',
          values: [
						{ id: "bold", name: "Bold", },
						{ id: "italic", name: "Italic", },
						{ id: "extra_bold", name: "Extra Bold", },
						{ id: "medium_weight", name: "Medium weight", },
						{ id: "custom", name: "Custom", },
          ],
        },
      ],
    },
    {
      section_id: 'font_style',
      name: 'Font Style',
      elements: [
        {
          id: 'font_style',
          default_enabled: true,
					type: 'select',
          values: [
						{ id: "serif font", name: "Serif font", },
						{ id: "sans-serif font", name: "Sans-serif font", },
						{ id: "Script font", name: "script font", },
						{ id: "gothic font", name: "Gothic font", },
          ],
        },
      ],
    },
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },

    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
	// 2D
  'stylized-cartoon': [

    {
      section_id: 'character_composition',
      name: 'Character Composition',
      elements: [
        {
          id: 'character_composition',
					type: 'select',
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'detail',
      name: 'Detail Level',
      elements: [
				{
					id: 'detail',
					type: 'select',
					default_enabled: false,
					values: [
						{
							id: 'low',
							name: 'Minimalist',
						},
						{
							id: 'high',
							name: 'Detailed',
						},
					],
				},
			],
    },
    {
      section_id: 'theme1',
      name: 'Mood 01',
      elements: [
        {
          id: 'theme1',
					type: 'select',
          values: [
						{ id: 'elegant', name: 'Elegant', },
						{ id: 'clean', name: 'Clean', },
						{ id: 'professional', name: 'Professional', },
						{ id: 'nostalgic', name: 'Nostalgic', },
						{ id: 'energetic', name: 'Energetic', },
						{ id: 'friendly', name: 'Friendly', },
						{ id: 'cheerful', name: 'Cheerful', },
						{ id: 'comforting', name: 'Comforting', },
						{ id: 'youthful', name: 'Youthful', },
						{ id: 'sweet', name: 'Sweet', },
						{ id: 'lively', name: 'Lively', },
						{ id: 'rugged', name: 'Rugged', },
          ],
        },
      ],
    },
    {
      section_id: 'theme2',
      name: 'Mood 02',
      elements: [
        {
          id: 'theme2',
					type: 'select',
          values: [
						{ id: 'playful', name: 'Playful', },
						{ id: 'modern', name: 'Modern', },
						{ id: 'rustic', name: 'Rustic', },
						{ id: 'classic', name: 'Classic', },
						{ id: 'sophisticated', name: 'Sophisticated', },
						{ id: 'minimalist', name: 'Minimalist', },
						{ id: 'whimsical', name: 'Whimsical', },
						{ id: 'dynamic', name: 'Dynamic', },
						{ id: 'vintage', name: 'Vintage', },
						{ id: 'artistic', name: 'Artistic', },
						{ id: 'inviting', name: 'Inviting', },
						{ id: 'natural', name: 'Natural', },
						{ id: 'edgy', name: 'Edgy', },
						{ id: 'stylish', name: 'Stylish', },
						{ id: 'luxurious', name: 'Luxurious', },
						{ id: 'adventurous', name: 'Adventurous', },
						{ id: 'bold', name: 'Bold', },
          ],
        },
      ],
    },
    {
      section_id: 'industry_assosiation',
      name: 'Industry Assosiation',
      elements: [
        {
          id: 'industry_assosiation',
					type: 'select',
          values: [
						{ id: 'Food and Beverage Industry', name: 'Food and Beverage Industry', },
						{ id: 'Technology Industry', name: 'Technology Industry', },
						{ id: 'Creative Arts Industry', name: 'Creative Arts Industry', },
						{ id: 'Health and Wellness Industry', name: 'Health and Wellness Industry', },
						{ id: 'Retail and Commerce Industry', name: 'Retail and Commerce Industry', },
						{ id: 'Education Industry', name: 'Education Industry', },
						{ id: 'Entertainment Industry', name: 'Entertainment Industry', },
						{ id: 'Home and Living Industry', name: 'Home and Living Industry', },
						{ id: 'Automotive Industry', name: 'Automotive Industry', },
						{ id: 'Nature and Outdoors Industry', name: 'Nature and Outdoors Industry', },
						{ id: 'Fashion and Apparel Industry', name: 'Fashion and Apparel Industry', },
						{ id: 'Hospitality and Travel Industry', name: 'Hospitality and Travel Industry', },
						{ id: 'Community and Nonprofit Industry', name: 'Community and Nonprofit Industry', },
						{ id: 'Sports and Recreation Industry', name: 'Sports and Recreation Industry', },
						{ id: 'Manufacturing and Industrial Industry', name: 'Manufacturing and Industrial Industry', },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
					type: 'select',
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'pixel-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'flat-graphic': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'coloring-book': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  cyberpunk: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  sticker: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'marker-drawing': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'sketch-note': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'color-sketch-note': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'digital-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'fantasy-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'pop-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'comic-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  manga: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  kawaii: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'punk-collage': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'dark-arts': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'charcoal-sketch': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  halftone: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],

	// 3D
  'stylized-3d': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  '3d': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  inflated: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  embroidered: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  isometric: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'low-poly': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],


	// Realistic
  cinematic: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  hyperrealism: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'craft-clay': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  futuristic: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  origami: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'paper-craft': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],

	// Fine Art
  'oil-painting': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'fantasy-illustration': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  watercolor: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'art-deco': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  bauhaus: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
};
