import { Popover, RadioGroup, Transition, Switch } from '@headlessui/react';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SettingsIcon } from '../../assets/styles/style-config/settings-icon.svg';
import { ReactComponent as ImgIcon } from '../../assets/styles/style-config/img-icon.svg';
import { ReactComponent as RandomizeIcon } from '../../assets/styles/style-config/randomize.svg';
import { ReactComponent as CarrotIcon } from '../../assets/CaretDownIcon.svg';
import { ReactComponent as RefreshIcon } from '../../assets/RefreshIcon.svg';
import { ReactComponent as Lightning } from '../../assets/Lightning.svg';
import {
  resetStyleConfig,
  updateConfigTutorial,
  updateContentStyle,
  updateShowStyleSelector,
  updateShowStyleSelectorTutorial,
  updateImagesToGenerate,
  updateShowOutOfCreditModal,
} from '../../reducers/formReducer';
import { ImagesToGenerateValues } from '../../components/redesign/constants';
import STYLES, { NoneIcon, V3_GROUPS } from './content-styles';
import useGenerationEndpoint from './useGenerationEndpoint';
import StyleSettings from './style-config/StyleSettings';
import { CONFIG_PROPS, STYLE_SETTINGS } from './style-config/constants';
import { Tooltip } from '@material-ui/core';

const isSettingsDisabled = (method, style) => {
  if (STYLE_SETTINGS[method]?.[style] === undefined) return true;
  return (
    STYLE_SETTINGS[method]?.[style].filter((c) => c.section_id !== 'colors')
      .length === 0
  );
};

const CustomPopoverButton = forwardRef((props, ref) => {
  const { 'data-headlessui-state': isChecked } = props;
  return (
    <div
      className='hidden'
      // className='h-7 flex gap-1 items-center justify-between cursor-pointer focus:outline-none overflow-hidden relative bg-overlay-bg rounded-lg w-32 px-2 outline-none'
    >
      <Switch
        checked={isChecked}
        className={`${
          isChecked ? ' bg-app-green' : ' bg-app-bg-gray'
        } relative inline-flex h-5 w-10 items-center rounded-full`}
      >
        <span
          className={`${
            isChecked ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-3 w-3 transform rounded-full bg-white transition`}
        />
      </Switch>
      <p className='truncate text-icon-text-color font-roboto text-xs grow text-center'>
        Suggestions
      </p>
      <div
        {...props}
        className='absolute top-0 right-0 left-0 bottom-0 '
        ref={ref}
      ></div>
    </div>
  );
});

const V4SuggestionSelector = (props) => {
  const dispatch = useDispatch();

  // const imagesToGenerate = useSelector(
  //   (state) => state.form.payload.imagesToGenerate
  // );

  const imagesToGenerate = useSelector(
    (state) => state.form.payload.imagesToGenerate
  );

  const { method } = useGenerationEndpoint();

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [tutorialEl, setTutorialEl] = useState();
  const [tutorialArrowEl, setTutorialArrowEl] = useState();

  const [settingsAnchorEl, setSettingsAnchorEl] = useState();
  const [settingsEl, setSettingsEl] = useState();
  const [configTutorialEl, setConfigTutorialEl] = useState();
  const [configTutorialArrowEl, setConfigTutorialArrowEl] = useState();

  const handleImagesToGenerate = (value) => {
    dispatch(updateImagesToGenerate(value));
    // setImagesToGenerate(value);
  };

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'top-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [-100, 0] },
      },
    ],
  });
  let settingsPopper = usePopper(settingsAnchorEl, settingsEl, {
    placement: 'top-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [5, -12] },
      },
    ],
  });

  let tutorialPopper = usePopper(popperEl, tutorialEl, {
    placement: 'left',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: tutorialArrowEl,
          padding: -100,
        },
      },
    ],
  });

  let configTutorialPopper = usePopper(settingsEl, configTutorialEl, {
    placement: 'left-start',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: configTutorialArrowEl,
          // padding: -100,
        },
      },
    ],
  });

  const style = useSelector((state) => state.form.payload.contentStyle);

  const isOpen = useSelector((state) => state.form.showPromptSuggestion);
  useEffect(() => {
    if (isOpen) {
      anchorEl?.click();
      // dispatch(updateShowStyleSelector(false));
    }
  }, [isOpen]);

  const handleCloseTutorial = () => {
    dispatch(updateShowStyleSelectorTutorial(false));
  };

  const showTutorial = useSelector(
    (state) => state.form.showStyleSelectorTutorial
  );

  const configTutorial = useSelector((state) => state.form.configTutorial);
  const handleCloseConfigTutorial = () => {
    dispatch(updateConfigTutorial({ ...configTutorial, style: null }));
  };

  const settingsDisabled = isSettingsDisabled(method, style);

  const handleClickRefresh = () => {};

  const user = useSelector((state) => state.userSlice.user);

  return (
		<Popover as={React.Fragment}>
			{({ open, close }) => (
				<>
					<Tooltip title='Number of images to generate'>
						<Popover.Button
							className='group relative flex justify-center items-center cursor-pointer disabled:cursor-not-allowed focus:outline-none overflow-hidden rounded-[4px] lg:rounded-lg p-[4px] bg-app-bg-gray lg:bg-app-search-gray w-[27px] h-[27px]'
							ref={setSettingsAnchorEl}
						>
							<ImgIcon
								className={`w-[17px] h-[17px] md:w-[19px] md:h-[19px] relative ${
									settingsDisabled
										? '[&_.text]:fill-config-disabled'
										: 'group-hover:[&_.text]:fill-app-green [&_.text]:fill-icon-text-color'
								} [&_.bg]:fill-overlay-bg [&_.bg]:[rx:0]`}
							/>
							<p className='absolute top-[-4px] md:top-[-5px] right-[2.5px] text-[9px] md:text-[10px] font-inter'>
								{imagesToGenerate}
							</p>
						</Popover.Button>
					</Tooltip>
					<Transition
						as={React.Fragment}
						enter='transition ease-out duration-100'
						enterFrom='transform opacity-0 scale-95'
						enterTo='transform opacity-100 scale-100'
						leave='transition ease-in duration-75'
						leaveFrom='transform opacity-100 scale-100'
						leaveTo='transform opacity-0 scale-95'
						{...props}
					>
						<Popover.Panel
							className='border border-solid border-app-search-gray rounded-xl bg-app-bg-gray mb-4 flex flex-col gap-3 w-auto relative'
							ref={setSettingsEl}
							style={settingsPopper.styles.popper}
							{...settingsPopper.attributes.popper}
						>
							<div className='flex flex-col w-full h-full px-2'>
								<div className='flex flex-col-reverse items-center justify-between py-2 gap-2'>
									{Array.from(
										Array(
											user?.subscriptionProvider === 'free' ? 2 : 4
										).keys()
									).map((_, i) => {
										return (
											<button
												className='relative'
												key={i}
												onClick={() => handleImagesToGenerate(i + 1)}
											>
												<ImgIcon
													className={`w-[21px] h-[21px] relative ${
														i + 1 === imagesToGenerate
															? '[&_.text]:fill-app-green [&_path]:fill-app-green'
															: 'group-hover:[&_.text]:fill-app-green [&_.text]:fill-icon-text-color'
													} [&_.bg]:fill-overlay-bg [&_.bg]:[rx:0]`}
												/>
												<p
													className={`absolute -top-[8px] -right-[1px] text-[10px] font-inter ${
														i + 1 === imagesToGenerate ? 'text-app-green' : ''
													}`}
												>
													{i + 1}
												</p>
											</button>
										);
									})}
									{Array.from(
										Array(
											user?.subscriptionProvider === 'free' ? 2 : 0
										).keys()
									).map((_, i) => {
										return (
											<button
												className='relative'
												key={i}
												onClick={() => {
													dispatch(
														updateShowOutOfCreditModal({
															open: true,
															title: (
																<>
																	Upgrade to have more simultaneous
																	generations!
																</>
															),
															subtitle: (
																<>
																	You need an active subscription to access
																	this feature.
																</>
															),
														})
													);
												}}
											>
												<ImgIcon
													className={`w-[21px] h-[21px] relative [&_.bg]:fill-overlay-bg [&_.bg]:[rx:0] [&_.text]:fill-[#494C58] [&_path]:fill-[#494C58]`}
												/>
												<p
													className={`absolute -top-[8px] -right-[1px] text-[10px] font-inter text-[#494C58]`}
												>
													{i + 3}
												</p>
												<p
													className={`absolute -top-[0.375rem] -left-[0.375rem] flex items-center justify-center  w-3 h-3 aspect-square rounded-full font-inter bg-button-purple`}
												>
													<Lightning className='h-[0.6rem] scale-x-125 w-[0.45rem] [&>*]:fill-white ' />
												</p>
											</button>
										);
									})}
								</div>
							</div>
							{configTutorial.style === style && (
								<div
									className='flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300 mr-4 w-60 rounded-md'
									ref={setConfigTutorialEl}
									style={configTutorialPopper.styles.popper}
									{...configTutorialPopper.attributes.popper}
								>
									<div
										ref={setConfigTutorialArrowEl}
										style={configTutorialPopper.styles.arrow}
										{...configTutorialPopper.attributes.arrow}
										className='h-3 w-3 inline-block left-full '
									>
										<div className='w-full h-full bg-style-method-enabled -translate-y-10 -translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-t border-r' />
									</div>
									<div className='flex items-center justify-start gap-2'>
										<span>
											<InfoIcon />
										</span>
										<span className='text-title-white text-sm font-roboto'>
											{configTutorial.title}
										</span>
									</div>
									<div className='font-roboto text-input-color text-xs '>
										{configTutorial.body}
									</div>
									<div className='flex items-center justify-end mt-1'>
										<button
											className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3'
											onClick={handleCloseConfigTutorial}
											type='button'
										>
											Got It
										</button>
									</div>
								</div>
							)}
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
  );
};

export default V4SuggestionSelector;
