import { ReactComponent as CreativeIcon } from '../assets/questionnaire/1-creative.svg';
import { ReactComponent as BusinessIcon } from '../assets/questionnaire/1-business.svg';
import { ReactComponent as MarketerIcon } from '../assets/questionnaire/1-marketer.svg';
import { ReactComponent as SearchIcon } from '../assets/questionnaire/2-search.svg';
import { ReactComponent as SocialIcon } from '../assets/questionnaire/2-social.svg';
import { ReactComponent as InfluencerIcon } from '../assets/questionnaire/2-influencer.svg';
import { ReactComponent as MyBusinessIcon } from '../assets/questionnaire/3-business.svg';
import { ReactComponent as ClientIcon } from '../assets/questionnaire/3-client.svg';
import { ReactComponent as CuriousIcon } from '../assets/questionnaire/3-curious.svg';
import { ReactComponent as OtherIcon } from "../assets/questionnaire/other.svg";

export const QuestionnairePages = [
  {
    title: 'What Describes you best?',
    key: 'occupation',
    options: [
      {
        title: 'Creative Professional',
        subtitle: 'Logo/Graphic Designer, digital artist...',
        icon: CreativeIcon,
      },
      {
        title: 'Business',
        subtitle: 'Startup, Small Business, Entrepreneur...',
        icon: BusinessIcon,
      },
      {
        title: 'Marketer/Brand Manager',
        subtitle: 'Creative/Advertising Agency, brand... ',
        icon: MarketerIcon,
      },
      {
        title: 'Other',
        subtitle: '',
        icon: OtherIcon,
      },
    ]
  },
  {
    title: 'How did you hear about logo diffusion?',
    key: 'discovery',
    options: [
      {
        title: 'Search Engine',
        subtitle: 'Google, Bing, Duck duck go...',
        icon: CreativeIcon,
      },
      {
        title: 'Social Media',
        subtitle: 'Instagram, Youtube, Facebook...',
        icon: BusinessIcon,
      },
      {
        title: 'Influencer / Newsletter',
        subtitle: 'Influencer? newsletter/A.I tools website',
        icon: MarketerIcon,
      },
      {
        title: 'Other',
        subtitle: '',
        icon: OtherIcon,
      },

    ]
  },
  {
    title: 'Your primary reason for using this tool?',
    key: 'usageExplanation',
    options: [
      {
        title: 'Design a logo/Content for my New/Existing business',
        subtitle: '',
        icon: MyBusinessIcon,
      },
      {
        title: 'Design a logo/Content for a client if Mine',
        subtitle: '',
        icon: ClientIcon,
      },
      {
        title: 'Curious about new A.I tools',
        subtitle: 'Influencer newsletter/A.I tool website?',
        icon: CuriousIcon,
      },
      {
        title: 'Other',
        subtitle: '',
        icon: OtherIcon,
      },
    ]
  },
]
