import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyle from "../../../hooks/useStyle";
import { randomize_config_item } from '../style-config/constants';
import { ReactComponent as RedoIcon } from '../../../assets/Prompt/redo.svg';
import { ReactComponent as RejectIcon } from '../../../assets/Prompt/reject.svg';
import { ReactComponent as FreezeIcon } from '../../../assets/FreezeIcon.svg';
import { ReactComponent as ChevronDown } from '../../../assets/ChevronDownLightGray.svg';
import { ReactComponent as AcceptIcon } from '../../../assets/Prompt/accept.svg';

import useGenerationEndpoint from "../useGenerationEndpoint";
import { Listbox, Transition } from '@headlessui/react';
import { twMerge } from "tailwind-merge";
import { usePopper } from "react-popper";
import { SelectOptionPortal } from "../v5/SelectOption";
import usePromptStatus from "../usePromptStatus";
import TextAnimationWrapper from "./TextAnimationWrapper";

const SelectConfig = (props) => {
  const { config_metadata, value, onChange, last, section } = props;

	const { method, mode } = useGenerationEndpoint();

  const isDisabled = useMemo(
    () => (value === undefined ? false : !value.enabled),
    [value]
  );
  const isEnhanced = useMemo(
    () => (value === undefined ? true : value.__enhance),
    [value]
  );
  const config_value = useMemo(
    () => value?.value || (config_metadata?.values || [])[0]?.id,
    [value, config_metadata]
  );
  const colors = useMemo(
    () => (value === undefined ? [] : value.colors),
    [value]
  );

  const style = useStyle();

  const configOptions = useMemo(() => {
    return config_metadata.values?.map((e) => {
      return { ...e, value: e.id };
    });
  }, [config_metadata]);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const handleSelect = (v) => {
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: true,
      value: v,
      colors,
    });
  };

  const handleDisable = (v) => {
    if (!isDevModeEnabled && config_metadata.always_enabled) return;
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: v,
      colors,
      value: config_value,
    });
  };

  const handleRandomize = (v) => {
		console.log(method, style, config_metadata.id, value)
    onChange({
      ...value,
      ...randomize_config_item(method, style, config_metadata.id, value),
      enabled: true,
      __randomized: true,
    });
  };

  const handleEnhance = (v) => {
		console.log(method, style, config_metadata.id, value)
    onChange({
      ...value,
      // ...randomize_config_item(method, style, config_metadata.id, value),
      enabled: true,
      __randomized: false,
      __enhance: v,
    });
  };

  const anchor = useRef();

	const [popoverEl, setPopoverEl] = useState();
  const [arrowEl, setArrowEl] = useState();
  const [anchorEl, setAnchorEl] = useState();
  let popper = usePopper(anchorEl, popoverEl, {
		placement: 'top',
    modifiers: [
      { 
        name: 'arrow',
        options: { 
          element: arrowEl,
          padding: {}
        },
      },
    ],
  });

	const selectedItem = useMemo(() => configOptions.find((item) => item.value === config_value), [config_value, configOptions])

	const timerRef = useRef(null);
  const [showChevron, setShowChevron] = useState(false);

	const { isIdle, isAnimating, isLoading } = usePromptStatus()

	if (isAnimating || isLoading) {
		return (
			<TextAnimationWrapper>{selectedItem?.name ?? config_metadata.label}{!last && (section.delimiter || ', ')}</TextAnimationWrapper>
		)
	}

	return (
		<Listbox value={config_value} onChange={handleSelect} disabled={!isIdle} >
      {({ open }) => (
        <>

					<Listbox.Button
						className={twMerge(`${

							isDisabled ? (
								open ? 
								'text-disabled-section bg-disabled-section/30' :
								'text-disabled-section hover:bg-disabled-section/30' 
							) : (
								open ? 
								'bg-app-green text-tag-text' : 
								'text-app-green hover:bg-app-green bg-v5-prompt-handle/20 hover:text-tag-text'
							)
						} font-inter text-xs gap-1 items-center rounded-md py-1 px-2 mr-1 inline-flex transition-all duration-300 group/inline`)}
						ref={setAnchorEl}
					 onMouseEnter={() => {
						 if (!isIdle) {
							 return ;
						 }

						 if (timerRef.current) clearTimeout(timerRef.current)
						 timerRef.current = setTimeout(() => setShowChevron(true), 200)
					 }}
					 onMouseLeave={() => {
						 if (!isIdle) {
							 setShowChevron(false)
							 return ;
						 }

						 if (timerRef.current) clearTimeout(timerRef.current)
						 timerRef.current = setTimeout(() => setShowChevron(false), 500)
					 }}
					>
						<span>{selectedItem?.name ?? config_metadata.label}{!last && (section.delimiter)}</span>

						<ChevronDown className={`transition-all duration-300 ${showChevron ? 'w-4' : 'w-0'} h-4 ${open ? 'rotate-180 ' : ''} ${
							isDisabled ? '[&>path]:stroke-white/30' : '[&>path]:stroke-tag-text'} `} />
					</Listbox.Button>

					<SelectOptionPortal 
						{...props}
						popoverEl={popoverEl}
						setPopoverEl={setPopoverEl}
						arrowEl={arrowEl}
						setArrowEl={setArrowEl}
						popper={popper}
						open={open}
					/>
        </>
      )}
		</Listbox>
	)
}

export default SelectConfig;

