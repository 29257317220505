import React, { forwardRef, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyle from "../../../hooks/useStyle";
import { randomize_config_item } from '../style-config/constants';
import useGenerationEndpoint from "../useGenerationEndpoint";
import { twMerge } from "tailwind-merge";
import { usePopper } from "react-popper";
import { updateConfigTutorial } from "../../../reducers/formReducer";
import { ReactComponent as InfoIcon } from '../../../assets/InfoIconGray.svg';
import { Listbox, Popover, Transition } from "@headlessui/react";
import { createPortal } from "react-dom";
import usePromptStatus from "../usePromptStatus";
import TextAnimationWrapper from "./TextAnimationWrapper";

const LETTERS = [
	...[...Array(26)].map((_, ind) =>
		String.fromCharCode(ind + 'A'.charCodeAt(0))
	),
	...[...Array(10)].map((_, i) => `${i}`)
]

const Letter = (props) => {
	const { value, config_value, handleChange, open, close, popper, l } = props
	const active = useMemo(() => {
		return config_value.includes(l)
	}, [config_value, l])

	const disabled = useMemo(() => {
		return config_value.length === 2 && !active
	}, [active, config_value])

  const onChange = (v) => {

		if (config_value.length < 2) {
			handleChange({
				...value,
				__randomized: false,
				__enhance: false,
				enabled: true,
				value: config_value + v,
			});
			return ;
		}

		if (config_value === `${v}${v}`) {
			handleChange({
				...value,
				__randomized: false,
				__enhance: false,
				enabled: true,
				value: "",
			});
			return ;
		}
		if (config_value.includes(v)) {
			handleChange({
				...value,
				__randomized: false,
				__enhance: false,
				enabled: true,
				value: `${v}${v}`,
			});
			return ;
		}
  };
	return (
		<button
			value={l}
			key={l}
			disabled={disabled}
			as='div'
			className={` 
				enabled:hover:scale-110 
				enabled:active:scale-90 
				transition-all duration-300
				${
				active ? '!bg-app-green !text-white' : `
				bg-[#363640] text-[#9C9CAA]
				disabled:bg-[#363640]/70 disabled:cursor-not-allowed
				`
			} aspect-square w-10 h-10 rounded-md font-bold text-lg text-center cursor-pointer relative`}
			onClick={() => {
				onChange(l);
			}}
		>
			
			{l}
			{active && (
				<div className="absolute flex right-0 top-0 -translate-y-1/3 translate-x-1 gap-[2px]">
					{config_value[0] === l && (
						<div className="w-[14px] h-[14px] text-xs [line-height:14px] text-switch-label-green bg-white rounded-full ">1</div>)}		
					{config_value[1] === l && (
						<div className="w-[14px] h-[14px] text-xs [line-height:14px] text-switch-label-green bg-white rounded-full ">2</div>)}		
				</div>
			)}
		</button>
	)
}

export const LetterPanel = forwardRef((props, ref) => {
	const { value, config_value, handleChange, open, close, popper } = props

	const { mode } = useGenerationEndpoint();


	return (
		<Transition
			show={open}
			as={React.Fragment}
			enter='transition duration-100 ease-out'
			enterFrom='transform scale-95 opacity-0'
			enterTo='transform scale-100 opacity-100'
			leave='transition duration-75 ease-out'
			leaveFrom='transform scale-100 opacity-100'
			leaveTo='transform scale-95 opacity-0'
		>
			<Popover.Panel
				ref={ref}
				{...popper.attributes.popper}
				style={popper.styles.popper}
				as="div"
				static
				className={`${
					mode >= 'v4.0' ? 'bg-[#474752]' : 'bg-zoom-border'
				} p-2 rounded-lg mt-2 gap-2 grid grid-cols-6 `}
			>
					{LETTERS.map((l) => (
						<Letter key={l} {...props} l={l} />
					))}
			</Popover.Panel>
		</Transition>
	)
})

const LetterSelector = (props) => {
	const { index, config_value, handleChange, value } = props

  const [modeSelectorAnchorEl, setModeSelectorAnchorEl] = useState();
  const [modeSelectorEl, setModeSelectorEl] = useState();
  let modeSelectorPopper = usePopper(modeSelectorAnchorEl, modeSelectorEl, {
    strategy: 'fixed',
    placement: 'bottom-start',
  });

	const { isIdle, isAnimating } = usePromptStatus()

	if (isAnimating) 
		return (
			<TextAnimationWrapper>{config_value || '?'}{' '}</TextAnimationWrapper>
		)

	return (
		<Popover disabled={!isIdle} as={React.Fragment} >
			{({ open, close }) => (
				<>
					<Popover.Button
						className={twMerge(`${
							open ? 
							'bg-app-green text-tag-text' : 
							'text-app-green hover:bg-app-green bg-v5-prompt-handle/20 hover:text-tag-text'
						} font-inter text-xs gap-1 items-center rounded-md py-1 px-2 mr-1 inline-flex transition-all duration-300 group/inline `)}
						ref={setModeSelectorAnchorEl}
					>
						{config_value || '?'}
					</Popover.Button>
					<LetterPanel ref={setModeSelectorEl} {...props} popper={modeSelectorPopper} />
				</>
			)}
		</Popover>
	);
}

const LetterConfig = (props) => {
  const { value, onChange, } = props;


  const config_value = useMemo(
    () => value?.value,
    [value,]
  );


	return (
		<>
			<LetterSelector handleChange={onChange} config_value={config_value} value={value} />
		</>
	)
}

export default LetterConfig;
