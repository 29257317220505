import React from 'react';
import { ReactComponent as PaidIcon } from '../../assets/Lightning.svg';

const PaidIconWrapper = ({ subscriptionProvider }) => {
    if (subscriptionProvider !== 'free') return null;

    return (
        <div
            className={`flex items-center justify-center p-[0.05rem] aspect-square rounded-full font-inter bg-button-purple absolute top-1 right-1 translate-x-1/2 -translate-y-1/2`}
        >
            <PaidIcon className='h-[0.7rem] scale-x-125 [&>*]:fill-white' />
        </div>
    );
};

export default PaidIconWrapper;