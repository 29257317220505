import React, { useRef, useState, useEffect } from 'react';

import Background from '../assets/BGRmoveBackground.svg';

const VectorizeResult = ({ beforeImage, afterImage }) => {
  const [sliderValue, setSlideValue] = useState(8);
  const minSliderVale = 4;
  const maxSliderValue = 44;
  const [scale, setScale] = useState(1);
  const minScale = 0.4;
  const maxScale = 4.4;
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef1 = useRef(null);
  const imageRef2 = useRef(null);
  const containerRef = useRef(null);

  const handleZoomIn = () => {
    const newScale = scale + 0.4;
    const newSliderValue = Number(sliderValue) + 4;
    if (newScale <= maxScale && newSliderValue <= maxSliderValue) {
      setScale(newScale);
      setSlideValue(newSliderValue);
    }
  };

  const handleZoomOut = () => {
    const newScale = scale - 0.4;
    const newSliderValue = sliderValue - 4;
    if (newScale >= minScale && newSliderValue >= minSliderVale) {
      setScale(newScale);
      setSlideValue(newSliderValue);
    }
  };

  useEffect(() => {
    const image1 = imageRef1.current;
    const image2 = imageRef2.current;
    const container = containerRef.current;
    let isDragging = false;
    let prevPosition = { x: 0, y: 0 };

    const handleMouseDown = (e) => {
      isDragging = true;
      prevPosition = { x: e.clientX, y: e.clientY };
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      const deltaX = e.clientX - prevPosition.x;
      const deltaY = e.clientY - prevPosition.y;
      prevPosition = { x: e.clientX, y: e.clientY };
      setPosition((position) => ({
        x: position.x + deltaX,
        y: position.y + deltaY,
      }));
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    image1?.addEventListener('mousedown', handleMouseDown);
    image1?.addEventListener('mousemove', handleMouseMove);
    image1?.addEventListener('mouseup', handleMouseUp);
    container?.addEventListener('mouseleave', handleMouseUp);

    image2?.addEventListener('mousedown', handleMouseDown);
    image2?.addEventListener('mousemove', handleMouseMove);
    image2?.addEventListener('mouseup', handleMouseUp);

    return () => {
      image1?.removeEventListener('mousedown', handleMouseDown);
      image1?.removeEventListener('mousemove', handleMouseMove);
      image1?.removeEventListener('mouseup', handleMouseUp);
      image2?.removeEventListener('mousedown', handleMouseDown);
      image2?.removeEventListener('mousemove', handleMouseMove);
      image2?.removeEventListener('mouseup', handleMouseUp);
    };
  }, [imageRef1, imageRef2, scale]);

  const handleZoomChange = (e) => {
    const newSliderValue = e.target.value;
    const newScale = newSliderValue / 10;
    if (newScale >= minScale && newSliderValue >= minSliderVale) {
      setScale(newScale);
      setSlideValue(newSliderValue);
    }
  };

  return (
    <div className='flex flex-col w-full h-full'>
      <div
        className='flex flex-col md:flex-row w-[100%] items-center justify-center relative rounded-[20px] overflow-hidden'
        ref={containerRef}
      >
        <div className='flex relative w-[100%] md:w-[50%] aspect-[60/60] overflow-hidden'>
          <div className='bg-gray-200 shadow-sm px-[9px] py-[3px] rounded-[6px] absolute left-[20px] top-[20px] text-gray-500 text-[14px] font-bold z-20'>
            Before
          </div>
          <img
            src={Background}
            alt='background'
            className='absolute w-full h-full object-cover select-none'
          />

          <img
            ref={imageRef1}
            src={beforeImage}
            alt='afterImage'
            className='w-[600px] aspect-[60/60] object-contain cursor-grabbing'
            draggable={false}
            style={{
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            }}
          />
        </div>
        <div className='flex relative w-[100%] md:w-[50%] aspect-[60/60] overflow-hidden'>
          <div className='hidden md:block bg-gray-200 shadow-sm px-[9px] py-[3px] rounded-[6px] absolute right-[20px] top-[20px] text-gray-500 text-[14px] font-bold z-20'>
            After
          </div>
          <div className='block md:hidden bg-gray-200 shadow-sm px-[9px] py-[3px] rounded-[6px] absolute left-[20px] top-[20px] text-gray-500 text-[14px] font-bold z-20'>
            After
          </div>
          <img
            src={Background}
            alt='background'
            className='absolute w-full h-full object-cover select-none'
          />
          <img
            ref={imageRef2}
            src={afterImage}
            alt='beforeImage'
            className='w-[600px] aspect-[60/60] object-contain cursor-grabbing'
            draggable={false}
            style={{
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            }}
          />
        </div>
        <div
          className='hidden md:block absolute top-0 bottom-0 w-[2.6px] bg-white z-0'
          style={{ left: `calc(50% - 1px)` }}
        ></div>
        <div
          className='block md:hidden absolute left-0 right-0 h-[2.6px] bg-white z-0'
          style={{ top: `calc(50% - 1px)` }}
        ></div>
      </div>
      <div className='flex items-center justify-around gap-2 w-64 mx-auto mt-4'>
        <button
          className='text-[#60607A] font-bold'
          onClick={() => handleZoomOut()}
        >
          {' '}
          &#8722;{' '}
        </button>
        <input
          type='range'
          min='4'
          max='44'
          step={4}
          value={sliderValue}
          onChange={handleZoomChange}
          className='slider-thumb w-full accent-[#3DD598] bg-white'
        />
        <button
          className='text-[#60607A] font-bold'
          onClick={() => handleZoomIn()}
        >
          {' '}
          &#43;{' '}
        </button>
      </div>
    </div>
  );
};

export default VectorizeResult;
