export const V5_ORDERING = {
	pictorial: [
		// { id: 'prefix', type: 'static', text: 'A pictorial logo of' },
		{ id: 'prompt', },
		{ id: "character_composition", pinned: true, },
		{ id: "detail", pinned: true, },
		{ id: "structure", pinned: true, },
		{ id: 'theme1', },
		{ id: 'theme2', },
		{ id: 'industry_assosiation', },
	],
	mascot: [
		// { id: 'prefix', type: 'static', text: 'A mascot logo of' },
		{ id: "prompt", },
		{ id: "detail", pinned: true, },
		{ id: "character_composition", pinned: true, },
		// { id: "composition", },
		{ id: "outline_stroke", },
		{ id: 'theme1', },
		{ id: 'theme2', },
		{ id: 'industry_assosiation', },
	],
  'retro-mascot': [
		// { id: 'prefix', type: 'static', text: 'A mascot logo of' },
		{ id: "prompt", },
		{ id: 'theme1', },
		{ id: 'theme2', },
		{ id: 'industry_assosiation', },
	],
  badge: [
		// { id: 'prefix', type: 'static', text: 'a badge logo of' },
		{ id: "prompt", },
		{ id: "text", pinned: true, },
		{ id: "tagline", pinned: true, },
		{ id: "badge_type", pinned: true, },
		{ id: "detail", delimiter: ' ', pinned: true,},
		{ id: "shape", delimiter: ' ', pinned: true,},
		{ id: 'theme1', },
		{ id: 'theme2', },
		{ id: 'industry_assosiation', },
	],
	cartoon: [
		// { id: 'prefix', type: 'static', text: 'A cartoon logo of' },
		{ id: "prompt", },
		{ id: "character_composition", pinned: true, },
		{ id: "style", },
		{ id: "detail", pinned: true, },
		{ id: "outline_stroke", },
	],
	'stylized-cartoon': [
		// { id: 'prefix', type: 'static', text: 'A cartoon logo of' },
		{ id: "prompt", },
		{ id: "character_composition", pinned: true, },
		{ id: "style", },
		{ id: "detail", pinned: true, },
		{ id: "outline_stroke", },
	],
	icon: [
		// { id: 'prefix', type: 'static', text: 'cartoon logo of' },
		{ id: "prompt", },
		{ id: "detail", pinned: true, },
		{ id: "view", pinned: true, },
		{ id: "theme1", },
		{ id: "theme2", },
		{ id: "industry_assosiation", },
	],
	abstract: [
		// { id: 'prefix', type: 'static', text: 'An abstract logo of' },
		{ id: "prompt", },
		{ id: 'detail', pinned: true, },
		{ id: 'structure', pinned: true, },
		{ id: 'theme1', },
		{ id: 'theme2', },
		{ id: 'industry_assosiation', },
	],
  'line-art': [
		// { id: 'prefix', type: 'static', text: 'A line-art logo of' },
		{ id: "prompt", },
		{ id: "character_composition", },
		{ id: "detail", },
		{ id: "line_thickness", pinned: true, },
		{ id: "structure", pinned: true, },
		{ id: "theme1", },
		{ id: "theme2", },
		{ id: "industry_assosiation", },
	],
  monogram: [
		// { id: 'prefix', type: 'static', text: 'A monogram logo of the letter' },
		{ id: "letter", pinned: true, },
		{ id: "prompt", },
		{ id: "capitalization", },
		{ id: "font_characteristic", pinned: true, },
		{ id: "font_style", pinned: true, },
		{ id: "style", },
		{ id: "detail", },
		{ id: "theme1", },
		{ id: "theme2", },
		{ id: "industry_assosiation", },
	],
	engraved: [
		// { id: 'prefix', type: 'static', text: 'An engraved logo of' },
		{ id: "prompt", },
		{ id: "engraving_technique", },
		{ id: 'line_quality', pinned: true, },
		{ id: "detail", },
		{ id: "palette", },
		{ id: "background", },
		{ id: "style", pinned: true, },
		{ id: "token", },
		{ id: "theme1", },
		{ id: "theme2", },
		{ id: "industry_assosiation", },
	],
	'logo-type': [
		// { id: 'prefix', type: 'static', text: 'A logotype containing the words' },
		{ id: "text", pinned: true, },
		{ id: "tagline", pinned: true, },
		{ id: "prompt", },
		{ id: "style", },
		{ id: "font_characteristic", pinned: true, },
		{ id: "font_style", pinned: true, },
		{ id: "composition", },
		{ id: "detail", },
		{ id: "theme1", },
		{ id: "theme2", },
		{ id: "industry_assosiation", },
	],
	'3d-text': [
		// { id: 'prefix', type: 'static', text: 'A logotype containing the words' },
		{ id: "text", pinned: true, },
		{ id: "prompt", },
		{ id: "style", },
		{ id: "font_characteristic", pinned: true, },
		{ id: "font_style", pinned: true, },
		{ id: "composition", },
		{ id: "detail", },
		{ id: "theme1", },
		{ id: "theme2", },
		{ id: "industry_assosiation", },
	],
}
