import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Dialog, Switch, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '../Tooltip';
import {
    convertToHtml,
    parentheseSplit,
} from '../../helpers/importanceHelpers';

import {
    GENERATION_STATUS,
    updateStyleImageUrl,
} from '../../reducers/formReducer';
import SpinnerLoader from '../base/SpinnerLoader';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import { ImageViewerContext } from './context';
import DownloadImage from '../../services/ImageDownloader';
import LoadingButton from '../base/LoadingButton';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SparkleIconV4 } from '../../assets/SparkleV4.svg';
import { ReactComponent as CopyIcon } from '../../assets/CopyIcon.svg';
import { ReactComponent as VectorizeIcon } from '../../assets/VectorizeIconLightGray.svg';
import { ReactComponent as BookmarkIcon } from '../../assets/BookmarkIconGray.svg';
import { ReactComponent as BookmarkIconGreen } from '../../assets/BookmarkIconGreenOutline.svg';
import { ReactComponent as DownloadIcon } from '../../assets/DownloadIcon.svg';
import { ReactComponent as ThumbsUpIcon } from '../../assets/ThumbsUpOutline.svg';
import { ReactComponent as ThumbsDownIcon } from '../../assets/ThumbsDownOutline.svg';
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg';
import { ReactComponent as ChevronDownV4 } from '../../assets/ChevronDownV4.svg';
import { ReactComponent as UpscaleIcon } from '../../assets/upscale-icon.svg';
import { ReactComponent as RemoveBackgroundIcon } from '../../assets/RemoveBG.svg';
import { ReactComponent as UseAsInputImageIcon } from '../../assets/UseInputImage.svg';
import { ReactComponent as UseAsStyleImageIcon } from '../../assets/UseStyleImage.svg';
import { ReactComponent as CleanIcon } from '../../assets/CleanV4.svg';
import { SDColors } from '../redesign/constants';
import { ReactComponent as PaidIcon } from '../../assets/Lightning.svg';
import { V5_STYLES } from '../input/v5-styles';
import ImageComparisonV4 from '../ImageComparisonV4';
import { twMerge } from 'tailwind-merge';
import PaidIconWrapper from './PaidIconWrapper';
import { V5_ORDERING } from '../input/style-config/v5-ordering';
import useStyle from '../../hooks/useStyle';
import useUnwrappedStyleSettingsConfig, { useStyleSettingsMap } from '../../hooks/useUnwrappedStyleSettingConfigs';
import PromptHandler from '../input/prompt-handlers/PromptHandler';
import Select from '../input/prompt-handlers/Select';
import MainText from '../input/v5/MainText';
import { formatDistanceToNow } from 'date-fns';
import moment from 'moment';
import V5StyleSettings from "./StyleSetingBadge";

const V5ImageViewer = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);
    const [width, setWidth] = useState("");

    const user = useSelector((state) => state.userSlice.user);

    const {
        viewingImage,
        actions: {
            handleNext,
            handlePrevious,
            onClose,

            handleOpenTool,
            copyToClipboard,
            handleBookmarkClicked,
            handleDeleteGeneration,
            handleReuse,
            handleFeedback,
            handleUseAsInputImage,
        },
        config: {
            isShowBookmark = true,
            isShowDelete = true,
            isShowLike = true,
            isShowDislike = true,
            isShowUpscale = true,
        },
    } = useContext(ImageViewerContext);

    const { mode: currentMode, method: currentMethod } = useGenerationEndpoint();
    const {
        mode,
        method: imageMethod,
        generationStyle,
    } = useGenerationEndpoint({
        method: viewingImage?.method,
    });

    const [showVectorizeSpinner, setShowVectorizeSpinner] = useState(false);
    const [showUpscalerSpinner, setShowUpscalerSpinner] = useState(false);
    const [showRemoveBgSpinner, setShowRemoveBgSpinner] = useState(false);
    const [isShowComparison, setIsShowComparison] = useState(false);

    const promptValue = useMemo(
        () =>
            convertToHtml(parentheseSplit(viewingImage?.prompt || ''), {
                editable: false,
            }),
        [viewingImage]
    );

    const formatRelativeTime = (createdAt) => {
        return moment(createdAt).fromNow();
    };

    const promptDate = useMemo(
        () => formatRelativeTime(viewingImage?.createdAt || ''),
        [viewingImage]
    );

    const promptStyleConfigs = useMemo(() => {
        const styleConfig = viewingImage?.styleConfig || {};
        return styleConfig;
    }, [viewingImage?.styleConfig]);

    const imageStyle = useMemo(() => {
        return V5_STYLES.find((style) => style.id === viewingImage?.style);
    }, [viewingImage]);
    const ImageStyleIcon = useMemo(() => {
        return imageStyle?.icon_preview ?? imageStyle?.icon ?? 'div';
    }, [imageStyle]);

    const handleCompareInputImage = () => {
        setIsShowComparison(!isShowComparison);
    };

    console.log("promptStyleConfigs =>>>>>>>>>>>>>>>>>> viewingImage =>>>>>>>>>>>>>>>>> ", viewingImage)

    useEffect(() => {
        const handleResize = () => {
          setWidth(window.innerWidth <= 1280 ? false : true);
        };
    
        handleResize();
    
        window.addEventListener("resize", handleResize);
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      });

    if (!viewingImage) {
        return (
            <Transition.Child
                as={React.Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
            >
                <Dialog.Panel
                    className={`w-auto h-auto p-[23px] bg-[#13131A] rounded-[5px] lg:flex-row gap-[29px] overflow-x-hidden overflow-y-auto relative custom-scroll ${width ? 'flex flex-col' : ''}`}
                    onClick={(e) => e.stopPropagation()}
                ></Dialog.Panel>
            </Transition.Child>
        );
    }

    return (
        <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
        >
            <Dialog.Panel
                className={`w-auto h-auto p-[23px] bg-[#13131A] rounded-[5px] lg:flex-row gap-[29px] overflow-x-hidden overflow-y-auto relative custom-scroll ${width ? 'flex flex-col' : ''}`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className='rounded-[6px] relative flex items-center justify-center aspect-square lg:w-[512px] xl:w-[600px] 2xl:w-[700px] 3xl:w-[850px] 4xl:w-[1024px]'>
                    {[GENERATION_STATUS.GENERATING, GENERATION_STATUS.IN_QUEUE].indexOf(
                        viewingImage?.status
                    ) === -1 && (
                            <div className='relative w-full h-full'>
                                {isShowComparison && viewingImage?.inputImage ? (
                                    <ImageComparisonV4
                                        className='z-30'
                                        beforeImage={viewingImage?.inputImage}
                                        afterImage={viewingImage?.imageUrl}
                                    />
                                ) : (
                                    <img
                                        src={viewingImage?.imageUrl}
                                        key={viewingImage?.imageUrl}
                                        alt={`Image: ${viewingImage?.prompt}`}
                                        className='rounded-lg z-10 max-w-full w-full'
                                    />
                                )}
                                <span className={`absolute right-4 top-4 flex flex-row z-40`}>
                                    <Tooltip title='Download'>
                                        <LoadingButton
                                            className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[6px] w-[32px] h-[32px] flex items-center justify-center cursor-pointer aspect-square group`}
                                            onClick={(e) =>
                                                DownloadImage.download('raster', viewingImage, {
                                                    element: e,
                                                    user,
                                                    imageName: viewingImage?.prompt,
                                                })
                                            }
                                        >
                                            <DownloadIcon
                                                className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px] [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
                                            />
                                        </LoadingButton>
                                    </Tooltip>
                                </span>
                                <span className={`absolute right-4 bottom-4 flex flex-row z-40`}>
                                    <Tooltip title='Delete'>
                                        <LoadingButton
                                            className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[6px] w-[32px] h-[32px] flex items-center justify-center cursor-pointer aspect-square group`}
                                            onClick={(e) => handleDeleteGeneration(viewingImage)}
                                        >
                                            <CleanIcon
                                                className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
                                            />
                                        </LoadingButton>
                                    </Tooltip>
                                </span>
                                <div className='absolute left-4 bottom-4 flex flex-row gap-[8px] z-40'>
                                    <Tooltip title='Like'>
                                        <LoadingButton
                                            className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[6px] w-[32px] h-[32px] flex items-center justify-center cursor-pointer aspect-square group`}
                                            onClick={() => handleFeedback(1)}
                                        >
                                            <ThumbsUpIcon
                                                className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  ${viewingImage?.feedback > 0
                                                        ? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/50'
                                                        : '[&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]'
                                                    }`}
                                            />
                                        </LoadingButton>
                                    </Tooltip>
                                    <Tooltip title='Dislike'>
                                        <LoadingButton
                                            className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[6px] w-[32px] h-[32px] flex items-center justify-center cursor-pointer aspect-square group`}
                                            onClick={() => handleFeedback(-1)}
                                        >
                                            <ThumbsDownIcon
                                                className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  ${viewingImage?.feedback < 0
                                                        ? '[&_path]:fill-dislike group-hover:[&_path]:fill-dislike/50'
                                                        : '[&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]'
                                                    } `}
                                            />
                                        </LoadingButton>
                                    </Tooltip>
                                    {viewingImage?.inputImage && (
                                        <LoadingButton
                                            className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] pt-2 pl-2 pb-2 pr-1 flex items-center space-x-2 rounded-[6px] justify-center cursor-pointer group
												group-hover:flex-grow group-hover:space-x-4 group-hover:justify-start aspect-auto group-hover:aspect-auto `}
                                            style={{
                                                minWidth: '32px',
                                                height: '32px',
                                                transition: 'width 0.3s',
                                            }}
                                            errorMessage='Failed to load image'
                                            onClick={() => { }}
                                        >
                                            <div className='w-auto h-auto'>
                                                <Switch
                                                    checked={isShowComparison}
                                                    onChange={handleCompareInputImage}
                                                    disabled={!viewingImage?.inputImage}
                                                    className={`relative inline-flex h-[1.125rem] w-8 items-center rounded-[0.5rem] ui-checked:bg-app-green ui-not-checked:bg-[#838387] ${isShowComparison ? 'justify-end pr-[5px]' : ''}`}
                                                >
                                                    <span className='inline-block h-3 w-3 transform rounded-full bg-white transition ui-checked:translate-x-[1.125rem] ui-not-checked:translate-x-[0.125rem] uppercase '></span>
                                                </Switch>
                                            </div>

                                            <span
                                                className={`max-w-0 overflow-hidden whitespace-nowrap transition-all duration-700 ease-in-out group-hover:max-w-[180px] text-[12px] text-[#4A4A4A]`}
                                            >
                                                Compare Input Image
                                            </span>
                                        </LoadingButton>
                                    )}
                                </div>
                                <span className={`absolute left-4 top-4 flex flex-row z-40`}>
                                    <Tooltip
                                        title={
                                            viewingImage?.bookmarked ? 'Remove bookmark' : 'Bookmark'
                                        }
                                    >
                                        <LoadingButton
                                            className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[6px] w-[32px] h-[32px] flex items-center justify-center cursor-pointer aspect-square group`}
                                            onClick={(e) => handleBookmarkClicked(viewingImage)}
                                        >
                                            {viewingImage?.bookmarked ? (
                                                <BookmarkIconGreen
                                                    className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[19px] lg:h-[19px] [&_path]:fill-app-green group-hover:[&_path]:fill-app-green/50`}
                                                />
                                            ) : (
                                                <BookmarkIcon
                                                    className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[19px] lg:h-[19px] [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
                                                />
                                            )}
                                        </LoadingButton>
                                    </Tooltip>
                                </span>
                            </div>
                        )}

                    <button
                        className={`flex justify-center items-center rounded-[7px] ${isShowComparison ? 'w-[22px]' : 'w-[32px]'
                            }  h-[32px] bg-[#FFFFFF66] shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] absolute top-1/2 left-4 -translate-y-1/2  z-40 group`}
                        onClick={() => handlePrevious()}
                    >
                        <ChevronDownV4 className='rotate-180 w-4 h-4 group-hover:[&_path]:fill-[#424248]' />
                    </button>

                    <button
                        className={`flex justify-center items-center rounded-[7px] ${isShowComparison ? 'w-[22px]' : 'w-[32px]'
                            } h-[32px] bg-[#FFFFFF66] shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] absolute top-1/2 right-4 -translate-y-1/2 z-40 group`}
                        onClick={() => handleNext()}
                    >
                        <ChevronDownV4 className='w-4 h-4 group-hover:[&_path]:fill-[#424248]' />
                    </button>

                    <SpinnerLoader
                        isLoading={
                            showUpscalerSpinner ||
                            showVectorizeSpinner ||
                            showRemoveBgSpinner ||
                            [
                                GENERATION_STATUS.GENERATING,
                                GENERATION_STATUS.IN_QUEUE,
                            ].indexOf(viewingImage?.status) > -1
                        }
                        classNames={{
                            backdrop: '!rounded-lg',
                        }}
                        icon={
                            {
                                ...(showVectorizeSpinner && {
                                    icon: <VectorizeIcon className='w-8 h-8' />,
                                }),
                                ...(showUpscalerSpinner && {
                                    icon: <UpscaleIcon className='w-8 h-8' />,
                                }),
                                ...(showRemoveBgSpinner && {
                                    icon: <RemoveBackgroundIcon className='w-8 h-8' />,
                                }),
                            }.icon
                        }
                    />
                </div>

                <div className='flex flex-row flex-wrap lg:flex-nowrap lg:flex-col lg:overflow-y-auto -mr-[10px] custom-scroll grow w-[346px] max-w-[346px] gap-4 lg:justify-start '>
                    <div className='grow basis-full lg:grow-0 lg:basis-auto'>
                        <div className='flex justify-end absolute right-[22px] top-[22px] z-30'>
                            <Tooltip title='Close'>
                                <button
                                    className='w-[28px] h-[28px] flex items-center justify-center bg-divider rounded-[3.5px]'
                                    onClick={(e) => onClose()}
                                >
                                    <CloseIcon />
                                </button>
                            </Tooltip>
                        </div>

                        <div className=' flex flex-col gap-[18px]'>
                            <p className='font-semibold text-title-white flex items-center gap-2 font-semiBold pt-[18px]' style={{fontSize: 'clamp(16px, 1.55vw, 20px)'}}>
                                Prompt:
                                <Tooltip title='Copy prompt'>
                                    <CopyIcon
                                        className='cursor-pointer [&_path]:fill-editor-label w-[19px] h-[19px]'
                                        onClick={(e) => copyToClipboard(viewingImage?.prompt)}
                                    />
                                </Tooltip>
                                <p className="text-[#606060] font-medium text-[13px]" dangerouslySetInnerHTML={{ __html: promptDate }}></p>
                            </p>

                            <p
                                className='text-editor-label text-start text-[13px]'
                                dangerouslySetInnerHTML={{ __html: promptValue }}
                            ></p>

                            {viewingImage?.negativePrompt && (
                                <p
                                    className='text-[12px] text-[#56565D] text-editor-labelfile:///C:/ProgramData/pbtmp245.html text-start'
                                >
                                    Negative Prompt: {viewingImage?.negativePrompt}
                                </p>
                            )}

                            <div className='flex flex-wrap justify-start gap-[8px]'>
                                <div className="">
                                    <V5StyleSettings viewingImage={viewingImage} promptStyleConfigs={promptStyleConfigs} />
                                </div>
                            </div>

                            {isDevModeEnabled && (
                                <>
                                    <hr className='border-divider' />
                                    <p className='text-xs text-start text-white'>
                                        <span className='text-text-secondary'>
                                            Internal Positive Prompt:{' '}
                                        </span>
                                        {viewingImage?.internals?.injectedPrompt}
                                    </p>
                                    <p className='text-xs text-start text-white'>
                                        <span className='text-text-secondary '>
                                            Internal Negative Prompt:{' '}
                                        </span>
                                        {viewingImage?.internals?.injectedNegativePrompt}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>

                    <div className='grid grid-cols-2 grid-rows-2 gap-[8px] border-t pt-[1rem] border-overlay-bg'>
                        {isShowUpscale && (
                            <button
                                className='w-[164px] h-[45px] bg-divider pl-[11.6px] rounded-[16px] text-[12px] text-title-white font-semibold relative'
                                onClick={() => handleOpenTool('upscale')}
                            >
                                <span className='flex items-center justify-start gap-[10px] font-medium text-[11px] text-[#92929D]'>
                                    <UpscaleIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                                    Upscale Image
                                </span>
                                <PaidIconWrapper subscriptionProvider={user?.subscriptionProvider} />
                            </button>
                        )}
                        <button
                            className='w-[164px] h-[45px] bg-divider pl-[11.6px] rounded-[16px] text-[12px] text-title-white font-semibold relative'
                            onClick={() => handleOpenTool('vectorizer')}
                        >
                            <span className='flex items-center justify-start gap-[10px] font-medium text-[11px] text-[#92929D]'>
                                <VectorizeIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                                Vectorize Image
                            </span>
                            <PaidIconWrapper subscriptionProvider={user?.subscriptionProvider} />
                        </button>
                        <button
                            className='w-[164px] h-[45px] bg-divider pl-[11.6px] rounded-[16px] text-[12px] text-title-white font-semibold relative'
                            onClick={() => handleOpenTool('bg-remover')}
                        >
                            <span className='flex items-center justify-start gap-[10px] font-medium text-[11px] text-[#92929D]'>
                                <RemoveBackgroundIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                                Remove BG
                            </span>
                            <PaidIconWrapper subscriptionProvider={user?.subscriptionProvider} />
                        </button>
                        <button
                            className='w-[164px] h-[45px] bg-divider pl-[11.6px] rounded-[16px] text-[12px] text-title-white font-semibold relative'
                            onClick={handleReuse}
                        >
                            <span className='flex items-center justify-start gap-[10px] font-medium text-[11px] text-[#92929D]'>
                                <SparkleIconV4 className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                                Reuse Settings
                            </span>
                        </button>
                        <button
                            className='w-[164px] h-[45px] bg-divider pl-[11.6px] rounded-[16px] text-[12px] text-title-white font-semibold'
                            onClick={handleUseAsInputImage}
                        >
                            <span className='flex items-center justify-start gap-[10px] font-medium text-[11px] text-[#92929D]'>
                                <UseAsInputImageIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                                Use as Input Image
                            </span>
                        </button>
                        <button
                            className='w-[164px] h-[45px] bg-divider pl-[11.6px] rounded-[16px] text-[12px] text-title-white font-semibold'
                            onClick={() => {
                                dispatch(updateStyleImageUrl(viewingImage?.imageUrl));
                                onClose();
                            }}
                        >
                            <span className='flex items-center justify-start gap-[10px] font-medium text-[11px] text-[#92929D]'>
                                <UseAsStyleImageIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                                Use as Style Image
                            </span>
                        </button>
                    </div>

                    <div className='flex flex-col w-full h-auto gap-[18px]'>
                        <div className='h-auto w-full gap-[12px]'>
                            <div className='grid grid-cols-10 relative items-center h-[45px] border-t border-overlay-bg'>
                                <div className='col-span-3 flex h-full justify-start items-center'>
                                    <p className='text-[#92929D] text-[13px] font-medium'>Style</p>
                                    {/* <div className='w-[20px] h-[20px] rounded-[4px] bg-color-353542 ml-[8px] flex items-center justify-center'>
                                        <ImageStyleIcon className={twMerge('w-[18px] h-[18px] overflow-hidden', imageStyle?.icon_preview_classes,)} />
                                    </div> */}
                                    {!imageStyle ? null : (
                                        <div className='w-[20px] h-[20px] rounded-[4px] bg-color-353542 ml-[8px] flex items-center justify-center'>
                                            <ImageStyleIcon className={twMerge('w-[18px] h-[18px] overflow-hidden')} />
                                        </div>
                                    )}
                                </div>
                                <div className='col-span-7 flex items-center justify-start'>
                                    <p className='text-[#92929D] text-[12px] capitalize font-medium'>
                                        {imageStyle?.name}
                                    </p>
                                </div>
                            </div>
                            {viewingImage?.styleConfig?.background?.colors[0] && (
                                <div className='grid grid-cols-10 relative items-center h-[45px] border-t border-overlay-bg'>
                                    <p className='col-span-3 text-[#92929D] text-[13px] font-medium justify-start flex'>
                                        Color
                                    </p>
                                    <div className='col-span-7 flex gap-[4px]'>
                                        {viewingImage?.styleConfig?.palette?.colors[0] && (
                                            <Tooltip title='Primary'>
                                                <div
                                                    className='w-[14px] h-[14px] rounded-[4px] border border-overlay-bg'
                                                    style={{
                                                        backgroundColor: viewingImage?.styleConfig?.palette?.colors[0]?.color ??
                                                        SDColors.find(
                                                            ({ name }) =>
                                                                name ===
                                                            viewingImage?.styleConfig?.palette?.colors[0]
                                                        )?.hex,
                                                    }}
                                                    ></div>
                                            </Tooltip>
                                        )}
                                        {viewingImage?.styleConfig?.palette?.colors[1] && (
                                            <Tooltip title='Secondary'>
                                                <div
                                                    className='w-[14px] h-[14px] rounded-[4px] border border-overlay-bg'
                                                    style={{
                                                        backgroundColor: viewingImage?.styleConfig?.palette?.colors[1]?.color ??
                                                        SDColors.find(
                                                            ({ name }) =>
                                                                name ===
                                                            viewingImage?.styleConfig?.palette?.colors[1]
                                                        )?.hex,
                                                    }}
                                                    ></div>
                                            </Tooltip>
                                        )}
                                        {viewingImage?.styleConfig?.outline_stroke?.colors[0] && (
                                            <Tooltip title='Thin Outline'>
                                                <div
                                                    className='w-[14px] h-[14px] rounded-[4px] border border-overlay-bg'
                                                    style={{
                                                        backgroundColor: viewingImage?.styleConfig?.outline_stroke
                                                            ?.colors[0]?.color ?? SDColors.find(
                                                                ({ name }) =>
                                                                    name ===
                                                                    viewingImage?.styleConfig?.outline_stroke
                                                                        ?.colors[0]
                                                            )?.hex,
                                                    }}
                                                ></div>
                                            </Tooltip>
                                        )}
                                        <Tooltip title='Background Color'>
                                            <div
                                                className='items-center rounded-[4px] h-[15px] w-[28px] border border-overlay-bg ml-[8px]'
                                                style={{
                                                    backgroundColor: viewingImage?.styleConfig?.background?.colors[0]?.color ?? SDColors.find(
                                                        ({ name }) =>
                                                            name ===
                                                            viewingImage?.styleConfig?.background?.colors[0]
                                                    )?.hex,
                                                }}
                                            ></div>
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                            <div className='grid grid-cols-10 items-center h-[45px] border-t border-overlay-bg'>
                                <div className='flex justify-start col-span-3'>
                                    <p className='text-[#92929D] text-[13px] font-medium'>Seed</p>
                                </div>
                                <div className='col-span-7 flex items-center justify-start'>
                                    <p className='text-[#92929D] text-[12px] font-medium'>
                                        {viewingImage?.seed}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-wrap h-auto w-full gap-[12px] -mt-[5px]'>
                            {viewingImage?.inputImage && (
                                <div className='flex flex-row items-center justify-start h-[45px] w-full xl:h-[60px] 4xl:h-[80px] pr-[7px] border border-overlay-bg rounded-[16px] overflow-hidden gap-[10px]'>
                                    <img
                                        src={viewingImage?.inputImage}
                                        className='w-[45px] h-[45px] xl:h-[60px] xl:w-[60px] 4xl:w-[80px] 4xl:h-[80px] round-l-[8px]'
                                        alt=''
                                    />
                                    <div className='flex gap-[3px] xl:gap-[6px] 3xl:gap-[10px] p-[12px]'>
                                        <p className='text-style-text text-[12px] font-roboto font-bold text-[#92929D]'>
                                            Input Image: 
                                        </p>
                                        <div className='flex items-center justify-start'>
                                            <p className='text-[#9393A3] text-[10px] font-roboto text-color-5F5F67'>
                                                {viewingImage?.imageStrength} Strength
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {viewingImage?.styleImage && (
                                <div className='flex flex-row items-center justify-start h-[45px] w-full xl:h-[60px] 4xl:h-[80px] pr-[7px] border border-overlay-bg rounded-[16px] overflow-hidden gap-[10px]'>
                                    <img
                                        src={viewingImage?.styleImage}
                                        className='w-[45px] h-[45px] xl:h-[60px] xl:w-[60px] 4xl:w-[80px] 4xl:h-[80px] round-l-[8px]'
                                        alt=''
                                    />
                                    <div className='flex gap-[3px] xl:gap-[6px] 3xl:gap-[10px] p-[12px]'>
                                        <p className='text-style-text text-[12px] font-roboto font-bold text-[#92929D]'>
                                            Style Image: 
                                        </p>
                                        <div className='flex items-center justify-start'>
                                            <p className='text-[#9393A3] text-[10px] font-roboto text-color-5F5F67'>
                                                {viewingImage?.styleStrength} Strength
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Dialog.Panel>
        </Transition.Child>
    );
};

export default V5ImageViewer;
