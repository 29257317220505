import { useMemo } from "react";
import useGenerationEndpoint from "../components/input/useGenerationEndpoint";
import useStyle from "./useStyle"
import { STYLE_SETTINGS } from "../components/input/style-config/constants";

const useUnwrappedStyleSettingsConfig = (usedStyle) => {
	const _style = useStyle()
	const style = useMemo(() => usedStyle ?? _style, [_style, usedStyle]);
	const { mode, method } = useGenerationEndpoint()

  const config = useMemo(
    () => STYLE_SETTINGS[method]?.[style] || [],
    [style, method]
  );

	const unwrapped = useMemo(() => {
		if (!config) return []
		return config.reduce((arr, cur) => {
			cur.elements.forEach((item) => arr.push({ label: cur.name, ...item }))
			return arr
		}, [])
	}, [config])

	return unwrapped
}

export const useStyleSettingsMap = (usedStyle) => {

	const unwrapped = useUnwrappedStyleSettingsConfig()

	const mapped = useMemo(() => {
		return unwrapped.reduce((t, x) => {
			t[x.id] = x
			return t
		}, {})
	}, [unwrapped])

	return mapped
}

export default useUnwrappedStyleSettingsConfig;
