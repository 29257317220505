import { Listbox, Transition } from '@headlessui/react';
import { useMemo, useState } from 'react';
import React from 'react';
import { usePopper } from 'react-popper';
import useGenerationEndpoint from '../useGenerationEndpoint';
import { ReactComponent as CaretDownIconV4 } from '../../../assets/caret-up-v4.svg';
import { ReactComponent as InfoIcon } from '../../../assets/InfoIconGray.svg';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateConfigTutorial } from '../../../reducers/formReducer';
import useStyle from '../../../hooks/useStyle';
import { createPortal } from 'react-dom';

const LETTERS_OLD = [...Array(26)].map((_, ind) =>
  String.fromCharCode(ind + 'A'.charCodeAt(0))
);

const LETTERS_V5 = [
	...[...Array(26)].map((_, ind) =>
		String.fromCharCode(ind + 'A'.charCodeAt(0))
	),
	...[...Array(10)].map((_, i) => `${i}`)
]

const ConfigLetterSelector = (props) => {
  const { config_metadata, value, onChange, label } = props;

	const dispatch = useDispatch();

	const style = useStyle();

  const input_value = useMemo(() => value?.value || '', [value]);

  const handleValueChange = (v) => {
    onChange({
      ...value,
      __randomized: false,
      enabled: true,
      value: v,
    });
  };

  const [modeSelectorAnchorEl, setModeSelectorAnchorEl] = useState();
  const [modeSelectorEl, setModeSelectorEl] = useState();
  let modeSelectorPopper = usePopper(modeSelectorAnchorEl, modeSelectorEl, {
    strategy: 'fixed',
    placement: 'bottom-start',
  });

  const [configTutorialEl, setConfigTutorialEl] = useState();
  const [configTutorialArrowEl, setConfigTutorialArrowEl] = useState();
  let configTutorialPopper = usePopper(modeSelectorAnchorEl, configTutorialEl, {
    placement: 'right',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: configTutorialArrowEl,
          // padding: -100,
        },
      },
    ],
  });

  const { mode } = useGenerationEndpoint();

	const LETTERS = useMemo(() => mode <= 'v4.0' ? LETTERS_OLD : LETTERS_V5, [mode])

  const configTutorial = useSelector((state) => state.form.configTutorial);
  const handleCloseConfigTutorial = () => {
    dispatch(updateConfigTutorial({ ...configTutorial, style: null }));
  };

  return (
    <div className={`flex ${mode === 'v4.0' ? 'flex-col' : 'items-center'} gap-2 z-30`}>
      <Listbox value={input_value} onChange={handleValueChange}>
        {({ open }) => (
          <>

						{label && (
							<Listbox.Label className={`text-gray-label font-poppins font-bold text-xs flex gap-1`}>
								{label}

							</Listbox.Label>
						)}
            <span ref={setModeSelectorAnchorEl}>

							{mode >= 'v4.0' ? (
								<Listbox.Button
									className={`p-1 relative font-poppins text-xs flex items-center
										border border-select-border px-2 grow w-full text-start
										!rounded-[4px] border-none bg-app-bg-gray h-[40px] !text-icon-text-color gap-2`}
								>
									<div
										className={` flex items-center justify-center text-lg font-bold ${
											'border-none bg-app-green text-[#08432B]'
										} h-6 w-6 focus-within:outline-none focus-within:border-style-text rounded-[4px]`}
									>
										{input_value || '?'}
									</div>
									{input_value ? (
										<span className='block truncate'>
											Letter{' '}
											{input_value}{' '}
											Monogram
										</span>
									) : (
										<span className='block truncate'>
											Select a letter
										</span>
									)}
									<div className="grow shrink" />
									<CaretDownIconV4 className='transition-all duration-300 -rotate-90 [&_path]:fill-chevron-icon ' />
								</Listbox.Button>
							) : (
								<Listbox.Button
									className={`${
										mode >= 'v4.0'
											? 'border-none bg-app-green text-[#08432B]'
											: 'border border-dashed border-style-config-text-border bg-transparent text-white/50'
									} h-8 w-8 focus-within:outline-none focus-within:border-style-text rounded-lg`}
								>
									{input_value || '?'}
								</Listbox.Button>
							)}
            </span>

						{configTutorial.style === style && 
							createPortal(
								<div
									className='flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300 ml-4 w-60 rounded-md'
									ref={setConfigTutorialEl}
									style={configTutorialPopper.styles.popper}
									{...configTutorialPopper.attributes.popper}
								>
									<div
										ref={setConfigTutorialArrowEl}
										style={configTutorialPopper.styles.arrow}
										{...configTutorialPopper.attributes.arrow}
										className='h-3 w-3 inline-block right-full top-1/2 '
									>
										<div className='w-full h-full bg-style-method-enabled translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-b border-l' />
									</div>
									<div className='flex items-center justify-start gap-2'>
										<span>
											<InfoIcon />
										</span>
										<span className='text-title-white text-sm font-roboto'>
											{configTutorial.title}
										</span>
									</div>
									<div className='font-roboto text-input-color text-xs '>
										{configTutorial.body}
									</div>
									<div className='flex items-center justify-end mt-1'>
										<button
											className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3'
											onClick={handleCloseConfigTutorial}
											type='button'
										>
											Got It
										</button>
									</div>
								</div>,
								document.getElementById('popup-root')
							)
						}

            <Transition
              show={open}
              as={React.Fragment}
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <div
                ref={setModeSelectorEl}
                {...modeSelectorPopper.attributes.popper}
                style={modeSelectorPopper.styles.popper}
                className={`${
                  mode >= 'v4.0' ? 'bg-[#474752]' : 'bg-zoom-border'
                } p-2 rounded-lg mt-2`}
              >
                <Listbox.Options
                  static
                  as='div'
                  className='gap-2 w-full grid grid-cols-6 '
                >
                  {LETTERS.map((l) => (
                    <Listbox.Option
                      value={l}
                      key={l}
                      as='div'
                      className={`${
                        mode >= 'v4.0'
                          ? 'bg-[#363640] text-[#9C9CAA]'
                          : 'bg-style-disabled text-black'
                      } ${
                        l === input_value ? '!bg-app-green !text-[#08432B]' : ''
                      } aspect-square w-7 h-7 rounded-md font-bold text-lg text-center cursor-pointer`}
                      onClick={() => {
                        console.log('selected value:', l, input_value);
                        handleValueChange(l);
                      }}
                    >
                      {l}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default ConfigLetterSelector;
