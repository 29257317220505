import V3StyleSelector from './V3StyleSelector';
import NegativePrompt from './NegativePrompt';
import { Popover, Switch, Transition } from '@headlessui/react';
import React, { useMemo, useState } from 'react';
import { ReactComponent as SettingsIcon } from '../../assets/styles/style-config/settings-icon.svg';
import { ReactComponent as ImprovePromptOffIcon } from '../../assets/ImprovePromptOffIcon.svg';
import { ReactComponent as ImprovePromptOnIcon } from '../../assets/ImprovePromptOnIcon.svg';
import { usePopper } from 'react-popper';
import StyleSettings from './style-config/StyleSettings';

import { ReactComponent as RandomizeIcon } from '../../assets/styles/style-config/randomize.svg';
import { STYLE_SETTINGS } from './style-config/constants';
import ColorSelector from './ColorSelector';
import useGenerationEndpoint from './useGenerationEndpoint';
import V4SuggestionSelector from './V4SuggestionSelector';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import V4ColorSelector from './V4ColorSelector';
import { useWindowDimensions } from '../../hooks';
import { breakpoints } from '../../constant/device';
import GenerateButton from './GenerateButton';
import { useDispatch, useSelector } from 'react-redux';
import { toggleEnhancePrompt } from '../../reducers/formReducer';

const V3Controls = (props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();

  const enhancePromptEnabled = useSelector((state) => state.form.payload.enhancePrompt)

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'top',
    strategy: 'fixed',
  });

  const { mode } = useGenerationEndpoint();

  const { workspace } = useCurrentWorkspace();
  const windowDimens = useWindowDimensions();
  const isMobile = useMemo(
    () => windowDimens.width < breakpoints.LAPTOP,
    [windowDimens.width]
  );

  const handleToogleImprovePrompt = () => {
    dispatch(toggleEnhancePrompt());
  };

  return (
    <>
      {isMobile && mode >= 'v4.0' ? (
        <div className='flex items-center justify-end gap-4 w-full'>
          <div
            className='flex items-center bg-app-bg-gray rounded-[4px] w-[27px] h-[27px]'
            onClick={handleToogleImprovePrompt}
          >
            {enhancePromptEnabled ? (
              <ImprovePromptOnIcon />
            ) : (
              <ImprovePromptOffIcon />
            )}
          </div>
          <div className='w-[27px] h-[27px]'>
            <V4ColorSelector />
          </div>
          <V4SuggestionSelector />
          <div className='w-[27px] h-[27px]'>
            <NegativePrompt isMobileButton={true} />
          </div>
          <GenerateButton isMobileButton={true} />
        </div>
      ) : mode === 'v5.0' ? (
        <></>
      ) : (
        <div className='flex flex-col gap-2'>
          <div className='flex flex-row gap-2'>
            {mode >= 'v4.0' && <V4ColorSelector />}
            {/* {mode >= 'v4.0' && <ColorSelector />} */}
            {mode >= 'v4.0' ? <V4SuggestionSelector /> : <V3StyleSelector />}
          </div>
          <div className='flex flex-row gap-2'>
            <NegativePrompt />

            {mode === 'v3.0' && <ColorSelector />}
          </div>
        </div>
      )}
    </>
  );
};

export default V3Controls;
