import React, {
  forwardRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  selectActiveHistoryGeneration,
  setImageAsInspiration,
  showImageModal,
} from '../../reducers/formReducer';
import { toast } from 'react-toastify';
import GeneratedImagePlaceholderV4 from '../GeneratedImagePlaceholderV4';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import Tooltip from '../Tooltip';
import { ReactComponent as UseAsInpirationIcon } from '../../assets/UseAsInspiration.svg';
import { ReactComponent as UseAsImageSketchIcon } from '../../assets/UseAsImageSketch.svg';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import DownloadImage from '../../services/ImageDownloader';

const GenerationsV4Grid = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const toGenerate = useSelector(
    (state) => state.form.payload.imagesToGenerate
  );
  const lastGenerationId = useSelector((state) => state.form.lastGenerationId);
  const generations = useSelector((state) => state.form.generations);

  const newGenerations = useMemo(() => {
    if (lastGenerationId) {
      return generations.filter((x) => x.generationId === lastGenerationId);
    } else {
      return generations.slice(0, 4);
    }
  }, [generations, lastGenerationId]);

  const showImagePlaceholders = useSelector(
    (state) => state.form.showPlaceholders
  );
  const activeHistoryGeneration = useSelector(
    (state) =>
      state.form?.activeHistoryGeneration ??
      newGenerations?.[0] ??
      state.form.generations?.[0]
  );

  const handleClickMainImage = useCallback(() => {
    if (showImagePlaceholders) {
      dispatch(showImageModal(activeHistoryGeneration.imageUrl));
    }
  }, [activeHistoryGeneration, dispatch, showImagePlaceholders]);

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div
      className={`h-full w-full grid grid-cols-2 grid-rows-2 gap-[15px] 3xl:gap-[18px] 4xl:gap-[25px] aspect-square`}
      ref={ref}
    >
      {[...Array(toGenerate)].map((img, i) => (
        <GeneratedImagePlaceholderV4
          image={newGenerations[i]}
          key={`placeholder-${i}`}
          appliedClasses={{
            container: '',
          }}
          mainGeneration={newGenerations[activeIndex]}
          isMain
          isGridView
        />
      ))}
    </div>
  );
});

export default GenerationsV4Grid;
