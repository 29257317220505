import { Disclosure } from "@headlessui/react"
import { Tooltip } from 'react-tippy';
import { ReactComponent as InfoIcon } from '../../../assets/InfoIconGray.svg';
import { ReactComponent as CaretDownIconV4 } from '../../../assets/caret-up-v4.svg';
import { useDispatch, useSelector } from "react-redux";
import useCurrentWorkspace from "../../../hooks/useCurrrentWorkspace";
import useGenerationEndpoint from "../../input/useGenerationEndpoint";
import { shortenFields } from '../../../helpers/shortenFields';
import { useMemo } from "react";
import { toast } from 'react-toastify';
import MulagoSettings from "./dev/MulagoSettings";
import { addDevModeField, partialUpdateStatePayload } from "../../../reducers/formReducer";
import DynamicSettings from "./dev/DynamicSettings";
import DevInput from "./dev/DevInput";
import FxSettings from "../v5/dev/FxSettings";


const stringify = (s) => {
  try {
    return JSON.stringify(s, null, 2);
  } catch (e) {
    return s;
  }
};

export const JSONItem = (props) => {
	const { name = "", value, path="", editable } = props;

	const dispatch = useDispatch();

	const copyText = async (text) => {
      await navigator.clipboard.writeText(text);
      toast('Item copied.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
	}

	if (typeof value !== "object") {
		return (
			<div className="pl-2 flex flex-row text-sm group items-center gap-1">
				<span className="w-4 h-4 shrink-0" />
				<span className="text-icon-text-color-v4">{name}{name && ": "}</span>
				<span className="text-app-green whitespace-nowrap">"{String(value).slice(0, 30)}{String(value).length > 30 && "..."}"</span>
				<button 
					onClick={() => copyText(String(value))}
					className="opacity-0 group-hover:opacity-100 rounded-lg px-1 hover:bg-white/20 text-xs text-icon-text-color-v4"
				>
					Copy
				</button>
				{editable && path === ".Payload.input.task.details" && (
					<button 
						onClick={() => dispatch(addDevModeField(name))}
						className="opacity-0 group-hover:opacity-100 rounded-lg px-1 hover:bg-white/20 text-xs text-icon-text-color-v4"
					>
						Edit
					</button>
				)}
			</div>
		);
	}

	return (
		<Disclosure className="text-sm">
			{({ open }) => (
				<>
					<Disclosure.Button className="pl-2 flex flex-row text-sm group items-center gap-1">
						<CaretDownIconV4 className={`${open ? 'rotate-[270deg]' : 'rotate-[90deg]'} w-4 h-4 transition-all duration-300`} />
						<span className="text-icon-text-color-v4">{name}{name && ": "}</span>
						<span className="text-app-green">{"{...}"}</span>
						<button 
							onClick={(e) => {
								e.stopPropagation();
								copyText(JSON.stringify(value));
							}}
							className="opacity-0 group-hover:opacity-100 rounded-lg px-1 hover:bg-white/20 text-xs text-icon-text-color-v4"
						>Copy</button>
					</Disclosure.Button>

					<Disclosure.Panel className="pl-6">
						{Object.entries(value).map(([key, val]) => (
							<JSONItem name={key} value={val} key={key} path={`${path}.${name}`} editable={editable} />
						))}
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}

const DevSettings = () => {
  const user = useSelector((state) => state.userSlice.user);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

	const dispatch = useDispatch();

  const { workspace } = useCurrentWorkspace();
  const { mode, method, generationStyle } = useGenerationEndpoint();

  const _runpodPayload = useSelector((state) => state.form.__runpod_payload);
  const _runpodError = useSelector((state) => state.form.__runpod_error);

  const runpodPayload = useMemo(() => {
		if (_runpodPayload && _runpodPayload !== "")
			return stringify(shortenFields(JSON.parse(_runpodPayload)));
		return ""
  }, [_runpodPayload]);
  const runpodError = useMemo(() => {
		if (_runpodError && _runpodError !== "")
			return stringify(shortenFields(_runpodError));
		return ""
  }, [_runpodError]);

	const copyText = async (text) => {
      await navigator.clipboard.writeText(text);
      toast('Item copied.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
	}


  const overridePod = useSelector(
    (state) => state.form.payload.runpodOverridePod
  );
  const handleOverridePod = (value) => {
    dispatch(partialUpdateStatePayload({ runpodOverridePod: value }));
  };

	if (!isDevModeEnabled || !(user?.isAdmin || user?.isDev)) return <></>

	return (
    <Disclosure
      className={`relative rounded-xl px-3 z-[30] bg-style-bg py-4 p-2 flex flex-col`}
      as='div'
    >
      <Disclosure.Button
        className={`outline-none text-sm text-white flex items-center justify-between font-sans-pro text-[15.13px] font-semibold`}
      >
        <span className='flex'>
          Dev Settings
          <Tooltip title='dev setting' position='right'>
            <InfoIcon className='w-[20px] h-[20px] ml-1' />
          </Tooltip>
        </span>
				<CaretDownIconV4 className='transition-all duration-300 ui-open:-rotate-90 [&_path]:fill-chevron-icon w-6 h-6 max-h' />
			</Disclosure.Button>
      <Disclosure.Panel className="flex flex-col gap-4 py-2 ">

				<div className='flex items-center text-gray-label font-poppins font-bold text-xs gap-1'>
					Runpod Payload
					<Tooltip title={"Here's the data sent from our api to runpod"}>
						<InfoIcon />
					</Tooltip>
				</div>
				<div className='flex flex-col gap-2'>
					<textarea
						className='bg-app-black rounded-2xl border border-solid border-select-border p-2 w-full outline-none text-gray-400 text-sm 4xl:text-base resize-none custom-scroll -mt-2'
						rows={6}
						value={runpodPayload}
						placeholder='Runpod payload will be displayed here'
					></textarea>
					<div className="overflow-auto w-full custom-scroll max-h-[300px] rounded-md bg-app-black">
						{_runpodPayload && _runpodPayload !== "" && <JSONItem name="Payload" value={JSON.parse(_runpodPayload)} editable />}
					</div>
					<button 
						onClick={() => copyText(_runpodPayload)}
						className="text-white hover:bg-white/30 transition-all duration-300 rounded-md py-1"
					>Copy Payload</button>
				</div>

				<div className='flex items-center text-gray-label font-poppins font-bold text-xs gap-1'>
					Runpod Error
					<Tooltip title={"Here's the data sent from our api to runpod"}>
						<InfoIcon />
					</Tooltip>
				</div>
				<div className='flex flex-col gap-2'>
					<textarea
						className='bg-app-black rounded-2xl border border-solid border-select-border p-2 w-full outline-none text-gray-400 text-sm 4xl:text-base resize-none custom-scroll -mt-2'
						rows={6}
						value={runpodError}
						placeholder='Runpod Error will be displayed here'
					></textarea>
					<div className="overflow-auto w-full custom-scroll max-h-[300px] rounded-md bg-app-black">
						{_runpodError && _runpodError !== "" && <JSONItem name="Error" value={_runpodError} />}
					</div>
					<button 
						onClick={() => copyText(_runpodError)}
						className="text-white hover:bg-white/30 transition-all duration-300 rounded-md py-1"
					>Copy Error</button>
				</div>
				<DevInput
					value={overridePod}
					onChange={handleOverridePod}
					label={'Runpod ID'}
					title='Change which pod is being used on runpod'
				/>
				<hr />
				<DynamicSettings />
				{generationStyle === 'mulago' && (
					<>
						<hr />
						<MulagoSettings />
					</>
				)}

				{mode === 'v5.0' && generationStyle === 'fx' && (
					<>
						<hr />
						<FxSettings />
					</>
				)}
			</Disclosure.Panel>
		</Disclosure>
	)
}

export default DevSettings;

