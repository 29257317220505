import React, {
  forwardRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Popover, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  selectActiveHistoryGeneration,
  setImageAsInspiration,
  showImageModal,
  updateLastGenerationId,
} from '../../reducers/formReducer';
import { toast } from 'react-toastify';
import GeneratedImagePlaceholderV4 from '../GeneratedImagePlaceholderV4';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import Tooltip from '../../components/Tooltip';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import DownloadImage from '../../services/ImageDownloader';
import {
  ContainerContext,
  FixedSizeContainerWRapper,
} from '../image-to-image/V4ContainerContext';

import { ReactComponent as ChevronDownV4 } from '../../assets/ChevronDownV4.svg';

const GenerationsV4 = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const { workspace } = useCurrentWorkspace();

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [115, 8] },
      },
    ],
  });

  const store = useStore();

  const toGenerate = useSelector(
    (state) => state.form.payload.imagesToGenerate
  );
  const lastGenerationId = useSelector((state) => state.form.lastGenerationId);
  const generations = useSelector((state) => state.form.generations);

  const [hover, setHover] = useState(false);

  const newGenerations = useMemo(() => {
    // if (!hasGenerationOccured) {
    // 	return []
    // }
    if (lastGenerationId) {
      return generations.filter((x) => x.generationId === lastGenerationId);
    } else {
      return generations.slice(0, 4);
    }
  }, [generations, lastGenerationId]);

  const showImagePlaceholders = useSelector(
    (state) => state.form.showPlaceholders
  );
	// const showImagePlaceholders = !lastGenerationId;
  const activeHistoryGeneration = useSelector(
    (state) =>
      state.form?.activeHistoryGeneration ??
      newGenerations?.[0] ??
      state.form.generations?.[0]
  );

  const { mode: currentMode, method: currentMethod } = useGenerationEndpoint();


	const activeGeneration = useMemo(() => {
		return newGenerations.find(img => img._id === activeHistoryGeneration) ||  newGenerations[0]
	}, [newGenerations, activeHistoryGeneration])

  const handleClickMainImage = useCallback(() => {
    if (!showImagePlaceholders && activeGeneration?.imageUrl) {
      // dispatch(showImageModal(activeHistoryGeneration.imageUrl));

			dispatch(showImageModal(activeGeneration.imageUrl));
    }
  }, [activeHistoryGeneration, dispatch, showImagePlaceholders, activeGeneration]);

  // const [activeIndex, setActiveIndex] = useState(0);

  const handelUseImageAsSketch = async (e) => {
    try {
      await dispatch(
        setImageAsInspiration({
          imageUrl: activeHistoryGeneration.imageUrl,
          method: currentMode >= 'v4.0' ? undefined : 'sdxl',
        })
      ).unwrap();

      props?.gotoTextWorkspace?.();
    } catch (e) {
      console.log(e);
      toast('Failed to load image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleGenerationChange = useCallback(
    (id) => {
      dispatch(selectActiveHistoryGeneration(id));

    },
    [newGenerations, dispatch, activeGeneration]
  );

  const handleUpscale = () => {};

  const handleDownload = async () => {
    // const link = document.createElement('a');
    // link.href = newGenerations[activeIndex].imageUrl;
    // link.download = `LoDi-${newGenerations[activeIndex].prompt}.svg`;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  const { height: imageHeight, containerSize } = useContext(ContainerContext);

	const currentGenerationStart = useMemo(() => {
		return generations.findIndex(g => g.generationId === lastGenerationId)
	}, [generations, lastGenerationId])

	const nextGenerationId = useMemo(() => {
		if (currentGenerationStart === -1) return generations[0]?.generationId
		if (currentGenerationStart + newGenerations.length >= generations.length) return null;
		return generations[currentGenerationStart + newGenerations.length].generationId
	}, [generations, currentGenerationStart, newGenerations])

	const prevGenerationId = useMemo(() => {
		// if (currentGenerationStart === -1) return generations[generations.length - 1]?.generationId
		if (currentGenerationStart - 1 < 0) return null
		return generations[currentGenerationStart - 1].generationId
	}, [generations, currentGenerationStart])

	const switchToNextGen = () => {
		const value = generations.find(g => g.generationId === nextGenerationId)
    dispatch(selectActiveHistoryGeneration(value._id));
    dispatch(updateLastGenerationId(value.generationId));
	}
	const switchToPrevGen = () => {
		const value = generations.find(g => g.generationId === prevGenerationId)
    dispatch(selectActiveHistoryGeneration(value._id));
    dispatch(updateLastGenerationId(value.generationId));
	}

  return (
    <div
      className={`h-full  ${
        workspace === 'image-workspace' ? 
					'grid justify-start gap-4 pb-2 ' : 
					'flex justify-end md:justify-between flex-col gap-[15px] 3xl:gap-[18px] 4xl:gap-[25px] items-center'
      } w-full `}
      ref={ref}
      style={workspace === 'image-workspace' ? {
        gridTemplateRows: `${imageHeight}px minmax(0, ${Math.min(120, containerSize.height * 0.2 - 16)}px)`,
      } : {}}
    >
      <div
        className={`flex relative ${
          workspace === 'image-workspace'
            ? 'mx-auto items-start h-auto self-start'
            : 'w-[100%] items-center h-auto md:h-[calc(100%-100px)]'
        } justify-center`}
      >
        <GeneratedImagePlaceholderV4
          isMain
          image={showImagePlaceholders ? newGenerations[0] : activeGeneration}
          key={`placeholder-main-${activeGeneration?._id}`}
          onClick={handleClickMainImage}
          appliedClasses={{
            container: '',
          }}
        />
      </div>
      <FixedSizeContainerWRapper size={80} maxHeight={workspace === 'image-workspace' && (containerSize?.height * .20 - 40)}>
        <div
          className={`flex w-auto h-auto gap-4 bg-app-bg-gray p-3 rounded-[18px] border border-app-search-gray mx-auto self-center`}
        >
          {[
						...(!showImagePlaceholders ? newGenerations : []),
						...Array(
							showImagePlaceholders ? toGenerate : Math.max(0, toGenerate - newGenerations.length)
						).map(() => (null))
					].map((img, i) => (
            <GeneratedImagePlaceholderV4
              image={img}
              key={img ? `generated-${img.generationId}-${img.imageUrl}-${img.ind}` : `placeholder-${i}`}
              onClick={() => img && i < newGenerations.length ? handleGenerationChange(img._id) : 0}
              appliedClasses={{
								container: workspace === 'image-workspace' ? '' : '!w-[72px] !h-[72px] 3xl:!w-[105px] 3xl:!h-[105px]',
              }}
              mainGeneration={activeGeneration}
            />
          ))}
					{false && (
						<div className="flex flex-col gap-2 justify-between py-2">
							<button 
								className="group shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-md p-1 disabled:bg-config-disabled disabled:cursor-not-allowed" 
								disabled={!prevGenerationId}
								onClick={switchToPrevGen}
							>
								<ChevronDownV4 className={`-rotate-90 w-4 h-4 ${!!prevGenerationId ? 'group-hover:[&_path]:fill-[#424248]' : 'group-disabled:fill-[#7E7979]'}`} />
							</button>
							<button 
								className="group shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-md p-1 disabled:bg-config-disabled disabled:cursor-not-allowed"  
								disabled={!nextGenerationId}
								onClick={switchToNextGen}
							>
								<ChevronDownV4 className={`rotate-90 w-4 h-4 ${!!nextGenerationId ? 'group-hover:[&_path]:fill-[#424248]' : 'group-disabled:fill-[#7E7979]'}`} />
							</button>
						</div>
					)}
        </div>
      </FixedSizeContainerWRapper>
    </div>
  );
});

export default GenerationsV4;
