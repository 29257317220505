import V5StyleSelector from "./V5StyleSelector"
import NegativePrompt from './NegativePrompt';
import GenerateButton from './GenerateButton';
import V4ColorSelector from './V4ColorSelector';
import V4SuggestionSelector from './V4SuggestionSelector';

import { ReactComponent as ImprovePromptOffIcon } from '../../assets/ImprovePromptOffIcon.svg';
import { ReactComponent as ImprovePromptOnIcon } from '../../assets/ImprovePromptOnIcon.svg';
import { ReactComponent as TrashIcon } from '../../assets/Prompt/trash.svg';
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg';
import { clearPrompt, toggleEnhanceEnabled, toggleEnhancePrompt, toggleUserDisabledEnhance, updateInputImage, updateMethod, updateStyleImage } from "../../reducers/formReducer";
import { setBackgroundImage } from "../../reducers/imageEditorReducer";
import { useDispatch, useSelector, useStore } from "react-redux";
import V5StyleSettings from "./v5/StyleSettings";
import { Tooltip } from "@material-ui/core";
import useGenerationEndpoint from "./useGenerationEndpoint";

const V5BottomToolBar = (props) => {

	const dispatch = useDispatch()

	const { mode } = useGenerationEndpoint();

	const store = useStore();
  const handleToogleImprovePrompt = () => {
    dispatch(toggleEnhancePrompt());
    // dispatch(toggleEnhanceEnabled());
		if (store.getState().form.payload.enhancePrompt) {
			dispatch(toggleUserDisabledEnhance(false))
		} else {
			dispatch(toggleUserDisabledEnhance(true))
		}
  };

	const handleClearPrompt = () => {
		dispatch(clearPrompt())
	}

  const styleImage = useSelector((state) => state.form.payload.styleImage);
  const styleImageUrl = useSelector(
    (state) => state.form.payload.styleImageUrl
  );

  const isImprovePrompt = useSelector(
    (state) => state.form.payload.enhancePrompt
  );

	const lastEnhancement = useSelector((state) => state.form.lastEnhancement)

	return (
		<div className='flex items-center justify-between w-full'>
			<div className='flex items-center justify-start gap-[9px] basis-[60%] overflow-x-hidden  relative  max-w-[200px] md:max-w-[250px] lg:max-w-[350px] xl:max-w-[500px] 2xl:max-w-[700px]'>
				{/* <V5StyleSelector /> */}
				<div className="custom-scroll v4-custom-scroll prompt-custom-scroll pb-1 w-full overflow-x-auto pr-10 ">

					<V5StyleSettings />
				</div>
					
				<div className="from-transparent to-app-bg-gray bg-gradient-to-r absolute w-10 top-0 bottom-0 right-0" />
			</div>
			<div className='flex items-center justify-end gap-[14px] grow '>
				<Tooltip
					title={isImprovePrompt ? "Turn Off Prompt Enhancement" : "Turn On Prompt Enhancement" }
				>
					<button
						className='flex items-center justify-center bg-overlay-bg rounded-[4px] w-[27px] h-[27px]'
						onClick={handleToogleImprovePrompt}
					>
						{isImprovePrompt ? (
							<ImprovePromptOnIcon className="w-[23px] h-[23px]" />
						) : (
							<ImprovePromptOffIcon className="w-[23px] h-[23px]" />
						)}
					</button>
				</Tooltip>
				<Tooltip
					title={"Reset Prompt"}
				>
					<button onClick={handleClearPrompt} className="flex justify-center items-center rounded-[4px] lg:rounded-lg bg-app-bg-gray lg:bg-overlay-bg h-[27px] w-[27px] gap-1 disabled:cursor-not-allowed disabled:text-config-disabled text-xs">
						<TrashIcon />
					</button>
				</Tooltip>
				{mode < 'v5.0' && (
					<div className='w-[27px] h-[27px]'>
						<NegativePrompt isMobileButton={true} />
					</div>
				)}
				<div className='w-[60px] h-[27px] flex'>
					<V4ColorSelector />
				</div>
				<V4SuggestionSelector />
				<div className="md:ml-2">
					<GenerateButton isMobileButton={true} />
				</div>
			</div>
		</div>
	)
}

export default V5BottomToolBar;
