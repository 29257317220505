import { Dialog, Transition } from '@headlessui/react';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { ReactComponent as CloseIcon } from "../../../../assets/CloseIcon.svg";
import DevInput from "../../v4/dev/DevInput";
import DevTextArea from "../../v4/dev/DevTextArea";
import Dropdown from "../../../base/Dropdown";
import { enhancePrompt, partialUpdateStatePayload, toggleEnhanceEnabled, toggleEnhancePrompt, updatePrompt, updatePromptForceUpdate, updateUserPrompt } from '../../../../reducers/formReducer';
import { JSONItem } from '../../v4/DevSettings';
import { twMerge } from 'tailwind-merge';
import Checkbox from '../../../base/Checkbox';
import SpinnerLoader from '../../../base/SpinnerLoader';
import { toast } from 'react-toastify';
import V5StyleSelector from '../../../input/V5StyleSelector';

const EnhancementModal = (props) => {

	const { open, setOpen } = props;
	const dispatch = useDispatch()

	const [isLoading, setIsLoading] = useState(false)

	const enhanceSystemPrompt = useSelector((state) => state.form.payload.enhance_system_prompt)
	const enhanceModel = useSelector((state) => state.form.payload.enhance_model)

	const handleFieldUpdate = (key) => (val) => {
		dispatch(partialUpdateStatePayload({
			[key]: val
		}))
	}

	const lastEnhancement = useSelector((state) => state.form.lastEnhancement)
	const lastEnhancementRaw = useSelector((state) => state.form.__lastEnhancementRaw)

	const prompt = useSelector((state) => state.form.payload.prompt)
	const user_prompt = useSelector((state) => state.form.payload.user_prompt)

	const enhanceEnabled = useSelector((state) => state.form.payload.enhanceEnabled)
	const enhancePromptEnabled = useSelector((state) => state.form.payload.enhancePrompt)

	const enhance = async () => {
		setIsLoading(true)
		try {
			await dispatch(enhancePrompt()).unwrap();
		} catch(e) {
			console.log(e);
			toast(
				e?.error?.detail?.message ||
					e?.error?.detail ||
					'Somewhing went wrong',
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					theme: 'dark',
					autoClose: 2000,
				}
			);
		} finally {
			setIsLoading(false)
		}
	}

	const setPrompt = async (newPrompt) => {
		dispatch(updatePrompt(newPrompt))
		dispatch(updateUserPrompt(newPrompt))
		dispatch(updatePromptForceUpdate(newPrompt))
	}

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className='relative z-40'
      >
        <Transition.Child
          as={React.Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/50' aria-hidden='true' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto custom-scroll'>
          <div className='flex min-h-full items-center justify-center p-4 text-center '>
            <Transition.Child
              as={React.Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel
                className='w-11/12 md:w-4/5 max-w-[900px] flex flex-col gap-4 overflow-hidden relative bg-app-black rounded-md px-8 py-12'
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="w-8 aspect-square rounded-full absolute top-2 right-3 bg-black bg-opacity-60 hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center"
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon className="w-6 h-6" />
                </button>

								<V5StyleSelector />

								<Dropdown 
									label="Enhance Model"
									values={[
										{ name: "Gemini Flash 1.5", value: "gemini-1.5-flash" },
										{ name: "Gemini Flash 2.0", value: "gemini-2.0-flash" },
										{ name: "Gemini 2.0 Flash Lite", value: "gemini-2.0-flash-lite" },
										{ name: "OpenAI 4o mini", value: "gpt-4o-mini" },
									]} 
									appliedClasses={{
										buttonCnt: 'max-w-[200px]'
									}}
									value={enhanceModel}
									onChange={handleFieldUpdate("enhance_model")}
									valueFallback={'gemini-2.0-flash'} />

								<div className={`flex justify-between flex-col gap-2`} >
									<label className={`font-bold font-poppins text-xs flex gap-2 text-gray-label items-center`}>
										User Prompt
									</label>
									<div className={"flex gap-2 items-center justify-end w-full"}>
										<input
											className={` p-1 text-sm bg-transparent font-poppins text-white border border-solid border-select-border rounded-md disabled:text-select-label-disabled w-full `}
											value={user_prompt}
											onChange={(e) => dispatch(updateUserPrompt(e.target.value))}
										/>
									</div>
								</div>

								<div className={`flex justify-between flex-col gap-2`} >
									<label className={`font-bold font-poppins text-xs flex gap-2 text-gray-label items-center`}>
										Prompt
									</label>
									<div className={"flex gap-2 items-center justify-end w-full"}>
										<input
											className={` p-1 text-sm bg-transparent font-poppins text-white border border-solid border-select-border rounded-md disabled:text-select-label-disabled w-full `}
											value={prompt}
											onChange={(e) => setPrompt(e.target.value)}
										/>
									</div>
								</div>
								
								<div className="flex justify-end">
									<button onClick={() => setPrompt(user_prompt)} className="px-2 py-1 text-white bg-gray-500 text-xs rounded-md">Revert to User Prompt</button>
								</div>

								<div className="flex gap-2">
									<Checkbox 
										label={"Enhance Enabled"} checked={enhanceEnabled}
										onChange={(e) => dispatch(toggleEnhanceEnabled(e.target.checked))} />
									<Checkbox 
										label={"Prompt Enhance Enabled"} checked={enhancePromptEnabled}
										onChange={(e) => dispatch(toggleEnhancePrompt(e.target.checked))} />
								</div>

								<DevTextArea
									value={enhanceSystemPrompt}
									onChange={handleFieldUpdate('enhance_system_prompt')}
									label={'Enhance System Prompt'}
									title='enhance_system_prompt'
									appliedClasses={{
										main: 'flex-wrap gap-2',
										inputCnt: 'basis-full',
										input: 'text-start p-2 bg-app-bg-gray bg-opacity-40 focus:bg-opacity-80 hover:bg-opacity-50',
									}}
									inputProps={{
										'aria-multiline': true,
										rows: 12,
									}}
								/>
								
								<div className="grid grid-cols-2 items-start gap-2">
									<div className="w-full ">
										<JSONItem name="Enhancement Results" value={lastEnhancement} path="injection" editable={false} />
									</div>


									<DevTextArea
										value={lastEnhancementRaw}
										onChange={() => {}}
										label={'Last enhancement model result'}
										title='enhance_system_prompt'
										appliedClasses={{
											main: 'flex-wrap gap-2',
											inputCnt: 'basis-full',
											input: 'text-start p-2 bg-app-bg-gray bg-opacity-40 focus:bg-opacity-80 hover:bg-opacity-50',
										}}
										inputProps={{
											'aria-multiline': true,
											rows: 5,
										}}
									/>

								</div>

								<div className="flex justify-end items-center gap-2">
									{isLoading && (
										<div className="relative w-12 h-12 self-center">
											<SpinnerLoader
												classNames={{
													backdrop: 'z-30 !rounded-[20px]',
													spinner: 'w-12 h-12',
												}}
												isLoading={isLoading}
											/>
										</div>
									)}
									<button
										className='flex justify-center py-2 px-2 items-center bg-button-purple rounded-md text-sm text-white text-center'
										onClick={enhance}
										disabled={isLoading}
									>
										Enhance
									</button>
								</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EnhancementModal;
