import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { STYLE_SETTINGS } from "../input/style-config/constants";

const ConfigTypes = {
    text: 'MainText',
    letter: 'LetterConfig',
    select: 'SelectConfig',
};

const StyleSettings = (props) => {
    const { viewingImage, promptStyleConfigs } = props;
    const currentStyle = useSelector((state) => state.form.payload.style);
    const currentMethod = useSelector((state) => state.form.method);
    const style = viewingImage ? viewingImage?.style : currentStyle;
    const method = viewingImage ? viewingImage?.method : currentMethod;

    const config = useMemo(
        () => STYLE_SETTINGS[method]?.[style] || [],
        [style, method]
    );

    const current_style_config = useSelector((state) => state.form.payload.styleConfig);
    const style_config = promptStyleConfigs ? promptStyleConfigs : current_style_config;

    return (
        <div className={'flex flex-initial flex-wrap gap-[8px]'}>
            {config
                .filter((config) => config.section_id !== 'colors')
                .map((config_item) => {
                    return (
                        <div key={config_item.id}>
                            {config_item.elements.map((el) => {
                                const Config = ConfigTypes[el.type];
                                if (!Config) return null;

                                return (
                                    <ConfigComponent
                                        type={Config}
                                        config_metadata={{
                                            label: config_item.name,
                                            ...el,
                                        }}
                                        value={style_config[el.id]}
                                        key={el.id}
                                        section={viewingImage}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    );
};

const ConfigComponent = ({ type, config_metadata, value, section }) => {
    switch (type) {
        case 'MainText':
            return <MainText config_metadata={config_metadata} value={value} />;
        case 'LetterConfig':
            return <LetterConfig value={value} />;
        case 'SelectConfig':
            return <SelectConfig config_metadata={config_metadata} value={value} />;
        default:
            return null;
    }
};

const SelectConfig = ({ config_metadata, value }) => {
    const config_value = useMemo(
        () => value?.value || (config_metadata?.values || [])[0]?.id,
        [value, config_metadata]
    );

    const configOptions = useMemo(() => {
        return config_metadata.values?.map((e) => {
            return { ...e, value: e.id };
        });
    }, [config_metadata]);

    const selectedItem = useMemo(() => configOptions.find((item) => item.value === config_value), [config_value, configOptions]);

    return (
        <div className="text-app-green bg-v5-prompt-handle/20 font-roboto text-xs gap-1 items-center rounded h-[29px] px-2 inline-flex transition-all duration-300 group/inline">
            <span className="whitespace-nowrap max-w-[15ch] truncate">{selectedItem?.name ?? config_metadata?.label}</span>
        </div>
    );
};

const MainText = ({ config_metadata, value }) => {
    const config_value = useMemo(
        () => value?.value || (config_metadata?.values || [])[0]?.id,
        [value, config_metadata]
    );
    
    console.log("config_value =>>>>>>>>>> ", config_metadata)

    return (
        <div className="text-app-green bg-v5-prompt-handle/20 font-roboto text-xs gap-1 items-center rounded h-[29px] px-2 inline-flex transition-all duration-300 group/inline">
            <span className="font-bold">
                {config_metadata.label === "Your Main Text" 
                    ? "Main Text" 
                    : config_metadata.label ?? "Main Text"}:
            </span>
            <span>{config_value}</span>
        </div>
    );
};

const LetterConfig = ({ value }) => {
    const config_value = useMemo(
        () => value?.value,
        [value]
    );

    return (
        <div className="text-app-green bg-v5-prompt-handle/20 font-roboto text-xs gap-1 items-center rounded h-[29px] px-2 inline-flex transition-all duration-300 group/inline">
            <span className="font-bold">Main Letter/s: </span>
            <div className="rounded-sm bg-app-green text-center w-4 h-4 font-bold font-roboto text-switch-label-green">{config_value[0]}</div>
            {config_value?.length > 1 && (
                <div className="rounded-sm bg-app-green text-center w-4 h-4 font-bold font-roboto text-switch-label-green">{config_value[1]}</div>
            )}
        </div>
    );
};

export default StyleSettings;