import { ReactComponent as PictorialStyle } from '../../assets/styles/pictorial.svg';
import { ReactComponent as MascotStyle } from '../../assets/styles/mascot.svg';
import { ReactComponent as BadgeStyle } from '../../assets/styles/badge.svg';
import { ReactComponent as CartoonStyle } from '../../assets/styles/cartoon.svg';
import { ReactComponent as IconStyle } from '../../assets/styles/icon.svg';
import { ReactComponent as AbstractStyle } from '../../assets/styles/abstract.svg';
import { ReactComponent as CancelIcon } from '../../assets/CancelIcon.svg';
import { ReactComponent as LetterIcon } from '../../assets/styles/letter.svg';
import { ReactComponent as Method3DIcon } from '../../assets/3d-icon-new.svg';
import ThSDXL3D from '../../assets/styles/sdxl/3d.jpg';
import ThSDXLCinematic from '../../assets/styles/sdxl/cinematic.jpg';
import ThSDXLDigitalArt from '../../assets/styles/sdxl/digital-art.jpg';
import ThSDXLFantasyArt from '../../assets/styles/sdxl/fantasy-art.jpg';
import ThSDXLNeopunk from '../../assets/styles/sdxl/neo-punk.jpg';
import ThSDXLIsometric from '../../assets/styles/sdxl/isometric.jpg';
import ThSDXLLowPoly from '../../assets/styles/sdxl/Low-poly.jpg';
import ThSDXLOrigami from '../../assets/styles/sdxl/origami.jpg';
import ThSDXLClayCraft from '../../assets/styles/sdxl/play-dough.jpg';
import ThSDXLPapercraft from '../../assets/styles/sdxl/papercraft.jpg';
import ThSDXLDarkArts from '../../assets/styles/sdxl/dark-art.jpg';
import ThSDXLFuturistic from '../../assets/styles/sdxl/futuristic.jpg';
import ThSDXLPixelArt from '../../assets/styles/sdxl/pixel-art.jpg';
import ThSDXLInflated from '../../assets/styles/sdxl/inflated.png';
import ThSDXLEmbroidered from '../../assets/styles/sdxl/embroidered.jpg';
// import ThSDXLLineArt from "../../assets/styles/sdxl/line-art.jpg";
import { ReactComponent as ThSDXLLineArt } from '../../assets/styles/sdxl/line-art.svg';
import ThSDXLComicArt from '../../assets/styles/sdxl/comic-art.jpg';
import ThSDXLLogoType from '../../assets/styles/sdxl/logotype.png';
import { ReactComponent as ThSDXLMonogram } from '../../assets/styles/sdxl/monogram.svg';
import ThSDXLFlatGraphic from '../../assets/styles/sdxl/flat-graphic.jpg';
import ThSDXLmanga from '../../assets/styles/sdxl/manga.jpg';
import ThSDXLKawaii from '../../assets/styles/sdxl/kawai.jpg';
import ThSDXLWatercolor from '../../assets/styles/sdxl/watercolor.jpg';
import ThSDXLPopArt from '../../assets/styles/sdxl/popart.jpg';

import environment from '../../helpers/envProvider';

import { V3_STYLES } from './v3-content-styles';
import { V4_STYLES } from './v4-content-styles';
import { V5_STYLES } from './v5-styles';

export { V3_GROUPS } from './v3-content-styles';
export { V4_GROUPS } from './v4-content-styles';
export { V5_GROUPS } from './v5-styles';

const ENVIRONMENT = environment('REACT_APP_ENVIRONMENT') || 'production';

const ImageThumbnail = (image, props) => () => <img src={image} {...props} />;

export const NoneIcon = () => (
  <div className='flex items-center justify-center w-[67px] h-[67px] '>
    {' '}
    <CancelIcon className='' />
  </div>
);

const STYLES = {
	// V5
  'v5/fx': V5_STYLES,
  'v5/style-transfer': V4_STYLES,
  // V4
  'v4/waterfall': V4_STYLES,
  'v4/xl': V4_STYLES,
  'v4/bolt': V4_STYLES,
  'v4/style-transfer': V4_STYLES,
  // V3
  'v3/2d': V3_STYLES,
  // V2
  'sdxl-3d': [
    {
      id: '3d',
      name: '3D',
      icon: ImageThumbnail(ThSDXL3D),
    },
    {
      id: 'embroidered',
      name: 'Embroidered',
      icon: ImageThumbnail(ThSDXLEmbroidered),
    },
    {
      id: 'inflated',
      name: 'Inflated',
      icon: ImageThumbnail(ThSDXLInflated),
    },
    {
      id: 'isometric',
      name: 'Isometric',
      icon: ImageThumbnail(ThSDXLIsometric),
    },
    {
      id: 'craft-clay',
      name: 'Craft Clay',
      icon: ImageThumbnail(ThSDXLClayCraft),
    },
    {
      id: 'origami',
      name: 'Origami',
      icon: ImageThumbnail(ThSDXLOrigami),
    },
    {
      id: 'cinematic',
      name: 'Cinematic',
      icon: ImageThumbnail(ThSDXLCinematic),
    },
    {
      id: 'digital-art',
      name: 'Digital art',
      icon: ImageThumbnail(ThSDXLDigitalArt),
    },
    {
      id: 'fantasy-art',
      name: 'Fantasy art',
      icon: ImageThumbnail(ThSDXLFantasyArt),
    },
    {
      id: 'neopunk',
      name: 'Neopunk',
      icon: ImageThumbnail(ThSDXLNeopunk),
    },
    {
      id: 'low-poly',
      name: 'Low Poly',
      icon: ImageThumbnail(ThSDXLLowPoly),
    },
    {
      id: 'papercraft-thick-layered',
      name: 'Papercraft',
      icon: ImageThumbnail(ThSDXLPapercraft),
    },
    {
      id: 'dark-arts',
      name: 'Dark Arts',
      icon: ImageThumbnail(ThSDXLDarkArts),
    },
    {
      id: 'futuristic',
      name: 'Futuristic',
      icon: ImageThumbnail(ThSDXLFuturistic),
    },
    {
      id: 'none',
      name: 'None',
      icon: () => (
        <div className='flex items-center justify-center w-[67px] h-[67px] '>
          {' '}
          <CancelIcon className='' />
        </div>
      ),
    },
  ],
  sdxl: [
    {
      id: 'pictorial',
      name: 'Pictorial',
      icon: PictorialStyle,
    },
    {
      id: 'mascot',
      name: 'Mascot',
      icon: MascotStyle,
    },
    {
      id: 'badge',
      name: 'Badge/Crest',
      icon: BadgeStyle,
    },
    {
      id: 'cartoon',
      name: 'Cartoon',
      icon: CartoonStyle,
    },
    {
      id: 'icon',
      name: 'Icon/Emoji',
      icon: IconStyle,
    },
    {
      id: 'abstract',
      name: 'Abstract',
      icon: AbstractStyle,
    },
    {
      id: 'line-art',
      name: 'Line Art',
      icon: ThSDXLLineArt,
      icon_classes: 'p-2',
      icon_preview_classes: 'p-1',
    },
    {
      id: 'logo-type',
      name: 'Logo Type',
      icon: ImageThumbnail(ThSDXLLogoType, { className: 'my-auto pb-2 px-2' }),
      icon_preview: ImageThumbnail(ThSDXLLogoType, {
        className: 'my-auto px-1',
      }),
      icon_preview_classes: '!p-1',
      beta: true,
    },
    {
      id: 'monogram',
      name: 'Monogram',
      icon: ThSDXLMonogram,
      icon_classes: 'p-3',
      icon_preview_classes: 'p-1',
      beta: true,
    },

    {
      id: 'art-styles-start',
      type: 'separator',
      title: '2D Art Styles',
      description: '2D Art Styles',
    },

    {
      id: 'pixel-art',
      name: 'Pixel Art',
      icon: ImageThumbnail(ThSDXLPixelArt),
    },
    /* {
			id: 'line-art',
			name: 'Line Art',
			icon: ImageThumbnail(ThSDXLLineArt),
		}, */
    {
      id: 'comic-art',
      name: 'Comic Art',
      icon: ImageThumbnail(ThSDXLComicArt),
    },
    {
      id: 'flat-graphic',
      name: 'Flat Graphic',
      icon: ImageThumbnail(ThSDXLFlatGraphic),
    },
    {
      id: 'manga',
      name: 'Manga',
      icon: ImageThumbnail(ThSDXLmanga),
    },
    {
      id: 'kawaii',
      name: 'Kawaii',
      icon: ImageThumbnail(ThSDXLKawaii),
    },
    {
      id: 'watercolor',
      name: 'Watercolor',
      icon: ImageThumbnail(ThSDXLWatercolor),
    },
    {
      id: 'pop-art',
      name: 'Pop Art',
      icon: ImageThumbnail(ThSDXLPopArt),
    },

    {
      id: 'none',
      name: 'None',
      icon: () => (
        <div className='flex items-center justify-center w-[67px] h-[67px] '>
          {' '}
          <CancelIcon className='' />
        </div>
      ),
    },
  ],

  // V1
  redesign: [
    {
      id: 'pictorial',
      name: 'Pictorial',
      icon: PictorialStyle,
    },
    {
      id: 'mascot',
      name: 'Mascot',
      icon: MascotStyle,
    },
    {
      id: 'badge',
      name: 'Badge/Crest',
      icon: BadgeStyle,
    },
    {
      id: 'cartoon',
      name: 'Cartoon',
      icon: CartoonStyle,
    },
    {
      id: 'icon',
      name: 'Icon/Emoji',
      icon: IconStyle,
    },
    {
      id: 'abstract',
      name: 'Abstract',
      icon: AbstractStyle,
      beta: true,
    },
  ],
  ddd: [
    {
      id: 'none',
      name: '3D',
      icon: ImageThumbnail(ThSDXL3D),
    },
  ],
};
export default STYLES;
