import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { partialUpdateStatePayload } from "../../../../reducers/formReducer"
import DevInput from "../../v4/dev/DevInput";
import DevTextArea from "../../v4/dev/DevTextArea";
import Dropdown from "../../../base/Dropdown";
import EnhancementModal from "./enhance_modal";

const FxSettings = () => {
	const dispatch = useDispatch()

	const enhanceSystemPrompt = useSelector((state) => state.form.payload.enhance_system_prompt)

	const handleFieldUpdate = (key) => (val) => {
		dispatch(partialUpdateStatePayload({
			[key]: val
		}))
	}

	const [enhanceModal, setEnhanceModal] = useState(false)

	const enhanceModel = useSelector((state) => state.form.payload.enhance_model)

	return (
		<>
			<div className="text-gray-label font-poppins font-bold">
				<span className="text-sm mr-2">Model:{" "}</span>
				<span className="font-bold text-base">Fx{" "}</span>
				<span className="text-xs text-app-green">(/v5/fx)</span>
			</div>
			<button className="cursor-pointer focus:outline-none overflow-hidden rounded-lg bg-app-bg-gray hover:bg-opacity-40 text-white px-2 py-1 " onClick={() => setEnhanceModal(true)}>
				Enhancement Settings
			</button>
			<EnhancementModal open={enhanceModal} setOpen={setEnhanceModal} />
		</>
	)
}

export default FxSettings;
