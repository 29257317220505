import { useUsermaven } from "@usermaven/react"
import environment from "../helpers/envProvider"

const myId = () => {}
const myTrack = () => {}

const env = environment("REACT_APP_ENVIRONMENT") || "local"
const useUsermavenWrapper = env === 'production' ? useUsermaven : () => {

	return {
		id: myId,
		track: myTrack,
	}
}

export default useUsermavenWrapper;
