import { Dialog, Transition } from "@headlessui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
// import Carousel from "../base/Carousel";
import { toast } from "react-toastify";
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as CloseIcon } from "../../assets/CloseIcon.svg";
// import { ReactComponent as ChevronDown } from "../../assets/ChevronDownLightGray.svg";
// import { ReactComponent as CheckmarkIcon } from "../../assets/CheckmarkIconGray.svg";
import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { QuestionnairePages } from "../../data/questionnaire";
import {
  loadQuestionnaireAnswers,
  submitQuestionnaireAnswers,
  updateShowQuestionnaireModal,
} from "../../reducers/userReducer";
// import { start } from "@popperjs/core";
import { FormControlLabel, TextField, Checkbox } from "@material-ui/core";
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as QuestionnaireIcon } from '../../assets/questionnaireModalBackground.svg';
// Custom styled checkbox
const CircleCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: '#3DD598', // Color when checked
  },
  '& .MuiSvgIcon-root': {
    borderRadius: '50%', // Make the checkbox circular
    border: '2px solid white', // Border color
    width: 24, // Adjust width of the checkbox
    height: 24, // Adjust height of the checkbox
  },
  '&.Mui-checked .MuiSvgIcon-root': {
    backgroundColor: '#3DD598', // Background color when checked
    border: '2px solid #3DD598', // Border color when checked
  },
}));

const QuestionnaireModal = (props) => {
  const user = useSelector((state) => state.userSlice.user);
  const [loaded, setLoaded] = useState(false);
  const openQuestion = useSelector((state) => state.userSlice.showQuestionnaireModal);
  const questionnaireLoading = useSelector(state => state.userSlice.isLoading)
  const open = useMemo(() => !openQuestion && !questionnaireLoading, [openQuestion, questionnaireLoading]);
  const [index, setIndex] = useState(0);
  const [values, setValues] = useState({});
  const [error, setError] = useState();
  const [errorField, setErrorField] = useState();
  const [hideModal, setHideModal] = useState(
    localStorage.getItem("hideQuestionnaireModal") === "true"
  );
  const [occupation, setOccupation] = useState('');
  const [termChecked, setTermChecked] = useState('');
  const [productChecked, setProductChecked] = useState('checked');
  const inputRef = useRef();

  const currentQuestion = useMemo(() => QuestionnairePages[index], [index]);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(updateShowQuestionnaireModal(false));
    if (hideModal) localStorage.setItem("hideQuestionnaireModal", "true");
  };

  const loadPreviousAnswers = async () => {
    try {
      await dispatch(loadQuestionnaireAnswers()).unwrap();
      setLoaded(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event) => {
    setOccupation(event.target.value);
  };

  const checkChange = (event) => {
    if (event.target.id === 'terms-and-conditions-checkbox') {
      if(termChecked === 'checked') {
        setTermChecked('');
      } else {
        setTermChecked('checked');
      }
    }
    if (event.target.id === 'product-updates-checkbox') {
      if(productChecked === 'checked') {
        setProductChecked('');
      } else {
          setProductChecked('checked');
      } 
    }
  }

  const next = async () => {
    if (!values[currentQuestion.key] && occupation === '') {
      setErrorField('Please select an option and enter occupation to continue.');
      return;
    } else if (!values[currentQuestion.key]) {
      setError("Please select an option to continue.");
      return;
    } else if(occupation === '') {
      setErrorField("Please enter occupation to continue.");
      return;
    }
    setError();
    setErrorField();
    let res = {
      ...values,
    };
    if (res[currentQuestion.key] === "-1")
      res[currentQuestion.key] = inputRef.current.value;
    if (index + 1 < QuestionnairePages.length) {
      setIndex((ind) => ind + 1);
      // setInputVal('');
      setValues(res);
      let nextQuestion = QuestionnairePages[index + 1];
      let nextAnswer = res[nextQuestion.key];
      if (
        nextAnswer &&
        nextQuestion.options.map((item) => item.title).indexOf(nextAnswer) < 0
      ) {
        setValues({
          ...res,
          [nextQuestion.key]: "-1",
        });

        inputRef.current.value = nextAnswer;
      } else {
        setValues(res);
        inputRef.current.value = "";
      }
    } else {
      console.log('else!!!!!')
      try {
        await dispatch(
          submitQuestionnaireAnswers({
            ...res,
          })
        ).unwrap();
        onClose();
      } catch (e) {
        console.log(e);
        toast("Failed to submit the answers", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          autoClose: 2000,
        });
      }
    }
  };

  const prev = async () => {
    if (user) setError();
    let res = {
      ...values,
    };
    if (res[currentQuestion.key] === "-1")
      res[currentQuestion.key] = inputRef.current.value;
    if (index > 0) {
      setIndex((ind) => ind - 1);
      // setInputVal('');
      let previousQuestion = QuestionnairePages[index - 1];
      let previousAnswer = res[previousQuestion.key];
      if (
        previousAnswer &&
        previousQuestion.options
          .map((item) => item.title)
          .indexOf(previousAnswer) < 0
      ) {
        setValues({
          ...res,
          [previousQuestion.key]: "-1",
        });

        inputRef.current.value = previousAnswer;
      } else {
        setValues(res);
        inputRef.current.value = "";
      }
    }
  };

  useEffect(() => {
    if (
      user &&
      !loaded &&
      localStorage.getItem("hideQuestionnaireModal") !== "true"
    )
      loadPreviousAnswers();
  }, [user, loaded]);

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog open={open} onClose={onClose} className="relative z-50">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto custom-scroll">
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="flex max-w-11/12 flex gap-6 justify-around overflow-hidden relative bg-app-black rounded-md w-[100%] items-center"
                onClick={(e) => e.stopPropagation()}
                style={{height: window.innerWidth < 1696 ? 'auto' : '100vh'}}
              >
                <div className="w-[100%] col-md-6 col-lg-6 gap-20 align-center pr-20 pl-20 pb-6 pt-6">
                  <div className='font-bold text-xl text-white flex gap-2 top-4 left-3 top-3 absolute'>
                    <Logo className='mr-[3px]' />
                    <span className='hidden lg:flex mr-[5px] font-poppins font-black'>Logo Diffusion</span>
                  </div>
                  <div className="flex flex-row items-center gap-10 pt-20">
                    <h1 className="text-white text-4xl font-bold">
                      {currentQuestion.title}
                    </h1>
                    <span className="text-carousel-next-count mr-1 rounded-md" style={{border: '1px solid #3DD598', color: '#3DD598', width: '50px', display: 'flex', justifyContent: 'center'}}>
                      ({index + 1}/{QuestionnairePages.length})
                    </span>
                  </div>

                  <RadioGroup
                    className="grid grid-cols-2 grid-rows-2 gap-4 pt-10"
                    value={values[currentQuestion.key] || ""}
                    onChange={(val) => {
                      if (val === "-1") {
                        inputRef.current.focus();
                      }
                      setValues((old) => ({
                        ...old,
                        [currentQuestion.key]: val,
                      }));
                      // console.log('u', inputRef.current, val, inputVal)
                    }}
                  >
                    {currentQuestion.options.map((resp) => {
                      const Icon = resp.icon;
                      return (
                        <RadioGroup.Option
                          className={`p-6 flex flex-col gap-4 rounded-md bg-app-bg-gray ui-checked:outline-[2px] ui-checked:[outline-style:solid] ui-checked:outline-app-green transition-all duration-300 cursor-pointer`}
                          key={resp.title}
                          value={resp.title}
                        >
                          <Icon className="" />
                          <span className="text-white text-base">
                            {resp.title}
                          </span>
                          <span className="w-11/12 text-sm text-modal-description">
                            {resp.subtitle}
                          </span>
                        </RadioGroup.Option>
                      );
                    })}
                    {/* <RadioGroup.Option
                      className={`p-6 flex flex-col gap-4 rounded-md bg-app-bg-gray ui-checked:outline-[2px] ui-checked:[outline-style:solid] ui-checked:outline-app-green transition-all duration-300 cursor-pointer`}
                      value={"Other"}
                      key="Other"
                    >
                      <OtherIcon className="" />
                      <span className="text-white text-base">Other</span>
                    </RadioGroup.Option> */}
                  </RadioGroup>

                  <span className="text-red-600">{error}</span>

                  {index <= 0 ? <div className="pt-10"><TextField
                    id="occupation" 
                    label="Enter your occupation" 
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      style: { color: 'white', border: '1px solid white' },
                    }}
                    type="text"
                    value={occupation}
                    onChange={handleChange}
                  /></div> : ''}
                  {index === 2 ? <div className="pt-10 flex-col" style={{display: 'flex'}}>
                    <FormControlLabel
                      control={
                        <CircleCheckbox
                          id="terms-and-conditions-checkbox"
                          value="terms"
                          checked={termChecked}
                          onChange={checkChange}
                          icon={<span style={{ borderRadius: '50%', width: '24px', height: '24px', border: '2px solid white' }} />} // Custom unchecked icon
                          checkedIcon={<span style={{ borderRadius: '50%', width: '24px', height: '24px', backgroundColor: '#3DD598',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CheckIcon style={{ color: 'white' }} />
                          </span>} // Custom checked icon
                        />
                      }
                      label="I have read and agree to the Terms and Conditions and Privacy Policy."
                      style={{ fontSize: '40px', color: 'white' }}
                    />
                    <FormControlLabel
                      control={
                        <CircleCheckbox
                          id="product-updates-checkbox"
                          value="product"
                          checked={productChecked}
                          onChange={checkChange}
                          icon={<span style={{ borderRadius: '50%', width: '24px', height: '24px', border: '2px solid white' }} />} // Custom unchecked icon
                          checkedIcon={<span style={{ borderRadius: '50%', width: '24px', height: '24px', backgroundColor: '#3DD598',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CheckIcon style={{ color: 'white' }} />
                          </span>} // Custom checked icon
                        />
                      }
                      label="I'd like to receive product updates, news, and marketing communications from Logo Diffusion."
                      style={{ fontSize: '40px', color: 'white' }}
                    />
                  </div> : ''}

                  {index === 0 || index ===2 ? <span className="text-red-600">{errorField}</span> : ''}

                  <div className="flex flex-row gap-2 justify-around pt-20">
                    {index <= 0 ? <div></div> : <button
                      className="border-white h-12 pt-4 pb-4 pr-8 pl-8 rounded-md border border-solid border-carousel-button-border group flex gap-1 items-center justify-center transition-all duration-300 [&_path]:transition-all [&_path]:duration-300 group disabled:cursor-not-allowed"
                      disabled={index <= 0}
                      onClick={prev}
                    >
                      <span className="text-white group-disabled:text-carousel-next-count">
                        Previous
                      </span>
                    </button>}
                    <button
                      className="h-12 pt-4 pb-4 pr-8 pl-8 border border-solid border-carousel-button-border bg-app-bg-gray rounded-md group flex gap-1 items-center justify-center transition-all duration-300 [&_path]:transition-all [&_path]:duration-300 group disabled:cursor-not-allowed bg-white"
                      // disabled={values[currentQuestion.key] === undefined}
                      onClick={next}
                    >
                      <span className="text-black group-disabled:text-carousel-next-count">
                        Continue
                      </span>
                    </button>
                  </div>
                </div>
                <div className="w-[100%] col-md-6 col-lg-6">
                  <button
                    className="w-8 aspect-square rounded-full absolute top-2 right-3 bg-black bg-opacity-60 hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center"
                    onClick={onClose}
                  >
                    <CloseIcon className="w-6 h-6" />
                  </button>
                  <QuestionnaireIcon />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default QuestionnaireModal;