import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { relogUser } from "../reducers/userReducer";
import useUsermavenWrapper from "../hooks/useUsermavenWrapper";

function ProtectedRoute(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
	const location = useLocation();
	const { id } = useUsermavenWrapper();

  const user = useSelector(state => state.userSlice.user);
	const isLoggedOut = useSelector(state => state.userSlice.isLoggedOut);
	const isLoading = useSelector(state => state.userSlice.isLoading);

	const [userLoaded, setUserLoaded] = useState(false) ;

  useEffect(() => {
		// if (isLoading) return;
		if(isLoggedOut) {
			navigate(`/login${location.search}`);
			return ;
		}

		if (userLoaded) return;

		// if(!user) {
			dispatch(relogUser()).unwrap().then((res) => {
				console.log('res =>> productedRoute =>>>>> ', res);
				setUserLoaded(true)
				id({
					id: res._id,
					email: res.email,
					created_at: res.createdAt,

					first_name: res.name,

					custom: {
						subscription_type: res.subscriptionProvider,
						stripe_subscription_price: res.plan?.productId,
					},

				})
				if (!res?.tos_accepted) {
					navigate("/questionnaire");
				}
			}).catch(e => navigate(`/login${location.search}`));
	}, [isLoggedOut, navigate, dispatch, location.search, userLoaded, id]);

  return props.children;
}

export default ProtectedRoute;
