import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyle from "../../../hooks/useStyle";
import { randomize_config_item } from '../style-config/constants';
import useGenerationEndpoint from "../useGenerationEndpoint";
import { twMerge } from "tailwind-merge";
import usePromptStatus from "../usePromptStatus";
import TextAnimationWrapper from "./TextAnimationWrapper";

const TextConfig = (props) => {
  const { config_metadata, value, onChange, label } = props;

	const { method, mode } = useGenerationEndpoint();

  const isDisabled = useMemo(
    () => (value === undefined ? false : !value.enabled),
    [value]
  );
  const isEnhanced = useMemo(
    () => (value === undefined ? true : value.__enhance),
    [value]
  );
  const config_value = useMemo(
    () => value?.value || (config_metadata?.values || [])[0]?.id,
    [value, config_metadata]
  );
  const colors = useMemo(
    () => (value === undefined ? [] : value.colors),
    [value]
  );

  const style = useStyle();

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const handleChange = (v) => {
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: true,
      value: v,
      colors,
    });
  };

  const handleDisable = (v) => {
    if (!isDevModeEnabled && config_metadata.always_enabled) return;
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: v,
      colors,
      value: config_value,
    });
  };

  const handleRandomize = (v) => {
		console.log(method, style, config_metadata.id, value)
    onChange({
      ...value,
      ...randomize_config_item(method, style, config_metadata.id, value),
      enabled: true,
      __randomized: true,
    });
  };

  const handleEnhance = (v) => {
		console.log(method, style, config_metadata.id, value)
    onChange({
      ...value,
      // ...randomize_config_item(method, style, config_metadata.id, value),
      enabled: true,
      __randomized: false,
      __enhance: v,
    });
  };

	const { isIdle, isAnimating } = usePromptStatus()

	if (isAnimating) 
		return (
			<TextAnimationWrapper>{config_value || label || 'Company Name'}{' '}</TextAnimationWrapper>
		)

	return (
		<span className="inline-flex relative text-xs font-inter items-center py-1 mr-1 h-6" onClick={e => e.stopPropagation()}>
			<span className={`px-2 ${!isIdle ? '' : 'opacity-0'} `}>{config_value || label || 'Company Name'}</span>
			{isIdle && (<input className="absolute inset-0 text-xs font-inter gap-1 items-center rounded-md py-1 px-2 duration-300 text-app-green border-none outline-none focus:bg-app-green focus:text-tag-text focus:hover-app-green hover:outline-2 hover:bg-app-green/30 bg-v5-prompt-handle/20 "
							disabled={!isIdle}
							value={config_value} 
							placeholder={config_metadata.placeholder || "Company Name"}
							onChange={e => handleChange(e.target.value)}
						/>)}
		</span>
	)
}

export default TextConfig;
