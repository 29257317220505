import { Popover, RadioGroup, Transition } from '@headlessui/react';
import { ReactComponent as CaretUp } from '../../assets/caret-up.svg';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { ReactComponent as ExcludeIcon } from '../../assets/styles/exclude.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateNegativePrompt,
  updateShowStyleSelector,
} from '../../reducers/formReducer';
import { useWindowDimensions } from '../../hooks';
import { breakpoints } from '../../constant/device';

const NegativePrompt = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'top',
    strategy: 'fixed',
  });

  const negativePrompt = useSelector(
    (state) => state.form.payload.negativePrompt
  );

  const handleTextAreaChange = (event) => {
    // setNegativePromptValue(event.target.value);
    dispatch(updateNegativePrompt(event.target.value));
  };

  const handleClear = () => {
    dispatch(updateNegativePrompt(''));
  };
  const handleDone = (event) => {
    event.preventDefault();
  };

  return (
    <Popover as={React.Fragment}>
      {({ open, close }) => (
        <>
          {props?.isMobileButton ? (
            <Popover.Button
              className={`grow relative flex justify-center items-center cursor-pointer focus:outline-none overflow-hidden rounded-[4px] lg:rounded-[8px] bg-app-bg-gray lg:bg-overlay-bg  w-[27px] h-[27px] gap-1 px-1 ${
                negativePrompt && negativePrompt.length > 0
                  ? 'text-white/90'
                  : ''
              } text-xs`}
              ref={setAnchorEl}
            >
              <ExcludeIcon
                className={`w-[17px] h-[17px] ${
                  negativePrompt && negativePrompt.length > 0
                    ? '[&_path]:fill-app-green [&_path]:stroke-app-green'
                    : ''
                }`}
              />
            </Popover.Button>
          ) : (
            <Popover.Button
              className={`grow relative flex justify-center items-center cursor-pointer focus:outline-none overflow-hidden rounded-[8px] bg-app-bg-gray md:bg-overlay-bg  h-7 gap-1 px-1 ${
                negativePrompt && negativePrompt.length > 0
                  ? 'text-white/90'
                  : ''
              } text-xs`}
              ref={setAnchorEl}
            >
              <ExcludeIcon
                className={`${
                  negativePrompt && negativePrompt.length > 0
                    ? '[&_path]:fill-app-green [&_path]:stroke-app-green'
                    : ''
                }`}
              />
              Exclude
            </Popover.Button>
          )}

          <Transition
            as={React.Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
            {...props}
          >
            <Popover.Panel
              className='border border-solid border-app-search-gray rounded-xl bg-app-bg-gray mb-4 py-2 flex flex-col w-[250px] h-[188px] overflow-hidden relative'
              ref={setPopperEl}
              style={styles.popper}
              {...attributes.popper}
            >
              <div className='relative flex w-full grow p-2'>
                <textarea
                  className='resize-none focus-within:outline-none focus-within:placeholder:text-white/30 border-none rounded-[10px] p-2 w-full h-auto bg-app-bg-gray'
                  name='negative prompt value'
                  cols='30'
                  rows='4'
                  value={negativePrompt}
                  placeholder='Describe what you want to exclude from your designs.'
                  onChange={handleTextAreaChange}
                />
              </div>
              <div className='flex w-full px-3 items-center justify-between'>
                <button
                  type='reset'
                  className='py-2 basis-24 border border-[#2A2931] text-sm font-bold text-[#A4A4A7] rounded-md'
                  onClick={handleClear}
                >
                  Clear
                </button>
                <button
                  type='submit'
                  className='py-2 basis-24 bg-button-purple text-white text-sm font-bold rounded-md'
                  onClick={(e) => {
                    handleDone(e);
                    close();
                  }}
                >
                  Done
                </button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default NegativePrompt;
