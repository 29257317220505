import { RadioGroup } from '@headlessui/react';
import React, { useMemo, useState } from 'react';
import SDColorSelector from '../../base/SDColorSelector';
import { ReactComponent as RandomizeIcon } from '../../../assets/styles/style-config/randomize.svg';
import { ReactComponent as DisableIcon } from '../../../assets/styles/style-config/disable.svg';
import { ReactComponent as EnhanceIcon } from '../../../assets/RandomizeIcon.svg';
import { ReactComponent as FreezeIcon } from '../../../assets/FreezeIcon.svg';
import { randomize_config_item } from './constants';
import { useSelector } from 'react-redux';
import Dropdown from '../../../components/base/Dropdown';
import useGenerationEndpoint from '../useGenerationEndpoint';
import useStyle from '../../../hooks/useStyle';

const Config = (props) => {
  const { config_metadata, value, onChange, label } = props;

	const { method } = useGenerationEndpoint();

  const isDisabled = useMemo(
    () => (value === undefined ? false : !value.enabled),
    [value]
  );
  const isRandomized = useMemo(
    () => (value === undefined ? true : value.__randomized),
    [value]
  );
  const isEnhanced = useMemo(
    () => (value === undefined ? true : value.__enhance),
    [value]
  );
  const colors = useMemo(
    () => (value === undefined ? [] : value.colors),
    [value]
  );
  const radio_value = useMemo(
    () => value?.value || (config_metadata?.values || [])[0]?.id,
    [value, config_metadata]
  );
  const checkbox_values = useMemo(() => value?.values || [], [value]);

  const style = useStyle();

  const configOptions = useMemo(() => {
    return config_metadata.values?.map((e) => {
      return { ...e, value: e.id };
    });
  }, [config_metadata]);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const handleRadioSelect = (v) => {
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: true,
      value: v,
      colors,
    });
  };
  // const handleDropdownSelect = (value) => {
  //   setSelectedValue(value);
  // };
  const handleCheckbox = (v) => {
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: true,
      values:
        checkbox_values.indexOf(v.id) > -1
          ? checkbox_values.filter((item) => item !== v.id)
          : [...checkbox_values, v.id],
    });
  };
  const handleColorSelect = (ind, v) => {
    const new_colors = [...colors];
    new_colors[ind] = v;
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: true,
      colors: new_colors,
    });
  };

  const handleDisable = (v) => {
    if (!isDevModeEnabled && config_metadata.always_enabled) return;
    onChange({
      ...value,
      __randomized: false,
      __enhance: false,
      enabled: v,
      colors,
      value: radio_value,
    });
  };

  const handleRandomize = (v) => {
		console.log(method, style, config_metadata.id, value)
    onChange({
      ...value,
      ...randomize_config_item(method, style, config_metadata.id, value),
      enabled: true,
      __randomized: true,
    });
  };

  const handleEnhance = (v) => {
		console.log(method, style, config_metadata.id, value)
    onChange({
      ...value,
      ...randomize_config_item(method, style, config_metadata.id, value),
      enabled: true,
      __randomized: false,
      __enhance: v,
    });
  };


  const { mode } = useGenerationEndpoint();

  return (
    <div className='flex items-center justify-between gap-2'>
      <div className='flex items-center gap-2 flex-wrap w-full'>
        {configOptions &&
          configOptions.length > 0 &&
          (mode >= 'v4.0' ? (
            <Dropdown
              values={configOptions}
              value={radio_value}
              onChange={handleRadioSelect}
							disabled={isDisabled}
              appliedClasses={{
                root: 'w-full flex-wrap gap-2',
                buttonCnt: '!max-w-[100%] ',
                button:
                  '!rounded-[4px] border-none bg-app-bg-gray h-[40px] !text-icon-text-color',
								label: 'w-full',
              }}
							label={label}
							labelAppend={
								<div className='flex grow items-center justify-end gap-2'>
									{(isDevModeEnabled || !config_metadata.always_enabled) && (
										<button
											role='checkbox'
											aria-checked={isDisabled}
											type='button'
											onClick={() => handleDisable(isDisabled)}
											className='[&_path]:checked:fill-app-green'
										>
											<DisableIcon
												className={` ${
													isDisabled
														? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/70'
														: 'group-hover:[&_path]:fill-app-green/30'
												}`}
											/>
										</button>
									)}
									{mode >= 'v5.0' ? (
										<>
											<button
												role='checkbox'
												aria-checked={!isEnhanced && !isDisabled}
												type='button'
												onClick={() => handleEnhance(false)}
												className='checked:[&_path]:fill-app-green'
											>
												<FreezeIcon
													className={` ${
														!isEnhanced && !isDisabled
															? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/70'
															: 'group-hover:[&_path]:fill-app-green/30'
													}`}
												/>
											</button>
											<button
												role='checkbox'
												aria-checked={isEnhanced}
												type='button'
												onClick={() => handleEnhance(true)}
												className='checked:[&_path]:fill-app-green'
											>
												<EnhanceIcon
													className={` ${
														isEnhanced
															? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/70'
															: 'group-hover:[&_path]:fill-app-green/30'
													}`}
												/>
											</button>
										</>
									) : (
										<button
											role='checkbox'
											aria-checked={isRandomized}
											type='button'
											onClick={() => handleRandomize(true)}
											className='checked:[&_path]:fill-app-green'
										>
											<RandomizeIcon
												className={` ${
													isRandomized
														? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/70'
														: 'group-hover:[&_path]:fill-app-green/30'
												}`}
											/>
										</button>
									)}
								</div>
							}
            />
          ) : (
            <RadioGroup
              className='flex flex-wrap gap-2'
              value={radio_value}
              onChange={handleRadioSelect}
            >
              {config_metadata.values.map((item) => (
                <RadioGroup.Option
                  key={item.id}
                  value={item.id}
                  className={`py-[0.125rem] px-2 rounded-md text-deactive bg-divider font-roboto ${
                    isDisabled
                      ? 'text-select-label-disabled ui-checked:bg-gray-label'
                      : 'ui-checked:bg-app-green ui-checked:text-tag-text ui-checked:font-bold cursor-pointer'
                  } flex items-center justify-center`}
                >
                  <RadioGroup.Label
                    className={`cursor-pointer whitespace-nowrap`}
                  >
                    {item.name}
                  </RadioGroup.Label>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          ))}

        {config_metadata.checkboxes &&
          config_metadata.checkboxes.length > 0 &&
          config_metadata.checkboxes.map((item) => (
            <button
              role='checkbox'
              type='button'
              key={item.id}
              value={item.id}
              aria-checked={checkbox_values.indexOf(item.id) > -1}
              className={`${
                isDisabled
                  ? checkbox_values.indexOf(item.id) > -1
                    ? 'font-bold cursor-not-allowed text-select-label-disabled bg-gray-label'
                    : 'cursor-not-allowed text-select-label-disabled bg-divider'
                  : checkbox_values.indexOf(item.id) > -1
                  ? 'bg-app-green text-tag-text font-bold'
                  : 'bg-divider '
              } py-[0.125rem] px-2 rounded-md text-deactive font-roboto  ui-disabled:text-select-label-disabled ui-disabled:ui-checked:bg-gray-label whitespace-nowrap`}
              disabled={isDisabled}
              onClick={() => handleCheckbox(item)}
            >
              {item.name}
            </button>
          ))}

        {config_metadata.colors &&
          config_metadata.colors > 0 &&
          [...Array(colors)].map((_, ind) => (
            <SDColorSelector
              color={colors[ind]}
              setColor={(val) => handleColorSelect(ind, val)}
              key={ind}
              disabled={isDisabled}
            />
          ))}
      </div>

			{mode < 'v4.0' && (<div className='flex gap-2'>
        {(isDevModeEnabled || !config_metadata.always_enabled) && (
          <button
            role='checkbox'
            aria-checked={isDisabled}
            type='button'
            onClick={() => handleDisable(isDisabled)}
            className='[&_path]:checked:fill-app-green'
          >
            <DisableIcon
              className={` ${
                isDisabled
                  ? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/70'
                  : 'group-hover:[&_path]:fill-app-green/30'
              }`}
            />
          </button>
        )}
        <button
          role='checkbox'
          aria-checked={isRandomized}
          type='button'
          onClick={() => handleRandomize(true)}
          className='checked:[&_path]:fill-app-green'
        >
          <RandomizeIcon
            className={` ${
              isRandomized
                ? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/70'
                : 'group-hover:[&_path]:fill-app-green/30'
            }`}
          />
        </button>
      </div>)}
    </div>
  );
};

export default Config;
